import { getMessages as getFilterMessages } from "@ignite-analytics/filters";
import { memoize } from "@ignite-analytics/general-tools";
import { getMessages as getNotifMessages } from "@ignite-analytics/notifications";
import { getMessages as getChartMessages } from "@ignite-analytics/pivot-charts";

import localeDataDeDE from "./de-DE.json";
import localeDataEnUS from "./en-US.json";
import localeDataNbNO from "./nb-NO.json";

const getProjectMessages = (locale: string) => {
    switch (locale) {
        case "en-US":
            return localeDataEnUS;
        case "nb-NO":
            return localeDataNbNO;
        case "de-DE":
            return localeDataDeDE;
        default:
            return localeDataEnUS;
    }
};

export const getMessages = memoize((locale: string) => ({
    ...getProjectMessages(locale),
    ...getNotifMessages(locale),
    ...getFilterMessages(locale),
    ...getChartMessages(locale),
}));
