import { defineMessages } from "react-intl";

const messages = defineMessages({
    value: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.value",
        defaultMessage: "Value",
    },
    constantNumber: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.constantNumber",
        defaultMessage: "Constant number",
    },
    date: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.date",
        defaultMessage: "Constant date",
    },
    lessThan: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.lessThan",
        defaultMessage: "Less than",
    },
    lessThanOrEqual: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.lessThanOrEqual",
        defaultMessage: "Less than or equal",
    },
    greaterThan: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.greaterThan",
        defaultMessage: "Greater than",
    },
    greaterThanOrEqual: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.greaterThanOrEqual",
        defaultMessage: "Greater than or equal",
    },
    equals: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.equals",
        defaultMessage: "Equals",
    },
    notEqual: {
        id: "customAnalysis.SplitField.AggregatedFilterItem.operatorOptions.notEqual",
        defaultMessage: "Not equal",
    },
});

export default messages;
