import { Filter } from "@ignite-analytics/filters";
import { Stringish } from "@ignite-analytics/general-tools";
import { ChartConfig, ChartData, Chart as StandardChart, getValidAggOption } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { useElasticFieldsForFilterContextProvider } from "@/hoc/withFilterContext";
import { useExportData } from "@/hooks/useExportData";
import { useColors } from "@/lib/colors";

interface Props {
    title: Stringish;
    subTitle?: string;
    config: ChartConfig;
    data: ChartData | undefined;
    hideTitle?: boolean;
    analysisQuery?: AnalysisQuery;
    disableRefresh?: boolean;
    /** Callback to handle updates to query when drilling down. Pass `undefined` if not possible to support. */
    onDrilldown: ((newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => void) | undefined;
    /** Callback to handle saving of drilldown. Pass `undefined` if not possible to support. */
    onSaveDrilldown: ((saveAsNew: boolean) => Promise<void>) | undefined;
}

/**
 * This component wraps the Chart component from charts package and provides required props based on the contexts
 * in this app.
 */
const ChartWithWebContext: React.FC<Props> = (props) => {
    const { config, analysisQuery, title } = props;
    const chartType = getValidAggOption(config)?.type;
    const exportFn = useExportData(title?.toString());
    const navigate = useNavigate();
    const trackingFn = useCallback(
        (eventName: string, additionalProperties?: Record<string, unknown>) => {
            track(eventName, {
                chartType,
                title,
                config,
                ...additionalProperties,
            });
        },
        [chartType, config, title]
    );

    const handleOnShowData = useCallback(
        (elasticIndex: string, filters: Filter[]) => {
            navigate(`./data-list-modal/${elasticIndex}/`, { state: { filters } });
        },
        [navigate]
    );

    return (
        <StandardChart
            {...props}
            onShowData={handleOnShowData}
            colors={useColors()}
            trackingFn={trackingFn}
            analysisQuery={analysisQuery}
            dispatchGlobalFilterAction={undefined}
            globalFilterIndex={undefined}
            globalFilters={undefined}
            exportFn={exportFn}
            useDataFields={useElasticFieldsForFilterContextProvider}
        />
    );
};

export default ChartWithWebContext;
