import { ScriptedMetricRelation, SplitItem, ValueAggregationItem } from "@ignite-analytics/pivot-ts";
import { Paper, Stack, Typography } from "@mui/material";
import React from "react";

import { useCAElasticIndex } from "../../CAContextProvider/hooks";
import { DragProps } from "../DraggableFieldItem/interfaces";
import { isOdd } from "../helpers";
import { DraggableDiv } from "../style";

import { typeToIcon } from "@/components/ScriptModal/constants";
import { useLabel } from "@/hooks/useLabel";

interface Props extends DragProps {
    item: ValueAggregationItem | SplitItem | ScriptedMetricRelation;
    index: number;
}

const AvailableField: React.FC<Props> = ({
    item,
    index,
    dragId,
    dragItem,
    isDraggable = false,
    onDragStart,
    onDragEnd,
    onDragOver,
}) => {
    const elasticIndex = useCAElasticIndex();
    const TypeIcon = typeToIcon[item.type];
    return (
        <DraggableDiv
            id={dragId}
            draggable={isDraggable}
            isDragging={"uuid" in item && dragItem?.item.uuid === item.uuid}
            onDragStart={onDragStart}
            onDragOver={onDragOver}
            onDragEnd={onDragEnd}
        >
            <Paper
                elevation={0}
                data-testid={dragId}
                sx={{
                    py: 0.5,
                    backgroundColor: (theme) =>
                        isOdd(index) ? theme.palette.grey[100] : theme.palette.background.paper,
                    "&:hover": {
                        backgroundColor: (theme) => theme.palette.grey[200],
                    },
                }}
            >
                <Stack pl={1} direction="row" spacing={1} alignItems="center">
                    <TypeIcon fontSize="inherit" />
                    <Typography variant="textSm">{useLabel(item, elasticIndex)}</Typography>
                </Stack>
            </Paper>
        </DraggableDiv>
    );
};

export default AvailableField;
