import { ScriptParam } from "@ignite-analytics/pivot-ts";
import update, { Spec } from "immutability-helper";
import React, { useCallback } from "react";

interface Props {
    params: Record<string, ScriptParam>;
    setParams: React.Dispatch<React.SetStateAction<Record<string, ScriptParam>>>;
    isLineScript: boolean;
    children: React.ReactNode;
}

const ParamsContext = React.createContext<{
    params: Record<string, ScriptParam>;
    updateParams: (spec: Spec<Record<string, ScriptParam>>) => void;
    isLineScript: boolean;
} | null>(null);

const ParamsContextProvider: React.FC<Props> = ({ params, setParams, children, isLineScript }) => {
    const updateParams = useCallback(
        (spec: Spec<Record<string, ScriptParam>>) => {
            setParams((prev) => update(prev, spec));
        },
        [setParams]
    );

    const value = React.useMemo(() => ({ params, updateParams, isLineScript }), [params, updateParams, isLineScript]);

    return <ParamsContext.Provider value={value}>{children}</ParamsContext.Provider>;
};

export const useParamsContext = () => React.useContext(ParamsContext);

export default ParamsContextProvider;
