import { defineMessages } from "react-intl";

const messages = defineMessages({
    toogleChartTooltipChart: {
        id: "customAnalysis.toogleChartTooltipChart",
        defaultMessage: "Switch to chart",
    },
    toogleChartTooltipTable: {
        id: "customAnalysis.toogleChartTooltipTable",
        defaultMessage: "Switch to table",
    },
    saveAnalysisNewTitle: {
        id: "customAnalysis.saveAnalysisNewTitle",
        defaultMessage: "Title",
    },
    titleLabel: {
        id: "customAnalysis.titleLabel",
        defaultMessage: "Analysis title",
    },
    defaultAnalysisTitle: {
        id: "customAnalysis.defaultAnalysisTitle",
        defaultMessage: "New analysis",
    },
    couldNotSaveErrMsg: {
        id: "customAnalysis.couldNotSaveErrMsg",
        defaultMessage: "Could not save analysis",
    },
    saveAnalysisLabel: {
        id: "customAnalysis.saveAnalysisLabel",
        defaultMessage: "Save analysis",
    },
    saveAnalysisInfoText: {
        id: "customAnalysis.saveAnalysisInfoText",
        defaultMessage: "Save",
    },
    querySettingsLabel: {
        id: "customAnalysis.querySettingsLabel",
        defaultMessage: "General settings",
    },
    numItemsSelectAlternatives: {
        id: "customAnalysis.numItemsSelectAlternatives",
        defaultMessage: "{num, plural, one {# item} other {# items}}",
    },
    availableFieldsLabel: {
        id: "customAnalysis.availableFieldsLabel",
        defaultMessage: "Data fields",
    },
    availableScriptsLabel: {
        id: "customAnalysis.availableScriptsLabel",
        defaultMessage: "Scripts",
    },
    valueItemsLabel: {
        id: "customAnalysis.valueItemsLabel",
        defaultMessage: "Values",
    },
    valuesDropPH: {
        id: "customAnalysis.valuesDropPH",
        defaultMessage: "Drop value fields here",
    },
    rowSplitLabel: {
        id: "customAnalysis.rowSplitLabel",
        defaultMessage: "Rows",
    },
    rowSplitDropPH: {
        id: "customAnalysis.rowSplitDropPH",
        defaultMessage: "Drop row fields here",
    },
    columnSplitLabel: {
        id: "customAnalysis.columnSplitLabel",
        defaultMessage: "Columns",
    },
    columnSplitDropPH: {
        id: "customAnalysis.columnSplitDropPH",
        defaultMessage: "Drop column fields here",
    },
    tableHeaderLabel: {
        id: "customAnalysis.tableHeaderLabel",
        defaultMessage: "Result",
    },
    resultInfoTooltipHeader: {
        id: "customAnalysis.resultInfoTooltipHeader",
        defaultMessage: "No chart?",
    },
    resultInfoTooltipText: {
        id: "customAnalysis.resultInfoTooltipText",
        defaultMessage:
            "Some charts have difficulties displaying too many items. Try reducing the number of results. If this is not the case, please let us know about your problem.",
    },
    filterHeader: {
        id: "customAnalysis.filterHeader",
        defaultMessage: "Filter on current analysis",
    },
    organizationError: {
        id: "customAnalysis.organizationError",
        defaultMessage: "Error in Hierarchy-chart: Not possible to split on columns.",
    },
    colSplitError: {
        id: "customAnalysis.colSplitError",
        defaultMessage: "The {chartType} chart type does not support splitting on columns.",
    },
    organizationValuesError: {
        id: "customAnalysis.organizationValuesError",
        defaultMessage: "Error in Hierarchy-chart: Not possible to split on more than 3 values.",
    },
    addScript: {
        id: "customAnalysis.addScript",
        defaultMessage: "Create a script",
    },
    atLeastOneValue: {
        id: "customAnalysis.atLeastOneValue",
        defaultMessage: "You need at least one value aggregation",
    },
    chartError: {
        id: "customAnalysis.chartError",
        defaultMessage: "Chart error",
    },
    unmixableChartType: {
        id: "customAnalysis.unmixableChartType",
        defaultMessage: "Only one value aggregation is possible when using {type} charts",
    },
    searchForFields: {
        id: "customAnalysis.searchForFields",
        defaultMessage: "Search for data fields in {elasticIndex}",
    },
    searchForScripts: {
        id: "customAnalysis.searchForScripts",
        defaultMessage: "Search for scripts in {elasticIndex}",
    },
    dataSource: {
        id: "customAnalysis.dataSource",
        defaultMessage: "Data source",
        description:
            "Used as label for the select menu where user chooses elasticsearch cluster index in Custom Analysis",
    },
    resetEverything: {
        id: "customAnalysis.resetEverything",
        defaultMessage: "Reset everything",
        description: "Used in the tooltip for the button that lets the user reset the entire custom analysis",
    },
    pleaseAddSplit: {
        id: "customAnalysis.pleaseAddSplit",
        defaultMessage:
            "You cannot use a script on aggregated values without adding either a row or a column split. Please add a data column as column or row split, or create a script on line level instead.",
    },
    totalAndExcludeOthers: {
        id: "customAnalysis.TotalAndExcludeWarning",
        defaultMessage: "Total value might contain hidden values when you select `Exclude others`.",
    },
    normalizeOnFirstValueError: {
        id: "customAnalysis.normalizeOnFirstValueError",
        defaultMessage:
            "Normalizing on first value when one or more rows miss values for the first column have led to erroneous behavior.",
    },
    splitOnYearAndWeekWarning: {
        id: "customAnalysis.splitOnYearAndWeekWarning",
        defaultMessage:
            "Weeks 1 and 53 might contain misclassified transactions from weeks beginning in the previous or ending in the next year.",
    },
    customAnalysisWidgetMessage: {
        id: "components.widgets.customAnalysis.customAnalysisWidgetMessage",
        defaultMessage: "Custom analysis",
        description: "At least part of the new widget menu",
    },
    sum: {
        id: "customAnalysis.components.draggableFieldAggTypesSum",
        defaultMessage: "Sum",
    },
    avg: {
        id: "customAnalysis.components.draggableFieldAggTypesAvg",
        defaultMessage: "Average",
    },
    max: {
        id: "customAnalysis.components.draggableFieldAggTypesMax",
        defaultMessage: "Maximum",
    },
    valueCount: {
        id: "customAnalysis.components.draggableFieldAggTypesValueCount",
        defaultMessage: "Count",
    },
    percentiles: {
        id: "customAnalysis.components.draggableFieldAggTypesPercentiles",
        defaultMessage: "Median",
    },
    min: {
        id: "customAnalysis.components.draggableFieldAggTypesMin",
        defaultMessage: "Minimum",
    },
    cardinality: {
        id: "customAnalysis.components.draggableFieldAggTypesCardinality",
        defaultMessage: "Count of unique values",
    },
    year: {
        id: "customAnalysis.components.draggableFieldIntervalOptionYear",
        defaultMessage: "Year",
    },
    quarter: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionQuarter",
        defaultMessage: "Quarter",
    },
    month: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionMonth",
        defaultMessage: "Month",
    },
    week: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionWeek",
        defaultMessage: "Week",
    },
    day: {
        id: "customAnalysis.components.draggableFieldSelectCalcTypeIntervalOptionDay",
        defaultMessage: "Day",
    },
    selectInterval: {
        id: "customAnalysis.components.draggableFieldSelectInterval",
        defaultMessage: "Select interval",
    },
    selectUnique: {
        id: "customAnalysis.components.selectUnique",
        defaultMessage: "Select unique",
        description: "Display the value as unique or generalized. I.e 01.02.19 or 02",
    },
    dateInFormulaWarning: {
        id: "customAnalysis.components.dateInFormulaWarning",
        defaultMessage: "Formulas doesn't support operations on date fields",
    },
    createFunctionScript: {
        id: "customAnalysis.components.createFunction",
        defaultMessage: "Create function",
    },
    twoConsecutiveOperatorsMsg: {
        id: "customAnalysis.components.twoConsecutiveOperatorsMsg",
        defaultMessage: "You can't use two operators consecutively",
    },
    twoConsecutiveValuesMsg: {
        id: "customAnalysis.components.twoConsecutiveValuesMsg",
        defaultMessage: "You can't use two values consecutively",
    },
    cantStartOrEndWith: {
        id: "customAnalysis.components.cantStartOrEndWith",
        defaultMessage: "You can't start or end with an operator",
    },
    missingFunctionMsg: {
        id: "customAnalysis.components.missingFunctionMsg",
        defaultMessage: "You must first define the function",
    },
    valueItems: {
        id: "customAnalysis.components.valueItems",
        defaultMessage: "Value {numItems, plural, one {item} other {items}}",
    },
    aggregation: {
        id: "customAnalysis.components.aggregation",
        defaultMessage: "Aggregation",
    },
    add: {
        id: "customAnalysis.components.add",
        defaultMessage: "Add",
        description: "Button text",
    },
    operators: {
        id: "customAnalysis.components.operators",
        defaultMessage: "Operators",
    },
    function: {
        id: "customAnalysis.components.function",
        defaultMessage: "Function",
    },
    measureUnit: {
        id: "customAnalysis.components.measureUnit",
        defaultMessage: "Measure unit",
    },
    constantNumber: {
        id: "customAnalysis.components.constantNumber",
        defaultMessage: "Constant number",
    },
    dontAggregate: {
        id: "customAnalysis.components.dontAggregate",
        defaultMessage: "Don`t aggregate total and `others`",
    },
    querySizePH: {
        id: "customAnalysis.querySizePH",
        defaultMessage: "Select size",
    },
    querySizeLabel: {
        id: "customAnalysis.querySizeLabel",
        defaultMessage: "Number of results",
    },
    excludeOthersLabel: {
        id: "customAnalysis.excludeOthersLabel",
        defaultMessage: "Exclude `Others` values",
    },
    customRangeLabel: {
        id: "customAnalysis.customRangeLabel",
        defaultMessage: "Select custom size intervals",
    },
    splitSettings: {
        id: "customAnalysis.splitSettings",
        defaultMessage: "{item} settings",
    },
    items: {
        id: "customAnalysis.items",
        defaultMessage: "items",
    },
    backToDashboard: {
        id: "customAnalysis.backToDashboard",
        defaultMessage: "Back to dashboard",
    },
    widgetHasChanged: {
        id: "customAnalysis.widgetHasChangedDialog.widgetHasChanged",
        defaultMessage: "There are unsaved changes",
    },
    widgetHasChangedDescription: {
        id: "customAnalysis.widgetHasChangedDialog.widgetHasChangedDescription",
        defaultMessage: "Are you sure you want to go back to dashboard without saving?",
    },
    deleteError: {
        id: "customAnalysis.deleteError",
        defaultMessage: "Something went wrong when trying to delete the analysis",
    },
    updateError: {
        id: "customAnalysis.updateError",
        defaultMessage: "Something went wrong when trying to update the analysis",
    },
    deleteWidgetConfirmation: {
        id: "customAnalysis.deleteWidgetConfirmation",
        defaultMessage: "Are you sure you want to delete this analysis?",
    },
    deleteWidget: {
        id: "customAnalysis.deleteWidget",
        defaultMessage: "Delete analysis",
    },
    permissionDenied: {
        id: "customAnalysis.permissionDenied",
        defaultMessage: "You do not have permission to perform this action. Contact admin user to get access.",
    },
    genericError: {
        id: "customAnalysis.genericError",
        defaultMessage: "Something went wrong. Please try again.",
    },
    aggregationItemMissingFieldError: {
        id: "customAnalysis.aggregationItemMissingFieldError",
        defaultMessage: "Attribute 'field' is required for all value aggregation items except scripts",
    },
    splitItemMissingAttributeError: {
        id: "customAnalysis.splitItemMissingAttributeError",
        defaultMessage: "Attributes 'type' and 'field' are required for all split items",
    },
    tooManyRowSplitsError: {
        id: "customAnalysis.tooManyRowSplitsError",
        defaultMessage:
            "Too many row splits. In order to provide quick analyses, we only support up to 6 splits in each direction",
    },
    tooManyColumnSplitsError: {
        id: "customAnalysis.tooManyColumnSplitsError",
        defaultMessage:
            "Too many column splits. In order to provide quick analyses, we only support up to 6 splits in each direction",
    },
});

export default messages;
