import { ElasticField, ElasticIndex } from "@ignite-analytics/elastic-fields";
import {
    BOOLEAN_TYPE,
    CONDITIONAL_TYPE,
    DateOptionValue,
    EXCLUDE_TYPE,
    EXISTS_TYPE,
    Filter,
    INCLUDE_TYPE,
    RANGE_TYPE,
    RELATIVE_DATE_TYPE,
    RelativeDateFilter,
    SEARCH_TERM_TYPE,
    STATIC_DATE_TYPE,
    isRelativeDateFilter,
    stringIdToName,
} from "@ignite-analytics/filters";
import { capitalCase, memoize } from "@ignite-analytics/general-tools";
import { IntlShape } from "react-intl";

import filterMessages from "../messages/filters";

import { getShortDescriptionOfFilters } from "./stringMappings";

import globalMessages from "@/lib/messages/globalMessages";

export const getPresetOptions = (intl: IntlShape): { text: string; option: DateOptionValue }[] => [
    ...[7, 14, 30, 90, 365, 730].map((value) => ({
        text: capitalCase(
            `${intl.formatMessage(filterMessages.last)} ${intl.formatMessage(filterMessages.day, {
                value,
            })}`
        ),
        option: { value, offset: 0, period: "day" as const },
    })),
    ...[-14, -30, -90].map((value) => ({
        text: capitalCase(
            `${intl.formatMessage(filterMessages.next)} ${intl.formatMessage(filterMessages.day, {
                value: Math.abs(value),
            })}`
        ),
        option: { value, offset: 0, period: "day" as const },
    })),
    {
        text: intl.formatMessage(filterMessages.thisMonth),
        option: { value: 1, offset: 0, period: "month" },
    },
    {
        text: intl.formatMessage(filterMessages.previousMonth),
        option: { value: 1, offset: 1, period: "month" },
    },
    ...[3, 12].map((value) => ({
        text: capitalCase(
            `${intl.formatMessage(filterMessages.previous)} ${intl.formatMessage(filterMessages.month, {
                value,
            })}`
        ),
        option: { value, offset: 1, period: "month" as const },
    })),
    {
        text: intl.formatMessage(filterMessages.thisYear),
        option: { value: 1, offset: 0, period: "year" },
    },
    {
        text: intl.formatMessage(filterMessages.previousYear),
        option: { value: 1, offset: 1, period: "year" },
    },
    {
        text: intl.formatMessage(filterMessages.wholePeriod),
        option: { period: "full" },
    },
];

/**
 * Returns a textual description of a relative date filter.
 */
export const getRelativeDateText = (filter: RelativeDateFilter, intl: IntlShape) => {
    const { periodLength: n, offset, periodUnit } = filter;
    if (periodUnit === "full") return intl.formatMessage(filterMessages.wholePeriod);
    const preset = getPresetOptions(intl).find(
        ({ option: opts }) => opts.period === periodUnit && opts.value === n && opts.offset === offset
    );
    return preset
        ? preset.text
        : offset
        ? `${intl.formatMessage(filterMessages[periodUnit], { value: n })}, ${intl.formatMessage(
              filterMessages.starting,
              {
                  time: intl.formatMessage(filterMessages[periodUnit], { value: offset + (n || 0) }),
              }
          )}`
        : `${intl.formatMessage(filterMessages.last)} ${intl.formatMessage(filterMessages[periodUnit], {
              value: n,
          })}`;
};

export const getFilterValueDescription = (
    filter: Filter,
    intl: IntlShape,
    elasticFields: ElasticField[],
    dataTables: ElasticIndex[],
    elasticIndex: string | undefined
): string | undefined => {
    switch (filter.filterType) {
        case BOOLEAN_TYPE:
            return intl.formatMessage(globalMessages[filter.value ? "yes" : "no"]);
        case EXISTS_TYPE:
            return filter.exists ? intl.formatMessage(filterMessages.any) : intl.formatMessage(filterMessages.none);
        case CONDITIONAL_TYPE:
            return (
                filter.valueDescription ??
                getShortDescriptionOfFilters(
                    filter.children,
                    intl,
                    elasticFields,
                    dataTables,
                    elasticIndex,
                    ` ${intl.formatMessage(globalMessages.or)} `
                )
            );
        case RANGE_TYPE:
            return `${filter.min} → ${filter.max}`;
        case STATIC_DATE_TYPE:
            return `${filter.start} → ${filter.end}`;
        case RELATIVE_DATE_TYPE:
            return getRelativeDateText(filter, intl);
        case INCLUDE_TYPE:
            return filter.include.map(stringIdToName(filter.idNameMap)).join(", ");
        case EXCLUDE_TYPE:
            return `${intl.formatMessage(filterMessages.allExcept, {})} ${filter.exclude
                .map(stringIdToName(filter.idNameMap))
                .join(", ")}`;
        case SEARCH_TERM_TYPE:
            return filter.searchTerm;
    }
};

export const removeFullPeriodFilters = memoize((filters: Filter[]) =>
    filters.filter((filter) => (isRelativeDateFilter(filter) ? filter.periodUnit !== "full" : true))
);
