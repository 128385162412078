import { FeatureToggleContextProvider } from "@ignite-analytics/feature-toggle";
import React from "react";

import CustomAnalysisPage from "./CustomAnalysisPage";

import AvailableIndicesProvider from "@/contexts/AvailableIndicesContext";
import { EntityChangeEventContextProvider } from "@/contexts/EntityEventChangeContext";
import NotificationsContextProvider from "@/contexts/notificationsContext";
import { useSessionContext } from "@/contexts/SessionContextProvider";

const ApplicationPage: React.FC = () => {
    const { id: userId, tenant, email } = useSessionContext();

    if (userId) {
        return (
            <FeatureToggleContextProvider userEmail={email} tenant={tenant}>
                <NotificationsContextProvider userId={userId} channel={0} topic="notifier-broadcast">
                    <EntityChangeEventContextProvider userId={userId} channel={0} topic="entity-change-event">
                        <AvailableIndicesProvider>
                            <CustomAnalysisPage />
                        </AvailableIndicesProvider>
                    </EntityChangeEventContextProvider>
                </NotificationsContextProvider>
            </FeatureToggleContextProvider>
        );
    }
    return null;
};

export default ApplicationPage;
