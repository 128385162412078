import { useQuery } from "@tanstack/react-query";

export type PermissionRelation = Parameters<typeof checkPermissions>[1][0];

export async function checkPermissions(
    namespace: string,
    relationTuples: {
        object: "general";
        relation: "read" | "write";
    }[]
): Promise<boolean[]> {
    const res = await fetch(`${String(process.env.REACT_APP_AUTH_SIDECAR_API_URL)}/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    const data = (await res.json()) as boolean[];
    return Array.isArray(data) ? data : Array<boolean>(relationTuples.length).fill(false);
}

const CheckPermission = (namespace: string, relationTuples: PermissionRelation[]) => {
    const { data, error } = useQuery({
        queryKey: ["permissionData"],
        queryFn: () => checkPermissions(namespace, relationTuples),
        staleTime: 60 * 1000,
    });

    return {
        permission: Array.isArray(data) ? data : Array<boolean>(relationTuples.length).fill(false),
        error,
    };
};

export function usePermission(namespace: string, relation: PermissionRelation): boolean {
    const { permission } = CheckPermission(namespace, [relation]);
    if (permission?.length === 1) return permission[0];
    return false;
}
