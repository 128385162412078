import * as Permissions from "@ignite-analytics/permissions";
import { CircularProgress } from "@mui/material";
import React, { ReactNode } from "react";

import { useSessionContext } from "@/contexts/SessionContextProvider";
import { PermissionRelation, usePermission } from "@/hooks/usePermissionCheck";

interface Props {
    requiredPermissionTypes: Permissions.BackendPermissionType[];
    equivalentUserPermission: { namespace: string; relation: PermissionRelation };
    guard?: number | null;
    modelGuard?: Permissions.ModelGuard;
    renderOnPermissionDenied?: ReactNode;
    children: ReactNode;
}

const PermissionContainer: React.FC<Props> = ({
    requiredPermissionTypes,
    equivalentUserPermission,
    children,
    renderOnPermissionDenied = null,
    guard,
    modelGuard,
}) => {
    const { id: oryId } = useSessionContext();
    const hasOryPermission = usePermission(equivalentUserPermission.namespace, equivalentUserPermission.relation);
    if (!hasOryPermission) return renderOnPermissionDenied as JSX.Element;
    if (!guard) {
        return children as JSX.Element;
    }

    /** Return loader if objectPermissions is not loaded yet */
    if (!modelGuard || !oryId) return <CircularProgress />;

    return Permissions.hasObjectPermission(modelGuard, requiredPermissionTypes, oryId)
        ? (children as JSX.Element)
        : (renderOnPermissionDenied as JSX.Element);
};

export default PermissionContainer;
