import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { ChevronLeft as ChevronLeftIcon } from "@ignite-analytics/icons";
import { Alert, Box, Collapse, Fab, Snackbar, Stack } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import ApplyFieldsContainer from "./ApplyFieldsContainer";
import { useCAContext } from "./CAContextProvider/hooks";
import { ChartTableContainer } from "./ChartTableContainer";
import ConfigurationSidePanel from "./ConfigurationSidePanel/index";
import EditWidgetTopBar from "./EditWidgetTopBar";
import { useAnalysisQuery } from "./hooks";

/**
 * Main component for custom analysis module
 */
const CustomAnalysis: React.FC = () => {
    const { openWidget: widget, updateWidget, resetWidget } = useCAContext();
    const filters = useFilters();
    const [sideBarOpen, setSideBarOpen] = useState(true);
    const [popupError, setPopupError] = useState<string | undefined>(undefined);

    const query = useAnalysisQuery(widget, widget?.customDashboard);

    const fieldsInIndex = useElasticFieldsInContext();

    // Get script fields and make sure redux state has a widget
    useEffect(
        function setInitialState() {
            if (!widget) resetWidget();
        },
        [widget, resetWidget]
    );

    // Update filters in widget when filters in Redux or context changes
    useEffect(() => {
        filters && updateWidget({ filters: { $set: filters } });
    }, [filters, updateWidget]);

    // Disable value as columns if there are no scripts left in value aggregations
    useEffect(
        function disableAggsAsColumns() {
            const config = widget?.chartConfiguration;
            if (!config) return;

            if (
                config.useAggsAsColumns &&
                (config.optionsPerAgg.length < 1 ||
                    !widget?.valueConfigurations.some((agg) => agg.type === "script" || agg.type === "scripted_metric"))
            ) {
                updateWidget({ chartConfiguration: { useAggsAsColumns: { $set: false } } });
            }
        },
        [widget?.chartConfiguration, widget?.valueConfigurations, updateWidget]
    );

    // Resize observer for the side panel
    const stackRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        const currentRef = stackRef.current;
        if (currentRef) {
            const resizeObserver = new ResizeObserver(() => {
                window.dispatchEvent(new Event("resize"));
            });
            resizeObserver.observe(currentRef);
            // Clean up function
            return () => {
                resizeObserver.unobserve(currentRef);
            };
        }
    }, []);

    const handleOpenConfigurationPanel = () => {
        setSideBarOpen(!sideBarOpen);
        setTimeout(() => window.dispatchEvent(new Event("resize")), 200);
    };
    const { defaultDashboardId } = useCAContext();
    const reduceHeightForTopBar = widget && Boolean(defaultDashboardId);
    return (
        <Stack direction="column" alignItems="stretch" spacing={1} p={1} ref={stackRef}>
            {/* Edit widget topbar */}
            {widget && Boolean(defaultDashboardId) && (
                <EditWidgetTopBar widget={widget} fieldsInIndex={fieldsInIndex} setPopupError={setPopupError} />
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={1}
                sx={{
                    height: reduceHeightForTopBar ? "80vh" : "85vh",
                }}
            >
                {/* Left Side Container */}
                <Box
                    sx={{
                        minWidth: "25%",
                        maxWidth: "300px",
                        display: "flex",
                        alignSelf: "stretch",
                    }}
                >
                    <ApplyFieldsContainer widget={widget} query={query} />
                </Box>

                {/* Chart & Table Container */}
                <Box sx={{ width: sideBarOpen ? "50%" : "70%" }}>
                    <ChartTableContainer
                        widget={widget}
                        query={query}
                        fieldsInIndex={fieldsInIndex}
                        filters={filters}
                        showSaveButton={!defaultDashboardId}
                    />
                </Box>
                <Box
                    sx={{
                        minWidth: sideBarOpen ? "20%" : "5%",
                        display: "flex",
                        alignSelf: "stretch",
                    }}
                >
                    {/* Chart | Table Configuration */}
                    {!sideBarOpen && (
                        <Fab onClick={handleOpenConfigurationPanel} size="small">
                            <ChevronLeftIcon />
                        </Fab>
                    )}
                    <Collapse in={sideBarOpen} orientation="horizontal" unmountOnExit>
                        <ConfigurationSidePanel closePanel={handleOpenConfigurationPanel} />
                    </Collapse>
                </Box>

                {/* Error snackbar */}
                <Snackbar
                    open={!!popupError}
                    autoHideDuration={5000}
                    onClose={() => setPopupError(undefined)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                >
                    <Alert severity="error" sx={{ width: "100%" }}>
                        {popupError}
                    </Alert>
                </Snackbar>
            </Stack>
        </Stack>
    );
};

export default CustomAnalysis;
