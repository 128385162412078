import { isApolloError } from "@apollo/client";
import { FieldValidationErrors, getFieldValidationErrors, isAxiosError } from "@ignite-analytics/api-client";
import React from "react";

import messages from "./messages";

import { staticFormatMessage } from "@/contexts/intlContext";

const getErrorList = (errors: FieldValidationErrors | undefined) => {
    if (errors === undefined) return [];
    return Object.keys(errors)
        .map((key: string) => {
            const err: string[] = errors[key] ?? [];
            return err.map((errorValue) => `${key}: ${errorValue}`);
        })
        .reduce((acc, next) => [...acc, ...next], []);
};

function isRecord(record: unknown): record is Record<string | number | symbol, unknown> {
    return typeof record === "object" && record !== null;
}

export const setErrorsFromCatch = (setErrors: React.Dispatch<React.SetStateAction<string[]>>, err: Error) => {
    if (isApolloError(err)) {
        if (err.networkError && "statusCode" in err.networkError && err.networkError.statusCode === 504) {
            setErrors([staticFormatMessage(messages.timeoutError)]);
        } else {
            setErrors([err.message]);
        }
        return;
    }
    if (isAxiosError(err)) {
        if (err.response.status < 500) {
            const errors = getErrorList(getFieldValidationErrors(err));
            if (!errors.length && isRecord(err.response.data) && typeof err.response.data.message === "string") {
                errors.push(err.response.data.message);
            }
            setErrors(errors);
        } else {
            setErrors([staticFormatMessage(messages.genericServerError)]);
        }
        return;
    }
    setErrors([err.message]);
};

export const setErrorsFromObject = (
    setErrors: React.Dispatch<React.SetStateAction<string[]>>,
    errors: { [key: string]: string[] }
) => {
    setErrors(getErrorList(errors));
};
