import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter, getFilterValueDescription } from "@ignite-analytics/filters";
import { Labelled } from "@ignite-analytics/pivot-ts";
import _ from "lodash";
import { IntlShape } from "react-intl";

import { Options } from "../interfaces";

import messages from "./messages";

import {
    CATEGORY_LEVEL,
    CUSTOM_PERIOD,
    DISPLAY_COMPARISION,
    INTERVAL,
    MIN_DIFF,
    MIN_SPEND_LTM,
    PERCENTAGE_LIMIT,
    QUERY_SIZE,
    SELECTED_BENCHMARKS,
    SELECTED_COMMODITY,
    SELECTED_FIELD,
    SPEND_LIMIT,
    STATE_FIELD,
    SUPPLIER_COUNT,
    SUPPLIER_NUMBER,
    TEXT_ALIGNMENT,
    TIME_PERIOD,
    VIEW_MODE,
} from "@/components/Widgets/constants";
import { staticFormatMessage } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

const getValueDescription = (value: any) =>
    typeof value === "boolean" ? staticFormatMessage(globalMessages[value ? "yes" : "no"]) : String(value);

export const getFilterFooterTuple =
    (intl: IntlShape, elasticFields: ElasticField[]) =>
    (filter: Labelled<Filter>): [string, string] =>
        [filter.label, getFilterValueDescription(filter, intl, elasticFields)];

/**
 * This type is a union of `Options`-keys that shouldn't be shown in the FilterCardFooter,
 * because they are not relevant.
 */
type InvisibleOptions =
    | "query"
    | "chartConfig"
    | "type"
    | typeof VIEW_MODE
    | typeof DISPLAY_COMPARISION
    | typeof SELECTED_FIELD
    | typeof PERCENTAGE_LIMIT
    | typeof STATE_FIELD
    | typeof TEXT_ALIGNMENT;

/**
 * @returns a mapping from `Options`-keys to formatted translated messages. Does not include the hidden
 * options that are specified in the `InvisibleOptions` type.
 */

export const getOptionsMapping = (): Record<Exclude<keyof Options, InvisibleOptions>, string> => ({
    [CUSTOM_PERIOD]: staticFormatMessage(messages.customPeriod),
    [QUERY_SIZE]: staticFormatMessage(messages.querySize),
    [MIN_SPEND_LTM]: staticFormatMessage(messages.minSpendLtm),
    [SUPPLIER_NUMBER]: staticFormatMessage(messages.supplierNumber),
    [CATEGORY_LEVEL]: staticFormatMessage(messages.categoryLevel),
    [TIME_PERIOD]: staticFormatMessage(messages.timePeriod),
    [SUPPLIER_COUNT]: staticFormatMessage(messages.supplierNumber),
    [SPEND_LIMIT]: staticFormatMessage(messages.minSpendLtm),
    [MIN_DIFF]: staticFormatMessage(messages.filterMinDifference),
    [SELECTED_COMMODITY]: staticFormatMessage(messages.selectedCommodity),
    [INTERVAL]: staticFormatMessage(messages.interval),
    [SELECTED_BENCHMARKS]: staticFormatMessage(messages.selectedBenchmarks),
});

export const getFilterFooterTuples = (
    intl: IntlShape,
    elasticFields: ElasticField[],
    filters: Labelled<Filter>[] = [],
    options: Options = {}
): [string, string][] => {
    const optionsMapping = getOptionsMapping();
    return _.orderBy(
        [
            ...filters.map<[string, string]>(getFilterFooterTuple(intl, elasticFields)),
            ...Object.keys(options || {})
                .filter((key): key is keyof typeof optionsMapping => key in optionsMapping)
                .map<[string, string]>((key) => [optionsMapping[key], getValueDescription(options[key])]),
        ],
        (a) => a[0]
    );
};

export const getFilterFooterHTML = (
    intl: IntlShape,
    elasticFields: ElasticField[],
    filters: Labelled<Filter>[] = [],
    options: Options = {}
) =>
    getFilterFooterTuples(intl, elasticFields, filters, options)
        .map(([title, value]) => `<strong>${title}:</strong> ${value}`)
        .join(", ");
