import _ from "lodash";

import { getSiUnits } from "@/lib/convert";

export const deformatBigNumber = (formattedNumber: string) =>
    _.orderBy(
        getSiUnits().reduce<{ unit: string; value: number }[]>(
            (units, { long, short, value }) => [...units, { unit: long, value }, { unit: short, value }],
            []
        ),
        "unit"
    )
        // Filter out empty strings
        .filter(({ unit }) => unit.length)
        // Reduce from longest to shortest string to prevent false positives in regex
        .reduceRight((res, { unit, value }) => res.replace(new RegExp(`(${unit})`, "gi"), `*${value}`), formattedNumber)
        .split("*")
        .map(Number)
        .filter(n => n > 0)
        .reduce((res, n) => res * n, 1);

export const formatBigNumber = (
    num: number,
    digits: number | "auto" | null = "auto",
    padString = true,
    unitDescription: "short" | "long" = "short"
): string => {
    const unitsWihtoutPercent = getSiUnits().slice(1);
    let i = 0;
    for (i = unitsWihtoutPercent.length - 1; i > 0; i -= 1) {
        if (num > 0 ? unitsWihtoutPercent[i].value <= num : -unitsWihtoutPercent[i].value >= num) break;
    }
    const decimals = typeof digits === "number" ? digits : num < 10 ? 2 : num < 100 ? 1 : 0;
    const toBeReturned =
        num < 0
            ? `-${formatBigNumber(-num, digits, padString, unitDescription)}`
            : (num / unitsWihtoutPercent[i].value).toFixed(decimals).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
              unitsWihtoutPercent[i][unitDescription];
    return padString
        ? toBeReturned.padStart(4 + decimals - toBeReturned.length + (i === 0 ? 6 : 0), "\u00A0")
        : toBeReturned;
};
