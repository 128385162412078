import { compareFilters, FilterModal, useFilters } from "@ignite-analytics/filters";
import { useDebounce } from "@ignite-analytics/general-tools";
import { X as ClearOutlinedIcon, Pen as EditOutlinedIcon } from "@ignite-analytics/icons";
import {
    AGG_TYPES,
    CumulativeFunction,
    cumulativeFunctions,
    isMetricsScript,
    isMovingFunction,
} from "@ignite-analytics/pivot-ts";
import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import React, { useEffect, useMemo, useRef, useState } from "react";

import { useCAContext, useCAElasticIndex } from "../../../CAContextProvider/hooks";
import { isDataTableV2ElasticIndex, safeFormatMessage, stringToOption } from "../../helpers";
import { getCumulativeMessage, isAggOption, isCumFunc } from "../helpers";
import { ValueItem } from "../interfaces";
import messages from "../messages";

import ScriptModal from "@/components/ScriptModal";
import { fm } from "@/contexts/intlContext";
import { useScriptField } from "@/entities/scriptFields";
import withFilterContext from "@/hoc/withFilterContext";
import { useElasticIndexInContextByName } from "@/hooks/useElasticIndexWithType";
import { removeFullPeriodFilters } from "@/lib/helpers/filters";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    item: ValueItem;
    onUpdate: (item: ValueItem) => void;
    onClose: () => void;
    aggItemIndex: number | undefined;
    inHistogram: boolean | undefined;
}

const ValueField: React.FC<Props> = ({ item, onClose, onUpdate, aggItemIndex, inHistogram = false }) => {
    const { updateChartConfigField } = useCAContext();
    const elasticIndex = useCAElasticIndex();
    const [viewScriptModal, setViewScriptModal] = useState(false);
    const [advancedMode, setAdvancedMode] = useState(!!item.cumulation);

    const aggOptions = AGG_TYPES[item.type];
    const { aggregation = aggOptions[0] } = item;
    const aggregationTitle = safeFormatMessage(aggregation);
    const _filters = useFilters();
    const filters =
        _filters && (isDataTableV2ElasticIndex(elasticIndex) ? _filters : removeFullPeriodFilters(_filters));
    const filtersChanged = useMemo(() => {
        if (filters === undefined) return false;
        return !compareFilters(item.filters, filters);
    }, [filters, item.filters]);
    const scriptField = useScriptField(item.type === "scripted_metric" ? item.script : NaN, undefined, undefined, {
        service: "dashboards",
    });
    useEffect(
        function updateFiltersInQuery() {
            filtersChanged && onUpdate({ ...item, filters: filters || [] });
        },
        [filters, item, onUpdate, filtersChanged]
    );

    const availableCumulations = useMemo(
        (): CumulativeFunction[] =>
            cumulativeFunctions.filter((key) => {
                if (key === "cumulative_cardinality" && aggregation !== "cardinality") return false;
                return true;
            }),
        [aggregation]
    );

    const debouncedUpdate = useDebounce(onUpdate, 300);

    type EMPTY_STRING_TYPE = "";
    const EMPTY_STRING: EMPTY_STRING_TYPE = "";
    const cumulationOptions = useMemo(
        () =>
            [
                { value: EMPTY_STRING, text: fm(messages.noCumulativeFn) },
                ...availableCumulations.map((value) => ({
                    value,
                    text: getCumulativeMessage(value),
                })),
            ].map((opt) => ({ ...opt, selected: opt.value === item.cumulation })),
        [availableCumulations, item]
    );

    const handleCalculationTypeChange = (e: SelectChangeEvent) => {
        const agg = e.target.value;
        if (isAggOption(agg)) {
            onUpdate({ ...item, aggregation: agg });

            if (item.type !== "date" || aggItemIndex === undefined) return;

            // Update the chart config to display date counts as numbers
            const newMeasureUnit = ["cardinality", "value_count"].includes(agg) ? "auto" : "date";
            updateChartConfigField(["optionsPerAgg", aggItemIndex, "measureUnit"], newMeasureUnit);
        }
    };

    // This container is passed into the dialog from material Ui so the filter menu is not rendered behind the dialog
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={containerRef}>
            <Dialog container={containerRef.current} open onClose={() => onClose()}>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="textLg" fontWeight={600}>
                            {fm(messages.calculationType)}
                        </Typography>
                        <IconButton onClick={() => onClose()} size="small">
                            <ClearOutlinedIcon fontSize="inherit" />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" alignItems="center" spacing={1} p={3}>
                        <Stack direction="column" alignItems="center">
                            <InputLabel id="select-calc-type-label">{fm(messages.selectCalcType)}</InputLabel>
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Select
                                    labelId="select-calc-type-label"
                                    onChange={handleCalculationTypeChange}
                                    placeholder={aggregationTitle}
                                    value={aggregation}
                                    size="small"
                                    sx={{ minWidth: "150px" }}
                                >
                                    {aggOptions.map(stringToOption).map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.text}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FilterModal title={item.label} placement="Custom Analysis - ApplyFieldsContainer" />
                                {isMetricsScript(item) && (
                                    <>
                                        <Tooltip title={fm(messages.editScript)}>
                                            <IconButton onClick={() => setViewScriptModal(true)}>
                                                <EditOutlinedIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <ScriptModal
                                            onClose={() => setViewScriptModal(false)}
                                            open={viewScriptModal}
                                            item={scriptField}
                                            elasticIndex={scriptField ? scriptField.elasticIndex : item.elasticIndex}
                                        />
                                    </>
                                )}
                            </Stack>
                        </Stack>
                        {advancedMode ? (
                            <Stack direction="column" alignItems="center" padding={2} spacing={2}>
                                <Stack sx={{ position: "relative" }} spacing={1}>
                                    <Stack direction="column">
                                        <InputLabel id="select-cum-type-label">
                                            {fm(messages.selectCumulativeFn)}
                                        </InputLabel>
                                        <Select
                                            labelId="select-cum-type-label"
                                            disabled={!inHistogram}
                                            defaultValue={item.cumulation ?? ""}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (isCumFunc(value)) {
                                                    onUpdate({ ...item, cumulation: value });
                                                } else {
                                                    onUpdate({ ...item, cumulation: undefined });
                                                }
                                            }}
                                            size="small"
                                        >
                                            {cumulationOptions.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.text}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Stack>
                                    {item.cumulation && (
                                        <Typography variant="textSm">
                                            {getCumulativeMessage(item.cumulation, true)}
                                        </Typography>
                                    )}
                                    {isMovingFunction(item.cumulation) && (
                                        <Stack direction="column">
                                            <TextField
                                                disabled={!inHistogram}
                                                type="number"
                                                label={fm(messages.windowSize)}
                                                defaultValue={item.windowSize ?? "12"}
                                                onChange={(e) =>
                                                    debouncedUpdate({
                                                        ...item,
                                                        windowSize: Number(e.target.value) ?? null,
                                                    })
                                                }
                                                size="small"
                                            />
                                            <Typography variant="textSm">
                                                {fm(messages.windowSizeExplanation)}
                                            </Typography>
                                        </Stack>
                                    )}
                                </Stack>
                                {!inHistogram && (
                                    <Alert severity="info">
                                        <Typography variant="textSm">{fm(messages.requireHistoExplanation)}</Typography>
                                    </Alert>
                                )}
                            </Stack>
                        ) : (
                            <Stack>
                                <Button color="ghostPrimary" onClick={() => setAdvancedMode(true)}>
                                    {fm(globalMessages.advancedMode)}
                                </Button>
                            </Stack>
                        )}
                    </Stack>
                    {aggregation === "cardinality" && (
                        <Stack direction="column" alignItems="center" padding={2} spacing={2}>
                            <Alert severity="info">
                                <Typography variant="textSm">{fm(messages.uniqueCountEstimationWarning)}</Typography>
                            </Alert>
                        </Stack>
                    )}
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default withFilterContext(ValueField, {
    useDataSource: () => useElasticIndexInContextByName(useCAElasticIndex()),
    useInitialFilters: (props) => props.item.filters,
    disableDefaultCombination: true,
    inherit: false,
});
