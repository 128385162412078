import { ExclamationTriangle } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

interface SentryFallbackProps {
    resetError: () => void;
}

const WidgetErrorFallback = ({ resetError }: SentryFallbackProps) => (
    <Stack alignItems="center" justifyContent="center" p={2} sx={{ height: "100%", width: "100%" }}>
        <ExclamationTriangle />
        <Typography variant="h5">{fm(messages.widgetErrorHeader)}</Typography>
        <Typography>{fm(messages.widgetErrorDescription)}</Typography>
        <Stack justifyContent="flex-end">
            <Button variant="text" size="small" onClick={resetError}>
                {fm(messages.retry)}
            </Button>
        </Stack>
    </Stack>
);
export default WidgetErrorFallback;
