import { X as CloseIcon } from "@ignite-analytics/icons";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import React from "react";

import messages from "../../messages";

import { fm } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    open: boolean;
    onClose: () => void;
    onContinue: () => void;
}

const WidgetHasChangedDialog: React.FC<Props> = ({ open, onClose, onContinue }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="h6">{fm(messages.widgetHasChanged)}</Typography>
                <IconButton onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </Stack>
        </DialogTitle>
        <DialogContent>
            <Typography variant="textMd">{fm(messages.widgetHasChangedDescription)}</Typography>
        </DialogContent>
        <DialogActions>
            <Button color="ghostGray" onClick={onClose}>
                {fm(globalMessages.cancelButton)}
            </Button>
            <Button onClick={onContinue}>{fm(globalMessages.continueButton)}</Button>
        </DialogActions>
    </Dialog>
);

export default WidgetHasChangedDialog;
