import { getRegion, getLocale } from "@ignite-analytics/locale";
import { convertMessages } from "@ignite-analytics/pivot-charts";

import { formatBigNumber } from "./helpers/numbers";

import { staticFormatMessage } from "@/contexts/intlContext";


export function separator(n: number, decimalsArg: number | null | "auto" = "auto"): string {
    const absoluteNumber = Math.abs(n);
    const decimals =
        typeof decimalsArg === "number" ? decimalsArg : absoluteNumber < 10 ? 2 : absoluteNumber < 100 ? 1 : 0;
    return n.toLocaleString(getRegion(), {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
    });
}

export const getSiUnits = () => [
    {
        value: 1e-2,
        short: staticFormatMessage(convertMessages.measureUnitPercentShort),
        long: staticFormatMessage(convertMessages.measureUnitPercentLong),
    },
    { value: 1, short: "", long: "" },
    {
        value: 1e3,
        short: staticFormatMessage(convertMessages.measureUnitThousandShort),
        long: staticFormatMessage(convertMessages.measureUnitThousandLong),
    },
    {
        value: 1e6,
        short: staticFormatMessage(convertMessages.measureUnitMillionShort),
        long: staticFormatMessage(convertMessages.measureUnitMillionLong),
    },
    {
        value: 1e9,
        short: staticFormatMessage(convertMessages.measureUnitBillionShort),
        long: staticFormatMessage(convertMessages.measureUnitBillionLong),
    },
    {
        value: 1e12,
        short: staticFormatMessage(convertMessages.measureUnitTrillionShort),
        long: staticFormatMessage(convertMessages.measureUnitTrillionLong),
    },
    {
        value: 1e15,
        short: staticFormatMessage(convertMessages.measureUnitQuadrillionShort),
        long: staticFormatMessage(convertMessages.measureUnitQuadrillionLong),
    },
    {
        value: 1e18,
        short: staticFormatMessage(convertMessages.measureUnitQuintillionShort),
        long: staticFormatMessage(convertMessages.measureUnitQuintillionLong),
    },
];

export const formatMeasureUnit = (unit: number, option: "short" | "long"): string => {
    const candidate = getSiUnits().find((candidateUnit) => candidateUnit.value === unit);
    return candidate ? candidate[option] : "";
};

export function formatNumber(
    n: number | undefined | null,
    decimals: number | "auto" | null = "auto",
    measureUnit: number | null | "auto" | "date" = 1,
    unitDescription: "short" | "long" = "short"
): string {
    if (n !== 0 && !n) {
        return "";
    }
    if (measureUnit === "auto") {
        return formatBigNumber(n, decimals, false, unitDescription);
    }
    if (measureUnit === "date") {
        return new Date(n).toLocaleDateString(getLocale());
    }
    return (
        separator(n / (measureUnit === null ? 1 : measureUnit), decimals) +
        formatMeasureUnit(measureUnit === null ? 1 : measureUnit, unitDescription)
    );
}

export function formatValue(n: number, decimals: number | "auto" = "auto", curr = true, percent = false): string {
    let nFormatted = String(n);
    if (typeof n === "number") {
        nFormatted = separator(n, decimals);
        if (curr) {
            nFormatted += "";
        } else if (percent) {
            nFormatted += " %";
        }
    } else {
        nFormatted = n;
    }

    return nFormatted;
}
