import { gql, useLazyQuery } from "@apollo/client";
import { toCamelCase, toSnakeCase } from "@ignite-analytics/api-client";
import { ElasticField, isTermsField, isTermsType } from "@ignite-analytics/elastic-fields";
import { DateOptionPeriod, Filter, populateFilters } from "@ignite-analytics/filters";
import { isResponse } from "@ignite-analytics/pivot-charts";
import {
    AggregationItem,
    AnalysisQuery,
    NotBottomRow,
    PivotColumnData,
    PivotParentColumn,
    PivotResponse,
    PivotRow,
    SplitItem,
} from "@ignite-analytics/pivot-ts";
import * as Sentry from "@sentry/react";
import { useCallback } from "react";

import messages from "./messages";
import pivotTableMessages from "./PivotTable/messages";

import { fm, localeMoment, staticFormatMessage } from "@/contexts/intlContext";
import { getCurrentDomain } from "@/lib/getCurrentDomain";
import { formatBigNumber } from "@/lib/helpers/numbers";
import globalMessages from "@/lib/messages/globalMessages";

function formatDate(date: string | number, interval?: DateOptionPeriod | number, unique = true) {
    if (!unique && (date === "-1" || date === -1)) return "(blank)";

    /*
     * moment returns "Invalid date" for Week 53 unless a specific year is provided.
     * We pass the year 2020 to obtain a valid date, and apply conditional formating
     * to obtain the proper weeklabel.
     */

    const createLocaleDate = () => {
        if (unique) {
            return localeMoment(date).utc();
        }
        let formatString: string | undefined;
        switch (interval) {
            case "year":
                formatString = "YY";
                break;
            case "month":
                formatString = "MM";
                break;
            case "quarter":
                formatString = "Q";
                break;
            case "week":
                if (date === "53") {
                    return localeMoment("2020-53", "GGGG-WW");
                }
                formatString = "w";
                break;
            case "day":
                formatString = "E";
                break;
            default:
                formatString = undefined;
        }
        return localeMoment(date, formatString);
    };
    const formatLocaleDate = (unformattedDate: moment.Moment) => {
        let formatString: string | undefined;
        switch (interval) {
            case "year":
                formatString = unique ? "YYYY" : "YY";
                break;
            case "quarter":
                formatString = unique ? "[Q]Q YYYY" : "[Q]Q";
                break;
            case "month":
                formatString = unique ? "MMM YYYY" : "MMM";
                break;
            case "week":
                if (unique) {
                    formatString = `[${fm(messages.week)}] W GGGG`;
                } else {
                    formatString = date === "53" ? `[${fm(messages.week)}] W` : `[${fm(messages.week)}] w`;
                }
                break;
            case "day":
                formatString = unique ? "Do MMM YYYY" : "ddd";
                break;
            default:
                formatString = undefined;
        }
        return unformattedDate.format(formatString);
    };
    const localeDate = createLocaleDate();
    return formatLocaleDate(localeDate);
}

const formatHeader = (data: PivotRow | PivotColumnData | PivotResponse) => {
    if (isResponse(data)) {
        return false;
    }
    if (data.meta.isOthers) {
        if (data.meta.count) return `${staticFormatMessage(pivotTableMessages.othersLabel)} (${data.meta.count})`;
        return staticFormatMessage(pivotTableMessages.othersLabel);
    }
    if (data.meta.isTotal) return staticFormatMessage(pivotTableMessages.totalLabel);
    if (data.type === "date") return formatDate(data.meta.numericKey || data.key, data.meta.interval, data.meta.unique);
    if (data.type === "boolean")
        return [staticFormatMessage(globalMessages.no), staticFormatMessage(globalMessages.yes)][
            Number(JSON.parse(data.key))
        ];
    if (typeof data.meta.numericKey === "number" && typeof data.meta.interval === "number") {
        if (data.meta.interval === 1) return formatBigNumber(data.meta.numericKey, 0, false);
        return `${formatBigNumber(data.meta.numericKey, 0, false)} → ${formatBigNumber(
            data.meta.numericKey + data.meta.interval,
            0,
            false
        )}`;
    }
    if (data.meta.label) return data.meta.label;
    return data.key;
};

const isTopLevelData = (data: PivotResponse | PivotRow | PivotColumnData): data is PivotResponse => "columns" in data;

const isParentAxis = (data: PivotResponse | PivotRow | PivotColumnData): data is NotBottomRow | PivotParentColumn =>
    "children" in data;

const isAxis = (data: PivotResponse | PivotRow | PivotColumnData): data is PivotRow | PivotColumnData => "key" in data;

export const formatPivotHeaders = <T extends PivotResponse | PivotRow | PivotColumnData>(
    data: T,
    elasticIndex: string,
    elasticFields: ElasticField[],
    parents?: PivotResponse | PivotRow | PivotColumnData,
    siblings?: T[]
): T => {
    const dataN: T = {
        ...data,
        parentPivot: parents,
        name:
            siblings?.length &&
            siblings?.length <= 1 &&
            isAxis(data) &&
            data.meta?.isOthers &&
            parents &&
            isAxis(parents)
                ? parents.name
                : formatHeader(data),
    };
    return {
        ...dataN,
        parentPivot: parents,
        ...(isParentAxis(dataN)
            ? {
                  parentPivot: parents,
                  children: dataN.children.map((child) =>
                      formatPivotHeaders(child, elasticIndex, elasticFields, dataN, dataN.children)
                  ),
              }
            : {}),
        ...(isTopLevelData(dataN)
            ? {
                  parentPivot: parents,
                  columns: dataN.columns.map((child) =>
                      formatPivotHeaders(child, elasticIndex, elasticFields, dataN, dataN.columns)
                  ),
                  rows: dataN.rows.map((child) =>
                      formatPivotHeaders(child, elasticIndex, elasticFields, dataN, dataN.rows)
                  ),
              }
            : {}),
        ...(isAxis(dataN) && dataN.meta?.isOthers && isTermsType(dataN.type)
            ? {
                  parentPivot: parents,
                  siblings: siblings?.reduce<string[]>(
                      (res, s) => (isAxis(s) && s.key !== dataN.key ? [...res, s.key] : res),
                      []
                  ),
              }
            : {}),
        ...(isAxis(dataN)
            ? {
                  parentPivot: parents,
                  name:
                      siblings?.length &&
                      siblings?.length <= 1 &&
                      isAxis(dataN) &&
                      dataN.meta?.isOthers &&
                      parents &&
                      isAxis(parents)
                          ? parents.name
                          : formatHeader(dataN),
              }
            : {}),
    };
};

export const updateFilters = (item: AggregationItem, elasticFields: ElasticField[]): AggregationItem => {
    if (item.type === "script") {
        return {
            ...item,
            params: Object.fromEntries(
                Object.entries(item.params || {}).map(([key, val]) => [key, updateFilters(val, elasticFields)])
            ),
        };
    }
    if ("filters" in item && item.filters) {
        return {
            ...item,
            filters: populateFilters(item.filters, elasticFields),
        };
    }
    return item;
};

export const ensureRelevantSplitItemsHaveLabelFields = (splitItems: SplitItem[], elasticFields: ElasticField[]) =>
    splitItems.map((item) => {
        if (!isTermsType(item.type)) return item;
        const matchingField = elasticFields.find((f) => f.fieldId === item.field);
        if (!matchingField || !isTermsField(matchingField) || !matchingField.labelField) return item;
        return { ...item, labelField: matchingField.labelField, labelFieldType: matchingField.labelFieldType };
    });

const validAggregationItemField = (aggregationItem: AggregationItem): boolean => {
    if (aggregationItem.type === "scripted_metric" || aggregationItem.type === "script") {
        return true;
    }
    return !!aggregationItem.field;
};

const validSplitItemAttributes = (splitItem: SplitItem): boolean => !!splitItem.field && !!splitItem.type;

const captureAndThrowError = (message: { id: string; defaultMessage: string }) => {
    Sentry.captureMessage(message.defaultMessage, { tags: { app: "analysis-app" } });
    const errorObj = new Error(fm(message)?.toString());
    throw errorObj;
};
const validateQuery = (pivotQuery: AnalysisQuery) => {
    if (pivotQuery.valueAggregationItems.some((item) => !validAggregationItemField(item))) {
        captureAndThrowError(messages.aggregationItemMissingFieldError);
    }
    if (pivotQuery.rowSplitItems.length > 6) {
        captureAndThrowError(messages.tooManyRowSplitsError);
    }
    if (pivotQuery.columnSplitItems.length > 6) {
        captureAndThrowError(messages.tooManyColumnSplitsError);
    }
    if ([...pivotQuery.rowSplitItems, ...pivotQuery.columnSplitItems].some((item) => !validSplitItemAttributes(item))) {
        captureAndThrowError(messages.splitItemMissingAttributeError);
    }
};

export function useGetDataFromAnalysisService() {
    const GET_PIVOT_QUERY = gql`
        query getPivot($input: GetPivotInput!) {
            getPivot(input: $input) {
                pivotResponse
            }
        }
    `;

    const [getPivot] = useLazyQuery<{ getPivot: { pivotResponse: string } }>(GET_PIVOT_QUERY, {
        fetchPolicy: "no-cache",
        context: {
            uri: getCurrentDomain() === "ignite" ? process.env.REACT_APP_GRAPHQL_ROUTER_IGNITE_URL : process.env.REACT_APP_GRAPHQL_ROUTER_IGNITEPROCUREMENT_URL,
        },
    });

    return useCallback(
        async (
            query: AnalysisQuery,
            elasticFields: ElasticField[],
            filters?: Filter[],
            includeHidden = false
        ): Promise<PivotResponse> => {
            const filterInput = filters ? JSON.stringify(toSnakeCase(filters, false)) : JSON.stringify([]);
            validateQuery(query);
            const pivotQuery = {
                valueAggregationItems: query.valueAggregationItems.map((item) => updateFilters(item, elasticFields)),
                rowSplitItems: ensureRelevantSplitItemsHaveLabelFields(query.rowSplitItems, elasticFields),
                columnSplitItems: ensureRelevantSplitItemsHaveLabelFields(query.columnSplitItems, elasticFields),
            };

            const inputData = {
                pivotQuery: JSON.stringify(toSnakeCase(pivotQuery, false)),
                elasticIndex: toSnakeCase(query.elasticIndex, true),
                filters: filterInput,
                includeHidden,
            };
            return getPivot({ variables: { input: inputData } }).then((response) => {
                if (response.data?.getPivot.pivotResponse) {
                    const pivotResponse: PivotResponse = JSON.parse(response.data.getPivot.pivotResponse);
                    return formatPivotHeaders(toCamelCase(pivotResponse, false), query.elasticIndex, elasticFields);
                }

                throw response.error ?? new Error("Error getting data");
            });
        },
        [getPivot]
    );
}
