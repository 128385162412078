import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { Permission } from "@ignite-analytics/permissions";

const listCreateResource = createListResource<Permission>`/permissions/`;
const detailResource = createDetailResource<Permission>`/permissions/${(params) => params.id}/`;

export const { Provider: PermissionContextProvider, useAll: useAllPermissions } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "Permission",
    }
);
