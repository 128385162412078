import { Filter } from "@ignite-analytics/filters";

import { AnalysisFilterInput } from "@/generated/client";

const base = {
    exclude: [],
    include: [],
    max: null,
    min: null,
    offset: null,
    periodUnit: null,
    periodLength: null,
    start: null,
    end: null,
    boolean: null,
    exists: null,
};

// Copied from apps/data-tables
export function mapFromFrontendFiltersToAnalysisFilter(filter: Filter): AnalysisFilterInput | undefined {
    switch (filter.filterType) {
        case "datefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                start: filter.start,
                end: filter.end,
            };
        case "relativedatefilter":
            return {
                ...base,
                field: filter.field,
                start: filter.start,
                end: filter.end,
                filterType: filter.filterType,
                offset: filter.offset,
                periodUnit: filter.periodUnit,
                periodLength: filter.periodLength,
            };
        case "rangefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                min: filter.min,
                max: filter.max,
            };
        case "includefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                include: filter.include,
                includeBlanks: filter.includeBlanks,
            };
        case "excludefilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exclude: filter.exclude,
                excludeBlanks: filter.excludeBlanks,
            };
        case "searchtermfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                searchTerm: filter.searchTerm,
            };
        case "existsfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                exists: filter.exists,
            };
        case "booleanfilter":
            return {
                ...base,
                field: filter.field,
                filterType: filter.filterType,
                boolean: filter.value,
            };
        default:
            throw new Error(`Unsupported filter type - ${filter.filterType}`);
    }
}
