import { toCamelCase } from "@ignite-analytics/api-client";
import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { orderBy } from "lodash";
import { useMemo } from "react";

import { getCurrentDomain } from "../getCurrentDomain";

interface Color {
    id: number;
    order: number;
    colorType: number;
    hex: string;
}

export const useColors = () => {
    const customersUrl =
        getCurrentDomain() === "ignite"
            ? process.env.REACT_APP_CUSTOMERS_API_IGNITE_URL
            : process.env.REACT_APP_CUSTOMERS_API_IGNITEPROCUREMENT_URL;
    const { data: colors } = useQuery({
        queryKey: ["colors"],
        staleTime: 60 * 1000 * 30, // 30 minutes
        queryFn: () =>
            fetch(`${customersUrl}/colors`, {
                credentials: "include",
            })
                .then((res) => {
                    const { status } = res;
                    if (status === 200) {
                        return res.json().then((data: Color[]) => {
                            return toCamelCase(data, false);
                        });
                    }
                    if (status === 404) {
                        return [];
                    }
                    Sentry.captureMessage("Failed to fetch colors", {
                        tags: { app: "analysis-app" },
                        extra: { status },
                    });
                    return [];
                })
                .catch((err) => {
                    Sentry.captureException(err, {
                        tags: { app: "analysis-app" },
                    });
                    return [];
                }),
    });

    return useMemo(() => orderBy(colors, ["order"], ["asc"]), [colors]);
};
