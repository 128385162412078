import { ElasticField } from "@ignite-analytics/elastic-fields";
import { populateFilters } from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { Spec } from "immutability-helper";
import { v4 as uuidv4 } from "uuid";

import { EditableAnalysisWidget } from "../../interfaces";

export const autoSetupComparisonPeriods = (
    relevantDateField: ElasticField | undefined,
    updateWidget: (spec: Spec<EditableAnalysisWidget>) => void
) => {
    if (!relevantDateField) {
        // If we do not know which date field to use, we cannot auto create filters
        // in a good way, so we just copy the value agg we have.
        updateWidget({
            valueConfigurations: (previousValues) => {
                const firstVisibleValueConfig = previousValues.find((agg) => agg.visible !== false);
                if (!firstVisibleValueConfig) {
                    throw Error("No visible values");
                }
                const { uuid, ...valueCopy } = firstVisibleValueConfig;
                return [...previousValues, { ...valueCopy, id: undefined, uuid: uuidv4() }];
            },
        });
        return;
    }
    // Otherwise, we use "This year" for the current value, and "Last year" for the comparison value.
    updateWidget({
        valueConfigurations: (previousValues): typeof previousValues => {
            const firstVisibleValueConfig = previousValues.find((agg) => agg.visible !== false);
            if (!firstVisibleValueConfig) {
                throw Error("No visible values");
            }
            if (firstVisibleValueConfig.type === "script") {
                throw Error("Cannot auto setup comparison periods for a script value");
            }
            const { uuid: _, ...valueCopy } = firstVisibleValueConfig;
            if (!relevantDateField) throw Error("relevantDateField was unexpectedly undefined");
            const prevFilters = firstVisibleValueConfig.filters.filter((f) => f.fieldId !== relevantDateField.fieldId);
            const [thisYearFilter, lastYearFilter] = populateFilters(
                [0, 1].map((offset) => ({
                    type: "date",
                    filterType: "relativedatefilter",
                    field: relevantDateField.field,
                    fieldId: relevantDateField.fieldId,
                    periodUnit: "year",
                    offset,
                    periodLength: 1,
                })),
                [relevantDateField]
            );
            return [
                ...previousValues.map((agg) => {
                    // If the value agg is the first visible value agg, we set it to "This year"
                    if (agg === firstVisibleValueConfig) {
                        return {
                            ...agg,
                            filters: [...prevFilters, thisYearFilter],
                        };
                    }
                    // Otherwise, do nothing with it
                    return agg;
                }),
                { ...valueCopy, id: undefined, uuid: uuidv4(), filters: [...prevFilters, lastYearFilter] },
            ];
        },
    });
};

export const removeTrendArrow = (updateWidget: (spec: Spec<EditableAnalysisWidget>) => void) => {
    updateWidget({
        valueConfigurations: (previousValues) => {
            const [mainValueIndex, baselineValueIndex] = previousValues
                .map((valueConfig, index) => (valueConfig.visible !== false ? index : undefined))
                .filter((index): index is number => index !== undefined);

            return previousValues
                .filter((_, index) => index !== baselineValueIndex)
                .map((valueConfig, index) => {
                    // Don't touch invisible values
                    if (valueConfig.visible === false) return valueConfig;
                    // Should not happen, but added for type safety
                    if (valueConfig.type === "script") return valueConfig;
                    // Remove baseline
                    if (index === baselineValueIndex) return undefined;
                    // Remove filter from main value agg
                    if (index === mainValueIndex) {
                        const { filters, ...rest } = valueConfig;
                        return { ...rest, filters: [] };
                    }
                    // Otherwise, do nothing with it
                    return valueConfig;
                })
                .filter(isNotNullish);
        },
    });
};
