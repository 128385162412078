import { Filter } from "@ignite-analytics/filters";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, InsightModalContextProvider, PivotResponse } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { SplitChangeSpec } from "../PivotTable/interfaces";

import { TableWrapper } from "./components/TableWrapper";

import { useElasticFieldsForFilterContextProvider } from "@/hoc/withFilterContext";
import { useExportData } from "@/hooks/useExportData";

interface Props {
    title: string;
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onSaveDrilldown: ((saveAsNew: boolean) => Promise<void>) | undefined;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onDrilldown?: (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const PivotTableV2: React.FC<Props> = ({
    title,
    data,
    query,
    config,
    editable,
    onSaveDrilldown,
    onSplitChange,
    onDrilldown,
    onHeaderChange,
}) => {
    const exportFn = useExportData(title);
    const navigate = useNavigate();
    const trackingFn = useCallback(
        (event: string, properties?: Record<string, unknown>) => {
            track(event, {
                chartType: "table",
                config,
                ...properties,
            });
        },
        [config]
    );

    const handleOnShowData = useCallback(
        (elasticIndex: string, filters: Filter[]) => {
            navigate(`./data-list-modal/${elasticIndex}/`, { state: { filters } });
        },
        [navigate]
    );

    return (
        <InsightModalContextProvider
            analysisQuery={query}
            onlyAllowRowSplits={false}
            useAggsAsColumns={false}
            onShowData={handleOnShowData}
            onDrilldown={onDrilldown}
            onSave={onSaveDrilldown}
            trackingFn={trackingFn}
            globalFilters={undefined}
            globalFilterIndex={undefined}
            dispatchGlobalFilterAction={undefined}
            exportFn={exportFn}
            useDataFields={useElasticFieldsForFilterContextProvider}
        >
            <TableWrapper
                data={data}
                query={query}
                config={config}
                editable={editable}
                onSplitChange={onSplitChange}
                onHeaderChange={onHeaderChange}
            />
        </InsightModalContextProvider>
    );
};
