import { defineMessages } from "react-intl";

const messages = defineMessages({
    barChart: {
        id: "customAnalysis.chartConfiguration.barChart",
        defaultMessage: "Bar",
    },
    columnChart: {
        id: "customAnalysis.chartConfiguration.columnChart",
        defaultMessage: "Column",
    },
    variwideChart: {
        id: "customAnalysis.chartConfiguration.variwideChart",
        defaultMessage: "Variwide",
    },
    areaChart: {
        id: "customAnalysis.chartConfiguration.areaChart",
        defaultMessage: "Area",
    },
    lineChart: {
        id: "customAnalysis.chartConfiguration.lineChart",
        defaultMessage: "Line",
    },
    waterfallChart: {
        id: "customAnalysis.chartConfiguration.waterfallChart",
        defaultMessage: "Waterfall",
    },
    sunburstChart: {
        id: "customAnalysis.chartConfiguration.sunburstChart",
        defaultMessage: "Sunburst",
    },
    heatMapChart: {
        id: "customAnalysis.chartConfiguration.heatMapChart",
        defaultMessage: "Heatmap",
    },
    bellCurveChart: {
        id: "customAnalysis.chartConfiguration.bellCurveChart",
        defaultMessage: "Bell curve",
    },
    splineChart: {
        id: "customAnalysis.chartConfiguration.splineChart",
        defaultMessage: "Spline",
    },
    bubbleChart: {
        id: "customAnalysis.chartConfiguration.bubbleChart",
        defaultMessage: "Bubble",
    },
    sankeyChart: {
        id: "customAnalysis.chartConfiguration.sankeyChart",
        defaultMessage: "Sankey",
    },
    hierarchyChart: {
        id: "customAnalysis.chartConfiguration.hierarchyChart",
        defaultMessage: "Hierarchy",
    },
    stackNone: {
        id: "customAnalysis.chartConfiguration.stackNone",
        defaultMessage: "Grouped",
    },
    stackStacked: {
        id: "customAnalysis.chartConfiguration.stackStacked",
        defaultMessage: "Stacked",
    },
    zoomNone: {
        id: "customAnalysis.chartConfiguration.zoomNone",
        defaultMessage: "None",
    },
    zoomX: {
        id: "customAnalysis.chartConfiguration.zoomX",
        defaultMessage: "X-axis",
    },
    zoomY: {
        id: "customAnalysis.chartConfiguration.zoomY",
        defaultMessage: "Y-axis",
    },
    zoomXY: {
        id: "customAnalysis.chartConfiguration.zoomXY",
        defaultMessage: "X- and Y-axis",
    },
    labelChartType: {
        id: "customAnalysis.chartConfiguration.labelChartType",
        defaultMessage: "Chart type",
    },
    labelStackOption: {
        id: "customAnalysis.chartConfiguration.labelStackOption",
        defaultMessage: "Stack option",
    },
    labelZoomOption: {
        id: "customAnalysis.chartConfiguration.labelZoomOption",
        defaultMessage: "Zoom option",
    },
    labelShowDataLabels: {
        id: "customAnalysis.chartConfiguration.labelShowDataLabels",
        defaultMessage: "Show data labels",
    },
    labelShowStackedDataLabels: {
        id: "customAnalysis.chartConfiguration.labelShowStackedDataLabels",
        defaultMessage: "Show stacked data labels",
    },
    shortenLabels: {
        id: "customAnalysis.chartConfiguration.shortenLabels",
        defaultMessage: "Shorten length of labels",
    },
    excessXAxisLabels: {
        id: "customAnalysis.chartConfiguration.excessXAxisLabels",
        defaultMessage: "Show overlapping x-axis labels",
    },
    labelAlignLabelsInside: {
        id: "customAnalysis.chartConfiguration.labelAlignLabelsInside",
        defaultMessage: "Align labels inside",
    },
    labelAllowOverlap: {
        id: "customAnalysis.chartConfiguration.labelAllowOverlap",
        defaultMessage: "Show overlapping data labels",
    },
    labelEnableTextOutline: {
        id: "customAnalysis.chartConfiguration.labelEnableTextOutline",
        defaultMessage: "Enable text outline",
    },
    labelShowLegends: {
        id: "customAnalysis.chartConfiguration.labelShowLegends",
        defaultMessage: "Show legends",
    },
    labelShowLineMarkers: {
        id: "customAnalysis.chartConfiguration.labelShowLineMarkers",
        defaultMessage: "Show line markers",
    },
    connectNulls: {
        id: "customAnalysis.chartConfiguration.connectNulls",
        defaultMessage: "Draw line over missing values",
    },
    labelIncludeSum: {
        id: "customAnalysis.chartConfiguration.labelIncludeSum",
        defaultMessage: "Include sum",
    },
    labelDataLabelFontOptions: {
        id: "customAnalysis.chartConfiguration.labelDataLabelFontOptions",
        defaultMessage: "Font size of data labels",
    },
    showTotalCircle: {
        id: "customAnalysis.chartConfiguration.showTotalCircle",
        defaultMessage: "Show total circle",
    },
    calculatePercent: {
        id: "customAnalysis.chartConfiguration.calculatePercent",
        defaultMessage: "Calculate percentages of total in labels",
    },
    showNone: {
        id: "customAnalysis.chartConfiguration.showNone",
        defaultMessage: "Show none",
    },
    showValue: {
        id: "customAnalysis.chartConfiguration.showValue",
        defaultMessage: "Show values in labels",
    },
    addPercent: {
        id: "customAnalysis.chartConfiguration.addPercent",
        defaultMessage: "Add percent in labels",
    },
    addNone: {
        id: "customAnalysis.chartConfiguration.addNone",
        defaultMessage: "None",
    },
    addValue: {
        id: "customAnalysis.chartConfiguration.addValue",
        defaultMessage: "Add values in labels",
    },
    labelAdditionalLabelData: {
        id: "customAnalysis.chartConfiguration.labelAdditionalLabelData",
        defaultMessage: "Additional label data",
    },
    labelDisplayMode: {
        id: "customAnalysis.chartConfiguration.labelDisplayMode",
        defaultMessage: "Label content",
    },
    chartConfigLabel: {
        id: "customAnalysis.chartConfigLabel",
        defaultMessage: "Chart configuration",
    },
    tableConfigLabel: {
        id: "customAnalysis.tableConfigLabel",
        defaultMessage: "Table configuration",
    },
    otherChartConfigLabel: {
        id: "customAnalysis.otherChartConfigLabel",
        defaultMessage: "Other chart options",
    },
    settingsDecimalsPH: {
        id: "customAnalysis.settingsDecimalsPH",
        defaultMessage: "Select no. of decimals",
    },
    settingsDecimalsLabel: {
        id: "customAnalysis.settingsDecimalsLabel",
        defaultMessage: "Number of decimals",
    },
    numDecimalsSelectAlternatives: {
        id: "customAnalysis.numDecimalsSelectAlternatives",
        defaultMessage: "{num, plural, one {# decimal} other {# decimals}}",
    },
    settingsMeasureUnitPH: {
        id: "customAnalysis.settingsMeasureUnitPH",
        defaultMessage: "Select measure unit",
    },
    settingsMeasureUnitLabel: {
        id: "customAnalysis.settingsMeasureUnitLabel",
        defaultMessage: "Measure unit",
    },
    measureUnitAuto: {
        id: "customAnalysis.measureUnitAuto",
        defaultMessage: "Automatic",
    },
    measureUnitDate: {
        id: "customAnalysis.measureUnitDate",
        defaultMessage: "Date",
    },
    measureUnitPercent: {
        id: "customAnalysis.measureUnitPercent",
        defaultMessage: "Percent - %",
    },
    measureUnitOne: {
        id: "customAnalysis.measureUnitOne",
        defaultMessage: "One - 1",
    },
    measureUnitThousand: {
        id: "customAnalysis.measureUnitThousand",
        defaultMessage: "Thousand - K",
    },
    measureUnitMillion: {
        id: "customAnalysis.measureUnitMillion",
        defaultMessage: "Million - M",
    },
    measureUnitBillion: {
        id: "customAnalysis.measureUnitBillion",
        defaultMessage: "Billion - B",
    },
    measureUnitBubbleX: {
        id: "customAnalysis.measureUnitBubbleX",
        defaultMessage: "Measure unit X-axis",
    },
    measureUnitBubbleY: {
        id: "customAnalysis.measureUnitBubbleY",
        defaultMessage: "Measure unit Y-axis",
    },
    aggregationConfiguration: {
        id: "charts.chartConfiguration.aggregationConfiguration",
        defaultMessage: "{field} configuration",
    },
    someTypesAreUnavailable: {
        id: "charts.chartConfiguration.someTypesAreUnavailable",
        defaultMessage: "Some chart types are unavailable because you have chosen more than one value",
    },
    disableYAxisLabels: {
        id: "charts.chartConfiguration.disableYAxisLabels",
        defaultMessage: "Disable labels on Y-axis",
    },
    disableXAxisLabels: {
        id: "charts.chartConfiguration.disableXAxisLabels",
        defaultMessage: "Disable labels on X-axis",
    },
    shareYAxis: {
        id: "charts.chartConfiguration.shareYAxis",
        defaultMessage: "Share Y-axis",
    },
    shareYAxisLeft: {
        id: "charts.chartConfiguration.shareYAxisLeft",
        defaultMessage: "Share left Y-axis",
    },
    shareYAxisRight: {
        id: "charts.chartConfiguration.shareYAxisRight",
        defaultMessage: "Share right Y-axis",
    },
    yAxisBreaks: {
        id: "charts.chartConfiguration.yAxisBreaks",
        defaultMessage: "Y-axis breaks",
    },
    yAxisRange: {
        id: "charts.chartConfiguration.yAxisRange",
        defaultMessage: "Y-axis",
    },
    dontShowLabels: {
        id: "charts.chartConfiguration.dontShowLabels",
        defaultMessage: "Don't show labels",
    },
    showValuesAs: {
        id: "charts.chartConfiguration.showValuesAs",
        defaultMessage: "Show values as {unit}",
    },
    autoDecimals: {
        id: "charts.chartConfigutation.autoDecimals",
        defaultMessage: "Automatic",
    },
    useAggsAsColumns: {
        id: "charts.chartConfigutation.useAggsAsColumns",
        defaultMessage: "Use values as columns",
    },
    absoluteValues: {
        id: "charts.chartConfigutation.absoluteValues",
        defaultMessage: "Use absolute values",
    },
    polarOriented: {
        id: "charts.chartConfigutation.polarOriented",
        defaultMessage: "Polar oriented",
    },
    normalizeOnFirst: {
        id: "charts.chartConfiguration.normalizeOnFirst",
        defaultMessage: "Normalize on first value",
    },
    totalValueRow: {
        id: "charts.chartConfiguration.totalValueRow",
        defaultMessage: "Total value rows",
    },
    totalValueColumn: {
        id: "charts.chartConfiguration.totalValueColumn",
        defaultMessage: "Total value columns",
    },
    deNormalizeHeaders: {
        id: "charts.chartConfiguration.deNormalizeHeaders",
        defaultMessage: "Ungroup table headers",
    },
    columnAlignment: {
        id: "charts.chartConfiguration.columnAlignment",
        defaultMessage: "Column alignment",
    },
    alignLeft: {
        id: "charts.chartConfiguration.alignLeft",
        defaultMessage: "Left",
    },
    alignCenter: {
        id: "charts.chartConfiguration.alignCenter",
        defaultMessage: "Center",
    },
    alignRight: {
        id: "charts.chartConfiguration.alignRight",
        defaultMessage: "Right",
    },
    placeholderSelectYAxis: {
        id: "charts.chartConfiguration.placeholderSelectYAxis",
        defaultMessage: "Select value fields",
    },
    verticalAlignBottomRow: {
        id: "charts.chartConfiguration.verticalAlignBottomRow",
        defaultMessage: "Vertical align bottom row",
    },
    auto: {
        id: "charts.chartConfiguration.auto",
        defaultMessage: "Auto",
    },
    labelFrom: {
        id: "charts.chartConfiguration.labelFrom",
        defaultMessage: "{label} - from",
    },
    labelTo: {
        id: "charts.chartConfiguration.labelTo",
        defaultMessage: "{label} - to",
    },
    valuesOverColumns: {
        id: "customAnalysis.chartConfiguration.valuesOverColumns",
        defaultMessage: "Values over columns",
    },
    fillAvailableSpace: {
        id: "charts.chartConfiguration.fillAvailableSpace",
        defaultMessage: "Fill available space",
    },
    disableStickyHeaders: {
        id: "charts.chartConfiguration.disableStickyHeaders",
        defaultMessage: "Disable sticky headers",
    },
    wrapHeaderLabels: {
        id: "charts.chartConfiguration.wrapHeaderLabels",
        defaultMessage: "Make headers break",
    },
});

export default messages;
