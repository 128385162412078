import { ElasticIndex } from "@ignite-analytics/elastic-fields";
import { TRANSACTIONS_GT } from "@ignite-analytics/global-types";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { DataTable as DataTableV2, useGetManyDataTablesQuery } from "@/generated/client";

export const getIndexNameForDataTableV2 = (dataTable?: DataTableV2 | null) => {
    if (!dataTable) return undefined;
    return `datatable_v2_${dataTable.id}`;
};

export const useIndexWithType = (globalTypeKey: string): ElasticIndex | undefined => {
    const { result: getManyDataTablesResult } = useGetManyDataTablesQuery({});
    if (getManyDataTablesResult.type === "success") {
        const dataTable = getManyDataTablesResult.data.entities.find((table) => table.globalTypeKey === globalTypeKey);
        if (dataTable) {
            const dataSourceName = getIndexNameForDataTableV2(dataTable);
            return dataSourceName
                ? {
                      name: dataSourceName,
                      label: dataTable.name,
                      globalTypeKey: dataTable.globalTypeKey ?? undefined,
                  }
                : undefined;
        }
    }
    return undefined;
};

export const useTransactionsIndex = () => useIndexWithType(TRANSACTIONS_GT);

export const useElasticIndexInContextByName = (elasticIndexName: string | undefined): ElasticIndex | undefined => {
    const elasticIndices = useAllElasticIndices();
    if (elasticIndexName === undefined) return;
    const elasticIndex = elasticIndices?.find(({ name }) => name === elasticIndexName);
    return elasticIndex
        ? {
              name: elasticIndex.name,
              label: elasticIndex.label ?? elasticIndex.name,
              globalTypeKey: elasticIndex.globalTypeKey,
          }
        : undefined;
};

export const useFirstElasticIndexInContext = (): ElasticIndex | undefined => {
    const elasticIndices = useAllElasticIndices();
    const elasticIndex = elasticIndices?.[0];
    return elasticIndex
        ? {
              name: elasticIndex.name,
              label: elasticIndex.label ?? elasticIndex.name,
              globalTypeKey: elasticIndex.globalTypeKey,
          }
        : undefined;
};
