import { useElasticFields } from "@ignite-analytics/elastic-fields";
import {
    FilterContextProvider,
    useCompanyFavouriteFilters,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { ChartConfig, defaultConfig, getDefaultAggConfig } from "@ignite-analytics/pivot-charts";
import { asAnalysisItem } from "@ignite-analytics/pivot-ts";
import update, { Spec } from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { ChartConfigTypes } from "../ConfigurationSidePanel/interfaces";
import { asEditableWidget, getInitialValueConfig } from "../helpers";
import { EditableAnalysisWidget } from "../interfaces";
import messages from "../messages";

import { CHART_VIEW } from "@/components/Widgets/constants";
import { AnalysisWidget } from "@/components/Widgets/interfaces";
import { staticFormatMessage } from "@/contexts/intlContext";
import { useCurrentDepartment } from "@/entities/departments";
import { useElasticFieldsForFilterContextProvider } from "@/hoc//withFilterContext";
import {
    useElasticIndexInContextByName,
    useFirstElasticIndexInContext,
    useTransactionsIndex,
} from "@/hooks/useElasticIndexWithType";
import { getCurrentDomain } from "@/lib/getCurrentDomain";

export const CACHED_INDEX_KEY = "custom-analysis-index";

export const CAContext = React.createContext<{
    openWidget: EditableAnalysisWidget | undefined;
    openModal: string | undefined;
    defaultDashboardId: number | undefined;
    setOpenModal: (modalKey: string | undefined) => void;
    updateWidget: (spec: Spec<EditableAnalysisWidget>) => void;
    updateChartConfigField: (path: (keyof ChartConfigTypes | number)[], value: any) => void;
    resetWidget: (updatedIndex?: string) => void;
    setWidget: (widget: AnalysisWidget | undefined) => void;
} | null>(null);

interface Props {
    children: React.ReactNode;
    initialWidget?: AnalysisWidget;
    defaultDashboardId?: number;
    onChange?: (widget: AnalysisWidget | undefined) => void;
}

/**
 * CAContextProvider
 * Creates a context with a state for all components in the CustomAnalysis module.
 */
const CAContextProvider: React.FC<Props> = ({ children, initialWidget, defaultDashboardId, onChange }) => {
    const [openWidget, setOpenWidget] = useState<EditableAnalysisWidget | undefined>(
        initialWidget && asEditableWidget(initialWidget)
    );

    const [openModal, setOpenModal] = useState<string>();
    const [filterResetKey, setFilterResetKey] = useState(0);

    const setWidget = useCallback((widget: AnalysisWidget | undefined) => {
        const newWidget = widget && asEditableWidget(widget);
        setOpenWidget(newWidget);
    }, []);

    useEffect(
        function resetWidgetOnIdChange() {
            if (openWidget?.id !== initialWidget?.id) {
                setWidget(initialWidget);
            }
        },
        [initialWidget, openWidget?.id, setWidget]
    );

    const department = useCurrentDepartment();

    const transactionsIndex = useTransactionsIndex();
    const fallBackIndex = useFirstElasticIndexInContext();
    const cachedIndex = useElasticIndexInContextByName(localStorage.getItem(CACHED_INDEX_KEY) ?? undefined);
    const _currentElasticIndex = useElasticIndexInContextByName(openWidget?.elasticIndex);

    const currentElasticIndex = useMemo(
        () => _currentElasticIndex || cachedIndex || transactionsIndex || fallBackIndex,
        [_currentElasticIndex, cachedIndex, transactionsIndex, fallBackIndex]
    );
    const elasticFields = useElasticFields(currentElasticIndex?.name, false);

    const resetWidget = useCallback(
        (updatedIndex?: string) => {
            if (!department) return;
            const elasticIndex = updatedIndex || currentElasticIndex?.name;
            if (!elasticIndex || !elasticFields) return;

            setFilterResetKey((prev) => prev + 1);
            setWidget({
                // Custom dashboard should only be set to -1 as a fallback
                customDashboard: -1,
                // Unpack any relevant properties from the old widget (including correct customDashboard)
                ...openWidget,
                // Override all properties that should be reset
                type: "ANALYSIS_NEW",
                title: staticFormatMessage(messages.defaultAnalysisTitle),
                modelName: "analysiswidget",
                filters: [],
                elasticIndex,
                rowSplitItems: [],
                columnSplitItems: [],
                valueConfigurations: [asAnalysisItem(getInitialValueConfig(elasticIndex, elasticFields))],
                chartConfiguration: { ...defaultConfig, optionsPerAgg: [getDefaultAggConfig(0)] },
                viewState: CHART_VIEW,
            });
        },
        [department, currentElasticIndex?.name, setWidget, openWidget, elasticFields]
    );

    const updateWidget = useCallback((spec: Spec<EditableAnalysisWidget>) => {
        setOpenWidget((prev) => prev && asEditableWidget(update(prev, spec)));
    }, []);

    const updateChartConfigField = useCallback(
        (path: (keyof ChartConfigTypes | number)[], value: any): ReturnType<typeof updateWidget> => {
            const spec = path.reduceRight<Spec<ChartConfig>>((val, key) => ({ [key]: val }), { $set: value });
            updateWidget({ chartConfiguration: spec });
        },
        [updateWidget]
    );

    const sendWidgetChange = () => onChange?.(openWidget);
    useEffect(sendWidgetChange, [onChange, openWidget]);

    const values = useMemo(
        () => ({
            openWidget,
            openModal,
            defaultDashboardId,
            setWidget,
            resetWidget,
            updateWidget,
            setOpenModal,
            updateChartConfigField,
        }),
        [openWidget, openModal, defaultDashboardId, setWidget, resetWidget, updateWidget, updateChartConfigField]
    );

    const graphqlRouterUrl = getCurrentDomain() === "ignite" ? process.env.REACT_APP_GRAPHQL_ROUTER_IGNITE_URL : process.env.REACT_APP_GRAPHQL_ROUTER_IGNITEPROCUREMENT_URL;
    const usePersonalFavouriteFiltersForContext = () =>
        usePersonalFavouriteFilters("deprecated-not-used", graphqlRouterUrl as string);
    const useCompanyFavouriteFiltersForContext = () =>
        useCompanyFavouriteFilters(graphqlRouterUrl as string);

    const upsertPersonalFavouriteFilters = useUpsertPersonalFavouriteFilters(
        "deprecated-not-used",
        graphqlRouterUrl as string
    );

    return (
        <CAContext.Provider value={values}>
            {currentElasticIndex && (
                <FilterContextProvider
                    inherit={false}
                    dataSource={currentElasticIndex}
                    initialFilters={initialWidget?.filters}
                    key={filterResetKey}
                    debugName={initialWidget ? `Edit mode: ${initialWidget.title}` : "New custom analysis widget"}
                    disableDefaultCombination
                    useDataFields={useElasticFieldsForFilterContextProvider}
                    // Favourite filter hooks
                    usePersonalFavouriteFilters={usePersonalFavouriteFiltersForContext}
                    upsertPersonalFavouriteFilters={upsertPersonalFavouriteFilters}
                    useCompanyFavouriteFilters={useCompanyFavouriteFiltersForContext}
                >
                    {children}
                </FilterContextProvider>
            )}
        </CAContext.Provider>
    );
};

export default CAContextProvider;
