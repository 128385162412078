import {
    X as CloseIcon,
    ArrowsUpDown as VerticalSwapIcon,
    ArrowsRightLeft as HorizontalSwapIcon,
} from "@ignite-analytics/icons";
import { MenuItem, Paper, Select, Stack, Typography, alpha } from "@mui/material";
import update from "immutability-helper";
import React from "react";

import ExpressionComponent from "..";
import WrapperWithButtons from "../WrapperWithButtons";
import { PositionedIconButton } from "../WrapperWithButtons/style";

import { OPERATOR_ICONS } from "./constants";
import { getOperatorsForExpressionType } from "./helpers";
import { ExpressionType, isComparativeOperator, isNumericalOperator } from "./interfaces";

import { BinaryExpressionDraft, ExpressionDraft, LogicalExpressionDraft } from "@/components/ScriptModal/interfaces";
import { fm } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    expression: BinaryExpressionDraft | LogicalExpressionDraft;
    elasticIndex: string;
    level: number;
    onChange: (expression: ExpressionDraft) => void;
    forcedType: ExpressionType;
    vertical: boolean;
}

const BinaryExpression: React.FC<Props> = ({ expression, forcedType, level, onChange, vertical, elasticIndex }) => {
    const { left, right, operator } = expression;
    const availableOperators = getOperatorsForExpressionType(forcedType);
    const nextLevel = level + 1;
    const bgWeight = (level * 10) / 100;
    const forcedChildType: ExpressionType =
        isNumericalOperator(operator) || isComparativeOperator(operator) ? "number" : "boolean";
    return (
        <WrapperWithButtons onChange={(spec) => onChange(update(expression, spec))}>
            <Paper
                variant="outlined"
                sx={{ width: "100%", p: 2, backgroundColor: (theme) => alpha(theme.palette.info.main, bgWeight) }}
            >
                <Stack direction={vertical ? "column" : "row"} spacing={1} alignItems="center" justifyContent="center">
                    <ExpressionComponent
                        onChange={(newLeft) => onChange({ ...expression, left: newLeft })}
                        expression={left}
                        level={nextLevel}
                        forcedType={forcedChildType}
                        vertical={!vertical}
                        elasticIndex={elasticIndex}
                    />
                    <WrapperWithButtons margin="0.5em">
                        {((right && !left) || (left && !right)) && (
                            <PositionedIconButton
                                color="ghostGray"
                                bgColor="error"
                                size="2xsmall"
                                position="top-right"
                                onClick={() => onChange(left ?? right)}
                            >
                                <CloseIcon fontSize="inherit" />
                            </PositionedIconButton>
                        )}
                        <PositionedIconButton
                            color="ghostGray"
                            position={vertical ? "right" : "top"}
                            size="2xsmall"
                            onClick={(e) => {
                                onChange({ ...expression, right: left, left: right });
                                e.currentTarget.blur();
                            }}
                            forceShow
                        >
                            {vertical ? (
                                <VerticalSwapIcon fontSize="inherit" />
                            ) : (
                                <HorizontalSwapIcon fontSize="inherit" />
                            )}
                        </PositionedIconButton>
                        <Select
                            name="operator"
                            value={operator}
                            onChange={(e) => onChange({ ...expression, operator: e.target.value })}
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.paper,
                                borderRadius: 1,
                            }}
                        >
                            {availableOperators.map((op) => {
                                const OperatorIcon = OPERATOR_ICONS[op];
                                return (
                                    <MenuItem key={op} value={op}>
                                        <Stack direction="row" justifyContent="center" alignItems="center">
                                            <Typography variant="textMd" align="center">
                                                {op === "&&" ? (
                                                    fm(globalMessages.and)
                                                ) : op === "||" ? (
                                                    fm(globalMessages.or)
                                                ) : typeof OPERATOR_ICONS[op] === "string" ? (
                                                    <b>{OPERATOR_ICONS[op]?.toString()}</b>
                                                ) : (
                                                    <OperatorIcon fontSize="small" />
                                                )}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </WrapperWithButtons>
                    <ExpressionComponent
                        onChange={(newRight) => onChange({ ...expression, right: newRight })}
                        expression={right}
                        level={nextLevel}
                        forcedType={forcedChildType}
                        vertical={!vertical}
                        elasticIndex={elasticIndex}
                    />
                </Stack>
            </Paper>
        </WrapperWithButtons>
    );
};

export default BinaryExpression;
