import { Stack } from "@mui/material";
import React, { useState } from "react";
import { useLocation, useParams } from "react-router-dom-v5-compat";

import CustomAnalysis from "./CustomAnalysis";
import CAContextProvider from "./CustomAnalysis/CAContextProvider";
import CustomAnalysisSkeleton from "./CustomAnalysisSkeleton";

import { useWidget } from "@/components/Widgets/services";

/**
 * Wrapper to provide the context to the CustomAnalysis component and get widget if widgetId is provided in the URL
 *
 */
const CustomAnalysisPage: React.FC = () => {
    const { widgetId } = useParams<{ widgetId: string }>();
    const location = useLocation();
    const [initialDashboardId] = useState(location.state?.dashboardId);
    const { widget, error, loading } = useWidget("analysiswidget", widgetId);

    // useSetBreadcrumbs(widget?.title);

    if (loading || error) {
        return (
            <Stack alignItems="center" sx={{ height: "100vh", maxWidth: "2000px" }}>
                <CustomAnalysisSkeleton />
            </Stack>
        );
    }

    return (
        <CAContextProvider initialWidget={widget} defaultDashboardId={Number(initialDashboardId) ?? undefined}>
            <Stack sx={{ maxWidth: "2000px" }}>
                <CustomAnalysis />
            </Stack>
        </CAContextProvider>
    );
};

export default CustomAnalysisPage;
