import { isNotNullish } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { LabelledAnalysisQuery, PivotColumnData } from "@ignite-analytics/pivot-ts";
import { ColumnDef } from "@tanstack/react-table";
import { IntlShape } from "react-intl";

import { getColumnWidth, getAccessorKey } from "../helpers/columns";
import { PivotTableRow } from "../interfaces";

import { getAggDescription, getAggregationHeaders } from "./aggregation";

const getInvisibleColumnsForTotalWithAggregationHeaders = (
    query: LabelledAnalysisQuery,
    intl: IntlShape,
    config: ChartConfig,
    key: string,
    zeroStopCounter: number,
    width: number
): ColumnDef<PivotTableRow>[] => [
        {
            header: key,
            columns:
                zeroStopCounter > 0
                    ? getInvisibleColumnsForTotalWithAggregationHeaders(
                          query,
                          intl,
                          config,
                          key,
                          zeroStopCounter - 1,
                          width
                      )
                    : getAggregationHeaders(query, key, intl, config, width),
            meta: {
                headerType: "normal",
                isHiddenTotalRow: true,
                columnWidthId: key,
            },
        },
    ];
const getColumnsWithAggregationsOnBottom = (
    query: LabelledAnalysisQuery,
    columns: PivotColumnData[],
    key: string,
    intl: IntlShape,
    config: ChartConfig,
    width: number,
    parentColumnPath?: PivotColumnData[]
): ColumnDef<PivotTableRow>[] => {
    if (query.columnSplitItems.length === 0) {
        const columnKey = `${key}_${columns[0]?.name}`.replaceAll(".", "_");
        return getAggregationHeaders(query, columnKey, intl, config, width);
    }
    return columns.map((column) => {
        const columnKey = getAccessorKey(`${key}_${column.name}`);
        const columnPath = [...(parentColumnPath ?? []), column];
        if (column.children) {
            return {
                header: column.name,
                columns: getColumnsWithAggregationsOnBottom(
                    query,
                    column.children,
                    columnKey,
                    intl,
                    config,
                    width,
                    columnPath
                ),
                meta: {
                    headerType: "normal",
                    columnPath,
                    columnWidthId: columnKey,
                },
            };
        }
        if (column.meta.isTotalRow && query.columnSplitItems.length > 1) {
            return {
                header: column.name,
                columns: getInvisibleColumnsForTotalWithAggregationHeaders(
                    query,
                    intl,
                    config,
                    columnKey,
                    query.columnSplitItems.length - 2,
                    width
                ),
                meta: {
                    headerType: "normal",
                    columnPath,
                    rowSpan: query.columnSplitItems.length,
                    columnWidthId: columnKey,
                },
            };
        }
        return {
            header: column.name,
            columns: getAggregationHeaders(query, columnKey, intl, config, width),
            meta: {
                headerType: "normal",
                columnPath,
                columnWidthId: columnKey,
            },
        };
    });
};

const getColumnsWithAggregationsOnTop = (
    columns: PivotColumnData[],
    key: string,
    query: LabelledAnalysisQuery,
    intl: IntlShape,
    index: number,
    config: ChartConfig,
    width: number,
    parentColumnPath?: PivotColumnData[]
): ColumnDef<PivotTableRow>[] => columns.map((column) => {
        const columnKey = getAccessorKey(`${key}_${column.name}`);
        const columnPath = [...(parentColumnPath ?? []), column];
        if (column.children) {
            return {
                header: column.name,
                columns: getColumnsWithAggregationsOnTop(
                    column.children,
                    columnKey,
                    query,
                    intl,
                    index,
                    config,
                    width,
                    columnPath
                ),
                meta: {
                    headerType: "normal",
                    columnPath,
                    columnWidthId: columnKey,
                },
            };
        }
        const accessorKey = getAccessorKey(`${key}_${column.name}_${index}`);
        const totalMeta = column.meta.isTotalRow ? { rowSpan: query.columnSplitItems.length } : {};
        const size = getColumnWidth(config, accessorKey);
        return {
            accessorKey: getAccessorKey(accessorKey),
            header: column.name,
            size: size || width,
            meta: {
                headerType: "normal",
                columnPath,
                columnWidthId: accessorKey,
                ...totalMeta,
            },
        };
    });

export const getRegularColumns = (
    query: LabelledAnalysisQuery,
    columns: PivotColumnData[],
    intl: IntlShape,
    config: ChartConfig,
    width: number
) => {
    if (config.valuesOverColumns) {
        if (query.columnSplitItems.length === 0) {
            const columnKey = getAccessorKey(`_${columns[0]?.name}`);
            return getAggregationHeaders(query, columnKey, intl, config, width);
        }
        return query.valueAggregationItems
            .map((valueAggregationItem, index) => {
                if (config.optionsPerAgg[index]?.isHidden) {
                    return null;
                }
                const aggDescription = getAggDescription(valueAggregationItem, index, intl, config);
                return {
                    header: aggDescription.customHeaderName ?? aggDescription.headerName,
                    columns: getColumnsWithAggregationsOnTop(columns, "", query, intl, index, config, width),
                    meta: {
                        headerType: "aggregation" as const,
                        defaultHeaderName: aggDescription.headerName,
                        valueAggregationIndex: index,
                        columnWidthId: getAccessorKey(`${aggDescription.headerName}_${index}`),
                    },
                };
            })
            .filter(isNotNullish);
    }
    return getColumnsWithAggregationsOnBottom(query, columns, "", intl, config, width);
};
