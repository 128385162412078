import { isNullOrUndefined } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AggregationItem, Labelled, SplitItem } from "@ignite-analytics/pivot-ts";
import { IntlShape } from "react-intl";

import { getAggDescription } from "../InnerTable/columnGenerators/aggregation";

import messages from "./messages";

import { staticFormatMessage } from "@/contexts/intlContext";

export const getSortDescription = (
    aggItems: Labelled<AggregationItem>[],
    { sortAggIndex, sortOrder }: SplitItem,
    intl: IntlShape,
    config: ChartConfig
) => {
    if (!sortOrder) return "";
    const aggDescriptionName =
        !isNullOrUndefined(sortAggIndex) && !isNullOrUndefined(aggItems[sortAggIndex])
            ? getAggDescription(aggItems[sortAggIndex], sortAggIndex, intl, config)
            : undefined;
    return staticFormatMessage(messages.sorting, {
        order: staticFormatMessage(messages[sortOrder]),
        field: aggDescriptionName
            ? aggDescriptionName.customHeaderName ?? aggDescriptionName.headerName
            : staticFormatMessage(messages.name),
    });
};
