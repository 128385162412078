import { defineMessages } from "react-intl";

const messages = defineMessages({
    filterInfoFrom: {
        id: "components.chart.filterInfoFrom",
        defaultMessage: "From {start}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoTo: {
        id: "components.chart.filterInfoTo",
        defaultMessage: "To {end}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoMin: {
        id: "components.chart.filterInfoMin",
        defaultMessage: "Min {min}",
        description: "Filter information at the bottom of the chart",
    },
    filterInfoMax: {
        id: "components.chart.filterInfoMax",
        defaultMessage: "Max {max}",
        description: "Filter information at the bottom of the chart",
    },
    noActiveFilters: {
        id: "components.chart.noActiveFilters",
        defaultMessage: "No active filters",
        description: "Filter information at the bottom of the chart",
    },
    activeTenders: {
        id: "components.widgets.activeTenders",
        defaultMessage: "Active tenders",
        description: "At least part of the new widget menu",
    },
    contractLoyalty: {
        id: "components.widgets.contractLoyalty",
        defaultMessage: "Contract loyalty",
        description: "At least part of the new widget menu",
    },
    customAnalysis: {
        id: "components.widgets.customAnalysis",
        defaultMessage: "Custom analysis",
        description: "At least part of the new widget menu",
    },
    fieldDistributions: {
        id: "components.widgets.fieldDistributions",
        defaultMessage: "Spend composition",
        description: "At least part of the new widget menu",
    },
    fieldHistory: {
        id: "components.widgets.fieldHistory",
        defaultMessage: "Development",
        description: "At least part of the new widget menu",
    },
    largestFields: {
        id: "components.widgets.largestFields",
        defaultMessage: "Largest fields",
        description: "At least part of the new widget menu",
    },
    mintecCommodities: {
        id: "components.widgets.mintecCommodities",
        defaultMessage: "Mintec Commodities",
        description: "At least part of the new widget menu",
    },
    notifications: {
        id: "components.widgets.notifications",
        defaultMessage: "Notifications",
        description: "At least part of the new widget menu",
    },
    potential: {
        id: "components.widgets.potential",
        defaultMessage: "Potential",
        description: "At least part of the new widget menu",
    },
    ongoingAssessments: {
        id: "components.widgets.ongoingAssessments",
        defaultMessage: "My ongoing assessments",

        description: "At least part of the new widget menu",
    },
    risk: {
        id: "components.widgets.risk",
        defaultMessage: "Risk",
        description: "At least part of the new widget menu",
    },
    spendDevelopment: {
        id: "components.widgets.spendDevelopment",
        defaultMessage: "Spend development",
        description: "At least part of the new widget menu",
    },
    upcomingTasks: {
        id: "components.widgets.upcomingTasks",
        defaultMessage: "Upcoming tasks",
        description: "At least part of the new widget menu",
    },
    copyWidget: {
        id: "components.widgets.copyWidget",
        defaultMessage: "Make copy of widget",
    },
    tasks: {
        id: "components.widgets.tasks",
        defaultMessage: "Tasks",
        description: "Tasks widget",
    },
    viewPermissionDenied: {
        id: "hoc.widgetWrapper.viewPermissionDenied",
        defaultMessage: "You do not have the permission to view the content of this widget",
        description: "",
    },
    widgetNotInDepartment: {
        id: "components.widgets.editModals.customAnalysisModal.widgetNotInDepartment",
        defaultMessage:
            "You are not allowed to edit this widget. The widget and dashboard was not created in this department or a sub-department. Create a copy of the dashboard and edit the copy to be able to save.",
    },
    somethingWentWrongSaving: {
        id: "components.widgets.editModals.customAnalysisModal.somethingWentWrongSaving",
        defaultMessage: "Something went wrong when saving this widget!",
    },
    editSettings: {
        id: "components.widgets.editSettings",
        defaultMessage: "Edit widget settings",
    },
    savingsRunRate: {
        id: "components.widgets.savingsRunRate",
        defaultMessage: "Savings run rate",
    },
    mintecCommoditiesTooltip: {
        id: "components.widgets.mintecCommoditiesTooltip",
        defaultMessage: "See Mintec Commodity Integration in Library to activate",
        description: "Mintec Commodities Widget tooltip",
    },
    benchmarkWidgetTooltip: {
        id: "components.widgets.benchmarkWidgetTooltip",
        defaultMessage: "If not activated, contact Ignite to see if you are eligible for benchmarking with peers.",
        description: "Benchmark Widget tooltip",
    },
    customPeriod: {
        id: "components.filter.customPeriod",
        defaultMessage: "Aggregation period",
    },
    querySize: {
        id: "components.filter.querySize",
        defaultMessage: "Number of elements",
    },
    minSpendLtm: {
        id: "components.filter.minSpendLtm",
        defaultMessage: "Min spend LTM",
    },
    supplierNumber: {
        id: "components.filter.supplierNumber",
        defaultMessage: "Max number of suppliers",
    },
    categoryLevel: {
        id: "components.filter.categoryLevel",
        defaultMessage: "Category level",
    },
    timePeriod: {
        id: "components.filter.timePeriod",
        defaultMessage: "Time period CAGR (years)",
    },
    selectedBenchmarks: {
        id: "components.filter.selectedBenchmarks",
        defaultMessage: "Selected Benchmark Fields",
    },
    selectedCommodity: {
        id: "components.filter.selectedCommodity",
        defaultMessage: "Selected Mintec Commodity",
    },
    interval: {
        id: "components.filter.interval",
        defaultMessage: "Date interval",
    },
    filterMinDifference: {
        id: "analyze.prioritize.largestDeviationFilterMinDifference",
        defaultMessage: "Minimum difference",
    },
});

export default messages;
