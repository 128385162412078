import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { ModelGuard } from "@ignite-analytics/permissions";

const listCreateResource = createListResource<ModelGuard>`/permissions/model-guards/`;
const detailResource = createDetailResource<ModelGuard>`/permissions/model-guards/${(params) => params.id}/`;

export const { Provider: ModelGuardContextProvider, useAll: useAllModelGuards } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "modelGuard",
    }
);
