import { defineMessages } from "react-intl";

const messages = defineMessages({
    addValue: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.addValue",
        defaultMessage: "Add a value",
    },
    selectField: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.selectField",
        defaultMessage: "Select a field",
    },
    selectScript: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.selectScript",
        defaultMessage: "Select a script",
    },
    selectValue: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.selectValue",
        defaultMessage: "Select a value",
    },
    dataField: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.valueOptions.dataField",
        defaultMessage: "Data field",
    },
    helperScript: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.valueOptions.helperScript",
        defaultMessage: "Helper script",
    },
    constant: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.valueOptions.constant",
        defaultMessage: "Constant number",
    },
    ifElse: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.valueOptions.ifElse",
        defaultMessage: "If/else operation",
    },
    comparison: {
        id: "components.ScriptModal.expressionComponent.selectNewValue.valueOptions.comparison",
        defaultMessage: "Comparison",
    },
});

export default messages;
