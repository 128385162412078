export const DEPRECATED_WIDGET_MODEL = "deprecatedwidget";
export const ANALYSIS_WIDGET_MODEL = "analysiswidget";
export const ONGOING_ASSESSMENTS_WIDGET_MODEL = "ongoingassessmentswidget";
export const LARGEST_FIELD_WIDGET_MODEL = "largestfieldwidget";
export const TABLE_VIEW = "table";
export const CHART_VIEW = "chart";
export const DISPLAY_COMPARISION = "displayComparison";
export const CATEGORY_LEVEL = "categoryLevel";
export const SUPPLIER_COUNT = "supplierCount";
export const SUPPLIER_NUMBER = "supplierNumber";
export const MIN_SPEND_LTM = "minSpendLtm";
export const SPEND_LIMIT = "spendLimit";
export const TIME_PERIOD = "timePeriod";
export const QUERY_SIZE = "querySize";
export const PERCENTAGE_LIMIT = "percentageLimit";
export const CUSTOM_PERIOD = "period";
export const SELECTED_FIELD = "selectedField";
export const SELECTED_FILTER_FIELD = "selectedField";
export const VIEW_MODE = "viewState";
export const MIN_DIFF = "minDifference";
export const SELECTED_COMMODITY = "selectedCommodity";
export const INTERVAL = "interval";
export const SELECTED_BENCHMARKS = "selectedBenchmarks";
export const STATE_FIELD = "state";
export const TEXT_ALIGNMENT = "textAlignment";
