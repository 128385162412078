import { useFilters } from "@ignite-analytics/filters";
import { Undo as UndoIcon } from "@ignite-analytics/icons";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, InsightModal, PivotResponse, useInsightModalContext } from "@ignite-analytics/pivot-ts";
import { Stack, Tooltip, IconButton } from "@mui/material";
import React from "react";

import { InnerTable } from "../InnerTable";

import { SplitChangeSpec } from "@/containers/CustomAnalysisPage/CustomAnalysis/PivotTable/interfaces";
import { staticFormatMessage } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const TableWrapper: React.FC<Props> = ({ data, query, config, editable, onSplitChange, onHeaderChange }) => {
    const { modalState, revertDrilldown, dirty } = useInsightModalContext();
    const filters = useFilters();
    return (
        <>
            <InnerTable
                data={data}
                query={query}
                config={config}
                editable={editable}
                onSplitChange={onSplitChange}
                onHeaderChange={onHeaderChange}
            />
            {dirty && (
                <Stack
                    sx={{
                        position: "absolute",
                        zIndex: 1,
                        right: 0,
                        marginRight: "1.5em",
                    }}
                    direction="row"
                >
                    <Tooltip title={staticFormatMessage(globalMessages.undoButton)}>
                        <IconButton onClick={revertDrilldown} size="2xsmall">
                            <UndoIcon color="warning" fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            )}
            {modalState && <InsightModal activeFilters={filters} />}
        </>
    );
};
