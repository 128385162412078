import { defineMessages } from "react-intl";

const messages = defineMessages({
    somethingWentWrong: {
        id: "errorboundary.fallback.somethingWentWrong",
        defaultMessage:
            "Our team has been informed. You can attempt to refresh the page. If the issue persists, please reach out to our support team for assistance.",
    },
    error: {
        id: "errorboundary.fallback.error",
        defaultMessage: "Something went wrong!",
    },
    refresh: {
        id: "errorboundary.fallback.refresh",
        defaultMessage: "Refresh page",
    },
});
export default messages;
