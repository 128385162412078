import { Typography, Box, Divider } from "@mui/material";
import React, { useEffect, useRef } from "react";

import { DropZone } from "../../interfaces";
import { FieldDragItem } from "../DraggableFieldItem/interfaces";

import { DropBox, MaxHeightContent, ScrollDiv, StyledCard } from "./style";

const FIELDS_CONTAINER = "fieldsContainer-";

interface OwnProps {
    zone: DropZone;
    placeholder: string;
    header: JSX.Element;
    children?: (JSX.Element | undefined)[];
    search?: React.ReactNode;
    height?: string;
    autoGrow?: boolean;
    maxHeight?: string;
    dragItem?: FieldDragItem;
    dropTarget?: string;
    setDropTarget?: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const FieldsContainer = (props: OwnProps) => {
    const {
        placeholder,
        children,
        search,
        zone,
        header,
        autoGrow,
        height,
        maxHeight,
        dragItem,
        dropTarget,
        setDropTarget,
    } = props;

    const dragOverCounterRef = useRef<number>(0);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (!dragItem || e.currentTarget.id === dropTarget) return;
        setDropTarget && setDropTarget(e.currentTarget.id);
    };

    const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragOverCounterRef.current += 1;
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        dragOverCounterRef.current -= 1;
        if (dragOverCounterRef.current > 0) return;
        setDropTarget && setDropTarget(undefined);
    };

    useEffect(
        function resetDragOverCounter() {
            if (!dragItem) {
                dragOverCounterRef.current = 0;
            }
        },
        [dragItem]
    );

    const showDropField = dragItem && dropTarget?.split("-")[0] === zone && dragItem.startIndex.split("-")[0] !== zone;

    return (
        <StyledCard
            id={`ca-zone-${zone}`}
            data-testid={`${FIELDS_CONTAINER}${zone}Zone`}
            autoGrow={autoGrow}
            sx={{
                height,
                px: 0.5,
                pb: 0.5,
            }}
            showDropField={showDropField}
        >
            {header}
            {search && <>{search}</>}
            <Divider />
            <MaxHeightContent>
                <Box sx={{ height: "100%" }}>
                    <DropBox
                        active={showDropField}
                        id={`${zone}-${children?.length ?? 0}`}
                        onDragOver={handleDragOver}
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                    >
                        <div
                            style={{ width: "100%", height: "100%" }}
                            id={`fieldsContainer-${zone}`}
                            data-testid={`${FIELDS_CONTAINER}${zone}`}
                        >
                            <ScrollDiv style={{ maxHeight }}>{children}</ScrollDiv>
                            {(showDropField || !children?.length) && (
                                <Typography p={1} variant="textSm">
                                    {placeholder}
                                </Typography>
                            )}
                        </div>
                    </DropBox>
                </Box>
            </MaxHeightContent>
        </StyledCard>
    );
};

export default FieldsContainer;
