import { useEffect } from "react";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export function useSetBreadcrumbs(title?: string) {
    useEffect(() => {
        let payload: { text: string; href?: string }[] = [
            { text: fm(messages.analytics)?.toString(), href: "/dashboard/overview/home" },
            { text: fm(messages.newAnalysis)?.toString() },
        ];
        if (title) {
            payload = [...payload, { text: title }];
        }
        const data = {
            source: "ignite-breadcrumbs",
            payload,
        };
        window.postMessage(data, window.location.origin);
    }, [title]);
}
