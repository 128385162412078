import { useContext } from "react";

import { CAContext } from ".";

export const useCAContext = () => {
    const context = useContext(CAContext);
    if (!context) {
        throw Error("useCAContext can only be used within a CAContextProvider");
    }
    return context;
};

export const useCAElasticIndex = () => useCAContext().openWidget?.elasticIndex;
