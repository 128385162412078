import { Widget } from "@/components/Widgets/interfaces";

export const CUSTOM_ANALYSIS_PREFIX = "customAnalysis-";
export const SELECT_DATA_SOURCE_PREFIX = `${CUSTOM_ANALYSIS_PREFIX}selectDataSource`;

export const NEW_FUNCTION_MODAL = "NEW_FUNCTION_MODAL";
export const SAVE_WIDGET_MODAL = "SAVE_WIDGET_MODAL";

export const DND_ID = "CUSTOM_ANALYSIS";

export const ANALYSIS_NEW = "ANALYSIS_NEW" as const;

export const SHARED_DEFAULT_CONFIG: Pick<Widget, "filters" | "height" | "width" | "xPosition" | "yPosition"> = {
    filters: [],
    height: 4,
    width: 6,
    xPosition: 0,
    yPosition: Infinity,
};
