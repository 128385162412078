import { isDateType, isNumericType } from "@ignite-analytics/elastic-fields";
import { useDebounce } from "@ignite-analytics/general-tools";
import { Plus as Add } from "@ignite-analytics/icons";
import { AnalysisQuery } from "@ignite-analytics/pivot-ts";
import { Autocomplete, Button, Stack, Tab, Tabs, TextField, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

import { CACHED_INDEX_KEY } from "../CAContextProvider";
import { useCAContext } from "../CAContextProvider/hooks";
import { CUSTOM_ANALYSIS_PREFIX, NEW_FUNCTION_MODAL, SELECT_DATA_SOURCE_PREFIX } from "../constants";
import { isScript } from "../helpers";
import { useAnalysisItemsByIndex } from "../hooks";
import { EditableAnalysisWidget } from "../interfaces";
import messages from "../messages";

import { DraggableFieldItem } from "./DraggableFieldItem";
import { FieldDragItem } from "./DraggableFieldItem/interfaces";
import FieldsContainer from "./FieldsContainer";

import ScriptModal from "@/components/ScriptModal";
import { useElasticIndicesLabelMap } from "@/contexts/AvailableIndicesContext";
import { fm } from "@/contexts/intlContext";
import { useAllTableNames, useTableName } from "@/hooks/useTableName";

interface Props {
    widget: EditableAnalysisWidget | undefined;
    query: AnalysisQuery | undefined;
}

const ApplyFieldsContainer: React.FC<Props> = ({ widget, query }) => {
    const { openModal, resetWidget, setOpenModal } = useCAContext();
    const [searchTerm, setSearchTerm] = useState("");
    const [scriptSearchTerm, setScriptSearchTerm] = useState("");
    const [selectedFieldsTab, setSelectedFieldsTab] = useState<"data" | "scripts">("data");

    const elasticIndex = useMemo(() => widget?.elasticIndex, [widget?.elasticIndex]);
    const dataSourceItems = useAllTableNames();
    const tableName = useTableName(elasticIndex);
    const currentIndexLabel = useElasticIndicesLabelMap()[elasticIndex ?? ""];
    const currentIndexDisplayName = useMemo(() => currentIndexLabel ?? tableName, [currentIndexLabel, tableName]);

    const [dataSource, setDataSource] = useState(
        dataSourceItems && dataSourceItems.find((item) => item.value === elasticIndex)
    );

    const [dragItem, setDragItem] = useState<FieldDragItem | undefined>();
    const [dropTarget, setDropTarget] = useState<string | undefined>(undefined);

    useEffect(
        function setInitialDataSource() {
            if (!currentIndexDisplayName || !elasticIndex) return;
            setDataSource({ text: currentIndexDisplayName, value: elasticIndex });
        },
        [currentIndexDisplayName, elasticIndex]
    );

    const bottomSplit = useMemo(() => query?.columnSplitItems.slice(-1)[0], [query?.columnSplitItems]);

    const isValidHistogram = useMemo(
        () => (isNumericType(bottomSplit?.type) || isDateType(bottomSplit?.type)) && !!bottomSplit?.interval,
        [bottomSplit?.interval, bottomSplit?.type]
    );

    const availableFields = useAnalysisItemsByIndex(elasticIndex, searchTerm)?.filter((field) => !isScript(field.type));
    const availableScripts = useAnalysisItemsByIndex(elasticIndex, scriptSearchTerm)?.filter((field) =>
        isScript(field.type)
    );

    const handleDataFieldSearch = useDebounce((input: string) => {
        setSearchTerm(input);
    }, 300);

    const handleScriptFieldSearch = useDebounce((input: string) => {
        setScriptSearchTerm(input);
    }, 300);

    return (
        <Stack direction="column" spacing={1} alignItems="stretch" minWidth="100%">
            {/* Data source search field */}
            {dataSourceItems && dataSource && (
                <Autocomplete
                    data-testid={SELECT_DATA_SOURCE_PREFIX}
                    value={dataSource}
                    onChange={(_, newIndex) => {
                        if (!newIndex?.value) return;
                        setDataSource(newIndex);
                        localStorage.setItem(CACHED_INDEX_KEY, newIndex.value);
                        resetWidget(newIndex.value);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={fm(messages.dataSource)}
                            sx={{
                                backgroundColor: (theme) => theme.palette.background.paper,
                                borderRadius: 1,
                            }}
                        />
                    )}
                    id="dataSourcePicker"
                    options={dataSourceItems}
                    renderOption={(props, option) => (
                        <li {...props} key={option.value}>
                            {option.text}
                        </li>
                    )}
                    getOptionLabel={(option) => option.text}
                    size="small"
                />
            )}
            {/* Show scripts or datafields  */}
            <Tabs
                variant="fullWidth"
                value={selectedFieldsTab}
                onChange={(_e, value: "data" | "scripts") => {
                    setSelectedFieldsTab(value);
                }}
                aria-label="available fields tabs"
            >
                <Tab value="data" label={fm(messages.availableFieldsLabel)} />
                <Tab value="scripts" label={fm(messages.availableScriptsLabel)} />
            </Tabs>
            {selectedFieldsTab === "data" ? (
                /* Available data fields */
                <FieldsContainer
                    autoGrow
                    zone="availableFields"
                    header={<></>}
                    placeholder=""
                    dragItem={dragItem}
                    dropTarget={dropTarget}
                    setDropTarget={setDropTarget}
                    search={
                        <TextField
                            data-testid={`${CUSTOM_ANALYSIS_PREFIX}searchFieldsInput`}
                            id="searchForFields"
                            placeholder={fm(messages.searchForFields, {
                                elasticIndex: currentIndexDisplayName?.toLowerCase(),
                            })?.toString()}
                            onChange={(event) => handleDataFieldSearch(event.target.value)}
                            size="small"
                        />
                    }
                    height="20vh"
                >
                    {availableFields?.map(
                        (field, index) =>
                            widget && (
                                <DraggableFieldItem
                                    key={field.uuid}
                                    i={index}
                                    zone="availableFields"
                                    item={field}
                                    widget={widget}
                                    dragItem={dragItem}
                                    dropTarget={dropTarget}
                                    isValidHistogram={isValidHistogram}
                                    setDragItem={setDragItem}
                                    setDropTarget={setDropTarget}
                                />
                            )
                    )}
                </FieldsContainer>
            ) : (
                /* Available scripts */
                <FieldsContainer
                    autoGrow
                    zone="availableFields"
                    header={<></>}
                    placeholder=""
                    dragItem={dragItem}
                    dropTarget={dropTarget}
                    setDropTarget={setDropTarget}
                    search={
                        <Stack direction="row" justifyContent="space-between" alignItems="center" pt={0}>
                            <TextField
                                data-testid={`${CUSTOM_ANALYSIS_PREFIX}searchScriptsInput`}
                                id="searchForScripts"
                                placeholder={fm(messages.searchForScripts, {
                                    elasticIndex: currentIndexDisplayName?.toLowerCase(),
                                })?.toString()}
                                onChange={(event) => handleScriptFieldSearch(event.target.value)}
                                size="small"
                                fullWidth
                            />
                            <Button
                                onClick={() => setOpenModal(NEW_FUNCTION_MODAL)}
                                startIcon={<Add fontSize="small" />}
                                color="ghostGray"
                                size="small"
                            >
                                {fm(messages.addScript)}
                            </Button>
                            {openModal === NEW_FUNCTION_MODAL && elasticIndex && (
                                <ScriptModal
                                    open={openModal === NEW_FUNCTION_MODAL}
                                    elasticIndex={elasticIndex}
                                    onClose={() => setOpenModal(undefined)}
                                />
                            )}
                        </Stack>
                    }
                    height="20vh"
                >
                    {availableScripts?.map(
                        (field, index) =>
                            widget && (
                                <DraggableFieldItem
                                    key={field.uuid}
                                    i={index}
                                    zone="availableFields"
                                    item={field}
                                    widget={widget}
                                    dragItem={dragItem}
                                    dropTarget={dropTarget}
                                    isValidHistogram={isValidHistogram}
                                    setDragItem={setDragItem}
                                    setDropTarget={setDropTarget}
                                />
                            )
                    )}
                </FieldsContainer>
            )}
            {/* Value Items */}
            <FieldsContainer
                zone="valueConfigurations"
                header={
                    <Typography variant="textLg" fontWeight={600} pl={2} pt={1}>
                        {fm(messages.valueItemsLabel)}
                    </Typography>
                }
                placeholder={fm(messages.valuesDropPH)?.toString()}
                dragItem={dragItem}
                dropTarget={dropTarget}
                setDropTarget={setDropTarget}
                maxHeight="120px"
            >
                {widget?.valueConfigurations.map(
                    (field, index) =>
                        widget && (
                            <DraggableFieldItem
                                key={field.uuid}
                                i={index}
                                zone="valueConfigurations"
                                item={field}
                                widget={widget}
                                dragItem={dragItem}
                                dropTarget={dropTarget}
                                isValidHistogram={isValidHistogram}
                                setDragItem={setDragItem}
                                setDropTarget={setDropTarget}
                            />
                        )
                )}
            </FieldsContainer>
            {/* Column Items */}
            {!widget?.chartConfiguration?.useAggsAsColumns && (
                <FieldsContainer
                    zone="columnSplitItems"
                    header={
                        <Typography variant="textLg" fontWeight={600} pl={2} pt={1}>
                            {fm(messages.columnSplitLabel)}
                        </Typography>
                    }
                    placeholder={fm(messages.columnSplitDropPH)?.toString()}
                    dragItem={dragItem}
                    dropTarget={dropTarget}
                    setDropTarget={setDropTarget}
                    maxHeight="100px"
                >
                    {widget?.columnSplitItems.map(
                        (field, index) =>
                            widget && (
                                <DraggableFieldItem
                                    key={field.uuid}
                                    i={index}
                                    zone="columnSplitItems"
                                    item={field}
                                    widget={widget}
                                    dragItem={dragItem}
                                    dropTarget={dropTarget}
                                    isValidHistogram={isValidHistogram}
                                    setDragItem={setDragItem}
                                    setDropTarget={setDropTarget}
                                />
                            )
                    )}
                </FieldsContainer>
            )}
            {/* Row Items */}
            <FieldsContainer
                zone="rowSplitItems"
                header={
                    <Typography variant="textLg" fontWeight={600} pl={2} pt={1}>
                        {fm(messages.rowSplitLabel)}
                    </Typography>
                }
                placeholder={fm(messages.rowSplitDropPH)?.toString()}
                dragItem={dragItem}
                dropTarget={dropTarget}
                setDropTarget={setDropTarget}
                maxHeight="100px"
            >
                {widget?.rowSplitItems.map(
                    (field, index) =>
                        widget && (
                            <DraggableFieldItem
                                key={field.uuid}
                                i={index}
                                zone="rowSplitItems"
                                item={field}
                                widget={widget}
                                dragItem={dragItem}
                                dropTarget={dropTarget}
                                isValidHistogram={isValidHistogram}
                                setDragItem={setDragItem}
                                setDropTarget={setDropTarget}
                            />
                        )
                )}
            </FieldsContainer>
        </Stack>
    );
};

export default ApplyFieldsContainer;
