import { SplitItem, ValueConfiguration } from "@ignite-analytics/pivot-ts";

import { AnalysisWidget } from "@/components/Widgets/interfaces";

export type DropZone = "columnSplitItems" | "rowSplitItems" | "availableFields" | "valueConfigurations";

export const DROP_ZONES: DropZone[] = ["columnSplitItems", "rowSplitItems", "availableFields", "valueConfigurations"];
export const stringIsDropZone = (s: string): s is DropZone => DROP_ZONES.includes(s as DropZone);

export interface EditableAnalysisQuery {
    rowSplitItems: (SplitItem & { uuid: string })[];
    columnSplitItems: (SplitItem & { uuid: string })[];
    valueConfigurations: (ValueConfiguration & { uuid: string })[];
}

/**
 * The EditableAnalysisWidget is like the AnalysisWidget interface, but with UUIDs in each element to make UI
 * operations easier.
 */
export interface EditableAnalysisWidget extends Omit<AnalysisWidget, DropZone>, EditableAnalysisQuery {}
