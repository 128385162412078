import { ComparativeOperator } from "@ignite-analytics/pivot-ts";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export const OPERATOR_OPTIONS: Record<ComparativeOperator, string> = {
    "<": fm(messages.lessThan).toString(),
    "<=": fm(messages.lessThanOrEqual).toString(),
    ">": fm(messages.greaterThan).toString(),
    ">=": fm(messages.greaterThanOrEqual).toString(),
    "==": fm(messages.equals).toString(),
    "!=": fm(messages.notEqual).toString(),
};

export const EMPTY_ARRAY = [];
