import React from "react";
import { createRoot } from "react-dom/client";

import { setupProxyAuth } from "../setupProxyAuth";

import App from "./App";
import { MicroAppMountValues } from "./types";

let root: ReturnType<typeof createRoot> | null = null;

function renderApp(props: MicroAppMountValues, rootNode = "root") {
    const container = document.getElementById(rootNode);
    if (container) {
        root = createRoot(container);
        root.render(
            <React.StrictMode>
                <App {...props} />
            </React.StrictMode>
        );
    }
}

// Running locally
function renderAppLocally(props: MicroAppMountValues, rootNode: string) {
    const container = document.getElementById(rootNode) as HTMLElement;
    createRoot(container).render(
        <React.StrictMode>
            <App {...props} />
        </React.StrictMode>
    );
}

window.renderAnalysis = (containerId, props: MicroAppMountValues) => {
    renderApp(props, containerId);
};

window.unmountAnalysis = () => {
    root?.unmount();
};

// running locally
if (!document.getElementById("Analysis-container")) {
    const isProxyToProductionBackend = process.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";

    if (isProxyToProductionBackend) {
        const sessionContext = await setupProxyAuth();
        renderAppLocally({ sessionContext }, "dev-root");
    } else {
        renderAppLocally({
            sessionContext: {
                id: process.env.REACT_APP_DEFAULT_USER_ID as string,
                email: process.env.REACT_APP_DEFAULT_USER as string,
                tenant: process.env.REACT_APP_DEFAULT_TENANT as string,
            }
        }, "dev-root");
    }
}
