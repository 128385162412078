import {
    Pen as EditOutlinedIcon,
    EyeSlash as VisibilityOffOutlinedIcon,
    Eye as VisibilityOutlinedIcon,
} from "@ignite-analytics/icons";
import { AnalysisItem } from "@ignite-analytics/pivot-ts";
import { Box, IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import { DragProps } from "../DraggableFieldItem/interfaces";
import { isOdd } from "../helpers";
import { DraggableDiv } from "../style";

import message from "./messages";

import ScriptModal from "@/components/ScriptModal";
import { typeToIcon } from "@/components/ScriptModal/constants";
import { fm } from "@/contexts/intlContext";
import { useScriptWithoutFetching } from "@/entities/scriptFields";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";

interface Props extends DragProps {
    scriptId: number;
    onOpenModal: (close?: boolean) => void;
    modalIsOpen: boolean;
    hideEditIcon?: boolean;
    toggleEyeIcon?: (index: number) => void;
    aggItemIndex?: number;
    hidden?: boolean | null;
    item?: AnalysisItem;
    index?: number;
}

const ScriptField: React.FC<Props> = ({
    scriptId,
    onOpenModal,
    modalIsOpen,
    toggleEyeIcon,
    hidden,
    aggItemIndex,
    hideEditIcon,
    item,
    index,
    dragId,
    dragItem,
    isDraggable = false,
    onDragStart,
    onDragEnd,
    onDragOver,
}: Props) => {
    const scriptField = useScriptWithoutFetching(scriptId);
    const TypeIcon = typeToIcon[scriptField?.type ?? "script"];
    const transactionsIndex = useTransactionsIndex()?.name || "transactions";
    return (
        <>
            <DraggableDiv
                id={dragId}
                draggable={isDraggable}
                isDragging={item && dragItem?.item.uuid === item.uuid}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
            >
                <Paper
                    elevation={0}
                    data-testid={dragId}
                    sx={{
                        backgroundColor: (theme) =>
                            isOdd(index) ? theme.palette.grey[100] : theme.palette.background.paper,
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.grey[200],
                        },
                    }}
                >
                    <Stack pl={1} direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                        <Box
                            role="button"
                            tabIndex={0}
                            onClick={() => onOpenModal()}
                            onKeyPress={() => onOpenModal()}
                            sx={{ maxWidth: "80%", py: 0.5 }}
                        >
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <TypeIcon fontSize="inherit" />
                                <Typography variant="textSm">{scriptField?.label}</Typography>
                            </Stack>
                        </Box>
                        <Stack direction="row">
                            {toggleEyeIcon && aggItemIndex !== undefined && (
                                <Tooltip
                                    title={hidden ? fm(message.showValue) : fm(message.hideValue)}
                                    disableInteractive
                                >
                                    <IconButton onClick={() => toggleEyeIcon(aggItemIndex)} size="2xsmall">
                                        {hidden ? (
                                            <VisibilityOffOutlinedIcon fontSize="inherit" />
                                        ) : (
                                            <VisibilityOutlinedIcon fontSize="inherit" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                            {!hideEditIcon && (
                                <Tooltip title={fm(message.editScript)} disableInteractive>
                                    <IconButton onClick={() => onOpenModal()} size="2xsmall">
                                        <EditOutlinedIcon fontSize="inherit" />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                </Paper>
            </DraggableDiv>
            {scriptField && modalIsOpen && transactionsIndex && (
                <ScriptModal
                    open={modalIsOpen}
                    onClose={() => onOpenModal(true)}
                    item={scriptField}
                    elasticIndex={scriptField.elasticIndex || transactionsIndex}
                />
            )}
        </>
    );
};

export default ScriptField;
