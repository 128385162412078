/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Api } from "@ignite-analytics/api-client";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

import { AnalysisWidget, Widget } from "@/components/Widgets/interfaces";
import { useDashboard, useUpdateDashboardDetailObjAndCache } from "@/entities/dashboards";

const reportAxiosErrorToSentry = (
    error: AxiosError,
    message: string,
    additionalTags: Record<string, string | number | undefined>
) => {
    const additionalResponseTags = {
        code: error?.code,
        statusCode: error?.response?.status,
        responseMessage: error?.response?.data?.message,
        headers: error?.response?.headers,
        data: error?.response?.data,
    };

    Sentry.captureException(error?.message || message, {
        tags: {
            app: "analysis-app",
            message,
            ...additionalResponseTags,
            ...additionalTags,
        },
    });
};

/**
 *
 *  Creates a new widget for a dashboard
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {number}    dashboardId: Id of the associated dashboard
 *  @param  {Widget}    widget: The widget data
 *
 *  @returns {Promise<Widget>}
 *
 */
export const useCreateWidget = (dashboardId: number) => {
    const dashboard = useDashboard(dashboardId, undefined, undefined, { service: "dashboards" });
    const updateDashboardEntityCache = useUpdateDashboardDetailObjAndCache();

    return useCallback(
        (widget: Partial<Widget>): Promise<Widget & { id: number }> => {
            const widgetWithoutPosition = { ...widget, xPosition: undefined, yPosition: undefined };
            const url = `/dashboards/${dashboardId}/widgets/`;
            return Api.post(url, widgetWithoutPosition, {
                service: "dashboards",
                onError: (error: AxiosError) =>
                    reportAxiosErrorToSentry(error, "Create widget error", {
                        statusCode: error.response?.status,
                        errorMessage: error.response?.data?.message,
                        widgetType: widget?.type,
                    }),
            }).then((res) => {
                if (dashboard) {
                    updateDashboardEntityCache({
                        ...dashboard,
                        widgets: [...dashboard.widgets, { ...res, isNew: true }],
                    });
                }
                return { ...res, isNew: true };
            });
        },
        [dashboard, dashboardId, updateDashboardEntityCache]
    );
};
/**
 *
 *  Retrieves a single widget from a custom dashboard page
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {string}    widgetModelName: The widget model name
 *  @param  {number}    widgetId: The widget id
 *
 *  @returns {Promise<AnalysisWidget>}
 *
 */
export function getWidget(widgetModelName: string, widgetId: number): Promise<AnalysisWidget> {
    const url = `/dashboards/widgets/${widgetModelName}/${widgetId}/`;
    return Api.get(url, {
        service: "dashboards",
        onError: (error: AxiosError) =>
            reportAxiosErrorToSentry(error, "Get widget error", {
                widgetModelName,
            }),
    });
}

export const useWidget = (widgetModelName: string, widgetId: string | undefined) => {
    const [widget, setWidget] = useState<AnalysisWidget | undefined>(undefined);
    const [error, setError] = useState<Error | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (!widgetId) {
            setWidget(undefined);
            return;
        }
        setLoading(true);
        getWidget(widgetModelName, Number(widgetId))
            .then((res) => {
                setWidget(res);
                setLoading(false);
            })
            .catch((err: Error) => {
                setError(err);
                setLoading(false);
            });
    }, [widgetModelName, widgetId]);

    return { widget, error, loading };
};

/**
 *
 *  Deletes a specific widget from a custom dashboard page
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {number}    widgetId: The widget id
 *
 *  @returns {Promise<void>}
 *
 */
export const useDeleteWidget = (dashboardId: number) => {
    const updateDashboardEntityCache = useUpdateDashboardDetailObjAndCache();
    const dashboard = useDashboard(dashboardId, undefined, undefined, { service: "dashboards" });

    return useCallback(
        (widget: Widget): Promise<void> => {
            const url = `/dashboards/widgets/${widget.modelName}/${widget.id ?? NaN}/`;
            return Api.delete(url, null, {
                service: "dashboards",
                onError: (error: AxiosError) =>
                    reportAxiosErrorToSentry(error, "Delete widget error", {
                        widgetType: widget?.type,
                    }),
            }).then(() => {
                if (dashboard) {
                    updateDashboardEntityCache({
                        ...dashboard,
                        widgets: dashboard.widgets.filter((w) => w.id !== widget.id),
                    });
                }
            });
        },
        [dashboard, updateDashboardEntityCache]
    );
};

/**
 *
 *  Updates a specific analysis
 *
 *  @param  {number}    departmentId: Id of the current department
 *  @param  {Widget}    widget: The widget data
 *
 *  @returns {Promise<Widget>}
 *
 */
export const useUpdateWidget = (dashboardId: number) => {
    const updateDashboardEntityCache = useUpdateDashboardDetailObjAndCache();
    const dashboard = useDashboard(dashboardId, undefined, undefined, { service: "dashboards" });

    return useCallback(
        <W extends Widget = Widget>(widget: W): Promise<W> => {
            const url = `/dashboards/widgets/${widget.modelName}/${widget.id ?? NaN}/`;
            return Api.put(url, widget, {
                service: "dashboards",
                onError: (error: AxiosError) =>
                    reportAxiosErrorToSentry(error, "Create widget error", {
                        widgetType: widget?.type,
                    }),
            }).then((res) => {
                if (dashboard) {
                    const widgets = dashboard.widgets.map((w) => (w.id === res.id ? res : w));
                    updateDashboardEntityCache({ ...dashboard, widgets });
                }
                return res;
            });
        },
        [dashboard, updateDashboardEntityCache]
    );
};
