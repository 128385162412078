import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFilters } from "@ignite-analytics/filters";
import { useDebounce } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import {
    AnalysisQuery,
    createFiltersFromRowsOrColumns,
    PivotColumnData,
    PivotResponse,
    PivotRow,
    useInsightModalContext,
} from "@ignite-analytics/pivot-ts";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { ColumnDef, flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useVirtualizer } from "@tanstack/react-virtual";
import { isNumber } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useIntl } from "react-intl";

import { HorizontalSortIcon } from "../HorizontalSortIcon";

import { getRegularColumns } from "./columnGenerators/regular";
import { getTopLeftColumns } from "./columnGenerators/split";
import { AggregationValueHeader } from "./components/AggregationValueHeader";
import { EMPTY_CELL_VALUE } from "./helpers/constants";
import { getAllRows } from "./helpers/rows";
import { Cell as InternalCell, PivotTableRow } from "./interfaces";
import messages from "./messages";
import { AggHeader, ResizeDiv, ScrollableDiv, Table, TBody, Td, Th, THead, Tr } from "./style";

import { useCAContext } from "@/containers/CustomAnalysisPage/CustomAnalysis/CAContextProvider/hooks";
import { SplitChangeSpec } from "@/containers/CustomAnalysisPage/CustomAnalysis/PivotTable/interfaces";
import { fm } from "@/contexts/intlContext";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";

interface Props {
    data: PivotResponse;
    query: AnalysisQuery;
    config: ChartConfig;
    editable?: boolean;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
}

export const InnerTable: React.FC<Props> = ({ data, query, config, editable, onSplitChange, onHeaderChange }) => {
    let lastColumnSplitPlaceholderColumnId: string | undefined;
    const tableContainerRef = React.useRef<HTMLDivElement>(null);
    const tableRef = React.useRef<HTMLTableElement>(null);
    const { updateChartConfigField } = useCAContext();
    const { setModalState } = useInsightModalContext();
    const intl = useIntl();
    const labelledQuery = useLabelledAnalysisQuery(query);
    const elasticFields = useElasticFieldsInContext();
    const filters = useFilters();

    const noData = !data || !data.rows.length || (query.columnSplitItems.length && !data.columns.length);

    const allRows = useMemo(
        () => (labelledQuery && !noData ? getAllRows(data, labelledQuery, config) : []),
        [data, config, labelledQuery, noData]
    );

    const sizeBeforeScrollbar = tableContainerRef.current?.offsetWidth ? tableContainerRef.current?.offsetWidth : 0;
    const numRows = useMemo(() => (allRows.length > 0 ? Object.keys(allRows[0]).length : 0), [allRows]);
    const paddingHeader = 15;
    const paddingCell = 5;
    const minWidth = 150;

    const width = useMemo(
        () =>
            sizeBeforeScrollbar && numRows
                ? (sizeBeforeScrollbar - (paddingHeader + paddingCell) * 2 * numRows) / numRows > minWidth
                    ? (sizeBeforeScrollbar - (paddingHeader + paddingCell) * 2 * numRows) / numRows
                    : minWidth
                : minWidth,
        [sizeBeforeScrollbar, numRows]
    );

    const columns = useMemo<ColumnDef<PivotTableRow>[]>(
        () =>
            labelledQuery && !noData
                ? [
                      ...getTopLeftColumns(labelledQuery, 0, config, width),
                      ...getRegularColumns(labelledQuery, data.columns, intl, config, width),
                  ]
                : [],
        [config, data.columns, intl, labelledQuery, noData, width]
    );

    const table = useReactTable({
        data: allRows,
        columns,
        columnResizeMode: "onChange",
        getCoreRowModel: getCoreRowModel(),
    });

    const { rows } = table.getRowModel();

    const rowVirtualizer = useVirtualizer({
        getScrollElement: () => tableContainerRef.current,
        count: rows.length,
        estimateSize: () => 35,
        overscan: 1000,
    });
    const totalRowSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
    const paddingBottom = virtualRows.length > 0 ? totalRowSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0;

    const handleCellClick = useCallback(
        (
            { pageX, pageY, timeStamp, currentTarget }: React.MouseEvent,
            columnPath?: PivotColumnData[],
            rowPath?: PivotRow[]
        ) => {
            if (currentTarget instanceof HTMLElement) {
                const columnFilters = columnPath
                    ? createFiltersFromRowsOrColumns(columnPath, elasticFields ?? [], filters)
                    : undefined;
                const rowFilters = rowPath
                    ? createFiltersFromRowsOrColumns(rowPath, elasticFields ?? [], filters)
                    : undefined;
                setModalState({
                    top: pageX,
                    left: pageY,
                    eventStamp: timeStamp,
                    target: currentTarget,
                    ...(columnFilters ? { selectedColumns: columnFilters } : {}),
                    ...(rowFilters ? { selectedRows: rowFilters } : {}),
                    positionWithTarget: true,
                });
            }
        },
        [elasticFields, filters, setModalState]
    );

    const originalSizes: {
        [header: string]: number | undefined;
    } = table
        .getHeaderGroups()
        .flatMap((headerGroup) => headerGroup.headers)
        .reduce((acc, header) => {
            if (header.column.columnDef.meta) {
                return { [header.column.columnDef.meta.columnWidthId]: header.column.columnDef.size, ...acc };
            }
            return acc;
        }, {});

    const columnsToBeSavedRef = useRef<{ [header: string]: number | undefined }>({});

    const saveSizing = useCallback(() => {
        updateChartConfigField(["columnWidths"], { ...config.columnWidths, ...columnsToBeSavedRef.current });
    }, [columnsToBeSavedRef, config.columnWidths, updateChartConfigField]);

    // Create a debounced version of the saveSizing function with a delay of 100ms
    const debouncedSaving = useDebounce(saveSizing, 100);

    // Create a ref to hold the debouncedSaving function so it doesn't change on every render
    // and avoids triggering the useEffect below
    // this is done to avoid using the // eslint-disable-next-line react-hooks/exhaustive-deps rule
    const debouncedSavingRef = useRef(debouncedSaving);

    // Call debouncedSavingRef.current whenever tableRef.current.offsetWidth or debouncedSavingRef changes
    useEffect(() => {
        debouncedSavingRef.current();
    }, [tableRef.current?.offsetWidth, debouncedSavingRef]);

    if (noData) {
        return (
            <Stack textAlign="center" pt={2} pl={6}>
                <Typography variant="textSm" fontWeight={600}>
                    {fm(messages.noData)}
                </Typography>
            </Stack>
        );
    }

    return (
        <ScrollableDiv ref={tableContainerRef}>
            <Table fillAvailable={config.fillAvailableSpace} ref={tableRef}>
                <THead sticky={!config.disableStickyHeaders}>
                    {table.getHeaderGroups().map((headerGroup) => (
                        <Tr key={headerGroup.id} headerRow>
                            {headerGroup.headers.map((header) => {
                                // Logic for skipping placeholder when having column splits and no row splits
                                if (header.column.id === lastColumnSplitPlaceholderColumnId) return;
                                if (header.isPlaceholder) {
                                    lastColumnSplitPlaceholderColumnId = header.column.id;
                                }

                                const {
                                    splitItem,
                                    splitItemIndex,
                                    splitType,
                                    rowSpan,
                                    headerType,
                                    columnPath,
                                    defaultHeaderName,
                                    valueAggregationIndex,
                                    isHiddenTotalRow,
                                } = header.column.columnDef.meta ?? {};

                                if (isHiddenTotalRow) return;

                                const isClickable = !splitItem?.sortOrder;
                                const widthId = header.column.columnDef.meta?.columnWidthId;
                                if (widthId) {
                                    if (
                                        originalSizes[widthId] !== header.getSize() &&
                                        columnsToBeSavedRef.current[widthId] !== header.getSize()
                                    ) {
                                        columnsToBeSavedRef.current[widthId] = header.getSize();
                                    }
                                }

                                if (headerType === "normal") {
                                    return (
                                        <Th
                                            alignment={config.columnAlignment}
                                            key={header.id}
                                            colSpan={header.colSpan}
                                            rowSpan={rowSpan ?? 1}
                                            style={{
                                                width: header.getSize(),
                                            }}
                                            wrapLabels={config.wrapHeaderLabels}
                                            onClick={(event: React.MouseEvent) =>
                                                isClickable && handleCellClick(event, columnPath)
                                            }
                                            horizontalPadding={paddingHeader}
                                            clickable={isClickable}
                                        >
                                            <Stack
                                                direction="row"
                                                justifyContent={config.columnAlignment ?? "center"}
                                                width={header.getSize()}
                                            >
                                                <Typography
                                                    width={header.getSize()}
                                                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    {header.isPlaceholder && !query.columnSplitItems.length
                                                        ? null
                                                        : flexRender(
                                                              header.column.columnDef.header,
                                                              header.getContext()
                                                          )}
                                                </Typography>
                                            </Stack>
                                            {splitItem?.sortOrder &&
                                                isNumber(splitItemIndex) &&
                                                splitType &&
                                                labelledQuery && (
                                                    <HorizontalSortIcon
                                                        labelledQuery={labelledQuery}
                                                        intl={intl}
                                                        splitItem={splitItem}
                                                        config={config}
                                                        splitItemIndex={splitItemIndex}
                                                        splitType={splitType}
                                                        onSplitChange={onSplitChange}
                                                        columnAlignment={config.columnAlignment}
                                                        header={header}
                                                    />
                                                )}
                                            <ResizeDiv
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                }}
                                                onMouseDown={header.getResizeHandler()}
                                                onTouchStart={header.getResizeHandler()}
                                            />
                                        </Th>
                                    );
                                }
                                const headerName =
                                    flexRender(header.column.columnDef.header, header.getContext())?.toString() ?? "";

                                return (
                                    <AggHeader
                                        key={header.id}
                                        alignment={config.columnAlignment}
                                        colSpan={header.colSpan}
                                        style={{ width: header.getSize() }}
                                        wrapLabels={config.wrapHeaderLabels}
                                        horizontalPadding={paddingHeader}
                                    >
                                        {header.isPlaceholder ? null : (
                                            <AggregationValueHeader
                                                headerName={headerName}
                                                editable={editable && !header.isPlaceholder}
                                                onHeaderChange={onHeaderChange}
                                                valueAggregationIndex={valueAggregationIndex ?? NaN}
                                                defaultHeaderName={defaultHeaderName ?? ""}
                                                header={header}
                                            />
                                        )}
                                        <ResizeDiv
                                            onClick={(e) => {
                                                e.stopPropagation();
                                            }}
                                            onMouseDown={header.getResizeHandler()}
                                            onTouchStart={header.getResizeHandler()}
                                        />
                                    </AggHeader>
                                );
                            })}
                        </Tr>
                    ))}
                </THead>
                <TBody>
                    {paddingTop > 0 && (
                        <tr>
                            <td style={{ height: `${paddingTop}px` }} />
                        </tr>
                    )}
                    {virtualRows.map((virtualRow) => {
                        const row = rows[virtualRow.index];
                        return (
                            <Tr key={row.id}>
                                {row.getVisibleCells().reduce((acc: JSX.Element[], cell) => {
                                    const val = cell.getValue<InternalCell>();
                                    const isClickable = val?.value !== EMPTY_CELL_VALUE && val?.value !== "Total";
                                    if (val?.value === null || val?.value === undefined) {
                                        return acc;
                                    }
                                    if (val?.isHeader) {
                                        return [
                                            ...acc,
                                            <Th
                                                key={`${cell.id}`}
                                                rowSpan={val.rowSpan}
                                                colSpan={val.colSpan}
                                                alignment={config.columnAlignment ?? "center"}
                                                clickable={isClickable}
                                                wrapLabels={config.wrapHeaderLabels}
                                                onClick={(event: React.MouseEvent) =>
                                                    isClickable && handleCellClick(event, undefined, val.rowPath)
                                                }
                                                style={{
                                                    width: cell.column.getSize(),
                                                }}
                                                horizontalPadding={paddingHeader}
                                            >
                                                <Typography
                                                    width={cell.column.getSize()}
                                                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                                >
                                                    {val.value?.toString() ?? EMPTY_CELL_VALUE}
                                                </Typography>
                                            </Th>,
                                        ];
                                    }
                                    return [
                                        ...acc,
                                        <Td
                                            key={`${cell.id}`}
                                            alignment={config.columnAlignment ?? "center"}
                                            clickable={isClickable}
                                            onClick={(event: React.MouseEvent) =>
                                                isClickable && handleCellClick(event, val.columnPath, val.rowPath)
                                            }
                                            horizontalPadding={paddingCell}
                                            style={{
                                                width: cell.column.getSize(),
                                            }}
                                        >
                                            <Typography
                                                width={cell.column.getSize()}
                                                style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                                            >
                                                {val?.value?.toString() ?? EMPTY_CELL_VALUE}
                                            </Typography>
                                        </Td>,
                                    ];
                                }, [])}
                            </Tr>
                        );
                    })}
                    {paddingBottom > 0 && (
                        <tr>
                            <td style={{ height: `${paddingBottom}px` }} />
                        </tr>
                    )}
                </TBody>
            </Table>
        </ScrollableDiv>
    );
};
