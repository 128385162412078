import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { ScriptField, ValueConfiguration } from "@ignite-analytics/pivot-ts";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useTableName } from "../useTableName";

import { getLabel, labelHelper } from "./helpers";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { useScriptField } from "@/entities/scriptFields";

export const useLabel = (
    item: Parameters<typeof getLabel>[1] | ScriptField | ValueConfiguration | undefined,
    elasticIndex: string | undefined
) => {
    const scriptField = useScriptField(item && "script" in item ? item.script : NaN, undefined, undefined, {
        service: "dashboards",
    });
    const intl = useIntl();
    const elasticFields = useElasticFields(elasticIndex, true);
    const elasticIndices = useAllElasticIndices();
    const tableName = useTableName(elasticIndex);
    return useMemo(
        () =>
            labelHelper(
                intl,
                item,
                scriptField ? [scriptField] : [],
                elasticFields,
                elasticIndices ?? [],
                elasticIndex,
                tableName
            ),
        [elasticIndices, elasticFields, elasticIndex, intl, item, scriptField, tableName]
    );
};

export const useLabelWithoutFilters: typeof useLabel = (item, elasticIndex) => {
    const clone: typeof item = item ? { ...item } : undefined;
    if (clone && "filters" in clone) delete clone.filters;
    return useLabel(clone, elasticIndex);
};
