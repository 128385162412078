import { ChevronRight as ChevronRightIcon, ChevronDown as ExpandMoreIcon } from "@ignite-analytics/icons";
import {
    ChartConfig,
    getDefaultAggConfig,
    getValidAggOption,
    removeHiddenOptions,
    SINGLE_CHART_CONFIG_TYPES,
} from "@ignite-analytics/pivot-charts";
import { ValueConfiguration } from "@ignite-analytics/pivot-ts";
import { Accordion, AccordionSummary, Box, Card, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useCAContext } from "../CAContextProvider/hooks";

import ConfigField from "./ConfigField";
import { otherConfigFields, tableConfigFields } from "./fields";
import FiltersDescriptor from "./FiltersDescriptor";
import { getChartConfigField, getTableConfigField, getYAxisTitles } from "./helpers";
import messages from "./messages";

import { fm } from "@/contexts/intlContext";
import { useWithLabels } from "@/hooks/useWithLabels";

interface ConfigurationPanelProps {
    closePanel: () => void;
}
interface NonAggregatedProps {
    aggregations: (ValueConfiguration & {
        uuid: string;
    })[];
    config: ChartConfig | undefined;
    showPanels: boolean | undefined;
    viewState: string | undefined;
}

interface HeaderProps {
    showPanels: boolean;
}
export interface SelectedRows {
    value: string;
}
const HeaderSidePanel: React.FC<HeaderProps> = ({ showPanels }) => {
    if (!showPanels) {
        return (
            <Typography variant="textLg" fontWeight={600}>
                {fm(messages.chartConfigLabel)}
            </Typography>
        );
    }
    return (
        <Typography variant="textLg" fontWeight={600}>
            {fm(messages.otherChartConfigLabel)}
        </Typography>
    );
};

const NonAggregatedContainer: React.FC<NonAggregatedProps> = ({ aggregations, config, showPanels, viewState }) => {
    const intl = useIntl();
    const validOption = config && getValidAggOption(removeHiddenOptions(aggregations, config));
    const labeledAggregations = useWithLabels(aggregations, false);
    const labels = useMemo(() => {
        const labeling = labeledAggregations?.reduce<SelectedRows[]>((acc, agg) => {
            if (agg.label) {
                return [...acc, { value: agg.label }];
            }
            return acc;
        }, []);
        return labeling;
    }, [labeledAggregations]);

    const validOptIndex = validOption && config ? config.optionsPerAgg.indexOf(validOption) : 0;
    return (
        <Stack direction="column" spacing={1} pb={5}>
            {!showPanels && viewState === "table"
                ? getTableConfigField(intl, validOption?.measureUnit, validOptIndex)
                : !showPanels && getChartConfigField(intl, validOption?.measureUnit, validOptIndex, aggregations)}
            {viewState === "table" ? (
                <ConfigField fieldConfig={{ children: tableConfigFields() }} />
            ) : (
                <ConfigField
                    fieldConfig={{
                        children: otherConfigFields(),
                    }}
                    labels={labels}
                />
            )}
        </Stack>
    );
};

const ConfigurationSidePanel: React.FC<ConfigurationPanelProps> = ({ closePanel }) => {
    const intl = useIntl();
    const {
        openWidget: { chartConfiguration: config, valueConfigurations: aggregations, elasticIndex, viewState } = {},
        updateWidget,
    } = useCAContext();
    const labeledAggregations = useWithLabels(aggregations, false);
    const [expandAccordion, setExpandAccordion] = useState<string | false>(false);

    const handleAccordionChange = (key: string, isExpanded: boolean) => {
        setExpandAccordion(isExpanded ? key : false);
    };
    // Validate chartConfig whenever value aggregations in query change
    useEffect(() => {
        if (!labeledAggregations || !config) return;
        const { shareYAxis, optionsPerAgg, shareYAxisLeft, shareYAxisRight } = config;
        const correctTitles = getYAxisTitles(labeledAggregations, shareYAxis);
        // Ensure correct titles on axes
        if (optionsPerAgg.map((opts) => (opts.yAxis && opts.yAxis.title) || "").join(",") !== correctTitles.join(","))
            updateWidget({
                chartConfiguration: {
                    optionsPerAgg: (prevs) =>
                        prevs.map((prev, i) => ({
                            ...prev,
                            yAxis: { ...prev.yAxis, title: correctTitles[i] },
                        })),
                },
            });
        // Ensure number of optionsPerAgg is the same as number of aggregations
        if (optionsPerAgg.length !== labeledAggregations.length)
            updateWidget({
                chartConfiguration: {
                    optionsPerAgg: (prevs) =>
                        labeledAggregations.map(
                            (agg, i) =>
                                prevs[i] ||
                                getDefaultAggConfig(i, agg.type, "aggregation" in agg ? agg.aggregation : undefined)
                        ),
                },
            });
        // Ensure that share axis options are still valid
        if (
            shareYAxisLeft?.some((title) => !correctTitles.includes(title)) ||
            shareYAxisRight?.some((title) => !correctTitles.includes(title))
        )
            updateWidget({
                chartConfiguration: {
                    shareYAxisLeft: (prev) => prev?.filter((title) => correctTitles.includes(title)),
                    shareYAxisRight: (prev) => prev?.filter((title) => correctTitles.includes(title)),
                },
            });
    }, [labeledAggregations, config, updateWidget, elasticIndex]);

    if (!aggregations || !aggregations.length) return null;

    const validOption = config && getValidAggOption(removeHiddenOptions(aggregations, config));
    const firstType = validOption?.type;
    const showPanels =
        aggregations.filter((agg) => agg.visible !== false).length > 1 &&
        firstType &&
        !SINGLE_CHART_CONFIG_TYPES.includes(firstType) &&
        !config?.useAggsAsColumns;

    return (
        <Card sx={{ maxHeight: "100%", maxWidth: "400px" }}>
            <Tooltip title="Hide configuration panel">
                <IconButton size="small" onClick={() => closePanel()}>
                    <ChevronRightIcon />
                </IconButton>
            </Tooltip>
            <Divider />
            <Box sx={{ overflowY: "auto", height: "80vh" }}>
                {showPanels &&
                    labeledAggregations &&
                    labeledAggregations.map((agg, aggIndex) => {
                        const measureUnit =
                            config?.optionsPerAgg[aggIndex]?.measureUnit || getDefaultAggConfig(aggIndex).measureUnit;
                        const iteratorKey = "field" in agg ? `${agg.field}-${aggIndex}` : `${agg.script}-${aggIndex}`;

                        if (agg.visible === false) return null;

                        return (
                            <Accordion
                                key={iteratorKey}
                                expanded={expandAccordion === iteratorKey}
                                disableGutters
                                onChange={(_e, expanded) => handleAccordionChange(iteratorKey, expanded)}
                                elevation={0}
                            >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls={`accordion-${aggIndex}`}
                                >
                                    <Typography variant="textLg" fontWeight={600}>
                                        {fm(messages.aggregationConfiguration, {
                                            field: agg.label,
                                        })}
                                    </Typography>
                                </AccordionSummary>
                                <Box>
                                    {"filters" in agg ? (
                                        <Stack direction="column">
                                            {elasticIndex && (
                                                <FiltersDescriptor filters={agg.filters} elasticIndex={elasticIndex} />
                                            )}
                                        </Stack>
                                    ) : null}
                                    {viewState === "table"
                                        ? getTableConfigField(intl, measureUnit, aggIndex)
                                        : getChartConfigField(intl, measureUnit, aggIndex, labeledAggregations)}
                                </Box>
                            </Accordion>
                        );
                    })}
                <Accordion defaultExpanded elevation={0} disableGutters>
                    <AccordionSummary sx={{ pointerEvents: "none" }}>
                        {typeof showPanels === "boolean" && typeof viewState === "string" && viewState !== "table" ? (
                            <HeaderSidePanel showPanels={showPanels} />
                        ) : (
                            !showPanels && (
                                <Typography variant="textLg" fontWeight={600}>
                                    {fm(messages.tableConfigLabel)}
                                </Typography>
                            )
                        )}
                    </AccordionSummary>
                    <Box>
                        {elasticIndex && (
                            <NonAggregatedContainer
                                aggregations={aggregations}
                                config={config}
                                viewState={viewState}
                                showPanels={showPanels}
                            />
                        )}
                    </Box>
                </Accordion>
            </Box>
        </Card>
    );
};

export default ConfigurationSidePanel;
