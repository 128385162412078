import { BackendPermissionType } from "@ignite-analytics/permissions";

import { PermissionRelation, usePermission } from "../usePermissionCheck";

import { useSessionContext } from "@/contexts/SessionContextProvider";
import { useAllModelGuards } from "@/entities/modelGuards";

export const useBackwardCompatiblePermissionCheck = (
    apiPermissionType: BackendPermissionType | BackendPermissionType[],
    equivalentUserPermission: { namespace: string; relation: PermissionRelation },
    guard?: number | null
) => {
    const modelGuards = useAllModelGuards(undefined, { service: "dashboards" });

    const hasOryPermission = usePermission(equivalentUserPermission.namespace, equivalentUserPermission.relation);

    const { id } = useSessionContext();

    const objectPermission = guard && modelGuards.find((mg) => mg.id === guard);
    const objectHasStandardPerms =
        !objectPermission || objectPermission[apiPermissionType === "view" ? "standardRead" : "standardWrite"];
    const invitedUsers =
        objectPermission && objectPermission[apiPermissionType === "view" ? "readUsers" : "writeUsers"];

    const hasObjectPerm = invitedUsers && id && invitedUsers.includes(id);
    return Boolean(objectHasStandardPerms ? hasOryPermission || hasObjectPerm : hasObjectPerm);
};
