import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { getValidAggOption } from "@ignite-analytics/pivot-charts";
import { AnalysisItem, SplitItem, ValueConfiguration } from "@ignite-analytics/pivot-ts";
import { Box, Typography } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { useCAContext } from "../../CAContextProvider/hooks";
import { CUSTOM_ANALYSIS_PREFIX } from "../../constants";
import { getUpdateQuerySpec } from "../../helpers";
import { DropZone, EditableAnalysisWidget } from "../../interfaces";
import AvailableField from "../AvailableField";
import { getSpecialValueLabels } from "../helpers";
import { NOT_SUPPORTED_IN_CA } from "../interfaces";
import ScriptField from "../ScriptField";
import SplitField from "../SplitField";
import ValueField from "../ValueField";
import { ValueItem } from "../ValueField/interfaces";

import { FieldDragItem } from "./interfaces";

interface Props {
    i: number;
    zone: DropZone;
    item: AnalysisItem;
    widget: EditableAnalysisWidget;
    dragItem: FieldDragItem | undefined;
    dropTarget: string | undefined;
    isValidHistogram: boolean;
    setDragItem: React.Dispatch<React.SetStateAction<FieldDragItem | undefined>>;
    setDropTarget: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const DraggableFieldItem: React.FC<Props> = ({
    i,
    zone,
    item,
    widget,
    dragItem,
    dropTarget,
    isValidHistogram,
    setDragItem,
    setDropTarget,
}) => {
    const { openModal, updateWidget, setOpenModal, updateChartConfigField } = useCAContext();
    const fieldsInIndex = useElasticFieldsInContext();

    const isVisible = (index: number) => widget?.valueConfigurations[index]?.visible ?? true;

    const onItemMove = (prevIndex: string, newIndex: string) => {
        if (!dragItem || !fieldsInIndex) return;
        const spec = getUpdateQuerySpec(prevIndex, newIndex, dragItem.item, fieldsInIndex);
        spec && updateWidget(spec);
    };

    const updateField =
        () =>
        <T extends SplitItem | ValueConfiguration>(_item: T) => {
            if (zone === "availableFields") return;
            if (!widget) return;
            updateWidget({
                [zone]: {
                    [i]: { $set: _item },
                },
            });
        };

    const toggleEyeIcon = () => {
        if (!widget?.chartConfiguration) return;
        updateWidget({
            valueConfigurations: { [i]: { visible: { $set: !isVisible(i) } } },
        });
        updateChartConfigField(["optionsPerAgg", i, "isHidden"], isVisible(i));
    };

    const fieldId = `item-${zone}-${item.uuid}`;
    const fieldIndex = `${zone}-${i}`;
    const modalIsOpen = fieldId === openModal;
    const activeAggItems = widget.valueConfigurations.filter(({ visible }) => visible !== false);
    const valueAggProps = {
        disableEyeIcon: activeAggItems.length < 1,
        toggleEyeIcon: zone === "valueConfigurations" ? toggleEyeIcon : undefined,
        aggItemIndex: zone === "valueConfigurations" ? i : undefined,
        hidden: !isVisible(i),
    };

    const uiTestsId = "field" in item ? item.field : item.script?.toString();
    const rowId = `${CUSTOM_ANALYSIS_PREFIX}${zone}DataField-${uiTestsId}`;

    const handleDragEnd = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (dragItem && dropTarget) {
            onItemMove(dragItem.startIndex, dropTarget);
            setDragItem(undefined);
            setDropTarget(undefined);
            return;
        }
        // If field is dropped outside a drop zone, move it back to the available fields
        if (dragItem && !dropTarget) {
            onItemMove(dragItem.startIndex, `availableFields-0`);
            setDragItem(undefined);
        }
    };

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.currentTarget.id === dropTarget) return;
        setDropTarget(fieldIndex);
    };

    const aggChartConfig = getValidAggOption(widget.chartConfiguration, i);
    const intl = useIntl();
    const specialValueLabels = aggChartConfig?.type ? getSpecialValueLabels(aggChartConfig.type, intl) : undefined;
    const specialValueLabel =
        zone === "valueConfigurations" &&
        specialValueLabels !== undefined &&
        specialValueLabels !== NOT_SUPPORTED_IN_CA &&
        widget.viewState === "chart"
            ? specialValueLabels[i]
            : undefined;

    return (
        <Box>
            {specialValueLabel && (
                <Typography variant="textSm" fontWeight={600}>
                    {specialValueLabel}
                </Typography>
            )}
            {"script" in item &&
            (item.type === "script" || (item.type === "scripted_metric" && zone === "availableFields")) ? (
                <ScriptField
                    {...valueAggProps}
                    index={i}
                    modalIsOpen={modalIsOpen}
                    onOpenModal={(close?: boolean) => setOpenModal(close ? undefined : fieldId)}
                    scriptId={item.script}
                    hideEditIcon={zone !== "valueConfigurations"}
                    item={item}
                    dragId={fieldIndex}
                    dragItem={dragItem}
                    isDraggable
                    onDragStart={() => {
                        setDragItem({ item, startIndex: fieldIndex });
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                />
            ) : zone === "valueConfigurations" ? (
                <ValueField
                    {...valueAggProps}
                    index={i}
                    modalIsOpen={modalIsOpen}
                    onOpenModal={(close?: boolean) => setOpenModal(close ? undefined : fieldId)}
                    aggItemIndex={i}
                    item={item as ValueItem}
                    onUpdate={updateField()}
                    inHistogram={isValidHistogram}
                    data-testid={rowId}
                    setOpenModal={setOpenModal}
                    dragId={fieldIndex}
                    dragItem={dragItem}
                    isDraggable
                    onDragStart={() => {
                        setDragItem({ item, startIndex: fieldIndex });
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                />
            ) : zone === "availableFields" ? (
                <AvailableField
                    index={i}
                    item={item}
                    dragId={fieldIndex}
                    dragItem={dragItem}
                    isDraggable
                    onDragStart={() => {
                        setDragItem({ item, startIndex: fieldIndex });
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                />
            ) : (
                <SplitField
                    index={i}
                    item={item as SplitItem}
                    onUpdate={updateField()}
                    onOpenModal={(close?: boolean) => setOpenModal(close ? undefined : fieldId)}
                    modalIsOpen={modalIsOpen}
                    dragId={fieldIndex}
                    dragItem={dragItem}
                    isDraggable
                    onDragStart={() => {
                        setDragItem({ item, startIndex: fieldIndex });
                    }}
                    onDragOver={handleDragOver}
                    onDragEnd={handleDragEnd}
                />
            )}
        </Box>
    );
};
