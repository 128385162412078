import * as Sentry from "@sentry/react";
import React from "react";

import WidgetErrorFallback from "../Fallbacks/WidgetErrorFallback";

import { Widget } from "@/components/Widgets/interfaces";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 *
 */
interface WidgetErrorProps {
    widget: Widget;
    children: React.ReactNode;
}
const WidgetErrorBoundary: React.FC<WidgetErrorProps> = ({ children, widget }) => (
    <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
            scope.setTag("widgetId", widget.id);
            scope.setTag("widgetType", widget.type);
            scope.setTag("app", "analysis-app");
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={({ resetError }) => <WidgetErrorFallback resetError={resetError} />}
    >
        {children}
    </Sentry.ErrorBoundary>
);

export default WidgetErrorBoundary;
