import { getCombinedProvider } from "@ignite-analytics/entities";

import { DashboardCollectionContextProvider } from "./dashboardCollections";
import { CustomDashboardContextProvider } from "./dashboards";
import { DepartmentProvider } from "./departments";
import { ModelGuardContextProvider } from "./modelGuards";
import { PermissionGroupContextProvider } from "./permissionGroups";
import { PermissionContextProvider } from "./permissions";
import { ScriptFieldContextProvider } from "./scriptFields";
import { UserProvider } from "./users";

export const EntitiesContextProvider = getCombinedProvider(
    DashboardCollectionContextProvider,
    CustomDashboardContextProvider,
    DepartmentProvider,
    ModelGuardContextProvider,
    PermissionGroupContextProvider,
    PermissionContextProvider,
    ScriptFieldContextProvider,
    UserProvider
);
