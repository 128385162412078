import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useFiltersWithIdNameMaps } from "@ignite-analytics/filters";
import { Stack } from "@mui/material";
import Parser from "html-react-parser";
import React from "react";
import { useIntl } from "react-intl";

import { Options } from "../interfaces";

import { getFilterFooterHTML } from "./helpers";
import messages from "./messages";
import { TruncatedColumn } from "./style";

import { staticFormatMessage } from "@/contexts/intlContext";
import { useWithLabels } from "@/hooks/useWithLabels";

const TEST_ID_PREFIX = "filterCardFooter-";

interface Props {
    analysisOptions?: Options;
    index?: number;
}
export const FilterCardFooter: React.FC<Props> = ({ analysisOptions, index }) => {
    const filters = useFiltersWithIdNameMaps();
    const elasticFields = useElasticFieldsInContext();
    const filtersWithLabels = useWithLabels(filters, false);
    const intl = useIntl();
    if (!elasticFields) return null;
    const html = getFilterFooterHTML(intl, elasticFields, filtersWithLabels, analysisOptions);

    return (
        <Stack p={1}>
            <TruncatedColumn variant="textXs" data-testid={`${TEST_ID_PREFIX}widgetFooter-${index}`}>
                {html.trim().length ? Parser(html) : staticFormatMessage(messages.noActiveFilters)}
            </TruncatedColumn>
        </Stack>
    );
};

export default FilterCardFooter;
