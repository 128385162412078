import { defineMessages } from "react-intl";

const messages = defineMessages({
    sortBy: {
        id: "customAnalysis.SplitField.sortPicker.sortBy",
        defaultMessage: "Sort by",
    },
    alphabetically: {
        id: "customAnalysis.SplitField.sortPicker.alphabetically",
        defaultMessage: "Alphabetically",
    },
});

export default messages;
