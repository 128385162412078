import { defineMessages } from "react-intl";

const messages = defineMessages({
    defaultAnalysisTitle: {
        id: "components.crateNewWidgetModal.defaultAnalysisTitle",
        defaultMessage: "New analysis",
    },
    customAnalysis: {
        id: "components.crateNewWidgetModal.customAnalysis",
        defaultMessage: "Custom analysis",
    },
    currentDashboard: {
        id: "components.createNewWidgetModal.currentDashboard",
        defaultMessage: "This dashboard",
    },
    createWidget: {
        id: "components.createNewWidgetModal.createWidget",
        defaultMessage: "Save as new analysis",
    },
    widgetTitle: {
        id: "components.createNewWidgetModal.widgetTitle",
        defaultMessage: "Analysis title",
    },
    chooseDashboard: {
        id: "components.createNewWidgetModal.chooseDashboard",
        defaultMessage: "Choose dashboard to save analysis to",
    },
    createNewDashboard: {
        id: "components.createNewWidgetModal.createNewDashboard",
        defaultMessage: "Create new dashboard",
    },
});

export default messages;
