import { defineMessages } from "react-intl";

const messages = defineMessages({
    current: {
        id: "customAnalysis.comparisonPeriodSetup.periodFilters.currentPeriod",
        defaultMessage: "Current period",
    },
    previous: {
        id: "customAnalysis.comparisonPeriodSetup.periodFilters.previousPeriod",
        defaultMessage: "Baseline period",
    },
});

export default messages;
