import { useElasticFieldsInContext, useElasticIndexInContext } from "@ignite-analytics/elastic-fields";
import { isNotNullish } from "@ignite-analytics/general-tools";
import {
    AnalysisQuery,
    Labelled,
    LabelledAnalysisQuery,
    ScriptField,
    ValueConfiguration,
} from "@ignite-analytics/pivot-ts";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { getLabel, labelHelper } from "../useLabel/helpers";
import { useTableName } from "../useTableName";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { useGetAllScriptsAction, useScriptFieldContext } from "@/entities/scriptFields";

type UnlabelledItem = Parameters<typeof getLabel>[1] | ScriptField | ValueConfiguration;

const getScriptIDs = (items: UnlabelledItem[] | undefined) => {
    if (!items) return undefined;
    return [
        ...new Set([
            ...items
                .map((item) => {
                    if (!item) return undefined;
                    if ("script" in item) {
                        // `item` is a ScriptRelation, return associated script ID
                        return item.script;
                    }
                    if (item.type === "scripted_metric" || item.type === "script") {
                        // `item` is an instance of a ScriptField, return its ID
                        return item.id;
                    }
                    // `item` is a Filter or a FieldValueItem (simple elastic field associated aggregation), return undefined
                    return undefined;
                })
                .filter(isNotNullish),
        ]),
    ].filter(
        // NaN means the script is not from the database (i.e. it's a hard coded script), which means it doesn't have an ID
        (id) => !Number.isNaN(id)
    );
};

/**
 * Takes in a list of items (many different types are allowed, Filters, ElasticFields, ValueConfigurations, etc.)
 * and adds a `label` property to each item with a correct label.
 * It also orders the items alphabetically, unless `order` is false.
 * For optimal performance, the input list should have the same identity on each call, unless it's actually changed.
 *
 * This is defined here in web because most of the code relates to how to get relevant objects from various stores
 * and contexts. If you need something similar in your app, just copy and modify this hook. Or even better: make a new
 * hook that is more specific to your use case (i.e. one that does not take in a generic list of "items").
 *
 * **NB**: This hook requires `items` to be items related to the elastic index in the current ElasticFieldsContext.
 * (If called from a FilterContext, the context includes an ElasticFieldsContext, so it's fine.)
 */
export const useWithLabels = <T extends Parameters<typeof getLabel>[1] | ScriptField | ValueConfiguration>(
    items: T[] | undefined,
    order = true
): Labelled<T>[] | undefined => {
    const scriptIds = useMemo(() => getScriptIDs(items), [items]);
    const getAllScripts = useGetAllScriptsAction();
    const { listObjs: scriptFields } = useScriptFieldContext();
    const relevantScriptsAreLoaded = useMemo(() => {
        if (!scriptIds) return true;
        return scriptIds.every((id) => scriptFields[id]);
    }, [scriptIds, scriptFields]);
    useEffect(
        function fetchScriptsIfMissing() {
            if (!relevantScriptsAreLoaded && !window.__currentlyFetchingDashboardScripts) {
                getAllScripts({ service: "dashboards" });
            }
        },
        [relevantScriptsAreLoaded, getAllScripts, scriptIds, scriptFields]
    );

    const intl = useIntl();
    const elasticIndex = useElasticIndexInContext();
    const elasticFields = useElasticFieldsInContext();
    const elasticIndices = useAllElasticIndices();
    const tableName = useTableName(elasticIndex?.name);

    return useMemo(() => {
        if (!relevantScriptsAreLoaded || !elasticFields || !elasticIndices || !items || !elasticIndex) return undefined;
        const mapped = items.map((item: any) => ({
            ...item,
            label: labelHelper(
                intl,
                item,
                Object.values(scriptFields).filter(isNotNullish),
                elasticFields,
                elasticIndices,
                elasticIndex.name,
                tableName
            ),
        }));
        return order ? _.orderBy(mapped, "label") : mapped;
    }, [
        relevantScriptsAreLoaded,
        elasticFields,
        elasticIndices,
        items,
        order,
        intl,
        scriptFields,
        elasticIndex,
        tableName,
    ]);
};

/**
 * Adds labels to an AnalysisQuery.
 */
export const useLabelledAnalysisQuery = (query: AnalysisQuery | undefined): LabelledAnalysisQuery | undefined => {
    const index = query?.elasticIndex;
    const columnSplitItems = useWithLabels(query?.columnSplitItems, false);
    const rowSplitItems = useWithLabels(query?.rowSplitItems, false);
    const valueAggregationItems = useWithLabels(query?.valueAggregationItems, false);
    const tableName = useTableName(index ?? "");
    return useMemo(() => {
        if (!index || !columnSplitItems || !rowSplitItems || !valueAggregationItems || !tableName) return;
        return {
            elasticIndex: index,
            columnSplitItems,
            rowSplitItems,
            valueAggregationItems,
            tableName,
        };
    }, [columnSplitItems, index, rowSplitItems, tableName, valueAggregationItems]);
};
