import { defineMessages } from "react-intl";

const messages = defineMessages({
    calculationType: {
        id: "customAnalysis.components.draggableFieldCalculationType",
        defaultMessage: "Calculation type",
    },
    selectCalcType: {
        id: "customAnalysis.components.draggableFieldSelectCalcType",
        defaultMessage: "Select calculation type",
    },
    editScript: {
        id: "customAnalysis.components.editScript",
        defaultMessage: "Edit script",
    },
    selectCumulativeFn: {
        id: "customAnalysis.components.ValueField.selectCumulativeFn",
        defaultMessage: "Select a cumulative function",
    },
    noCumulativeFn: {
        id: "customAnalysis.components.ValueField.noCumulativeFn",
        defaultMessage: "Don't use a cumulative function",
    },
    cumulativeSum: {
        id: "customAnalysis.components.ValueField.cumulative_sum",
        defaultMessage: "Cumulative sum",
    },
    cumulativeSumDescription: {
        id: "customAnalysis.components.ValueField.cumulative_sumDescription",
        defaultMessage: "The cumulative sum adds together all values in the window.",
    },
    derivative: {
        id: "customAnalysis.components.ValueField.derivative",
        defaultMessage: "Derivative",
    },
    derivativeDescription: {
        id: "customAnalysis.components.ValueField.derivativeDescription",
        defaultMessage:
            "The derivative aggregation calculates the difference between the value in the current column and the value in the previous column.",
    },
    cumulativeCardinality: {
        id: "customAnalysis.components.ValueField.cumulative_cardinality",
        defaultMessage: "Cumulative count of unique values",
    },
    cumulativeCardinalityDescription: {
        id: "customAnalysis.components.ValueField.cumulative_cardinalityDescription",
        defaultMessage: "Counts how many unique values there are in a column together with all preceding columns.",
    },
    movingMax: {
        id: "customAnalysis.components.ValueField.movingMax",
        defaultMessage: "Moving maximum",
    },
    movingMaxDescription: {
        id: "customAnalysis.components.ValueField.movingMaxDescription",
        defaultMessage: "Moving maximum calculates the maximum in each window.",
    },
    movingMin: {
        id: "customAnalysis.components.ValueField.movingMin",
        defaultMessage: "Moving minimum",
    },
    movingMinDescription: {
        id: "customAnalysis.components.ValueField.movingMinDescription",
        defaultMessage: "Moving minimum calculates the minimum in each window.",
    },
    movingSum: {
        id: "customAnalysis.components.ValueField.movingSum",
        defaultMessage: "Moving sum",
    },
    movingSumDescription: {
        id: "customAnalysis.components.ValueField.movingSumDescription",
        defaultMessage: "Moving sum calculates the sum of all values in each window.",
    },
    unweightedAvg: {
        id: "customAnalysis.components.ValueField.unweightedAvg",
        defaultMessage: "Unweighted average",
    },
    unweightedAvgDescription: {
        id: "customAnalysis.components.ValueField.unweightedAvgDescription",
        defaultMessage:
            "The unweighted average function calculates the sum of all values in the window, then divides by the size of the window. The simple moving average does not perform any time-dependent weighting, which means the values from a simple moving average tend to lag behind the real data.",
    },
    linearWeightedAvg: {
        id: "customAnalysis.components.ValueField.linearWeightedAvg",
        defaultMessage: "Linear weighted average",
    },
    linearWeightedAvgDescription: {
        id: "customAnalysis.components.ValueField.linearWeightedAvgDescription",
        defaultMessage:
            "This function assigns a linear weighting to points in the series, such that older datapoints (i.e. those at the beginning of the window) contribute a linearly less amount to the total average. The linear weighting helps reduce the lag behind the data’s mean, since older points have less influence.",
    },
    stdDevFromUnweightedAvg: {
        id: "customAnalysis.components.ValueField.stdDevFromUnweightedAvg",
        defaultMessage: "Standard deviation from unweighted average",
    },
    stdDevFromUnweightedAvgDescription: {
        id: "customAnalysis.components.ValueField.stdDevFromUnweightedAvgDescription",
        defaultMessage:
            "This function calculates the standard deviation from the average in the window. Each data point in the window is weighted equally.",
    },
    stdDevFromLinearAvg: {
        id: "customAnalysis.components.ValueField.stdDevFromLinearAvg",
        defaultMessage: "Standard deviation from linear weighted average",
    },
    stdDevFromLinearAvgDescription: {
        id: "customAnalysis.components.ValueField.stdDevFromLinearAvgDescription",
        defaultMessage:
            "This function calculates the standard deviation from the linear weighted average in the window. See the description of linear weighted average for more details.",
    },
    serialDiff: {
        id: "customAnalysis.components.ValueField.serialDiff",
        defaultMessage: "Compare with column X steps earlier",
    },
    serialDiffDescription: {
        id: "customAnalysis.components.ValueField.serialDiffDescription",
        defaultMessage:
            "This function subtracts the value of the column X steps back. This gives you the possibility to compare across years/months/number splits/etc.",
    },
    windowSize: {
        id: "customAnalysis.components.ValueField.windowSize",
        defaultMessage: "Window size",
    },
    windowSizeExplanation: {
        id: "customAnalysis.components.ValueField.windowSizeExplanation",
        defaultMessage:
            "The window size decides how many data points are included in the moving window. E.g. a window size of 4 means that all the moving functions are based on the current value and the 3 preceding values when calculating the result for each column.",
    },
    requireHistoExplanation: {
        id: "customAnalysis.components.ValueField.requireHistoExplanation",
        defaultMessage:
            "In order to use cumulative and moving functions, the last column split has to be either a date split (with unique dates) or a split on a number interval.",
    },
    uniqueCountEstimationWarning: {
        id: "customAnalysis.components.ValueField.uniqueCountEstimationWarning",
        defaultMessage:
            "Our unique count aggregation is an estimation, and may not be accurate on datasets with large cardinality. Unique counts are guaranteed to be accurate for fields with up to 10,000 unique values, and can have a relative error of 0.5%. Contact support for more information.",
    },
    hideValue: {
        id: "customAnalysis.ValueField.hideValue",
        defaultMessage: "Hide value from analysis",
    },
    showValue: {
        id: "customAnalysis.ValueField.showValue",
        defaultMessage: "Show value in analysis",
    },
    editValue: {
        id: "customAnalysis.ValueField.editValue",
        defaultMessage: "Edit value calculation",
    },
});

export default messages;
