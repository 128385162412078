import { Pen as EditOutlinedIcon } from "@ignite-analytics/icons";
import { SplitItem } from "@ignite-analytics/pivot-ts";
import { IconButton, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useCAElasticIndex } from "../../CAContextProvider/hooks";
import { DragProps } from "../DraggableFieldItem/interfaces";
import { isOdd, safeFormatMessage } from "../helpers";
import { DraggableDiv } from "../style";

import { isSplitItemSizeReliable } from "./helpers";
import { SortPicker } from "./SortPicker";
import SplitFieldSettingsModal from "./SplitFieldSettingsModal";

import { typeToIcon } from "@/components/ScriptModal/constants";
import customAnalysisMessages from "@/containers/CustomAnalysisPage/CustomAnalysis/messages";
import { staticFormatMessage } from "@/contexts/intlContext";
import { useLabel } from "@/hooks/useLabel";
import { formatBigNumber } from "@/lib/helpers/numbers";

interface Props extends DragProps {
    item: SplitItem & { uuid?: string };
    onUpdate: (item: SplitItem) => void;
    onOpenModal: (close?: boolean) => void;
    modalIsOpen: boolean;
    index: number;
}

const SplitField: React.FC<Props> = ({
    item: _item,
    onOpenModal,
    modalIsOpen,
    onUpdate,
    index,
    dragId,
    dragItem,
    isDraggable = false,
    onDragStart,
    onDragEnd,
    onDragOver,
}) => {
    const elasticIndex = useCAElasticIndex();
    const [item, setItem] = useState(_item);
    const [sortPickerOpen, setSortPickerOpen] = useState(false);

    const label = useLabel(item, elasticIndex);

    const formattedInterval =
        typeof item.interval === "number" ? formatBigNumber(item.interval, 0, false) : safeFormatMessage(item.interval);

    useEffect(
        function updateLocalItemWhenPropsChange() {
            setItem(_item);
        },
        [_item]
    );

    const sizeIsReliable = isSplitItemSizeReliable(item);

    useEffect(
        function setInitialSearchSize() {
            // Make sure we have specified a search size if the aggregated filters make the size unreliable
            if (!sizeIsReliable) setItem((prev) => ({ ...prev, searchSize: prev.searchSize ?? 1000 }));
            // Otherwise, there's no need for a specified searchSize
            else setItem((prev) => ({ ...prev, searchSize: null }));
        },
        [sizeIsReliable, setItem]
    );

    const TypeIcon = typeToIcon[item.type];

    return (
        <>
            <DraggableDiv
                id={dragId}
                draggable={isDraggable}
                isDragging={"uuid" in item && dragItem?.item.uuid === item.uuid}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
            >
                <Paper
                    elevation={0}
                    data-testid={dragId}
                    sx={{
                        backgroundColor: (theme) => {
                            if (sortPickerOpen) {
                                return theme.palette.grey[200];
                            }
                            return isOdd(index) ? theme.palette.grey[100] : theme.palette.background.paper;
                        },
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.grey[200],
                        },
                    }}
                >
                    <div
                        role="button"
                        tabIndex={0}
                        onClick={() => !sortPickerOpen && onOpenModal()}
                        onKeyPress={() => onOpenModal()}
                    >
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack pl={1} direction="row" spacing={1} alignItems="center">
                                <TypeIcon fontSize="inherit" />
                                <Typography variant="textSm">
                                    {formattedInterval ? `${label} - ${formattedInterval}` : label}
                                </Typography>
                            </Stack>
                            <Stack direction="row" alignItems="center">
                                <SortPicker
                                    index={index}
                                    item={item}
                                    onUpdate={onUpdate}
                                    onOpen={() => setSortPickerOpen(true)}
                                    onClose={() => setSortPickerOpen(false)}
                                />
                                <Typography variant="textXs">
                                    {item.size ?? 10} {staticFormatMessage(customAnalysisMessages.items)}
                                </Typography>
                                <IconButton size="2xsmall" onClick={() => onOpenModal()}>
                                    <EditOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Stack>
                        </Stack>
                    </div>
                </Paper>
            </DraggableDiv>
            <SplitFieldSettingsModal
                item={item}
                setItem={setItem}
                onUpdate={onUpdate}
                onOpenModal={onOpenModal}
                modalIsOpen={modalIsOpen}
                label={label}
                formattedInterval={formattedInterval}
                sizeIsReliable={sizeIsReliable}
            />
        </>
    );
};

export default SplitField;
