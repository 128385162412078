import { isApolloError } from "@apollo/client";
import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter } from "@ignite-analytics/filters";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { useCallback } from "react";

import { mapFromFrontendFiltersToAnalysisFilter } from "./helpers";
import messages from "./messages";

import { useInformUser } from "@/contexts/InfoMessageProvider";
import { fm } from "@/contexts/intlContext";
import { useExportDataTableToFileMutation } from "@/generated/client";

const getDataTableIdFromIndexName = (indexName: string): string | undefined => {
    // eslint-disable-next-line prefer-regex-literals
    const dataTableRegexp = new RegExp(
        "^datatable_v2_([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})",
        "g"
    );
    const result = dataTableRegexp.exec(indexName);
    if (result === null || !result[1]) {
        return undefined;
    }
    return result[1];
};

export const useExportData = (analysisName: string) => {
    const [exportDataTable] = useExportDataTableToFileMutation();
    const informUser = useInformUser();
    return useCallback(
        (elasticIndex: string, filters: Filter[], elasticFields: ElasticField[]): Promise<void> => {
            const dataTableId = getDataTableIdFromIndexName(elasticIndex);
            if (!dataTableId) {
                informUser({
                    message: fm(messages.exportToExcelFailure),
                    type: "error",
                });
                return Promise.reject();
            }
            return exportDataTable({
                input: {
                    dataTableId,
                    filters: filters.map(mapFromFrontendFiltersToAnalysisFilter).filter(isNotNullish),
                    exportFormat: "XLSX",
                    fields: elasticFields.map(({ fieldId }) => fieldId),
                    notificationTitle: `Selected data from the analysis '${analysisName}' to file`,
                },
            })
                .then(() =>
                    informUser({
                        message: fm(messages.exportToExcelSuccess),
                        type: "success",
                    })
                )
                .catch((err) => {
                    if (isApolloError(err) && err.graphQLErrors.length) {
                        const fullMessage = err.graphQLErrors[0].message;
                        const messageWithoutErrorPrefix = fullMessage.split(
                            `${err.graphQLErrors[0].extensions.code}: `
                        )[1];
                        informUser({
                            message:
                                messageWithoutErrorPrefix ??
                                fullMessage ??
                                fm(messages.exportToExcelFailure)?.toString(),
                            type: "error",
                        });
                        return;
                    }

                    informUser({
                        message: fm(messages.exportToExcelFailure),
                        type: "error",
                    });
                });
        },
        [exportDataTable, informUser, analysisName]
    );
};
