import { Typography, styled } from "@mui/material";

export const TruncatedColumn = styled(Typography)`
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    -webkit-line-clamp: 2;
    -moz-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
`;
