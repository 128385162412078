import { defineMessages } from "react-intl";

const messages = defineMessages({
    nothing: {
        id: "components.ScriptModal.mathematicalPreview.nothing",
        defaultMessage: "Nothing",
    },
});

export default messages;
