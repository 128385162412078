/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Api } from "@ignite-analytics/api-client";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { Guarded } from "@ignite-analytics/permissions";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";

import { useAllModelGuards } from "../modelGuards";

import { Widget } from "@/components/Widgets/interfaces";
import { useSessionContext } from "@/contexts/SessionContextProvider";
import { useBackwardCompatiblePermissionCheck } from "@/hooks/useBackwardCompatiblePermissionCheck";

export interface CustomDashboard extends Guarded {
    id: number;
    name: string;
    description: string;
    client: number;
    department: number;
    module: "home" | "analyze" | "prioritize" | "implement" | "followup" | "sourcing";
    owner: string;
    dashboardCollection: number | null;
    precedence: number;
    createdAt: string;
    updatedAt: string;
    widgets: Widget[];
}

/**
 * More restricted version of CustomDashboard used in list endpoint.
 */
export type CustomDashboardListObject = Omit<CustomDashboard, "widgets">;

const listCreateResource = createListResource<CustomDashboardListObject>`/dashboards/user/${() =>
    useSessionContext().id}/`;

const detailResource = createDetailResource<CustomDashboard>`/dashboards/${(params) => params.id}/`;

export const {
    Provider: CustomDashboardContextProvider,
    useObject: useDashboard,
    useAll: useAllCustomDashboards,
    useThisContext: useCustomDashboardContext,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: false,
    name: "Custom dashboards",
});

export const useCreatePartialCustomDashboard = () => {
    const { addListObjs } = useCustomDashboardContext();
    const { id: userId } = useSessionContext();

    return useCallback(
        (dashboard: Pick<CustomDashboard, "name" | "description" | "dashboardCollection" | "client">) => {
            const url = `/dashboards/user/${userId}/`;
            return Api.post<CustomDashboard>(url, dashboard, {
                service: "dashboards",
                onError: (error: AxiosError) =>
                    Sentry.captureException(error?.response, {
                        tags: {
                            message: "Create dashboards error",
                            app: "analysis-app",
                            ...{
                                statusCode: error.response?.status,
                                errorMessage: error.response?.data?.message,
                                dashboardName: dashboard?.name,
                            },
                        },
                    }),
            }).then((createdDashboard: CustomDashboard) => {
                addListObjs([createdDashboard], url);
                return createdDashboard;
            });
        },
        [addListObjs, userId]
    );
};

// We need to update the entities cache when using the addDetailObj does not, so the dashboard will fetch from the cache if changing micro apps
export const useUpdateDashboardDetailObjAndCache = () => {
    const { addDetailObj } = useCustomDashboardContext();

    return useCallback(
        (updatedDashboard: CustomDashboard) => {
            const url = `/dashboards/${updatedDashboard.id}/`;
            if (!window._igniteEntitiesCache) window._igniteEntitiesCache = {};
            addDetailObj(updatedDashboard, url);
            window._igniteEntitiesCache[url] = Promise.resolve(updatedDashboard);
        },
        [addDetailObj]
    );
};

export const useEditableDashboards = () => {
    const { id: userId } = useSessionContext();
    const [dashboards, setDashboards] = useState<CustomDashboardListObject[] | undefined>(undefined);
    const hasModelPermission = useBackwardCompatiblePermissionCheck("change", {
        namespace: "dashboards",
        relation: { object: "general", relation: "write" },
    });
    const modelGuards = useAllModelGuards(undefined, { service: "dashboards" });
    const url = `/dashboards/user/${userId}/`;

    const getDashboards = useCallback(
        () =>
            Api.get<CustomDashboardListObject[]>(url, {
                service: "dashboards",
                onError: (error: AxiosError) =>
                    Sentry.captureException(error?.response, {
                        tags: {
                            message: "Get dashboards error",
                            app: "analysis-app",
                            ...{
                                statusCode: error.response?.status,
                                errorMessage: error.response?.data?.message,
                            },
                        },
                    }),
            }).then((_dashboards) => {
                setDashboards(_dashboards);
                return _dashboards;
            }),
        [url]
    );

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        getDashboards();
    }, [getDashboards]);

    if (!dashboards) return { editableDashboards: undefined, getDashboards };

    const editableDashboards = dashboards.filter((dashboard) => {
        const modelGuard = dashboard.guard && modelGuards[dashboard.guard];
        if (modelGuard && userId) {
            return (modelGuard.standardWrite && hasModelPermission) || modelGuard.writeUsers.includes(userId);
        }

        return hasModelPermission;
    });

    return {
        editableDashboards,
        getDashboards,
    };
};
