import { defineMessages } from "react-intl";

const messages = defineMessages({
    days: {
        id: "units.days",
        defaultMessage: "days",
    },
    dayOfWeek: {
        id: "units.dayOfWeek",
        defaultMessage: "day of week",
    },
    dayOfMonth: {
        id: "units.dayOfMonth",
        defaultMessage: "day of month",
    },
    dayOfYear: {
        id: "units.dayOfYear",
        defaultMessage: "day of year",
    },
    weeks: {
        id: "units.weeks",
        defaultMessage: "weeks",
    },
    quarters: {
        id: "units.quarters",
        defaultMessage: "quarters",
    },
    quarterOfYear: {
        id: "units.quarterOfYear",
        defaultMessage: "quarter of year",
    },
    months: {
        id: "units.months",
        defaultMessage: "months",
    },
    monthOfYear: {
        id: "units.monthOfYear",
        defaultMessage: "month of year",
    },
    years: {
        id: "units.years",
        defaultMessage: "years",
    },
});

export default messages;
