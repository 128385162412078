import { defineMessages } from "react-intl";

const messages = defineMessages({
    sorting: {
        id: "customAnalysis.pivotTable.sorting",
        defaultMessage: "Sorted {order} by {field}.",
    },
    desc: {
        id: "customAnalysis.pivotTable.desc",
        defaultMessage: "descendingly",
    },
    asc: {
        id: "customAnalysis.pivotTable.asc",
        defaultMessage: "ascendingly",
    },
    name: {
        id: "customAnalysis.pivotTable.name",
        defaultMessage: "name",
    },
});

export default messages;
