import { styled, css, Paper } from "@mui/material";

export const MaxHeightContent = styled("div")`
    overflow-y: auto;
    height: 100%;
`;

export const StyledCard = styled(Paper)<{ autoGrow?: boolean; showDropField?: boolean }>`
    ${(props) =>
        props.autoGrow
            ? css`
                  display: flex;
                  flex-grow: 1;
                  flex-direction: column;
                  min-height: 0;
              `
            : ""}
    margin-left: 0:
    margin-right: 0;
    &:last-child {
        margin-bottom: 0;
    }

    ${({ showDropField, theme }) =>
        showDropField &&
        css`
            border: 2px dashed ${theme.palette.primary.light};
        `}
`;

export const ScrollDiv = styled("div")`
    overflow-y: auto;
    flex-grow: 1;
`;

export const DropBox = styled("div")<{ active?: boolean }>`
    border-radius: 5px;
    color: ${({ theme }) =>
        (props) =>
            props.active ? theme.palette.primary.main : theme.palette.grey[700]};
    min-height: 95%;
`;
