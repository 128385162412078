import { defineMessages } from "react-intl";

const messages = defineMessages({
    analytics: {
        id: "hooks.useSetBreadcrumbs.analytics",
        defaultMessage: "Analytics",
    },
    newAnalysis: {
        id: "hooks.useSetBreadcrumbs.newAnalysis",
        defaultMessage: "New Analysis",
    },
});

export default messages;
