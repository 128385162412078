import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useShortDescriptionOfFilters } from "@ignite-analytics/filters";
import {
    Pen as EditOutlinedIcon,
    EyeSlash as VisibilityOffOutlinedIcon,
    Eye as VisibilityOutlinedIcon,
} from "@ignite-analytics/icons";
import { AGG_TYPES } from "@ignite-analytics/pivot-ts";
import { IconButton, Paper, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import { useCAElasticIndex } from "../../CAContextProvider/hooks";
import { DragProps } from "../DraggableFieldItem/interfaces";
import { isOdd, safeFormatMessage } from "../helpers";
import { DraggableDiv } from "../style";

import { ValueItem } from "./interfaces";
import messages from "./messages";
import ValueAggModal from "./ValueAggModal";

import { typeToIcon } from "@/components/ScriptModal/constants";
import { fm } from "@/contexts/intlContext";
import { useLabelWithoutFilters } from "@/hooks/useLabel";

interface Props extends DragProps {
    item: ValueItem;
    onUpdate: (item: ValueItem) => void;
    onOpenModal: (close?: boolean) => void;
    modalIsOpen: boolean;
    toggleEyeIcon?: (index: number) => void;
    setOpenModal?: (value: string | undefined) => void;
    disableEyeIcon: boolean;
    aggItemIndex?: number;
    hidden?: boolean | null;
    inHistogram?: boolean;
    "data-testid"?: string;
    index?: number;
}

const ValueField: React.FC<Props> = ({
    item,
    onOpenModal,
    setOpenModal,
    modalIsOpen,
    onUpdate,
    toggleEyeIcon,
    disableEyeIcon,
    aggItemIndex,
    hidden,
    inHistogram = false,
    "data-testid": dataTestId,
    index,
    dragId,
    dragItem,
    isDraggable = false,
    onDragStart,
    onDragEnd,
    onDragOver,
}) => {
    const elasticIndex = useCAElasticIndex();
    const dataFields = useElasticFieldsInContext();
    const shortFilterDescription = useShortDescriptionOfFilters(item.filters, dataFields);
    const label = useLabelWithoutFilters(item, elasticIndex);

    const aggOptions = AGG_TYPES[item.type];
    const { aggregation = aggOptions[0] } = item;
    const aggregationTitle = safeFormatMessage(aggregation);
    const ID_PREFIX = `${dataTestId}-`;

    const TypeIcon = typeToIcon[item.type];

    return (
        <>
            <DraggableDiv
                id={dragId}
                draggable={isDraggable}
                isDragging={dragItem?.item.uuid === item.uuid}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
            >
                <Paper
                    elevation={0}
                    data-testid={dragId}
                    sx={{
                        backgroundColor: (theme) =>
                            isOdd(index) ? theme.palette.grey[100] : theme.palette.background.paper,
                        "&:hover": {
                            backgroundColor: (theme) => theme.palette.grey[200],
                        },
                    }}
                >
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <div
                            role="button"
                            tabIndex={0}
                            onClick={() => onOpenModal()}
                            onKeyPress={() => onOpenModal()}
                            style={{ minWidth: "80%" }}
                        >
                            <Stack pl={1} direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                <TypeIcon fontSize="inherit" />
                                <Typography variant="textSm">{`${label} ${shortFilterDescription} - ${aggregationTitle}`}</Typography>
                            </Stack>
                        </div>

                        <Stack direction="row" justifyContent="flex-end">
                            {toggleEyeIcon && aggItemIndex !== undefined && (
                                <Tooltip
                                    title={hidden ? fm(messages.showValue) : fm(messages.hideValue)}
                                    disableInteractive
                                >
                                    <IconButton
                                        disabled={hidden ? false : disableEyeIcon}
                                        onClick={() => toggleEyeIcon(aggItemIndex)}
                                        data-testid={`${ID_PREFIX}viewButton`}
                                        size="2xsmall"
                                    >
                                        {hidden ? (
                                            <VisibilityOffOutlinedIcon fontSize="inherit" />
                                        ) : (
                                            <VisibilityOutlinedIcon fontSize="inherit" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                            <Tooltip title={fm(messages.editValue)} disableInteractive>
                                <IconButton
                                    onClick={() => onOpenModal()}
                                    data-testid={`${ID_PREFIX}editButton`}
                                    size="2xsmall"
                                >
                                    <EditOutlinedIcon fontSize="inherit" />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                </Paper>
            </DraggableDiv>
            {modalIsOpen && (
                <ValueAggModal
                    item={item}
                    onClose={() => {
                        setOpenModal?.(undefined);
                        onOpenModal(true);
                    }}
                    onUpdate={onUpdate}
                    aggItemIndex={aggItemIndex}
                    inHistogram={inHistogram}
                />
            )}
        </>
    );
};

export default ValueField;
