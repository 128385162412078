import { toCamelCase } from "@ignite-analytics/api-client";
import { CumulativeFunction } from "@ignite-analytics/pivot-ts";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export const getCumulativeMessage = (cumulation: CumulativeFunction, getDescription = false) => {
    const key = toCamelCase(cumulation + (getDescription ? "Description" : ""), true);
    if (key in messages) return fm(messages[key as keyof typeof messages]);
    console.error(`Missing translation for ${key}`);
    return key;
};

type aggOptionType = "script" | "sum" | "avg" | "max" | "min" | "value_count" | "cardinality" | "percentiles";
const aggOptions: aggOptionType[] = ["script", "sum", "avg", "max", "min", "value_count", "cardinality", "percentiles"];
export const isAggOption = (x: any): x is aggOptionType => aggOptions.includes(x);

const cumFunctions: CumulativeFunction[] = [
    "cumulative_cardinality",
    "cumulative_sum",
    "derivative",
    "movingMax",
    "movingMin",
    "movingSum",
    "unweightedAvg",
    "linearWeightedAvg",
    "stdDevFromUnweightedAvg",
    "stdDevFromLinearAvg",
    "serial_diff",
];
export const isCumFunc = (x: any): x is CumulativeFunction => cumFunctions.includes(x);
