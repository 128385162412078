import { defineMessages } from "react-intl";

const messages = defineMessages({
    genericServerError: {
        id: "components.errorAlerts.genericServerError",
        defaultMessage: "An error occurred, hover to get more information",
    },
    networkError: {
        id: "components.errorAlerts.genericServerError",
        defaultMessage: "A network error has occurred, to reload the analysis press the refresh symbol",
    },
    timeoutError: {
        id: "components.errorAlerts.timeoutError",
        defaultMessage:
            "The request has timed out, due to network issues or the size of the analysis. If you suspect that the analysis could be too big, please try reducing the number of splits or results per split.",
    },
});

export default messages;
