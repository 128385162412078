import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useConsistentIdentity } from "@ignite-analytics/general-tools";
import {
    AggregationItem,
    AnalysisQuery,
    BucketScript,
    Labelled,
    MetricsScript,
    ScriptField,
    SplitItem,
    ValueConfiguration,
    toAnalysisItem,
} from "@ignite-analytics/pivot-ts";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

import { AnalysisWidget } from "@/components/Widgets/interfaces";
import { useMatchingScriptFields, useScriptsForDashboard } from "@/entities/scriptFields";
import { useTransactionsIndex } from "@/hooks/useElasticIndexWithType";
import { useWithLabels } from "@/hooks/useWithLabels";

/**
 * Takes in a ValueConfiguration and returns an AggregationItem.
 * If the ValueConfiguration refers to a script that does not exist in redux, `undefined` will be returned,
 * that usually means that the scripts are not loaded yet.
 */
const toAggregationItem = (
    valueConf: ValueConfiguration,
    scriptFields: Partial<Record<number, ScriptField>>
): AggregationItem | undefined => {
    if (!(valueConf.type === "scripted_metric" || valueConf.type === "script")) {
        return valueConf;
    }
    const { script: scriptId } = valueConf;
    const match = scriptFields[scriptId];
    if (!match) return;
    const params = match.valueConfigurations.reduce<Record<string, AggregationItem> | undefined>((res, val) => {
        if (!res) return undefined;
        const param = toAggregationItem(val, scriptFields);
        if (!param) return undefined;
        return { ...res, [val.key]: param };
    }, {});
    if (!params) return;
    if (match.type === "scripted_metric") {
        if (valueConf.type === "scripted_metric") {
            return {
                ...match,
                ...valueConf,
                type: "scripted_metric",
                id: scriptId,
                params,
                aggregation: valueConf.aggregation,
                visible: valueConf.visible,
            };
        }
        const asScript: MetricsScript = {
            ...match,
            type: "scripted_metric",
            id: scriptId,
            params,
            aggregation: "avg",
            visible: valueConf.visible,
        };
        return asScript;
    }
    const asScript: BucketScript = {
        ...match,
        type: "script",
        id: scriptId,
        params,
        visible: valueConf.visible,
    };
    return asScript;
};

const emptySplitItemArray: SplitItem[] = [];
const emptyValueConfigArray: ValueConfiguration[] = [];

/**
 * Takes in an AnalysisWidget and returns an AnalysisQuery.
 * If the widget contains scripts that are not loaded yet, the function will return undefined.
 */
export const useAnalysisQuery = (
    widget:
        | Pick<AnalysisWidget, "columnSplitItems" | "rowSplitItems" | "valueConfigurations" | "elasticIndex">
        | undefined,
    dashboardId: number | undefined
): AnalysisQuery | undefined => {
    const scriptFields = useScriptsForDashboard(dashboardId);
    const transactionIndex = useTransactionsIndex()?.name;
    const {
        rowSplitItems = emptySplitItemArray,
        columnSplitItems = emptySplitItemArray,
        valueConfigurations = emptyValueConfigArray,
        elasticIndex = transactionIndex,
    } = widget ?? {};
    return useConsistentIdentity(
        useMemo(() => {
            if (!valueConfigurations.length || !elasticIndex) {
                return undefined;
            }
            const valueAggregationItems = valueConfigurations
                .map((v) => toAggregationItem(v, scriptFields))
                .filter((v): v is AggregationItem => v !== undefined);
            if (valueAggregationItems.length !== valueConfigurations.length) {
                return undefined;
            }
            return { rowSplitItems, columnSplitItems, valueAggregationItems, elasticIndex };
        }, [rowSplitItems, columnSplitItems, valueConfigurations, elasticIndex, scriptFields])
    );
};

export const useAnalysisItemsByIndex = (elasticIndex?: string, searchTerm?: string) => {
    const scriptFields = useMatchingScriptFields({ elasticIndex, autoCreated: false }, {}, true, {
        service: "dashboards",
    });
    const fieldsInIndex = useElasticFieldsInContext();
    const allFields = useMemo(() => [...(fieldsInIndex ?? []), ...scriptFields], [fieldsInIndex, scriptFields]);
    const labeledFields = useWithLabels(allFields);

    const availableFields = useMemo(() => {
        if (!labeledFields) {
            return undefined;
        }
        return _.orderBy(
            labeledFields.filter(
                (item) => !searchTerm || item.label.trim().toLowerCase().includes(searchTerm.trim().toLowerCase())
            ),
            "label"
        ).map(toAnalysisItem);
    }, [labeledFields, searchTerm]);
    return availableFields;
};

/**
 * Works exactly like useAnalysisItemsByIndex, but has a more limited return type (can be useful in some cases)
 */
export const useValueConfigurationsByIndex = (elasticIndex: string, searchTerm?: string) =>
    useAnalysisItemsByIndex(elasticIndex, searchTerm) as Labelled<ValueConfiguration & { uuid: string }>[];

export const useWindowDimensions = () => {
    function getWindowDimensions() {
        const { innerWidth: width, innerHeight: height } = window;
        return {
            width,
            height,
        };
    }
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};
