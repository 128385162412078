import { Alert, Snackbar } from "@mui/material";
import React, { useContext } from "react";

import { InfoMessageContext } from "@/contexts/InfoMessageProvider";

export const InfoMessage: React.FC = () => {
    const messageContext = useContext(InfoMessageContext);

    if (messageContext === undefined) {
        throw new Error("Missing message context");
    }

    const { infoMessage, hideInfoMessage } = messageContext;

    return (
        <Snackbar
            key={infoMessage?.timestamp}
            open={infoMessage !== null}
            autoHideDuration={infoMessage?.timeout ?? 5000}
            onClose={hideInfoMessage}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        >
            <Alert severity={infoMessage?.type} sx={{ width: "100%" }}>
                {infoMessage?.message?.toString()}
            </Alert>
        </Snackbar>
    );
};
