import { css, keyframes, styled } from "@mui/material";

const fadeIn = keyframes`
    0%, 70% {opacity: 0}
    100% {opacity: 1}
`;

export const AbsoluteLoadingScreen = styled("div")<{ delay?: boolean }>`
    position: absolute;
    top: 30%;
    left: 50%;
    background: rgba(255, 255, 255, 0.5);
    ${(props) =>
        props.delay &&
        css`
            animation: ${fadeIn} 500ms ease-in;
        `}
`;
