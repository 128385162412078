import { DUMMY_DEPARTMENT_ID, Department } from "@ignite-analytics/department";
import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { useMemo } from "react";

const listResource = createListResource<Department>`/clients/1/departments/`;

const detailResource = createDetailResource<Department>`/departments/${(dep) => dep.id}/`;

const {
    Provider: DepartmentProvider,
    useObject: useDepartment,
    useThisContext: useDepartmentContext,
} = createEntityContext(listResource, detailResource, {
    interchangableTypes: true,
    name: "Departments",
});

export { DepartmentProvider };

export const useCurrentDepartment = () => {
    const { detailObjs } = useDepartmentContext();
    useDepartment(DUMMY_DEPARTMENT_ID);
    return useMemo(() => Object.values(detailObjs)?.[0], [detailObjs]);
};