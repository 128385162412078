import { isNotNullish } from "@ignite-analytics/general-tools";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";

/**
 * Takes in the name of an ElasticSearch index and returns the correct name for the corresponding table.
 */
export function useTableName(indexName: undefined): undefined;
export function useTableName(indexName: string): string;
export function useTableName(indexName: string | undefined): string | undefined;
export function useTableName(indexName: string | undefined) {
    const elasticIndexes = useAllElasticIndices();
    if (!indexName) return;
    const elasticLabel = elasticIndexes?.find((index) => index.name === indexName)?.label;
    return elasticLabel || indexName;
}

export const useTableNames = (indexNames: string[]) => {
    const elasticIndices = useAllElasticIndices();
    const indices = elasticIndices
        ?.filter((elasticIndex) => indexNames.some((name) => elasticIndex.name.startsWith(name)))
        .map((elasticIndex) => ({
            text: elasticIndex.label || elasticIndex.name,
            value: elasticIndex.name,
        }));
    return indices?.filter(isNotNullish);
};

export const useAllTableNames = () => useTableNames(useAllElasticIndices()?.map((index) => index.name) ?? []);
