import { Plus, X, Minus } from "@ignite-analytics/icons";
import { COMPARATIVE_OPERATORS } from "@ignite-analytics/pivot-ts";
import SvgIcon from "@mui/material/SvgIcon";

import { BinaryOperator } from "./interfaces";

export const NUMERICAL_OPERATORS = ["+", "-", "/", "*"] as const;

export const BOOLEAN_OPERATORS = ["&&", "||"] as const;

export const ALL_BINARY_OPERATORS = [...NUMERICAL_OPERATORS, ...COMPARATIVE_OPERATORS, ...BOOLEAN_OPERATORS];

export const OPERATOR_ICONS: Record<BinaryOperator, typeof SvgIcon | string> = {
    "+": Plus,
    "-": Minus,
    "/": "/", // Divide,
    "*": X,
    "<": "<", // Less than
    "<=": "<=", // Less than or equal to
    ">": ">", // Greater than
    ">=": ">=", // Greater than or equal to
    "==": "==", // Equal to
    "!=": "!=", // Not equal to
    "&&": "&&", // And
    "||": "||", // Or
};
