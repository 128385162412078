import { isNumericType } from "@ignite-analytics/elastic-fields";
import { useDebounce } from "@ignite-analytics/general-tools";
import { Plus as Add, X as ClearOutlinedIcon } from "@ignite-analytics/icons";
import { DateInterval, SplitItem } from "@ignite-analytics/pivot-ts";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import update from "immutability-helper";
import React, { useEffect, useMemo, useState } from "react";

import { safeFormatMessage } from "../../helpers";
import AggregatedFilterItem from "../AggregatedFilterItem";
import { dateIntervalOptions } from "../constants";
import messages from "../messages";

import customAnalysisMessages from "@/containers/CustomAnalysisPage/CustomAnalysis/messages";
import { fm, staticFormatMessage } from "@/contexts/intlContext";
import { formatNumber } from "@/lib/convert";
import { deformatBigNumber } from "@/lib/helpers/numbers";

const formatValue = (value: string): string => {
    if (value.includes(",")) {
        if (value.includes(".")) {
            return value.indexOf(".") > value.indexOf(",") ? value.replace(/\./g, "") : value.replace(/,/g, "");
        }
        return value.replace(/\s/g, "").replace(/,/g, "");
    }
    return value.replace(/\s/g, "").replace(/\./g, "");
};

interface CustomSplitSizeProps {
    numberOfIntervals?: number;
    currentIntervals: number[];
    setItem: React.Dispatch<React.SetStateAction<SplitItem>>;
    interval: number;
}

interface CustomIntervalInputProps {
    index: number;
    numberOfIntervals?: number;
    message: string;
    defaultValue: number;
    setItem: React.Dispatch<React.SetStateAction<SplitItem>>;
    interval: number;
    currentIntervals: number[];
}

const CustomIntervalInput: React.FC<CustomIntervalInputProps> = ({
    index,
    message,
    setItem,
    numberOfIntervals,
    defaultValue,
    interval,
    currentIntervals,
}) => {
    let iterativeValue = 0;

    const valueFromCurrentIntervals = () => {
        if (currentIntervals.length > index) {
            return currentIntervals[index];
        }
        const lastIndex = currentIntervals.length - 1;
        const numberOfIntervalsToAdd = (index - lastIndex + 1) / 2;
        return currentIntervals[lastIndex] + numberOfIntervalsToAdd * interval;
    };
    const currentValue = currentIntervals.length > 0 ? valueFromCurrentIntervals() : defaultValue;
    const currentValueAsString = formatNumber(currentValue, 0);
    const newIntervals = (formerIntervals?: number[], value?: string) =>
        numberOfIntervals
            ? [...Array(numberOfIntervals * 2)].map((_element, mappingIndex) => {
                  if (mappingIndex !== 0 && mappingIndex % 2 !== 0) {
                      iterativeValue += interval;
                  }
                  if (index === mappingIndex) {
                      return parseFloat(formatValue(value || "0"));
                  }
                  if (formerIntervals && formerIntervals?.length >= mappingIndex) {
                      return formerIntervals[mappingIndex];
                  }
                  return iterativeValue;
              })
            : [defaultValue];

    return (
        <TextField
            size="small"
            key={`${index}_input`}
            required
            name="interval"
            type="text"
            label={message}
            onChange={(e) => {
                if (!e.target.value) return;
                setItem((prevState) => {
                    const { value } = e.target;
                    const formerIntervals = prevState.intervals;
                    return {
                        ...prevState,
                        intervals:
                            numberOfIntervals && formerIntervals && formerIntervals.length === numberOfIntervals * 2
                                ? formerIntervals.map((element, mappingIndex) => {
                                      if (index === mappingIndex) {
                                          return parseFloat(formatValue(value) || "0");
                                      }
                                      return element;
                                  })
                                : newIntervals(formerIntervals, value),
                    };
                });
            }}
            value={currentValueAsString}
        />
    );
};

const CustomSizeSplit: React.FC<CustomSplitSizeProps> = ({
    numberOfIntervals,
    interval,
    setItem,
    currentIntervals,
}) => {
    let iterator = -1;
    let iterativeValue = 0;
    return (
        <>
            {numberOfIntervals && numberOfIntervals > 0 ? (
                [...Array(numberOfIntervals)].map((_, i) => {
                    const key = `${i}_row`;
                    const headerText = `${staticFormatMessage(messages.intervalIndex)} ${i + 1}`;
                    return (
                        <>
                            <Typography variant="h5"> {headerText} </Typography>
                            <Stack direction="row" spacing={2} pb="s" key={key} alignItems="center">
                                {[...Array(2)].map((__, innerIndex) => {
                                    const message = innerIndex === 0 ? messages.intervalFrom : messages.intervalTo;
                                    if (innerIndex === 1) {
                                        iterativeValue += interval;
                                    }
                                    iterator += 1;
                                    return (
                                        <CustomIntervalInput
                                            key={`${iterator}_inputElement`}
                                            interval={interval}
                                            index={iterator}
                                            message={staticFormatMessage(message)}
                                            setItem={setItem}
                                            numberOfIntervals={numberOfIntervals}
                                            defaultValue={iterativeValue}
                                            currentIntervals={currentIntervals}
                                        />
                                    );
                                })}
                            </Stack>
                        </>
                    );
                })
            ) : (
                <Typography variant="textSm">{fm(messages.largerThanZero)}</Typography>
            )}
            <Typography variant="h5">{fm(messages.numberOfIntervals)}</Typography>
        </>
    );
};
interface Props {
    item: SplitItem & { uuid?: string | undefined };
    setItem: React.Dispatch<
        React.SetStateAction<
            SplitItem & {
                uuid?: string | undefined;
            }
        >
    >;
    onUpdate: (item: SplitItem) => void;
    onOpenModal: (close?: boolean) => void;
    modalIsOpen: boolean;
    label: string;
    formattedInterval: string;
    sizeIsReliable: boolean;
}

const SplitFieldSettingsModal: React.FC<Props> = ({
    item,
    setItem,
    onUpdate,
    onOpenModal,
    modalIsOpen,
    label,
    formattedInterval,
    sizeIsReliable,
}) => {
    const [isCustom, setIsCustom] = useState<boolean>();
    const [creatingNewAggFilter, setCreatingNewAggFilter] = useState(false);
    const numericInterval = typeof item.interval === "number" ? item.interval : 1;
    const excludeOthers = item.excludeOthers ?? false;

    useEffect(() => {
        const isCustomIntervals = item.intervals && item.intervals.length > 0;
        item.intervals && setIsCustom(isCustomIntervals);
    }, [item.intervals]);

    const intervals = useMemo(() => {
        if (item.intervals) return item.intervals;
        return [];
    }, [item.intervals]);

    const debounceHandleChangeSearchSize = useDebounce((value: number) => {
        setItem((prev) => ({ ...prev, searchSize: value }));
    }, 500);

    return (
        <Dialog open={modalIsOpen} onClose={() => onOpenModal(true)}>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography variant="h5">
                        {staticFormatMessage(customAnalysisMessages.splitSettings, { item: label })}
                    </Typography>
                    <IconButton onClick={() => onOpenModal(true)} size="small">
                        <ClearOutlinedIcon fontSize="inherit" />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack pt={1} direction="column" spacing={1}>
                    {item.type === "date" ? (
                        <>
                            <FormControl fullWidth>
                                <InputLabel id="interval-select-label">
                                    {staticFormatMessage(customAnalysisMessages.selectInterval)}
                                </InputLabel>
                                <Select
                                    labelId="interval-select-label"
                                    required
                                    name="interval"
                                    label={staticFormatMessage(customAnalysisMessages.selectInterval)}
                                    onChange={(e) => {
                                        const { value } = e.target;
                                        const isDateInterval = (x: any): x is DateInterval =>
                                            dateIntervalOptions.includes(x);
                                        if (!isDateInterval(value)) return;
                                        setItem((prevState) => ({ ...prevState, interval: value }));
                                    }}
                                    value={item.interval}
                                    placeholder={formattedInterval}
                                >
                                    {dateIntervalOptions.map((opt) => (
                                        <MenuItem key={opt} value={opt}>
                                            {safeFormatMessage(opt)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            {item.interval !== "year" && (
                                <FormControlLabel
                                    label={
                                        <Typography pl={1}>
                                            {staticFormatMessage(customAnalysisMessages.selectUnique)}
                                        </Typography>
                                    }
                                    control={
                                        <Switch
                                            checked={item.unique ?? true}
                                            onChange={(e) =>
                                                setItem((prevState) => ({ ...prevState, unique: e.target.checked }))
                                            }
                                        />
                                    }
                                />
                            )}
                        </>
                    ) : (
                        isNumericType(item.type) &&
                        (!isCustom && item.size !== undefined ? (
                            <TextField
                                required
                                name="interval"
                                type="text"
                                label={staticFormatMessage(customAnalysisMessages.selectInterval)}
                                placeholder={formattedInterval}
                                disabled={item.size === 0}
                                onChange={(e) => {
                                    const { value } = e.target;
                                    setItem((prevState) => ({
                                        ...prevState,
                                        interval: value ? deformatBigNumber(value) : undefined,
                                    }));
                                }}
                                value={formattedInterval}
                            />
                        ) : (
                            <CustomSizeSplit
                                numberOfIntervals={item.size}
                                interval={numericInterval}
                                currentIntervals={intervals}
                                setItem={setItem}
                            />
                        ))
                    )}
                    <TextField
                        autoFocus
                        required
                        name="querySize"
                        type="number"
                        value={item.size}
                        onChange={(e) => {
                            // returning if value is undefined or less than zero since query size shouldnt be negative
                            if (Number(e.target.value) < 0) return;
                            if (e.target.value.startsWith("0")) {
                                // remove leading zeros from input
                                e.target.value = e.target.value.replace(/^0+/, "");
                            }
                            const numericValue = Number(e.target.value);
                            setItem((prevState) => ({
                                ...prevState,
                                size: numericValue,
                                intervals:
                                    numericValue && numericValue < intervals.length
                                        ? intervals.slice(0, numericValue * 2)
                                        : intervals,
                            }));
                        }}
                        placeholder={staticFormatMessage(customAnalysisMessages.querySizePH)}
                        label={staticFormatMessage(customAnalysisMessages.querySizeLabel)}
                    />

                    {isNumericType(item.type) && (
                        <FormControlLabel
                            label={
                                <Typography pl={1}>
                                    {staticFormatMessage(customAnalysisMessages.customRangeLabel)}
                                </Typography>
                            }
                            control={
                                <Switch
                                    name="customInterval"
                                    checked={isCustom ?? false}
                                    onChange={() => {
                                        if (isCustom) {
                                            setItem({ ...item, intervals: [] });
                                        }
                                        setIsCustom(!isCustom);
                                    }}
                                />
                            }
                        />
                    )}
                    <FormControlLabel
                        label={
                            <Typography pl={1}>
                                {staticFormatMessage(customAnalysisMessages.excludeOthersLabel)}
                            </Typography>
                        }
                        control={
                            <Switch
                                name="excludeOthers"
                                checked={excludeOthers ?? false}
                                onChange={(e) => {
                                    const isChecked = e.target.checked ?? false;
                                    setItem((prevState) => ({ ...prevState, excludeOthers: isChecked }));
                                }}
                            />
                        }
                    />
                    <Typography sx={{ pt: 2 }} variant="h6">
                        {fm(messages.aggregatedFilters)}
                    </Typography>
                    {item?.aggregatedFilters?.map((aggregatedFilter, i) => (
                        <AggregatedFilterItem
                            onChange={(updatedFilter) =>
                                setItem((prevState) =>
                                    update(prevState, { aggregatedFilters: { [i]: { $set: updatedFilter } } })
                                )
                            }
                            key={
                                aggregatedFilter.id ??
                                `${aggregatedFilter.leftAggIndex}${aggregatedFilter.operator}${
                                    "rightConstant" in aggregatedFilter
                                        ? aggregatedFilter.rightConstant
                                        : aggregatedFilter.rightAggIndex
                                }`
                            }
                            aggregatedFilter={aggregatedFilter}
                            onRemove={() => {
                                setItem((prevState) => update(prevState, { aggregatedFilters: { $splice: [[i, 1]] } }));
                            }}
                        />
                    ))}
                    {creatingNewAggFilter ? (
                        <AggregatedFilterItem
                            onChange={(newAggFilter) => {
                                setItem((prevState) =>
                                    update(prevState, {
                                        aggregatedFilters: (prev) => [...(prev ?? []), newAggFilter],
                                    })
                                );
                                setCreatingNewAggFilter(false);
                            }}
                            onRemove={() => setCreatingNewAggFilter(false)}
                        />
                    ) : (
                        <Button onClick={() => setCreatingNewAggFilter(true)} startIcon={<Add />}>
                            {fm(messages.addAggregatedFilters)}
                        </Button>
                    )}
                    {!sizeIsReliable && (
                        <>
                            <TextField
                                required
                                name="searchSize"
                                type="number"
                                defaultValue={item.searchSize}
                                onChange={(e) => debounceHandleChangeSearchSize(Number(e.target.value))}
                                label={fm(messages.searchSize)}
                            />
                            <Typography variant="textXs">
                                {fm(messages.searchSizeHelpText, { num: item.size })}
                            </Typography>
                        </>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack>
                    <Button
                        type="submit"
                        disabled={creatingNewAggFilter}
                        onClick={() => {
                            onOpenModal(true);
                            onUpdate(item);
                        }}
                    >
                        {fm(messages.applyFilters)}
                    </Button>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default SplitFieldSettingsModal;
