import { defineMessages } from "react-intl";

const messages = defineMessages({
    of: {
        id: "pivotTable.aggHeader.of",
        defaultMessage: "of",
    },
});

export default messages;
