import { toSnakeCase } from "@ignite-analytics/api-client";
import { ScriptParam } from "@ignite-analytics/pivot-ts";
import { MenuItem, Paper, Select, Typography } from "@mui/material";
import { Spec } from "immutability-helper";
import React, { useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { DATE_UNITS } from "@/components/ScriptModal/constants";
import dateUnitMessages from "@/components/ScriptModal/dateUnitMessages";
import { ConstrainedMemberExpression } from "@/components/ScriptModal/interfaces";
import { useParamsContext } from "@/components/ScriptModal/ParamsContext";
import ScriptField from "@/containers/CustomAnalysisPage/CustomAnalysis/ApplyFieldsContainer/ScriptField";
import ValueField from "@/containers/CustomAnalysisPage/CustomAnalysis/ApplyFieldsContainer/ValueField";
import { fm } from "@/contexts/intlContext";
import { useLabel } from "@/hooks/useLabel";

interface Props {
    expression: ConstrainedMemberExpression;
    elasticIndex: string;
}

const ParamComponent: React.FC<Props> = ({ expression, elasticIndex }) => {
    const { params, updateParams, isLineScript } = useParamsContext() ?? {};
    const key = expression.property.name;
    const updateParam = useCallback((spec: Spec<ScriptParam>) => updateParams?.({ [key]: spec }), [updateParams, key]);
    const param = params?.[key];
    const id = `param-${String(useRef(param?.id ?? uuidv4()).current)}`;
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const label = useLabel(param, elasticIndex);
    if (!param) return <>Error</>;
    return (
        <Paper variant="outlined" sx={{ minWidth: "100%", p: 2 }}>
            {param?.type === "script" ? (
                <ScriptField
                    onOpenModal={(close) => setModalIsOpen(!close)}
                    scriptId={param.script}
                    modalIsOpen={modalIsOpen}
                />
            ) : isLineScript ? (
                <>
                    <Typography variant="textSm">{label}</Typography>
                    {param?.type === "date" && (
                        <Select
                            name="measureUnit"
                            value={param.measureUnit}
                            onChange={(e) => updateParam({ measureUnit: { $set: e.target.value } })}
                        >
                            {DATE_UNITS.map((unit) => (
                                <MenuItem key={unit} value={toSnakeCase(unit, true)}>
                                    {unit in dateUnitMessages ? fm(dateUnitMessages[unit]) : unit}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </>
            ) : (
                <ValueField
                    item={{ ...param, uuid: id }}
                    disableEyeIcon
                    onUpdate={(value) => updateParam({ $set: { ...value, key } })}
                    modalIsOpen={modalIsOpen}
                    onOpenModal={(close) => setModalIsOpen(!close)}
                />
            )}
        </Paper>
    );
};

export default ParamComponent;
