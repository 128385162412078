import { defineMessages } from "react-intl";

const messages = defineMessages({
    widgetErrorHeader: {
        id: "components.ErrorBoundries.widgetErrorHeader",
        defaultMessage: "An error occurred",
        description: "Header for errorboundry-fallback",
    },
    widgetErrorDescription: {
        id: "components.ErrorBoundries.widgetErrorDescription",
        defaultMessage: "Something went wrong inside this widget. The development team has been notified",
        description: "Subtitle for errorboundry-fallback",
    },
    retry: {
        id: "components.ErrorBoundries.retry",
        defaultMessage: "Retry",
        description: "Text for retry-button",
    },
});
export default messages;
