import { ElasticField, ElasticFieldType } from "@ignite-analytics/elastic-fields";
import { isNullOrUndefined } from "@ignite-analytics/general-tools";
import { TRANSACTION_VALUE_GT } from "@ignite-analytics/global-types";
import {
    ChartConfig,
    ONLY_ROW_SPLIT_CHARTS,
    UNMIXABLE_CHART_TYPES,
    chartTypes,
    defaultConfig,
    getDefaultAggConfig,
    getValidAggOption,
} from "@ignite-analytics/pivot-charts";
import {
    AGG_TYPES,
    AnalysisItem,
    AnalysisQuery,
    FieldValueItem,
    PivotResponse,
    SplitItem,
    ValueAggregationItem,
    ValueConfiguration,
    asAnalysisItem,
    isBottomRow,
    toSplitItem,
    toValueAnalysisItem,
} from "@ignite-analytics/pivot-ts";
import deepmerge from "deepmerge";
import update, { Spec } from "immutability-helper";
import { IntlShape } from "react-intl";
import { v4 as uuidv4 } from "uuid";

import { isSplitItemSizeReliable } from "./ApplyFieldsContainer/SplitField/helpers";
import { ANALYSIS_NEW, SHARED_DEFAULT_CONFIG } from "./constants";
import { DropZone, EditableAnalysisQuery, EditableAnalysisWidget, stringIsDropZone } from "./interfaces";
import messages from "./messages";

import { CHART_VIEW, VIEW_MODE } from "@/components/Widgets/constants";
import { AnalysisWidget } from "@/components/Widgets/interfaces";
import globalMessages from "@/lib/messages/globalMessages";

export const getInitialValueAgg = (
    elasticIndex: string,
    elasticFields: ElasticField[] | undefined
): ValueAggregationItem => {
    switch (elasticIndex) {
        case "transactions":
            return {
                field: "transaction_value",
                type: "float",
                aggregation: "sum",
                label: "Transaction value",
                visible: true,
            };
        case "mintec_master":
            return { field: "commodity_value", type: "float", aggregation: "avg", visible: true };
        case "currency_master":
            return { field: "currency_value", type: "float", aggregation: "avg", visible: true };
    }
    const globalValueTypes = [TRANSACTION_VALUE_GT];
    const relevantField: ElasticField | undefined = elasticFields?.find(
        (f) => f.globalTypeKey && globalValueTypes.includes(f.globalTypeKey)
    );
    if (relevantField) {
        return {
            field: relevantField.fieldId,
            type: relevantField.type,
            label: relevantField.label,
            aggregation: "sum",
            visible: true,
        };
    }
    return {
        field: "_id",
        type: "integer",
        aggregation: "value_count",
        visible: true,
    };
};

export const getInitialValueConfig = (
    elasticIndex: string,
    elasticFields: ElasticField[] | undefined
): FieldValueItem => ({
    ...getInitialValueAgg(elasticIndex, elasticFields),
    filters: [],
});

export const getDefaultAnalysisWidget = (
    elasticIndex: string,
    elasticFields: ElasticField[] | undefined,
    intl: IntlShape
): Omit<AnalysisWidget, "customDashboard"> => ({
    ...SHARED_DEFAULT_CONFIG,
    type: ANALYSIS_NEW,
    title: intl.formatMessage(messages.customAnalysisWidgetMessage)?.toString(),
    [VIEW_MODE]: CHART_VIEW,
    modelName: "analysiswidget",
    chartConfiguration: defaultConfig,
    rowSplitItems: [],
    columnSplitItems: [],
    valueConfigurations: [getInitialValueConfig(elasticIndex, elasticFields)],
    elasticIndex,
});

/**
 * Function that ensures all items in an analysis query has a `uuid` property, which is used when editing.
 * It only creates new objects for the parts of the input that needs to be changed, so you can safely use strict equal (===)
 * on the output and parts of the output to check equality.
 */
export const asEditableWidget = <T extends AnalysisWidget | EditableAnalysisWidget>(
    widget: T
): EditableAnalysisWidget => {
    const { rowSplitItems: rows, columnSplitItems: cols, valueConfigurations: vals, ...rest } = widget;
    type InvalidSplit = SplitItem & { uuid?: string };
    type InvalidVal = ValueConfiguration & { uuid?: string };
    const [rowsAreValid, colsAreValid, valsAreValid] = [rows, cols, vals].map(
        (items) => !items || (items as (InvalidSplit | InvalidVal)[]).every((item) => "uuid" in item)
    );
    if (rowsAreValid && colsAreValid && valsAreValid) return widget as EditableAnalysisWidget;
    type ValidSplit = SplitItem & { uuid: string };
    type ValidVal = ValueConfiguration & { uuid: string };
    const rowSplitItems: ValidSplit[] = rowsAreValid
        ? (rows as ValidSplit[])
        : (rows as InvalidSplit[])
              .map((r) => ({ filters: [], aggregation: AGG_TYPES[r.type][0], ...r }))
              .map(asAnalysisItem);
    const columnSplitItems: ValidSplit[] = colsAreValid
        ? (cols as ValidSplit[])
        : (cols as InvalidSplit[])
              .map((r) => ({ filters: [], aggregation: AGG_TYPES[r.type][0], ...r }))
              .map(asAnalysisItem);
    return {
        ...rest,
        rowSplitItems,
        columnSplitItems,
        valueConfigurations: valsAreValid ? (vals as ValidVal[]) : (vals as InvalidVal[]).map(asAnalysisItem),
    };
};

export const toFieldValueItem = (item: ElasticField): FieldValueItem => ({
    type: item.type,
    field: item.field,
    visible: true,
    filters: [],
    aggregation: AGG_TYPES[item.type][0],
    ...(item.type === "date" && { measureUnit: "days" }),
});

export const parseFieldIndex = (s: string): [DropZone | undefined, number | undefined] => {
    const [zone, index] = s.split("-");
    if (!stringIsDropZone(zone)) return [undefined, undefined];
    return [zone, Number(index)];
};

const getValidItemForZone = (item: AnalysisItem, zone: DropZone | undefined, elasticFields: ElasticField[]) => {
    const field = elasticFields.find((f) => item && "field" in item && f.field === item.field);
    if (zone === "columnSplitItems" || zone === "rowSplitItems") {
        return { ...(field ? toSplitItem(field) : {}), ...item };
    }
    if (zone === "valueConfigurations") {
        return { ...(field ? toValueAnalysisItem(field) : {}), ...item };
    }
    return item;
};

export const getUpdateQuerySpec = (
    prevIndex: string,
    newIndex: string,
    selectedItem: AnalysisItem,
    elasticFields: ElasticField[]
): Spec<EditableAnalysisWidget> | undefined => {
    const [[fromZone, fromIndex], [toZone, toIndex]] = [prevIndex, newIndex].map(parseFieldIndex);

    // Don't allow rearranging in "availableFields" section
    if (fromZone === toZone && toZone === "availableFields") return;

    // Create the object that should be added
    const _addItem =
        toZone !== "availableFields"
            ? update(selectedItem, { uuid: (prev) => (fromZone === "availableFields" ? uuidv4() : prev) })
            : undefined;

    const addItem = _addItem && getValidItemForZone(_addItem, toZone, elasticFields);

    // Create the spec for removing the item from its previous location
    const removeSpec: Spec<EditableAnalysisQuery> =
        fromZone && fromZone !== "availableFields"
            ? {
                  [fromZone]: {
                      $splice: [[fromIndex, 1]],
                  },
              }
            : {};

    // Create the spec for adding the item to its new location
    const addSpec: Spec<EditableAnalysisQuery> =
        toZone && toZone !== "availableFields" && addItem
            ? {
                  [toZone]: toIndex !== undefined ? { $splice: [[toIndex, 0, addItem]] } : { $push: [addItem] },
              }
            : {};

    const aggregation = addItem && "aggregation" in addItem ? addItem.aggregation : undefined;
    // Create spec for making sure each value aggregation keeps or gets correct config
    const chartConfigSpec: Spec<ChartConfig["optionsPerAgg"]> =
        fromZone === "valueConfigurations"
            ? fromIndex !== undefined
                ? toZone === fromZone
                    ? toIndex !== undefined
                        ? (prevs) =>
                              update(prevs, {
                                  $splice: [
                                      [fromIndex, 1],
                                      [toIndex, 0, prevs[fromIndex]],
                                  ],
                              })
                        : {}
                    : { $splice: [[fromIndex, 1]] }
                : {}
            : toZone === "valueConfigurations" && toIndex !== undefined
            ? {
                  $splice: [[toIndex, 0, getDefaultAggConfig(toIndex + 1, addItem?.type, aggregation)]],
              }
            : {};

    /**
     * Returns an updated index if the index was involved in a switch
     */
    const updateIndexBasedOnSwitch = (indexToUpdate: number, switchedIndexX: number, switchedIndexY: number) => {
        if (indexToUpdate === switchedIndexX) return switchedIndexY;
        if (indexToUpdate === switchedIndexY) return switchedIndexX;
        return indexToUpdate;
    };

    // Create spec for making sure that aggregated filters are updated correctly
    const aggregatedFiltersAndSortingSpec:
        | Spec<EditableAnalysisQuery["rowSplitItems" | "columnSplitItems"][number]>
        | undefined =
        fromZone === "valueConfigurations" &&
        fromZone === toZone &&
        toIndex !== undefined &&
        fromIndex !== undefined &&
        fromIndex !== toIndex
            ? {
                  aggregatedFilters: (previousAggregatedFilters) =>
                      previousAggregatedFilters?.map((prev) => ({
                          ...prev,
                          leftAggIndex: updateIndexBasedOnSwitch(prev.leftAggIndex, fromIndex, toIndex),
                          ...("rightAggIndex" in prev && {
                              rightAggIndex: updateIndexBasedOnSwitch(prev.rightAggIndex, fromIndex, toIndex),
                          }),
                      })),
                  sortAggIndex: (previousSortAggIndex) =>
                      typeof previousSortAggIndex === "number"
                          ? updateIndexBasedOnSwitch(previousSortAggIndex, fromIndex, toIndex)
                          : previousSortAggIndex,
              }
            : undefined;

    const querySpec = deepmerge<Spec<EditableAnalysisQuery>>(removeSpec, addSpec, {
        arrayMerge: (a, b) => [...a, ...b],
    });

    return (prev) => {
        // First apply changes to order of items and chart config
        const updated = update<EditableAnalysisWidget>(prev, {
            ...querySpec,
            chartConfiguration: { optionsPerAgg: chartConfigSpec },
        });
        // Then apply changes to update indexes in split items if necessary
        if (!aggregatedFiltersAndSortingSpec) return updated;
        return update(updated, {
            rowSplitItems: (previousItems) =>
                previousItems.map((item) => update(item, aggregatedFiltersAndSortingSpec)),
            columnSplitItems: (previousItems) =>
                previousItems.map((item) => update(item, aggregatedFiltersAndSortingSpec)),
        });
    };
};

/**
 * @return {boolean} An object containing all errors
 */
export const getChartErrors = (config: ChartConfig, query: AnalysisQuery, intl: IntlShape) => {
    if (query.valueAggregationItems.length < 1) {
        return { [intl.formatMessage(globalMessages.error)]: [intl.formatMessage(messages.atLeastOneValue)] };
    }
    const validOption = getValidAggOption(config);
    const chartType = validOption?.type;
    if (chartType && ONLY_ROW_SPLIT_CHARTS.includes(chartType) && query.columnSplitItems.length) {
        const chartTypeTranslated = chartTypes(intl)
            .find((t) => t.value === chartType)
            ?.text?.toString();
        return {
            [intl.formatMessage(globalMessages.error)]: [
                intl.formatMessage(messages.colSplitError, { chartType: chartTypeTranslated ?? chartType }),
            ],
        };
    }
    if (validOption?.type === "organization") {
        if (query.columnSplitItems.length) {
            return { [intl.formatMessage(globalMessages.error)]: [intl.formatMessage(messages.organizationError)] };
        }
        if (query.valueAggregationItems.length > 3) {
            return {
                [intl.formatMessage(globalMessages.error)]: [intl.formatMessage(messages.organizationValuesError)],
            };
        }
    }
    if (query.valueAggregationItems.length > 1) {
        const unmixableType = config.optionsPerAgg
            .map((opts) => opts.type)
            .find((type) => type && UNMIXABLE_CHART_TYPES.includes(type));
        if (unmixableType) {
            return {
                [intl.formatMessage(messages.chartError)]: [
                    intl.formatMessage(messages.unmixableChartType, {
                        type: unmixableType,
                    }),
                ],
            };
        }
    }
    return {};
};

export const getIconForSortingRule = ({ sortOrder, sortAggIndex }: SplitItem) =>
    !isNullOrUndefined(sortAggIndex)
        ? sortOrder === "asc"
            ? "sort-numeric-up-alt"
            : "sort-numeric-down-alt"
        : sortOrder === "asc"
        ? "sort-alpha-down"
        : "sort-alpha-up";

export const createSplitItemSortUpdater =
    (aggs: number) =>
    ({ sortAggIndex: prevIndex, sortOrder: prevOrder = "desc", ...otherProps }: SplitItem): SplitItem => {
        const order = prevOrder === "desc" ? "asc" : "desc";
        const switchIndex = order === "desc";
        const aggIndex = !isNullOrUndefined(prevIndex) ? (prevIndex < aggs - 1 ? prevIndex + 1 : null) : 0;
        const nextIndex = switchIndex ? aggIndex : prevIndex;

        const newItem: SplitItem = {
            ...otherProps,
            sortAggIndex: nextIndex,
            sortOrder: order,
        };
        const sizeIsReliable = isSplitItemSizeReliable(newItem);
        return {
            ...newItem,
            searchSize: sizeIsReliable ? null : newItem.searchSize ?? 1000,
        };
    };

export const getNormalizationStatus = (data: PivotResponse, config?: ChartConfig) => {
    if (!config) return [];
    let listOfRowsWithErrors: string[] = [];
    config.optionsPerAgg.forEach((elementValueAgg, indexValueAgg) => {
        if (elementValueAgg.normalizeOnFirst && (elementValueAgg.type === "bar" || elementValueAgg.type === "line")) {
            const rowsWithErrors = data.rows.reduce((acc: string[], rowElement) => {
                if (isBottomRow(rowElement)) {
                    if (rowElement.values[0][indexValueAgg] === null) {
                        return [...acc, rowElement.name];
                    }
                }
                return acc;
            }, []);
            listOfRowsWithErrors = [...listOfRowsWithErrors, ...rowsWithErrors];
        }
    });

    return listOfRowsWithErrors;
};

export const isScript = (type: ElasticFieldType | "script" | "scripted_metric") =>
    type === "script" || type === "scripted_metric";
