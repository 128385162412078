import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";

import { useSessionContext } from "@/contexts/SessionContextProvider";

interface UserSettings {
    id: number;
    user: string;
    language: string;
    AVAILABLE_LANGUAGES: [string, string][];
    region: string;
    AVAILABLE_REGIONS: [string, string][];
}
interface User {
    id: string;
    firstName: string;
    lastName: string;
    client: number;
    email: string;
    phoneNumber: string;
    position: string;
    settings: UserSettings;
    isStaff: boolean;
    isActive: boolean;
    isOnboardingCompleted: boolean;
    profileImage?: string;
    profilePicture?: string | null;
    departmentIds: number[];
    createdAt?: string;
    isExternallyManaged: boolean;
    isExternal?: boolean;
    customerId: number;
}

const listCreateResource = createListResource<User>`/departments/:department/users/`;
const detailResource = createDetailResource<User>`/users/${(params) => params.id}/`;

/**
 * Get reducer and action creators for User objects
 */
export const {
    Provider: UserProvider,
    useAll: useAllUsers,
    useObject: useUser,
} = createEntityContext(listCreateResource, detailResource, {
    interchangableTypes: true,
    name: "User",
});

export function useCurrentUser() {
    const { id } = useSessionContext();
    const user = useUser(id);
    return user;
}
