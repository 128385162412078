import { ElasticFieldType } from "@ignite-analytics/elastic-fields";
import { Calendar, Text, Code, Hashtag, Boolean } from "@ignite-analytics/icons";
import SvgIcon from "@mui/material/SvgIcon";

import dateUnitMessages from "./dateUnitMessages";

export const DATE_UNITS: (keyof typeof dateUnitMessages)[] = [
    "days",
    "dayOfWeek",
    "dayOfMonth",
    "dayOfYear",
    "weeks",
    "quarters",
    "quarterOfYear",
    "monthOfYear",
    "years",
];

export const BUCKET_MODE = "bucket";
export const LINE_MODE = "line";
export const WEIGHTED_MODE = "weighted";
export type Tab = typeof BUCKET_MODE | typeof LINE_MODE | typeof WEIGHTED_MODE;
export const ZERO_STRING = "0";

export const typeToIcon: Record<ElasticFieldType | "script" | "scripted_metric", typeof SvgIcon> = {
    boolean: Boolean,
    text: Text,
    keyword: Text,
    integer: Hashtag,
    long: Hashtag,
    short: Hashtag,
    date: Calendar,
    float: Hashtag,
    script: Code,
    scripted_metric: Code,
};
