import { DataTable } from "@ignite-analytics/data-table";
import { ElasticField, ElasticIndex } from "@ignite-analytics/elastic-fields";
import { Filter } from "@ignite-analytics/filters";
import { capitalCase } from "@ignite-analytics/general-tools";
import { IntlShape } from "react-intl";

import columnFieldMessages from "../messages/dataColumnFields";

import { getFilterValueDescription } from "./filters";

import { getLabel } from "@/hooks/useLabel/helpers";

export const getShortDescriptionOfFilters = (
    filters: Filter[] | undefined,
    intl: IntlShape,
    elasticFields: ElasticField[],
    dataTables: ElasticIndex[],
    elasticIndex: string | undefined,
    separator = ", "
) => {
    if (!filters || !filters.length) return "";
    return filters
        .map(
            (f) =>
                `${getLabel(intl, f, elasticFields, dataTables, elasticIndex, undefined)}: ${getFilterValueDescription(
                    f,
                    intl,
                    elasticFields,
                    dataTables,
                    elasticIndex
                )}`
        )
        .join(separator);
};

/**
 * Cleans strings to a more read-friendly format
 */
export function cleanTitle(title: string) {
    if (!title) {
        return "";
    }

    return capitalCase(
        title
            .replace(/_/g, " ")
            .replace(/([a-z])([A-Z])([a-z\s])/g, (_, a, b, c) => `${a} ${b.toLowerCase()}${c}`)
            .replace(/\s+/g, " ")
            .replace(/^\s+/g, "")
    );
}

const getDataTableFromIndex = (indexName: string, dataTables: DataTable[]) =>
    dataTables.find((dg) => {
        if (!dg) return false;
        if (indexName?.startsWith("data_table")) {
            return dg.id === Number(indexName.replace("data_table_", ""));
        }
        return dg.name === indexName;
    });

export const formatDataType = (indexName: string, intl: IntlShape, dataTables: DataTable[]) => {
    const dataTable = getDataTableFromIndex(indexName, dataTables);
    const cleanedTitle = cleanTitle(dataTable?.name || indexName);
    switch (cleanedTitle) {
        case "Transactions":
            return intl.formatMessage(columnFieldMessages.transactions);
        case "Contracts":
            return intl.formatMessage(columnFieldMessages.contracts);
        case "Client suppliers":
            return intl.formatMessage(columnFieldMessages.clientSuppliers);
        case "Mintec master":
            return intl.formatMessage(columnFieldMessages.mintecCommodities);
        case "Currency master":
            return intl.formatMessage(columnFieldMessages.currencies);
        default:
            return cleanedTitle;
    }
};
