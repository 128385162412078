import { defineMessages } from "react-intl";

const messages = defineMessages({
    buildYourScript: {
        id: "components.ScriptModal.buildYourScript",
        defaultMessage: "Build your script",
    },
    mathematicalPreview: {
        id: "components.ScriptModal.mathematicalPreview",
        defaultMessage: "Mathematical preview",
    },
    metricsMode: {
        id: "customAnalysis.components.metricsMode",
        defaultMessage: "Calculate on line level",
    },
    bucketMode: {
        id: "customAnalysis.components.bucketMode",
        defaultMessage: "Calculate using summarized values",
    },
    weightedMode: {
        id: "customAnalysis.components.weightedMode",
        defaultMessage: "Weight by selected field",
    },
    weightBy: {
        id: "customAnalysis.components.weightBy",
        defaultMessage: "Field to group data by",
    },
    weightByExplanation: {
        id: "customAnalysis.components.weightByExplanation",
        defaultMessage: "The script you create will be executed for each group of data",
    },
    unableToParse: {
        id: "customAnalysis.unableToParse",
        defaultMessage:
            "The script could not be parsed. It might be defect, or we might be experiencing issues on our side. Please contact us if you need help.",
    },
    weightAggregator: {
        id: "customAnalysis.components.weightAggregator",
        defaultMessage: "How should the results from each group be aggregated?",
    },
    functionScriptName: {
        id: "customAnalysis.components.functionName",
        defaultMessage: "Script name",
    },
    functionNamePH: {
        id: "customAnalysis.components.functionNamePH",
        defaultMessage: "Write the name of the script",
    },
    createFunctionScript: {
        id: "customAnalysis.components.createFunction",
        defaultMessage: "Create function",
    },
    deleteScript: {
        id: "customAnalysis.components.functionDragField.deleteScript",
        defaultMessage: "Delete script",
    },
    saveScript: {
        id: "customAnalysis.components.saveScript",
        defaultMessage: "Save script",
    },
    missingTitleMsg: {
        id: "customAnalysis.components.missingTitleMsg",
        defaultMessage: "You must have a title",
    },
    addScript: {
        id: "customAnalysis.components.addScript",
        defaultMessage: "Add script",
    },
    unselect: {
        id: "customAnalysis.components.unselect",
        defaultMessage: "Select no field",
    },
});

export default messages;
