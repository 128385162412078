/* eslint-disable react/no-unstable-nested-components */
import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { HoverContextProvider, arrayHelpers } from "@ignite-analytics/general-tools";
import { toScriptRelation } from "@ignite-analytics/pivot-ts";
import {
    Autocomplete,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Popper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useMemo, useState } from "react";

import { VALUE_OPTIONS } from "./constants";
import { Choice } from "./interfaces";
import messages from "./messages";

import { typeToIcon } from "@/components/ScriptModal/constants";
import { ExpressionDraft } from "@/components/ScriptModal/interfaces";
import { useParamsContext } from "@/components/ScriptModal/ParamsContext";
import { toFieldValueItem } from "@/containers/CustomAnalysisPage/CustomAnalysis/helpers";
import { fm } from "@/contexts/intlContext";
import { useMatchingScriptFields } from "@/entities/scriptFields";

interface Props {
    onChange: (expression: ExpressionDraft) => void;
    forcedType: "number" | "boolean";
    elasticIndex: string;
}

const SelectNewValue: React.FC<Props> = ({ onChange, forcedType, elasticIndex }) => {
    const [showDataFieldMenu, setShowDataFieldMenu] = useState(false);
    const [showScriptMenu, setShowScriptMenu] = useState(false);
    const { params, updateParams, isLineScript } = useParamsContext() ?? {};
    const scripts = useMatchingScriptFields(
        {
            elasticIndex,
            ...(isLineScript && { type: "scripted_metric" }),
            autoCreated: false,
        },
        {},
        true,
        { service: "dashboards" }
    );
    const sortedScripts = scripts.slice().sort((a, b) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    });
    const elasticFields = useElasticFields(elasticIndex, true);

    const selected: Choice | undefined = showDataFieldMenu ? "field" : showScriptMenu ? "script" : undefined;
    const unavailableKeys = useMemo(() => Object.keys(params ?? {}), [params]);
    const nextKey = useMemo(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        () => `var${arrayHelpers.range(100).find((n) => !unavailableKeys.includes(`var${n}`))}`,
        [unavailableKeys]
    );

    const onChoice = (choice: Choice | undefined) => {
        switch (choice) {
            case "constant":
                onChange({ type: "Literal", raw: "0", value: 0 });
                break;
            case "if/else":
                onChange({
                    type: "ConditionalExpression",
                    test: {
                        type: "BinaryExpression",
                        operator: ">=",
                        left: null,
                        right: null,
                    },
                    consequent: null,
                    alternate: null,
                });
                break;
            case "comparison":
                onChange({ type: "BinaryExpression", operator: ">=", left: null, right: null });
                break;
            case "field":
                setShowDataFieldMenu(true);
                setShowScriptMenu(false);
                break;
            case "script":
                setShowScriptMenu(true);
                setShowDataFieldMenu(false);
                break;
            case undefined:
                setShowDataFieldMenu(false);
                setShowScriptMenu(false);
        }
    };

    const addParamToExpression = (key: string) =>
        onChange({
            type: "MemberExpression",
            object: { type: "Identifier", name: "params" },
            property: { type: "Identifier", name: key },
            computed: false,
        });

    const onDataFieldSelected = (field: string) => {
        const elasticField = elasticFields?.find((ef) => ef.field === field);
        if (!elasticField) return;
        const key = nextKey;
        updateParams?.({ [key]: { $set: { ...toFieldValueItem(elasticField), key } } });
        addParamToExpression(key);
    };

    const onScriptSelected = (scriptId: number) => {
        const script = scripts.find((s) => s.id === scriptId);
        if (!script) return;
        const key = nextKey;
        updateParams?.({ [key]: { $set: { ...toScriptRelation(script), key } } });
        addParamToExpression(key);
    };

    return (
        <HoverContextProvider>
            <Stack direction="column" spacing={1}>
                <FormControl>
                    <InputLabel id="select-add-value-label">{fm(messages.addValue)}</InputLabel>
                    <Select
                        labelId="select-add-value-label"
                        label={fm(messages.addValue)}
                        name="value"
                        onChange={(e) => onChoice(e.target.value as Choice)}
                        value={selected}
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.paper,
                            borderRadius: 1,
                            minWidth: "100px",
                        }}
                    >
                        {[
                            ...VALUE_OPTIONS.filter((opt) => opt.type === forcedType).map(
                                ({ name, icon, description }) => {
                                    const Icon = icon;
                                    return (
                                        <MenuItem value={name} key={name}>
                                            <Stack direction="row" spacing={2} alignItems="center">
                                                <Icon fontSize="small" />
                                                <Typography variant="textSm">{description}</Typography>
                                            </Stack>
                                        </MenuItem>
                                    );
                                }
                            ),
                        ]}
                    </Select>
                </FormControl>
                {showDataFieldMenu && (
                    <FormControl fullWidth>
                        <Autocomplete
                            id="select-select-field-label"
                            options={elasticFields ?? []}
                            getOptionLabel={(option) => option.label}
                            renderOption={(props, option) => {
                                const TypeIcon = typeToIcon[option.type];
                                return (
                                    <Box key={option.field} component="li" {...props}>
                                        <Stack direction="row" spacing={1}>
                                            <TypeIcon fontSize="small" />
                                            <Typography variant="textSm">{option.label}</Typography>
                                        </Stack>
                                    </Box>
                                );
                            }}
                            renderInput={(inputParams) => (
                                <TextField
                                    {...inputParams}
                                    label={fm(messages.selectField)}
                                    sx={{
                                        width: "200px",
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        borderRadius: 1,
                                    }}
                                />
                            )}
                            onChange={(_, value) => {
                                if (value?.field) {
                                    onDataFieldSelected(value?.field);
                                }
                            }}
                            PopperComponent={(props) => (
                                <Popper {...props} style={{ minWidth: "fit-content" }} placement="bottom-start" />
                            )}
                        />
                    </FormControl>
                )}
                {showScriptMenu && (
                    <FormControl fullWidth>
                        <Autocomplete
                            id="select-select-script-label"
                            options={sortedScripts}
                            onChange={(_, value) => {
                                onScriptSelected(value?.id ?? -1);
                            }}
                            renderInput={(inputParams) => (
                                <TextField
                                    {...inputParams}
                                    label={fm(messages.selectScript)}
                                    sx={{
                                        width: "200px",
                                        backgroundColor: (theme) => theme.palette.background.paper,
                                        borderRadius: 1,
                                    }}
                                />
                            )}
                            renderOption={(props, option) => {
                                const TypeIcon = typeToIcon[option.type];
                                return (
                                    <Box key={`${option.label}-${option.id}`} component="li" {...props}>
                                        <Stack direction="row" spacing={1}>
                                            <TypeIcon fontSize="small" />
                                            <Typography variant="textSm">{option.label}</Typography>
                                        </Stack>
                                    </Box>
                                );
                            }}
                            PopperComponent={(props) => (
                                <Popper {...props} style={{ minWidth: "fit-content" }} placement="bottom-start" />
                            )}
                        />
                    </FormControl>
                )}
            </Stack>
        </HoverContextProvider>
    );
};

export default SelectNewValue;
