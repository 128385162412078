import { Paper, TextField, Typography, alpha } from "@mui/material";
import update, { Spec } from "immutability-helper";
import jsep from "jsep";
import React from "react";

import { getNumberUnary } from "../helpers";
import { ExpressionDraft, isSupportedExpression } from "../interfaces";

import BinaryExpression from "./BinaryExpression";
import messages from "./messages";
import ParamComponent from "./ParamComponent";
import SelectNewValue from "./SelectNewValue";
import WrapperWithButtons from "./WrapperWithButtons";

import { fm } from "@/contexts/intlContext";

interface Props {
    expression: ExpressionDraft;
    elasticIndex: string;
    level: number;
    onChange: (expression: ExpressionDraft) => void;
    forcedType: "number" | "boolean";
    vertical: boolean;
}

const ExpressionComponent: React.FC<Props> = ({ expression, level, forcedType, onChange, vertical, elasticIndex }) => {
    const bgWeight = (level * 10) / 100;

    const updateWithSpec = (spec: Spec<ExpressionDraft>) => onChange(update(expression, spec));

    const onNumberChange = (value: string) => {
        try {
            if (!value) {
                const parsedZero = jsep("0");
                if (isSupportedExpression(parsedZero)) onChange(parsedZero);
                return;
            }
            // since the value is being parsed by jsep, 0 cannot be removed so need to clean the value if 0, but not if it is a decimal number
            if (value.length > 1 && value.startsWith("0") && !value.startsWith("0.")) {
                const cleanValue = value.slice(1);
                const parsed = jsep(cleanValue);
                if (isSupportedExpression(parsed)) onChange(parsed);
                return;
            }
            const parsed = jsep(value);
            if (isSupportedExpression(parsed)) onChange(parsed);
        } catch (error) {
            // Don't do anything
        }
    };

    switch (expression?.type) {
        case undefined:
            return <SelectNewValue forcedType={forcedType} onChange={onChange} elasticIndex={elasticIndex} />;
        case "UnaryExpression":
            return (
                <WrapperWithButtons
                    vertical={vertical}
                    onChange={updateWithSpec}
                    forceShowOperationButton={level === 0}
                >
                    <TextField
                        label={fm(messages.value)}
                        name="value"
                        onChange={(e) => {
                            onNumberChange(e.target.value);
                        }}
                        value={getNumberUnary(expression)}
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.paper,
                            borderRadius: 1,
                            minWidth: "50px",
                        }}
                    />
                </WrapperWithButtons>
            );
        case "Literal":
            return (
                <WrapperWithButtons
                    vertical={vertical}
                    onChange={updateWithSpec}
                    forceShowOperationButton={level === 0}
                >
                    <TextField
                        label={fm(messages.value)}
                        name="value"
                        onChange={(e) => {
                            onNumberChange(e.target.value);
                        }}
                        value={expression.raw}
                        sx={{
                            backgroundColor: (theme) => theme.palette.background.paper,
                            borderRadius: 1,
                            minWidth: "50px",
                        }}
                    />
                </WrapperWithButtons>
            );

        case "BinaryExpression":
        case "LogicalExpression":
            return (
                <BinaryExpression
                    onChange={onChange}
                    expression={expression}
                    level={level}
                    forcedType={forcedType}
                    vertical={vertical}
                    elasticIndex={elasticIndex}
                />
            );

        case "ConditionalExpression":
            return (
                <WrapperWithButtons vertical={vertical} onChange={updateWithSpec}>
                    <Paper
                        variant="outlined"
                        sx={{
                            p: 3,
                            backgroundColor: (theme) => alpha(theme.palette.info.main, bgWeight),
                            width: "100%",
                        }}
                    >
                        <Typography variant="textSm" fontWeight={600}>
                            {fm(messages.ifHeader)}
                        </Typography>
                        <ExpressionComponent
                            onChange={(test) => onChange({ ...expression, test })}
                            expression={expression.test}
                            level={level + 1}
                            forcedType="boolean"
                            vertical={false}
                            elasticIndex={elasticIndex}
                        />
                        <Typography variant="textSm" fontWeight={600}>
                            {fm(messages.thenHeader)}
                        </Typography>
                        <ExpressionComponent
                            onChange={(consequent) => onChange({ ...expression, consequent })}
                            expression={expression.consequent}
                            level={level + 1}
                            forcedType="number"
                            vertical={false}
                            elasticIndex={elasticIndex}
                        />
                        <Typography variant="textSm" fontWeight={600}>
                            {fm(messages.elseHeader)}
                        </Typography>
                        <ExpressionComponent
                            onChange={(alternate) => onChange({ ...expression, alternate })}
                            expression={expression.alternate}
                            level={level + 1}
                            forcedType="number"
                            vertical={false}
                            elasticIndex={elasticIndex}
                        />
                    </Paper>
                </WrapperWithButtons>
            );

        case "MemberExpression":
            return (
                <WrapperWithButtons
                    vertical={vertical}
                    onChange={updateWithSpec}
                    forceShowOperationButton={level === 0}
                >
                    <ParamComponent expression={expression} elasticIndex={elasticIndex} />
                </WrapperWithButtons>
            );
    }
};

export default ExpressionComponent;
