/* eslint-disable @typescript-eslint/no-floating-promises */
import { X as CloseIcon } from "@ignite-analytics/icons";
import { LoadingButton } from "@mui/lab";
import {
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";
import { useAllDashboardCollections } from "@/entities/dashboardCollections";
import {
    CustomDashboardListObject,
    useAllCustomDashboards,
    useCreatePartialCustomDashboard,
} from "@/entities/dashboards";
import { useCurrentDepartment } from "@/entities/departments";

const TEST_ID_PREFIX = "createDashboardModal-";

interface CreateDashboardModalProps {
    onClose: () => void;
    open: boolean;
    setDashboardId: (number: number) => void;
    refetchDashboards: () => Promise<CustomDashboardListObject[]>;
}

const CreateDashboardModal: React.FC<CreateDashboardModalProps> = (props: CreateDashboardModalProps) => {
    const { setDashboardId, onClose, open, refetchDashboards } = props;
    const [name, setName] = useState<string | undefined>(undefined);
    const [collection, setCollection] = useState<number | null>(null);
    const dashboards = useAllCustomDashboards(undefined, { service: "dashboards" });
    const collections = useAllDashboardCollections(undefined, { service: "dashboards" });

    const createNewDashboardAction = useCreatePartialCustomDashboard();
    const department = useCurrentDepartment();
    const [loading, setLoading] = useState(false);

    const createDashboard = (): void => {
        if (!dashboards) return;
        setLoading(true);
        name !== undefined &&
            createNewDashboardAction({
                description: "",
                name,
                dashboardCollection: collection,
                client: department?.client ?? 1,
            }).then((res) => {
                setLoading(false);
                refetchDashboards();
                setDashboardId(res.id);
                onClose();
            });
    };

    const handleSelectCollection = (dashboardCollectionId: number) => {
        if (Number.isNaN(dashboardCollectionId)) {
            setCollection(null);
            return;
        }
        setCollection(dashboardCollectionId);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    {fm(messages.createNewdashboardModalTitle)}
                    <IconButton
                        data-testid={`${TEST_ID_PREFIX}closeButton`}
                        onClick={() => {
                            onClose();
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <TextField
                        type="text"
                        label={fm(messages.createDashboardNameField)}
                        name="name"
                        data-testid={`${TEST_ID_PREFIX}nameInput`}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={fm(messages.createDashboardNameHelper)?.toString()}
                    />
                    {collections ? (
                        <FormControl>
                            <InputLabel>{fm(messages.addToCollection)}</InputLabel>
                            <Select
                                name="collection"
                                label={fm(messages.addToCollection)}
                                onChange={(e) => handleSelectCollection(Number(e.target.value))}
                                data-testid={`${TEST_ID_PREFIX}addToCollectionPicker`}
                            >
                                <MenuItem data-testid={`${TEST_ID_PREFIX}option-no-collection`} value={NaN}>
                                    <Typography>{fm(messages.noCollection)}</Typography>
                                </MenuItem>
                                {dashboards &&
                                    collections.map((dashboardCollection) => (
                                        <MenuItem
                                            data-testid={`${TEST_ID_PREFIX}option-${dashboardCollection.name}`}
                                            key={dashboardCollection.id}
                                            value={dashboardCollection.id}
                                        >
                                            <Typography>{dashboardCollection.name}</Typography>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    ) : (
                        <CircularProgress />
                    )}
                    <LoadingButton
                        variant="contained"
                        loading={loading}
                        disabled={!name}
                        data-testid={`${TEST_ID_PREFIX}createDashboardButton`}
                        onClick={() => createDashboard()}
                    >
                        {fm(messages.createDashboard)}
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateDashboardModal;
