import { compareFilters, InlineFilters, useFilters } from "@ignite-analytics/filters";
import { Stack, Typography } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { isDataTableV2ElasticIndex } from "../../../ApplyFieldsContainer/helpers";
import { useCAContext, useCAElasticIndex } from "../../../CAContextProvider/hooks";

import messages from "./messages";

import withFilterContext from "@/hoc/withFilterContext";
import { useElasticIndexInContextByName } from "@/hooks/useElasticIndexWithType";
import { removeFullPeriodFilters } from "@/lib/helpers/filters";

interface Props {
    aggIndex: number;
    period: "current" | "previous";
}

const PeriodFilters: React.FC<Props> = ({ aggIndex, period }) => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fm = useIntl().formatMessage;
    const { openModal } = useCAContext();
    const elasticIndex = useCAElasticIndex();
    const _filters = useFilters();
    const filters =
        _filters && (isDataTableV2ElasticIndex(elasticIndex) ? _filters : removeFullPeriodFilters(_filters));
    const item = useCAContext().openWidget?.valueConfigurations[aggIndex];
    const filtersChanged = useMemo(() => {
        if (!item) return false;
        if (!("filters" in item)) return false;
        if (filters === undefined) return false;
        return !compareFilters(item.filters, filters);
    }, [filters, item]);
    const { updateWidget } = useCAContext();

    useEffect(
        function updateFiltersInQuery() {
            if (openModal) {
                // If a modal is open, the user might be changing filters there (and this component is not accessible, so we know they are not changing filters here).
                // To avoid back and forth updates, we only update the filters if all modals are closed.
                return;
            }
            filtersChanged &&
                updateWidget({
                    valueConfigurations: {
                        [aggIndex]: {
                            filters: { $set: filters || [] },
                        },
                    },
                });
        },
        [filters, item, updateWidget, filtersChanged, aggIndex, openModal]
    );

    return (
        <Stack padding={1}>
            <Typography variant="textSm" fontWeight={600}>
                {fm(messages[period])}
            </Typography>
            {/** Disabling editing actions for comparison period setup filters */}
            <InlineFilters hideFilterOptions minify hasEditPermission={false} />
        </Stack>
    );
};

export default withFilterContext(PeriodFilters, {
    useDataSource: () => useElasticIndexInContextByName(useCAElasticIndex()),
    useInitialFilters: ({ aggIndex }) => {
        const aggItem = useCAContext().openWidget?.valueConfigurations[aggIndex];
        return aggItem && "filters" in aggItem ? aggItem.filters : undefined;
    },
    inherit: false,
    debugName: "PeriodFilters",
});
