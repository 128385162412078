import { defineMessages } from "react-intl";

const messages = defineMessages({
    noData: {
        id: "customAnalysis.pivotTable.noData",
        defaultMessage: "No data was found with the current configuration. Try changing some of the parameters.",
    },
});

export default messages;
