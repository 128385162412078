import { ElasticField, useElasticFields } from "@ignite-analytics/elastic-fields";
import {
    FilterContextHocConfig,
    withFilterContext as generalWithFilterContext,
    useCompanyFavouriteFilters,
    useFilterFieldMappingDataFields,
    usePersonalFavouriteFilters,
    useUpsertPersonalFavouriteFilters,
} from "@ignite-analytics/filters";
import { useMemo } from "react";

import { useAllElasticIndices } from "@/contexts/AvailableIndicesContext";
import { getCurrentDomain } from "@/lib/getCurrentDomain";

const graphqlRouterUrl = getCurrentDomain() === "ignite" ? process.env.REACT_APP_GRAPHQL_ROUTER_IGNITE_URL : process.env.REACT_APP_GRAPHQL_ROUTER_IGNITEPROCUREMENT_URL;
/**
 * Works exactly like the withFilterContext-hoc from the filters package, except that how to get user and department ID is preconfigured.
 */

/**
 * Returns elastic fields without filter field mappings
 */
export const useElasticFieldsForFilterContextProvider = (
    indexNames: string[] | undefined
): ElasticField[] | undefined => {
    return useElasticFields(indexNames, true);
};

/**
 * Uses useElasticFields to get the elastic fields, and then adds the filter field mappings to the result if the feature toggle returns true.
 */
export const useElasticFieldsWithFilterFieldMappings = (
    indexNames: string[] | undefined
): ElasticField[] | undefined => {
    const elasticFields = useElasticFields(indexNames, true);
    const filterFieldMappingElasticFields = useFilterFieldMappingDataFields(
        graphqlRouterUrl as string,
        indexNames
    );

    return useMemo(() => {
        if (!elasticFields) return undefined;
        return elasticFields.concat(filterFieldMappingElasticFields);
    }, [elasticFields, filterFieldMappingElasticFields]);
};

const withFilterContext = <
    InnerProps extends {},
    Config extends Omit<FilterContextHocConfig<InnerProps>, "useDataFields">
>(
    WrappedComponent: React.FC<InnerProps>,
    config: Config
) =>
    generalWithFilterContext(WrappedComponent, {
        ...config,
        useDataFields: useElasticFieldsWithFilterFieldMappings,

        // Filter favourite hooks
        useAllDataSources: useAllElasticIndices,
        usePersonalFavouriteFilters: () => usePersonalFavouriteFilters("deprecated-not-in-use", graphqlRouterUrl as string),
        useCompanyFavouriteFilters: () =>
            useCompanyFavouriteFilters(graphqlRouterUrl as string),
        useUpsertPersonalFavouriteFilters: () => useUpsertPersonalFavouriteFilters("deprecated-not-in-use", graphqlRouterUrl as string),
    });

export default withFilterContext;
