import { defineMessages } from "react-intl";

const messages = defineMessages({
    exportToExcelSuccess: {
        id: "hooks.useExportData.exportToExcelSuccess",
        defaultMessage:
            "Creating export from selected data. When the process is finished, click on the notification or head to exports on 'Data tables' page to download the file.",
    },
    exportToExcelFailure: {
        id: "hooks.useExportData.exportToExcelFailure",
        defaultMessage:
            "Something happened while exporting selected data to Excel. Please contact our customer support if the issue persists.",
    },
});

export default messages;
