import { defineMessages } from "react-intl";

const messages = defineMessages({
    hideValue: {
        id: "customAnalysis.ScriptField.hideValue",
        defaultMessage: "Hide value from analysis",
    },
    showValue: {
        id: "customAnalysis.ScriptField.showValue",
        defaultMessage: "Show value in analysis",
    },
    editScript: {
        id: "customAnalysis.ScriptField.editScript",
        defaultMessage: "Edit script",
    },
});

export default messages;
