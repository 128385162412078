import { defineMessages } from "react-intl";

const messages = defineMessages({
    moreThanTwoAggs: {
        id: "customAnalysis.comparisonPeriodSetup.moreThanTwoAggs",
        defaultMessage:
            "You have more than two value aggregations. The first will be used as the value, and the second will be used as a baseline, but the other value aggregations will be ignored.",
    },
    setUpComparisonPeriodButton: {
        id: "customAnalysis.comparisonPeriodSetup.setUpComparisonPeriodButton",
        defaultMessage: "Set up trend arrow",
    },
    removeTrendArrow: {
        id: "customAnalysis.comparisonPeriodSetup.removeTrendArrow",
        defaultMessage: "Remove trend arrow",
    },
});

export default messages;
