import { defineMessages } from "react-intl";

const messages = defineMessages({
    valueItemSingular: {
        id: "customAnalysis.applyFields.valueItemSingular",
        defaultMessage: "Value",
    },
    widthValues: {
        id: "customAnalysis.applyFields.widthValues",
        defaultMessage: "Width",
    },
    baseline: {
        id: "customAnalysis.applyFields.baseline",
        defaultMessage: "Baseline",
    },
    yAxis: {
        id: "customAnalysis.applyFields.yAxis",
        defaultMessage: "Y-axis",
    },
    xAxis: {
        id: "customAnalysis.applyFields.xAxis",
        defaultMessage: "X-axis",
    },
    bubbleSize: {
        id: "customAnalysis.applyFields.bubbleSize",
        defaultMessage: "Bubble size",
    },
    additionalValuesForToolip: {
        id: "customAnalysis.applyFields.additionalValuesForToolip",
        defaultMessage: "Additional values (only shown in tooltip)",
    },
});

export default messages;
