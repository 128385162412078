import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { useShortDescriptionOfFilters } from "@ignite-analytics/filters";
import { capitalCase } from "@ignite-analytics/general-tools";
import { ArrowsUpDown as Swap, ArrowRight as KeyboardArrowRightIcon } from "@ignite-analytics/icons";
import { AGG_TYPES, BucketScriptRelation, ScriptedMetricRelation, SplitItem } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { IconButton, Menu, MenuItem, Stack, Tooltip, Typography } from "@mui/material";
import React, { useState } from "react";

import { useCAContext, useCAElasticIndex } from "../../../CAContextProvider/hooks";
import { safeFormatMessage } from "../../helpers";
import { ValueItem } from "../../ValueField/interfaces";
import { isSplitItemSizeReliable } from "../helpers";

import messages from "./messages";

import { useAnalysisQuery } from "@/containers/CustomAnalysisPage/CustomAnalysis/hooks";
import { getSortDescription } from "@/containers/CustomAnalysisPage/CustomAnalysis/PivotTable/helpers/headers";
import sortingMessages from "@/containers/CustomAnalysisPage/CustomAnalysis/PivotTable/messages";
import { fm } from "@/contexts/intlContext";
import { useScriptWithoutFetching } from "@/entities/scriptFields";
import { useLabelWithoutFilters } from "@/hooks/useLabel";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";

const SortValueFieldLabel: React.FC<{ valueConfiguration: ValueItem }> = ({ valueConfiguration }) => {
    const elasticIndex = useCAElasticIndex();
    const dataFields = useElasticFieldsInContext();
    const shortFilterDescription = useShortDescriptionOfFilters(valueConfiguration.filters, dataFields);
    const label = useLabelWithoutFilters(valueConfiguration, elasticIndex);

    const aggOptions = AGG_TYPES[valueConfiguration.type];
    const { aggregation = aggOptions[0] } = valueConfiguration;
    const aggregationTitle = safeFormatMessage(aggregation);

    return <Typography variant="textSm">{`${label} ${shortFilterDescription ?? ""} - ${aggregationTitle}`}</Typography>;
};

const SortScriptFieldLabel: React.FC<{ scriptField: BucketScriptRelation | ScriptedMetricRelation }> = ({
    scriptField: { script },
}) => {
    const scriptObj = useScriptWithoutFetching(script);
    return <Typography variant="textSm">{scriptObj?.label}</Typography>;
};

type Props = {
    index: number;
    item: SplitItem & { uuid?: string };
    onUpdate: (item: SplitItem) => void;
    onOpen: () => void;
    onClose: () => void;
};

const ALPHABETIC_SORT_AGG_INDEX = null;

export const SortPicker: React.FC<Props> = ({ index, item, onUpdate, onOpen, onClose }) => {
    const { openWidget } = useCAContext();
    const query = useAnalysisQuery(openWidget, openWidget?.customDashboard);
    const labelledQuery = useLabelledAnalysisQuery(query);

    const [sortIconAnchorEl, setSortIconAnchorEl] = useState<HTMLElement>();
    const [sortFieldMenuAnchorEl, setSortFieldMenuAnchorEl] = useState<HTMLElement>();
    const [selectedSortAggIndex, setSelectedSortAggIndex] = useState<number | null | undefined>();

    const handleOpenSortMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        onOpen();
        setSortIconAnchorEl(e.currentTarget);
    };

    const handleCloseSortMenu = () => {
        setSortIconAnchorEl(undefined);
        setSortFieldMenuAnchorEl(undefined);
        setSelectedSortAggIndex(undefined);
        onClose();
    };

    const handleClickSortMenuItem = (
        e: React.MouseEvent<HTMLLIElement, MouseEvent>,
        selectedAggIndex: number | null
    ) => {
        e.stopPropagation();
        setSortFieldMenuAnchorEl(e.currentTarget);
        setSelectedSortAggIndex(selectedAggIndex);
    };

    const handleSortSelected = (selectedSortOrder: "asc" | "desc") => {
        const updatedItem: SplitItem = {
            ...item,
            sortAggIndex: selectedSortAggIndex,
            sortOrder: selectedSortOrder,
        };
        updatedItem.searchSize = isSplitItemSizeReliable(updatedItem) ? null : updatedItem.searchSize ?? 1000;
        onUpdate(updatedItem);
        track("Analysis: Sort change", {
            splitItemIndex: index,
            splitItemLabel: item.label,
            sortOrder: selectedSortOrder,
            sortAggIndex: selectedSortAggIndex,
            sortAggItemLabel: selectedSortAggIndex
                ? openWidget?.valueConfigurations[selectedSortAggIndex]?.label
                : "Alphabetically",
        });
        handleCloseSortMenu();
    };

    const sortDescriptionTooltip = getSortDescription(labelledQuery?.valueAggregationItems ?? [], item);
    return (
        <>
            <Tooltip title={sortDescriptionTooltip}>
                <IconButton onClick={handleOpenSortMenu} size="2xsmall">
                    <Swap fontSize="inherit" />
                </IconButton>
            </Tooltip>
            <Menu id="sort-menu" anchorEl={sortIconAnchorEl} open={!!sortIconAnchorEl} onClose={handleCloseSortMenu}>
                <Stack pl={2} pt={0} pb={0.5}>
                    <Typography variant="textMd" color={(theme) => theme.palette.grey[600]}>
                        {fm(messages.sortBy)}
                    </Typography>
                </Stack>
                {openWidget?.valueConfigurations.map((valueConfiguration, i) => (
                    <MenuItem
                        key={`${valueConfiguration.id}-${valueConfiguration.uuid}`}
                        selected={i === item.sortAggIndex}
                        aria-expanded={i === selectedSortAggIndex}
                        onClick={(e) => handleClickSortMenuItem(e, i)}
                    >
                        <Stack direction="row" gap={2} justifyContent="space-between" width="100%">
                            {valueConfiguration.type === "script" || valueConfiguration.type === "scripted_metric" ? (
                                <SortScriptFieldLabel scriptField={valueConfiguration} />
                            ) : (
                                <SortValueFieldLabel valueConfiguration={valueConfiguration} />
                            )}
                            <KeyboardArrowRightIcon fontSize="small" />
                        </Stack>
                    </MenuItem>
                ))}
                <MenuItem
                    selected={ALPHABETIC_SORT_AGG_INDEX === item.sortAggIndex}
                    onClick={(e) => handleClickSortMenuItem(e, ALPHABETIC_SORT_AGG_INDEX)}
                >
                    <Stack direction="row" justifyContent="space-between" width="100%" gap={2}>
                        <Typography variant="textSm">{fm(messages.alphabetically)}</Typography>
                        <KeyboardArrowRightIcon fontSize="small" />
                    </Stack>
                </MenuItem>
            </Menu>
            <Menu
                id="sub-sort-menu"
                anchorEl={sortFieldMenuAnchorEl}
                open={!!sortFieldMenuAnchorEl}
                onClose={() => {
                    setSortFieldMenuAnchorEl(undefined);
                }}
                anchorOrigin={{
                    horizontal: "right",
                    vertical: "top",
                }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "top",
                }}
            >
                <MenuItem
                    selected={selectedSortAggIndex === item.sortAggIndex && item.sortOrder === "asc"}
                    onClick={() => handleSortSelected("asc")}
                >
                    <Typography variant="textSm">{capitalCase(fm(sortingMessages.asc))}</Typography>
                </MenuItem>
                <MenuItem
                    selected={selectedSortAggIndex === item.sortAggIndex && item.sortOrder === "desc"}
                    onClick={() => handleSortSelected("desc")}
                >
                    <Typography variant="textSm">{capitalCase(fm(sortingMessages.desc))}</Typography>
                </MenuItem>
            </Menu>
        </>
    );
};
