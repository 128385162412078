import * as Sentry from "@sentry/react";
import React from "react";

import { FatalErrorFallback } from "../Fallbacks/FatalErrorFallback";

/**
 * FatalErrorBoundry
 *
 * Use this for avoiding white-screens on the outermost level
 */

interface Props {
    children: React.ReactNode;
}

export const FatalErrorBoundary: React.FC<Props> = ({ children }) => (
    <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
            scope.setTag("app", "analysis-app");
        }}
        // eslint-disable-next-line react/no-unstable-nested-components
        fallback={({ error }) => <FatalErrorFallback error={error} />}
    >
        {children}
    </Sentry.ErrorBoundary>
);
