import { COMPARATIVE_OPERATORS } from "@ignite-analytics/pivot-ts";

import { NUMERICAL_OPERATORS, ALL_BINARY_OPERATORS } from "./constants";

export type NumericalOperator = typeof NUMERICAL_OPERATORS[number];

export const isNumericalOperator = (operator: string): operator is NumericalOperator =>
    NUMERICAL_OPERATORS.includes(operator as NumericalOperator);

export type ComparativeOperator = typeof COMPARATIVE_OPERATORS[number];

export const isComparativeOperator = (operator: string): operator is ComparativeOperator =>
    COMPARATIVE_OPERATORS.includes(operator as ComparativeOperator);

export type BinaryOperator = typeof ALL_BINARY_OPERATORS[number];

export const isBinaryOperator = (operator: string): operator is BinaryOperator =>
    ALL_BINARY_OPERATORS.includes(operator as BinaryOperator);

export type ExpressionType = "number" | "boolean";
