import { ElasticField } from "@ignite-analytics/elastic-fields";
import { Filter, FilterModal } from "@ignite-analytics/filters";
import { useDebounce } from "@ignite-analytics/general-tools";
import {
    ChartLine as InsertChartIcon,
    Undo as RestartAltIcon,
    FloppyDisk as SaveIcon,
    Table as TableChartIcon,
} from "@ignite-analytics/icons";
import { getValidAggOption, pivotParser, UserError } from "@ignite-analytics/pivot-charts";
import { AnalysisQuery, PivotResponse } from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { Box, Button, Card, Divider, IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import axios from "axios";
import update from "immutability-helper";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { useCAContext } from "../CAContextProvider/hooks";
import { CUSTOM_ANALYSIS_PREFIX, SAVE_WIDGET_MODAL } from "../constants";
import { asEditableWidget, getChartErrors, getNormalizationStatus } from "../helpers";
import { EditableAnalysisWidget } from "../interfaces";
import messages from "../messages";
import PivotTable from "../PivotTable";
import { useGetDataFromAnalysisService } from "../services";

import ComparisonPeriodSetup from "./ComparisonPeriodSetup";

import Chart from "@/components/Charts/Chart";
import CreateWidgetModal from "@/components/CreateWidgetModal";
import ErrorAlerts from "@/components/ErrorAlerts";
import { setErrorsFromCatch, setErrorsFromObject } from "@/components/ErrorAlerts/helpers";
import WidgetErrorBoundary from "@/components/ErrorBoundries/WidgetErrorBoundary";
import OverlayLoader from "@/components/OverlayLoader";
import PermissionContainer from "@/components/PermissionContainer";
import { CHART_VIEW, TABLE_VIEW } from "@/components/Widgets/constants";
import FilterCardFooter from "@/components/Widgets/FilterCardFooter";
import { fm } from "@/contexts/intlContext";
import { useLabelledAnalysisQuery } from "@/hooks/useWithLabels";
import { useColors } from "@/lib/colors";

interface Props {
    widget: EditableAnalysisWidget | undefined;
    query: AnalysisQuery | undefined;
    fieldsInIndex: ElasticField[] | undefined;
    filters: Filter[] | undefined;
    showSaveButton: boolean;
}

export const ChartTableContainer: React.FC<Props> = ({ widget, query, filters, fieldsInIndex, showSaveButton }) => {
    const { openModal, updateWidget, resetWidget, setOpenModal, updateChartConfigField } = useCAContext();

    const [data, setData] = useState<PivotResponse | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);

    const colors = useColors();
    const intl = useIntl();

    // Key to ensure drilldown state is also reset when widget is reset
    const [resetKey, setResetKey] = useState(0);

    const handleHeaderChange = (name: string, defaultName: string, i: number) => {
        name !== ""
            ? updateChartConfigField(["optionsPerAgg", i, "tableHeaderName"], name)
            : updateChartConfigField(["optionsPerAgg", i, "tableHeaderName"], defaultName);
    };

    const getPivotGraphql = useGetDataFromAnalysisService();
    // As we are listening for changes to both filters and analysisQuery in updateDataPromise, we need to debounce the execution. Otherwise, we will get two requests for e.g. drilldowns.
    const debouncedGetData = useDebounce(
        useCallback(
            (
                _analysisQuery: AnalysisQuery,
                _fields: ElasticField[],
                _filters: Filter[],
                _getPivotGraphql: typeof getPivotGraphql,
                handlePromise: (res: Promise<PivotResponse>) => void
            ) => handlePromise(_getPivotGraphql(_analysisQuery, _fields, _filters, false)),
            []
        ),
        300
    );

    /**
     * Retrieves data from endpoint and updates state
     */
    const getData = useCallback(() => {
        setLoading(true);
        if (!widget || !filters || !query) return;

        const { chartConfiguration } = widget;
        const chartErrors = widget.viewState === CHART_VIEW ? getChartErrors(chartConfiguration, query, intl) : {};

        if (!fieldsInIndex?.length) return;

        if (Object.keys(chartErrors).length) {
            setLoading(false);
            setErrorsFromObject(setErrors, chartErrors);
        } else {
            setLoading(true);
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            debouncedGetData(query, fieldsInIndex, filters, getPivotGraphql, (promise) =>
                promise
                    .then((res) => {
                        setData(res);
                        setErrors([]);
                        setLoading(false);
                    })
                    .catch((errs: Error) => {
                        setErrorsFromCatch(setErrors, errs);
                        if (!axios.isCancel(errs)) setLoading(false);
                    })
            );
        }
    }, [widget, filters, query, intl, fieldsInIndex, debouncedGetData, getPivotGraphql]);

    // Get data if query or filters change, or if the user drops an item
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(getData, [query, filters, fieldsInIndex]);

    // Update errors if chart config, filters, or query change
    useEffect(() => {
        const chartConfig = widget?.chartConfiguration;
        if (widget?.viewState === TABLE_VIEW) {
            setErrors([]);
            return;
        }
        query && chartConfig && setErrorsFromObject(setErrors, getChartErrors(chartConfig, query, intl));
    }, [intl, query, widget?.chartConfiguration, widget?.viewState]);

    const labelledQuery = useLabelledAnalysisQuery(query);

    const parsedData = useMemo(() => {
        if (data === undefined || widget?.chartConfiguration === undefined || labelledQuery === undefined) return;
        try {
            return pivotParser(intl, data, widget?.chartConfiguration, labelledQuery, colors);
        } catch (error) {
            if (error instanceof UserError) {
                setErrors([error.message]);
                return;
            }
            throw error;
        }
    }, [data, widget?.chartConfiguration, labelledQuery, colors, intl]);

    /**
     * Sets view of AnalysisBuilder
     */

    const getView = () => {
        // throw new Error("FAIL");
        if (!widget || !widget?.chartConfiguration || !query) return;
        const { title } = widget;
        if (widget?.viewState === TABLE_VIEW) {
            return data ? (
                <Stack sx={{ maxHeight: "100%" }}>
                    <PivotTable
                        title={title}
                        data={data}
                        config={widget?.chartConfiguration}
                        onSplitChange={(spec) =>
                            updateWidget((prev) => {
                                // Done like this to minimize internal changes in the component since there is an open
                                // branch with very many changes.
                                const { rowSplitItems, columnSplitItems } = prev;
                                const updated = update({ rowSplitItems, columnSplitItems }, spec);
                                return asEditableWidget({ ...prev, ...updated });
                            })
                        }
                        onSplitChangeForInsightModal={(splitItems) =>
                            updateWidget((prev) => asEditableWidget({ ...prev, ...splitItems }))
                        }
                        query={query}
                        onHeaderChange={handleHeaderChange}
                    />
                </Stack>
            ) : null;
        }

        return (
            <Chart
                hideTitle
                disableRefresh={loading}
                analysisQuery={query}
                onDrilldown={(splitItems) => updateWidget((prev) => asEditableWidget({ ...prev, ...splitItems }))}
                title={title}
                config={widget?.chartConfiguration}
                data={parsedData}
                key={resetKey}
                onSaveDrilldown={
                    // Setting to undefined because we already have a save button
                    undefined
                }
            />
        );
    };

    const revertWidget = () => {
        resetWidget();
        setResetKey((prev) => prev + 1);
    };

    useEffect(
        function sendCARenderedAfterEditWidgetEvent() {
            if (widget && parsedData) {
                if (window.__editAnalysisWidgetClickedTime) {
                    const timeFromEdit = Date.now() - window.__editAnalysisWidgetClickedTime;
                    const clickSource = window.__analysisLoadTimeSource;
                    track("Custom Analysis Modal: Rendered", {
                        loadingTime: timeFromEdit,
                        newVerison: true,
                        clickSource,
                    });
                    window.__editAnalysisWidgetClickedTime = undefined;
                }
            }
        },
        [widget, parsedData]
    );

    const normalizeOnFirstValueError = useMemo(
        () => data && getNormalizationStatus(data, widget?.chartConfiguration),
        [data, widget?.chartConfiguration]
    );

    const handleChangeWidgetTitle = useDebounce((title: string) => {
        updateWidget({ title: { $set: title } });
    }, 500);

    const shouldShowComparisonPeriodSetup =
        widget?.viewState === CHART_VIEW &&
        widget?.chartConfiguration &&
        getValidAggOption(widget?.chartConfiguration)?.type === "metric";

    return (
        <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <Stack direction="row" alignItems="center">
                {widget?.title !== undefined && (
                    <TextField
                        data-testid={`${CUSTOM_ANALYSIS_PREFIX}editableHeaderInput`}
                        variant="standard"
                        defaultValue={widget.title}
                        onChange={(e) => handleChangeWidgetTitle(e.target.value)}
                        placeholder={fm(messages.titleLabel)?.toString()}
                        fullWidth
                        sx={{ paddingLeft: 2 }}
                    />
                )}
                {!widget?.id && (
                    <Tooltip title={fm(messages.resetEverything)}>
                        <IconButton
                            data-testid={`${CUSTOM_ANALYSIS_PREFIX}resetButton`}
                            onClick={() => revertWidget()}
                            size="small"
                        >
                            <RestartAltIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                )}

                {/* Toggle the view option */}
                <Tooltip
                    title={
                        widget?.viewState === TABLE_VIEW
                            ? fm(messages.toogleChartTooltipChart)
                            : fm(messages.toogleChartTooltipTable)
                    }
                >
                    <IconButton
                        data-testid={`${CUSTOM_ANALYSIS_PREFIX}viewTypeButton`}
                        onClick={() =>
                            updateWidget({
                                viewState: {
                                    $set: widget?.viewState === TABLE_VIEW ? CHART_VIEW : TABLE_VIEW,
                                },
                            })
                        }
                        size="small"
                    >
                        {widget?.viewState === TABLE_VIEW ? (
                            <InsertChartIcon fontSize="inherit" />
                        ) : (
                            <TableChartIcon fontSize="inherit" />
                        )}
                    </IconButton>
                </Tooltip>

                {/* The filter component */}
                <FilterModal
                    title={fm(messages.defaultAnalysisTitle)?.toString()}
                    placement="Custom Analysis - Main filter"
                />

                {/* Save Analysis */}
                {showSaveButton ? (
                    <PermissionContainer
                        requiredPermissionTypes={["add"]}
                        equivalentUserPermission={{
                            namespace: "dashboards",
                            relation: { object: "general", relation: "write" },
                        }}
                    >
                        <Button
                            data-testid={`${CUSTOM_ANALYSIS_PREFIX}saveButton`}
                            onClick={() => setOpenModal(SAVE_WIDGET_MODAL)}
                            startIcon={<SaveIcon />}
                            size="medium"
                        >
                            {fm(messages.saveAnalysisInfoText)}
                        </Button>
                        {widget && openModal === SAVE_WIDGET_MODAL && (
                            <CreateWidgetModal
                                open={openModal === SAVE_WIDGET_MODAL}
                                newWidget={widget}
                                onClose={() => setOpenModal(undefined)}
                            />
                        )}
                    </PermissionContainer>
                ) : null}
            </Stack>
            {shouldShowComparisonPeriodSetup && <ComparisonPeriodSetup />}

            {/* Table | Chart */}
            {widget && (
                <WidgetErrorBoundary widget={widget}>
                    {!errors.length ? (
                        <Box sx={{ position: "relative", overflow: "hidden", height: "100%" }}>
                            {data && getView()}
                            {loading && <OverlayLoader delay={!!data} />}
                        </Box>
                    ) : (
                        <ErrorAlerts errors={errors} onUpdateData={getData} />
                    )}
                </WidgetErrorBoundary>
            )}
            <Divider />
            <FilterCardFooter />

            {normalizeOnFirstValueError && normalizeOnFirstValueError.length > 0 && (
                <Typography variant="textXs" fontWeight={600}>
                    <b>{fm(messages.normalizeOnFirstValueError)}</b>
                </Typography>
            )}
        </Card>
    );
};
