import { defineMessages } from "react-intl";

const messages = defineMessages({
    totalLabel: {
        id: "customAnalysis.pivotTable.totalLabel",
        defaultMessage: "Total",
    },
    othersLabel: {
        id: "customAnalysis.pivotTable.othersLabel",
        defaultMessage: "Others",
    },
    of: {
        id: "customAnalysis.pivotTable.of",
        defaultMessage: "of",
    },
    desc: {
        id: "customAnalysis.pivotTable.desc",
        defaultMessage: "descendingly",
    },
    asc: {
        id: "customAnalysis.pivotTable.asc",
        defaultMessage: "ascendingly",
    },
    sorting: {
        id: "customAnalysis.pivotTable.sorting",
        defaultMessage: "Sorted {order} by {field}.",
    },
    name: {
        id: "customAnalysis.pivotTable.name",
        defaultMessage: "name",
    },
    totalAndExcludeOthers: {
        id: "customAnalysis.TotalAndExcludeWarning",
        defaultMessage: "Total value field might contain hidden values when you select: Exclude `Others` values",
    },
});

export default messages;
