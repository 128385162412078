import { ExclamationTriangle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { AlertTitle, Button, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export const FatalErrorFallback: React.FC<{ error: Error }> = ({ error }) => {
    useEffect(
        function trackErrorEvent() {
            error && track("Custom Analysis ErrorPage: Showed", { error });
        },
        [error]
    );

    return (
        <Stack direction="row" alignItems="center" justifyContent="center" sx={{ width: "100%", height: "80vh" }}>
            <Stack direction="column" alignItems="center" justifyContent="center" gap={2}>
                <Stack direction="row" alignItems="center" justifyContent="center" gap={2}>
                    <ExclamationTriangle fontSize="large" sx={{ marginBottom: "10px" }} />
                    <AlertTitle>
                        <Typography variant="h5">{fm(messages.error)}</Typography>
                    </AlertTitle>
                </Stack>
                <Typography variant="textMd">{fm(messages.somethingWentWrong)}</Typography>
                <Button size="large" onClick={() => window.location.reload()}>
                    <Typography variant="buttonLarge">{fm(messages.refresh)}</Typography>
                </Button>
            </Stack>
        </Stack>
    );
};
