import { getRegion } from "@ignite-analytics/locale";
import { AggregatedFilter, COMPARATIVE_OPERATORS } from "@ignite-analytics/pivot-ts";

import { IncompleteAggregatedFilter } from "./interfaces";

import "dayjs/locale/de";
import "dayjs/locale/nb";

export const isValidAggregatedFilter = (filter: IncompleteAggregatedFilter): filter is AggregatedFilter =>
    typeof filter.leftAggIndex === "number" &&
    filter.operator !== undefined &&
    COMPARATIVE_OPERATORS.includes(filter.operator) &&
    ((typeof filter.rightAggIndex === "number" && typeof filter.rightConstant !== "number") ||
        (typeof filter.rightAggIndex !== "number" && typeof filter.rightConstant === "number"));

export const dayJsLocale = () => {
    const region = getRegion();
    if (region === "nb-NO") {
        return "nb";
    }
    if (region === "de-DE") {
        return "de";
    }
    return "en";
};
