import { useElasticFields } from "@ignite-analytics/elastic-fields";
import { Filter, useShortDescriptionOfFilters } from "@ignite-analytics/filters";
import React from "react";

interface Props {
    filters: Filter[];
    elasticIndex: string;
}

const FiltersDescriptor: React.FC<Props> = ({ filters, elasticIndex }) => (
    <>{useShortDescriptionOfFilters(filters, useElasticFields(elasticIndex, true))}</>
);

export default FiltersDescriptor;
