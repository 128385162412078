import { useHoverContext } from "@ignite-analytics/general-tools";
import { Plus as Add, X as CloseIcon } from "@ignite-analytics/icons";
import { Spec } from "immutability-helper";
import React, { useRef } from "react";
import { v4 as uuidv4 } from "uuid";

import { PositionedIconButton, RelativeContainer } from "./style";

import { ExpressionDraft } from "@/components/ScriptModal/interfaces";

interface Props {
    onChange?: (spec: Spec<ExpressionDraft>) => void;
    vertical?: boolean;
    forceShowOperationButton?: boolean;
    margin?: string;
    children: React.ReactNode;
}

const WrapperWithButtons: React.FC<Props> = ({ children, onChange, vertical, forceShowOperationButton, margin }) => {
    const uuid = useRef(uuidv4()).current;
    const hoverContext = useHoverContext();
    const showIcons = hoverContext?.current === uuid;
    const closeTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    return (
        <RelativeContainer
            onMouseEnter={() => {
                hoverContext?.onMouseEnter(uuid);
                closeTimeoutRef?.current && clearTimeout(closeTimeoutRef.current);
            }}
            onMouseLeave={() => {
                closeTimeoutRef.current = setTimeout(() => hoverContext?.onMouseLeave(uuid), 150);
            }}
            showIcons={showIcons}
            margin={margin}
        >
            {onChange && (
                <>
                    <PositionedIconButton
                        color="ghostGray"
                        bgColor="error"
                        aria-hidden={!showIcons}
                        size="2xsmall"
                        position="top-right"
                        forceShow={showIcons}
                        onClick={() => onChange({ $set: null })}
                    >
                        <CloseIcon fontSize="small" />
                    </PositionedIconButton>
                    <PositionedIconButton
                        aria-hidden={!showIcons}
                        color="ghostGray"
                        bgColor="success"
                        size="2xsmall"
                        position={vertical ? "bottom" : "right"}
                        forceShow={forceShowOperationButton || showIcons}
                        onClick={() =>
                            onChange((prev) => ({ type: "BinaryExpression", operator: "+", left: prev, right: null }))
                        }
                    >
                        <Add fontSize="small" />
                    </PositionedIconButton>
                </>
            )}
            {children}
        </RelativeContainer>
    );
};

export default WrapperWithButtons;
