import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { Plus as Add, X as CloseIcon } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    Stack,
    TextField,
    Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import messages from "./messages";

import CreateDashboardModal from "@/components/CreateDashboardModal";
import PermissionContainer from "@/components/PermissionContainer";
import { Widget } from "@/components/Widgets/interfaces";
import { useCreateWidget } from "@/components/Widgets/services";
import {
    useCAContext,
    useCAElasticIndex,
} from "@/containers/CustomAnalysisPage/CustomAnalysis/CAContextProvider/hooks";
import { getDefaultAnalysisWidget } from "@/containers/CustomAnalysisPage/CustomAnalysis/helpers";
import { fm } from "@/contexts/intlContext";
import { useEditableDashboards } from "@/entities/dashboards";

const CREATE_WIDGET_PREFIX = "createWidgetModal-";

interface Props<W extends Widget> {
    open: boolean;
    newWidget: W;
    onClose: () => void;
}
const CreateWidgetModal: React.FC<Props<Widget>> = <W extends Widget>({ open, newWidget, onClose }: Props<W>) => {
    const { defaultDashboardId } = useCAContext();
    const elasticIndex = useCAElasticIndex();
    const navigate = useNavigate();
    const { editableDashboards, getDashboards: refetchDashboards } = useEditableDashboards();
    const [openDashboardModal, setOpenDashboardModal] = useState(false);
    const [dashboardId, setDashboardId] = useState<number | undefined>(defaultDashboardId);
    const [widgetTitle, setWidgetTitle] = useState<string>(newWidget.title);
    const elasticFields = useElasticFieldsInContext();
    const intl = useIntl();
    const createWidget = useCreateWidget(dashboardId ?? NaN);

    useEffect(() => {
        setDashboardId(defaultDashboardId);
    }, [defaultDashboardId]);

    const createWidgetToDashboard = () => {
        if (!dashboardId || !elasticIndex) return;

        const widgetConfig = {
            message: messages.customAnalysis,
            defaultConfig: getDefaultAnalysisWidget(elasticIndex, elasticFields ?? [], intl),
            excelExport: true,
        };

        const widget: W = {
            // Default config for type of widget
            ...widgetConfig,
            // The partial widget from props
            ...newWidget,
            title: widgetTitle,
            customDashboard: dashboardId,
        };
        createWidget(widget).then(() => {
            track("newWidgetCreated", { widgetType: newWidget.type });
            onClose();
            navigate(`/dashboard/overview/${dashboardId}/`);
        });
    };

    const dashboardSelectionItems = editableDashboards
        ? editableDashboards?.map((dashboard) => ({
              label: dashboard.name,
              value: dashboard.id,
          }))
        : [];

    return (
        <Dialog open={open} onClose={onClose}>
            <PermissionContainer
                requiredPermissionTypes={["add"]}
                equivalentUserPermission={{
                    namespace: "dashboards",
                    relation: { object: "general", relation: "write" },
                }}
            >
                <CreateDashboardModal
                    open={openDashboardModal}
                    onClose={() => {
                        setOpenDashboardModal(false);
                    }}
                    setDashboardId={setDashboardId}
                    refetchDashboards={refetchDashboards}
                />
            </PermissionContainer>
            <DialogTitle>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    {fm(messages.createWidget)}
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Stack pt={1} direction="column" spacing={2}>
                    <TextField
                        label={fm(messages.widgetTitle)}
                        name="title"
                        value={widgetTitle}
                        onChange={(e) => setWidgetTitle(e.target.value)}
                        data-testid={`${CREATE_WIDGET_PREFIX}titleInput`}
                    />
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <FormControl fullWidth>
                            <Autocomplete
                                loading={!editableDashboards}
                                options={dashboardSelectionItems}
                                renderInput={(params) => <TextField {...params} label={fm(messages.chooseDashboard)} />}
                                onChange={(_, option) => setDashboardId(option?.value)}
                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                value={
                                    dashboardId
                                        ? {
                                              label: dashboardSelectionItems?.find((d) => d.value === dashboardId)
                                                  ?.label,
                                              value: dashboardId,
                                          }
                                        : undefined
                                }
                                renderOption={(props, option) => (
                                    <li {...props} key={`${option.label}-${option.value}`}>
                                        {option.label}
                                    </li>
                                )}
                                getOptionLabel={(option) => option.label ?? ""}
                                data-testid={`${CREATE_WIDGET_PREFIX}selectDashboard`}
                            />
                        </FormControl>
                        <Tooltip title={fm(messages.createNewDashboard)}>
                            <IconButton
                                data-testid={`${CREATE_WIDGET_PREFIX}createDashboardButton`}
                                onClick={() => {
                                    setOpenDashboardModal(true);
                                }}
                            >
                                <Add />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                    <Button
                        data-testid={`${CREATE_WIDGET_PREFIX}submitButton`}
                        color="primary"
                        onClick={() => {
                            createWidgetToDashboard();
                            onClose();
                        }}
                        disabled={!dashboardId || !widgetTitle}
                    >
                        {fm(messages.createWidget)}
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
};

export default CreateWidgetModal; //
