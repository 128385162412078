import { styled, Box, CircularProgress } from "@mui/material";
import React from "react";

import { AbsoluteLoadingScreen } from "./style";

interface Props {
    delay?: boolean;
}

const OverlayLoader: React.FC<Props> = ({ delay = true }) => (
    <AbsoluteLoadingScreen delay={delay}>
        <CircularProgress />
    </AbsoluteLoadingScreen>
);

export const RelativeContainer = styled(Box)`
    position: relative;
`;

export default OverlayLoader;
