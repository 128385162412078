import { styled } from "@mui/material";

interface Props {
    isDragging: boolean | undefined;
    draggable: boolean;
    fullWidth?: boolean;
}

export const DraggableDiv = styled("div")<Props>`
    background-color: ${({ theme }) => theme.palette.background.paper},
    transition: ${(props) => (props.isDragging ? "filter 0.5s ease-in" : "none")};
    filter: ${(props) => (props.isDragging ? "blur(2px) brightness(90%)" : "none")};
    width: ${(props) => (props.fullWidth === false ? "" : "100%")};
    cursor: ${(props) => (props.draggable ? "move" : "inherit")};
`;
