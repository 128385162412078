import { defineMessages } from "react-intl";

const messages = defineMessages({
    aggregatedFilters: {
        id: "customAnalysis.SplitField.aggregatedFilters",
        defaultMessage: "Filter on aggregated values",
    },
    addAggregatedFilters: {
        id: "customAnalysis.SplitField.addAggregatedFilters",
        defaultMessage: "Add aggregated filter",
    },
    applyFilters: {
        id: "customAnalysis.SplitField.applyFilters",
        defaultMessage: "Apply",
    },
    numberOfIntervals: {
        id: "customAnalysis.SplitField.numberOfIntervals",
        defaultMessage: "Number of intervals",
    },
    largerThanZero: {
        id: "customAnalysis.SplitField.largerthanzero",
        defaultMessage: "Please choose a number larger than zero",
    },
    intervalIndex: {
        id: "customAnalysis.SplitField.intervalIndex",
        defaultMessage: "Interval number",
    },
    intervalFrom: {
        id: "customAnalysis.SplitField.intervalFrom",
        defaultMessage: "From",
    },
    intervalTo: {
        id: "customAnalysis.SplitField.intervalTo",
        defaultMessage: "To",
    },
    searchSize: {
        id: "customAnalysis.SplitField.intervalTo.searchSize",
        defaultMessage: "Search size",
    },
    searchSizeHelpText: {
        id: "customAnalysis.SplitField.intervalTo.searchSizeHelpText",
        defaultMessage:
            "With the aggregated filters you have chosen, it might be necessary to search through more than {num} objects to find {num} objects. Choose an appropriate number here to ensure correct results.",
    },
});

export default messages;
