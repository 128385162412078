import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { getShortDescriptionOfFilters } from "@ignite-analytics/filters";
import { useMemo } from "react";
import { useIntl } from "react-intl";

import { safeFormatMessage } from "../../helpers";

import { EMPTY_ARRAY } from "./constants";

import { useCAContext } from "@/containers/CustomAnalysisPage/CustomAnalysis/CAContextProvider/hooks";
import { useWithLabels } from "@/hooks/useWithLabels";

/**
 * This function returns full labels for each active value configuration for use in Select menu.
 * The labels are in the same format that is used in the `ValueField` component.
 * Ideally, this should have been done in an Option component, but the current Select component
 * in ignite-ui is unable to successfully extract text from sub components of Options.
 */
export const useValueAggDescriptors = () => {
    const { openWidget } = useCAContext();
    const filtersWithSource = useMemo(
        () =>
            openWidget?.valueConfigurations
                .map((v, i) => ("filters" in v ? v.filters.map((filter) => ({ ...filter, source: i })) : EMPTY_ARRAY))
                .flat(),
        [openWidget?.valueConfigurations]
    );
    const labelledFilters = useWithLabels(filtersWithSource ?? EMPTY_ARRAY);
    const valuesWithoutFilters = useMemo(
        () =>
            openWidget?.valueConfigurations.map((value, i) => {
                if (!("filters" in value)) return { ...value, source: i };
                const { filters, ...rest } = value;
                return { ...rest, filters: EMPTY_ARRAY, source: i };
            }),
        [openWidget?.valueConfigurations]
    );
    const labelledValues = useWithLabels(valuesWithoutFilters ?? EMPTY_ARRAY);
    const elasticFields = useElasticFieldsInContext();
    const intl = useIntl();
    return useMemo(
        () =>
            labelledValues?.map((valueConfiguration) => [
                valueConfiguration.label +
                    (elasticFields && labelledFilters
                        ? getShortDescriptionOfFilters(
                              labelledFilters.filter(({ source }) => source === valueConfiguration.source),
                              intl,
                              elasticFields
                          )
                        : "") +
                    ("aggregation" in valueConfiguration
                        ? ` - ${safeFormatMessage(valueConfiguration.aggregation)}`
                        : ""),
                valueConfiguration.uuid,
            ]),
        [labelledValues, labelledFilters, intl, elasticFields]
    );
};
