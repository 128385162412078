import { toCamelCase } from "@ignite-analytics/api-client";
import { arrayHelpers, isNullOrUndefined } from "@ignite-analytics/general-tools";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import {
    AggregationItem,
    LabelledAnalysisQuery,
    PivotColumnData,
    SplitItem,
    ValueConfiguration,
    Labelled,
} from "@ignite-analytics/pivot-ts";

import messages from "../messages";

import customAnalysisMessages from "@/containers/CustomAnalysisPage/CustomAnalysis/messages";
import { staticFormatMessage } from "@/contexts/intlContext";

const getAggIndex = (item: SplitItem, aggs: number, aggIndex?: number) => {
    if (aggIndex !== undefined) return aggIndex;
    if (!item || isNullOrUndefined(item.sortAggIndex)) return 0;
    if (item && item.sortAggIndex + 1 < aggs) return item.sortAggIndex + 1;
    return 0;
};

const getSortOrder = (item: SplitItem, nextAggIndex: number, aggIndex?: number) => {
    if (!item) return "desc";
    if (isNullOrUndefined(aggIndex)) {
        if (nextAggIndex === 0 || nextAggIndex === item.sortAggIndex) {
            return item.sortOrder === "desc" ? "asc" : "desc";
        }
    }
    return "desc";
};
export const isExcluded = (query: SplitItem[]): boolean => {
    let returnVal = false;
    query.forEach((list) => {
        if (list.excludeOthers) {
            returnVal = true;
        }
    });
    return returnVal;
};
export const onHeaderClick =
    (aggs: number, path: string[], aggIndex?: number) =>
    (prevs: SplitItem[]): SplitItem[] => {
        const prev = prevs && prevs[0];
        const nextAggIndex = getAggIndex(prev, aggs, aggIndex);
        const sortOrder = getSortOrder(prev, nextAggIndex, aggIndex);

        return prevs.map((item) => ({
            ...item,
            sortOrder,
            path,
            sortAggIndex: nextAggIndex,
        }));
    };

export const getAggDescription = (aggItem: Labelled<AggregationItem | ValueConfiguration>) => {
    if (aggItem) {
        const aggMessageKey = toCamelCase(
            "aggregation" in aggItem && aggItem.aggregation
                ? aggItem.aggregation
                : aggItem.type === "script"
                ? "script"
                : "sum",
            true
        ) as keyof typeof customAnalysisMessages;
        const messageKey = customAnalysisMessages[aggMessageKey];
        const aggregation = messageKey ? staticFormatMessage(messageKey).toLowerCase() : aggMessageKey;
        if (aggregation === "script") {
            return aggItem.label?.toString().toLowerCase();
        }
        return `${aggregation} ${staticFormatMessage(messages.of)} ${aggItem.label?.toString().toLowerCase()}`;
    }
    return "";
};

export const getSortDescription = (aggItems: Labelled<AggregationItem>[], { sortAggIndex, sortOrder }: SplitItem) =>
    sortOrder
        ? staticFormatMessage(messages.sorting, {
              order: staticFormatMessage(messages[sortOrder]),
              field: !isNullOrUndefined(sortAggIndex)
                  ? getAggDescription(aggItems[sortAggIndex])
                  : staticFormatMessage(messages.name),
          })
        : "";
export const hasParent = (column: PivotColumnData): boolean => !!column.parentPivot;
export const isParent = (column: PivotColumnData): boolean => !!column.children;

export const getAggLabels = (
    query: LabelledAnalysisQuery,
    columns?: PivotColumnData[],
    config?: ChartConfig
): string[] =>
    columns && columns.length
        ? columns.reduce((accumulator: string[], col) => {
              const stringD = getAggLabels(query, col.children, config);
              return [...stringD, ...accumulator];
          }, [])
        : query.valueAggregationItems.reduce((accumulator: string[], agg, i) => {
              const aggDesc: string = getAggDescription(agg);
              const headerName =
                  config?.optionsPerAgg[i]?.tableHeaderName && config?.optionsPerAgg[i]?.tableHeaderName !== undefined
                      ? config.optionsPerAgg[i].tableHeaderName
                      : "";
              return config?.optionsPerAgg[i]?.tableHeaderName && headerName !== undefined
                  ? [...accumulator, headerName]
                  : [...accumulator, aggDesc];
          }, []);

export const getTopLeftCellsLabel = ({
    columnSplitItems: colSplits,
    rowSplitItems: rowSplits,
}: LabelledAnalysisQuery) => {
    const labels: (string | string[])[] =
        colSplits.length || rowSplits.length
            ? [
                  ...arrayHelpers.range(colSplits.length).map((index) => {
                      const splitItem = colSplits[index];
                      const { label } = splitItem;
                      return label;
                  }),
                  rowSplits.map((item) => item.label),
              ]
            : [""];
    return labels.reduce(
        (accumulator: { column: string[]; row: string[] }, element) =>
            element instanceof Array
                ? {
                      ...accumulator,
                      row: element.reduce(
                          (innerAcc: string[], innerElement: string) => [...innerAcc, innerElement],
                          []
                      ),
                  }
                : { ...accumulator, column: [...accumulator.column, element] },
        { column: [], row: [] }
    );
};
