import { NotificationsContextProvider as GeneralNotificationsContextProvider } from "@ignite-analytics/notifications";
import React from "react";
import { useIntl } from "react-intl";

import { getCurrentDomain } from "@/lib/getCurrentDomain";

interface Props {
    userId: string;
    channel: number;
    topic: string;
    children: React.ReactNode;
}

const NotificationsContextProvider: React.FC<Props> = ({ children, topic, channel, userId }) => {
    const intl = useIntl();

    const notificationsUrl = getCurrentDomain() === "ignite" ? process.env.REACT_APP_NOTIFICATIONS_IGNITE_URL : process.env.REACT_APP_NOTIFICATIONS_IGNITEPROCUREMENT_URL;
    return (
        <GeneralNotificationsContextProvider
            socketUrl={`${process.env.REACT_APP_WS_PROTOCOL ?? ""}://${notificationsUrl ?? ""
                }/ws/v2/${topic}/${localStorage.tenant}/${channel}/${userId}`}
            intl={intl}
        >
            {children}
        </GeneralNotificationsContextProvider>
    );
};

export default NotificationsContextProvider;
