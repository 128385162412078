import { typingHelpers } from "@ignite-analytics/general-tools";
import { ChartConfig, isResponse } from "@ignite-analytics/pivot-charts";
import {
    AnalysisQuery,
    BottomRow,
    NotBottomRow,
    PivotBottomColumn,
    PivotColumnData,
    PivotParentColumn,
    PivotResponse,
    PivotRow,
    PivotValues,
    isBottomRow,
    isBottomRowList,
    isParentColumn,
} from "@ignite-analytics/pivot-ts";
import { isArray } from "lodash";
import React from "react";

import { PivotTableV2 } from "../PivotTableV2";

import { hasParent } from "./helpers/headers";
import { SplitChangeSpec } from "./interfaces";

const { unionized } = typingHelpers;

interface Props {
    title: string;
    data: PivotResponse;
    config: ChartConfig;
    query: AnalysisQuery;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    onSplitChangeForInsightModal?: (newSplitItems: Pick<AnalysisQuery, "rowSplitItems" | "columnSplitItems">) => void;
    onHeaderChange?: (name: string, defaultName: string, i: number) => void;
    saveWidget?: (saveAsNew: boolean) => Promise<void>;
}
const findLastColumn = (column: PivotColumnData): PivotColumnData[] => {
    if (isParentColumn(column)) {
        return unionized(column.children).reduce(
            (bottomColumnAgg: PivotColumnData[], child) => bottomColumnAgg.concat(findLastColumn(child)),
            []
        );
    }
    return [column];
};

const findBottom = (row: PivotRow): PivotRow[] => {
    if (!isBottomRow(row)) {
        return unionized(row.children).reduce(
            (bottomRowsAgg: PivotRow[], child) => bottomRowsAgg.concat(findBottom(child)),
            []
        );
    }
    return [row];
};

const getBottomColumns = (columns: PivotColumnData[]): PivotColumnData[] =>
    columns.reduce(
        (columnAgg: PivotColumnData[], column: PivotColumnData) => columnAgg.concat(findLastColumn(column)),
        []
    );
const getBottomRows = (rows: PivotRow[]): PivotRow[] =>
    rows.reduce((bottomRowsAgg: PivotRow[], row: PivotRow) => bottomRowsAgg.concat(findBottom(row)), []);

const getValuesFromTotal = (columns: PivotColumnData[]): PivotValues[] => {
    let newValues: PivotValues[] = [];
    const bottomColumns = getBottomColumns(columns);
    bottomColumns.forEach((column) => {
        if (column.totals !== undefined) {
            newValues = newValues.concat([column.totals]);
        }
    });
    return newValues;
};

const addTotalValueColumn = (
    dataRows: PivotRow[],
    dataColumns: PivotColumnData[]
): { rows: PivotRow[]; columns: PivotColumnData[] } => {
    if (dataRows.length !== 0) {
        const newRow: BottomRow = {
            field: "Total",
            key: "sumValues",
            name: "Total",
            values: getValuesFromTotal(dataColumns),
            type: dataRows[0].type,
            meta: { isTotalColumn: true },
        };
        return { rows: [...dataRows, newRow], columns: dataColumns };
    }
    return { rows: dataRows, columns: dataColumns };
};

const removeChildrenRow = (row: BottomRow | NotBottomRow): PivotRow => {
    if (row.parentPivot && !isResponse(row.parentPivot)) {
        const notBottomChild: NotBottomRow[] = isBottomRow(row) ? [] : [row];
        const bottomChild: BottomRow[] = isBottomRow(row) ? [row] : [];
        return bottomChild.length > 0
            ? removeChildrenRow({ ...row.parentPivot, children: bottomChild })
            : removeChildrenRow({ ...row.parentPivot, children: notBottomChild });
    }
    return row;
};
const removeChildrenColumn = (column: PivotBottomColumn | PivotParentColumn): PivotColumnData => {
    if (hasParent(column) && column.parentPivot && !isResponse(column.parentPivot)) {
        const parent = { ...column.parentPivot, children: [column], key: `${column.parentPivot.key}_${column.key}` };
        return removeChildrenColumn(parent);
    }
    return column;
};

export const deNormalizeHeaders = (
    dataRows: PivotRow[],
    dataColumns: PivotColumnData[]
): { rows: PivotRow[]; columns: PivotColumnData[] } => {
    const bottomRows = getBottomRows(dataRows);
    const bottomRowsRemoved = bottomRows.map((row) => removeChildrenRow(row));
    const bottomColumns = getBottomColumns(dataColumns);
    const bottomColumnsRemoved = bottomColumns.map((column) => removeChildrenColumn(column));
    return { rows: bottomRowsRemoved, columns: bottomColumnsRemoved };
};

const addTotalBottom = (row: BottomRow): BottomRow => {
    const newValue = row.totals;
    if (isArray(newValue)) {
        return { ...row, values: row.values.concat([newValue]) };
    }
    return row;
};
const checkChildrenNotBottom = (row: NotBottomRow): NotBottomRow => {
    if (row.children.length > 0) {
        return isBottomRowList(row.children)
            ? { ...row, children: row.children.map((child) => addTotalBottom(child)) }
            : { ...row, children: row.children.map((child) => checkChildrenNotBottom(child)) };
    }
    return row;
};
const addTotalRow = (row: BottomRow | NotBottomRow): NotBottomRow | BottomRow => {
    if (isBottomRow(row)) {
        return addTotalBottom(row);
    }
    return checkChildrenNotBottom(row);
};

const addTotalValueRows = (
    dataRows: PivotRow[],
    dataColumns: PivotColumnData[]
): { rows: PivotRow[]; columns: PivotColumnData[] } => {
    if (isArray(dataColumns) && dataColumns.length === 0) {
        return { rows: dataRows, columns: dataColumns };
    }
    const totalValueRow: PivotColumnData = {
        field: "Total",
        key: "sumValues",
        name: "Total",
        type: dataColumns[0].type,
        meta: { isTotalRow: true },
    };
    const columnWithTotal =
        isArray(dataColumns) && dataColumns.length !== 0 ? dataColumns.concat(totalValueRow) : dataColumns;
    const rowsWithTotal = dataRows.map((bottomRow) => addTotalRow(bottomRow));

    return { rows: rowsWithTotal, columns: columnWithTotal };
};

const formatData = (data: PivotResponse, config: ChartConfig): PivotResponse => {
    const dataWithTotalRows = addTotalValueColumn(data.rows, data.columns);
    const newData = config.totalValueRow
        ? config.totalValueColumn
            ? addTotalValueRows(dataWithTotalRows.rows, dataWithTotalRows.columns)
            : addTotalValueRows(data.rows, data.columns)
        : config.totalValueColumn
        ? dataWithTotalRows
        : data;
    const dataWithNormalization = config.deNormalizeHeaders
        ? deNormalizeHeaders(newData.rows, newData.columns)
        : newData;
    return dataWithNormalization;
};

const PivotTable: React.FC<Props> = (props) => {
    const { title, data, query, onSplitChange, onSplitChangeForInsightModal, config, onHeaderChange, saveWidget } =
        props;
    const tableConfig = { ...config };
    tableConfig.optionsPerAgg = config.optionsPerAgg.map((value) => {
        if (value.labelDisplayMode === "percent") {
            return { ...value, labelDisplayMode: "value" };
        }
        return value;
    });
    const formattedData = formatData(data, tableConfig);

    return (
        <PivotTableV2
            title={title}
            data={formattedData}
            query={query}
            config={config}
            editable
            onSplitChange={onSplitChange}
            onDrilldown={onSplitChangeForInsightModal}
            onSaveDrilldown={saveWidget}
            onHeaderChange={onHeaderChange}
        />
    );
};

function shouldIgnoreUpdate(prevProps: Props, nextProps: Props) {
    return prevProps.data === nextProps.data && prevProps.config === nextProps.config;
}

export default React.memo(PivotTable, shouldIgnoreUpdate);
