import { DiagramCells, Hashtag, DiagramSubtask, Code, ArrowsRightLeft } from "@ignite-analytics/icons";

import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export const VALUE_OPTIONS = [
    { name: "field", icon: DiagramCells, description: fm(messages.dataField), type: "number" },
    { name: "script", icon: Code, description: fm(messages.helperScript), type: "number" },
    { name: "constant", icon: Hashtag, description: fm(messages.constant), type: "number" },
    { name: "if/else", icon: DiagramSubtask, description: fm(messages.ifElse), type: "number" },
    { name: "comparison", icon: ArrowsRightLeft, description: fm(messages.comparison), type: "boolean" },
] as const;
