import { COMPARATIVE_OPERATORS } from "@ignite-analytics/pivot-ts";

import { ALL_BINARY_OPERATORS, BOOLEAN_OPERATORS, NUMERICAL_OPERATORS } from "./constants";
import { BinaryOperator, ExpressionType, isNumericalOperator } from "./interfaces";

import { BinaryExpressionDraft, ExpressionDraft } from "@/components/ScriptModal/interfaces";

export const getExpressionType = (expression: ExpressionDraft): ExpressionType | null => {
    switch (expression?.type) {
        case undefined:
            return null;
        case "BinaryExpression":
            return isNumericalOperator(expression.operator) ? "number" : "boolean";
        case "ConditionalExpression":
            return "boolean";
        case "MemberExpression":
        case "UnaryExpression":
            return "number";
        case "LogicalExpression":
            return "boolean";
        case "Literal":
            return "number";
    }
};

export const getOperatorsForExpressionType = (expressionType: ExpressionType | null): readonly BinaryOperator[] => {
    if (expressionType === "number") return NUMERICAL_OPERATORS;
    if (expressionType === "boolean") return [...COMPARATIVE_OPERATORS, ...BOOLEAN_OPERATORS];
    return ALL_BINARY_OPERATORS;
};

export const getBinaryExpressionType = (
    expression: BinaryExpressionDraft,
    forcedType?: ExpressionType
): ExpressionType | null => {
    if (forcedType) return forcedType;
    const { left, right } = expression;

    // If the binary expression doesn't have any operands, allow it to be of any type
    if (!left && !right) return null;

    // If the expression has a left side, but not a right side, use the left side type
    if (left && !right) return getExpressionType(left);

    // If the expression has a right side, but not a left side, use the right side type
    if (right && !left) return getExpressionType(right);

    // If the expression has two sides with different types, we can't tell what type it should be
    if (getExpressionType(left) !== getExpressionType(right)) return null;

    // If the expression has two sides of the same type, return operators that makes sense for that type
    return getExpressionType(left);
};
