import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { LabelledAnalysisQuery } from "@ignite-analytics/pivot-ts";
import { ColumnDef } from "@tanstack/react-table";

import { getColumnWidth, getAccessorKey } from "../helpers/columns";
import { TOTAL_FIELD } from "../helpers/constants";
import { PivotTableRow } from "../interfaces";

export const getTopLeftColumns = (
    query: LabelledAnalysisQuery,
    index: number,
    config: ChartConfig,
    width: number
): ColumnDef<PivotTableRow>[] => {
    if (index >= query.columnSplitItems.length - 1) {
        if (query.rowSplitItems.length) {
            if (query.columnSplitItems.length) {
                const columnSplitItem = query.columnSplitItems[index];
                const parentAccessorKey = getAccessorKey(`${columnSplitItem}_${index}`);
                return [
                    {
                        header: columnSplitItem.label,
                        columns: query.rowSplitItems.map((rowSplitItem, rowSplitItemIndex) => {
                            const accessorKey = getAccessorKey(`${rowSplitItem.field}_${rowSplitItemIndex}`);
                            const size = getColumnWidth(config, accessorKey);
                            return {
                                accessorKey,
                                header: rowSplitItem.label,
                                size: size || width,
                                meta: {
                                    rowSpan: 1,
                                    headerType: "normal",
                                    splitItem: rowSplitItem,
                                    splitItemIndex: rowSplitItemIndex,
                                    splitType: "row",
                                    columnWidthId: accessorKey,
                                },
                            };
                        }),
                        meta: {
                            headerType: "normal",
                            splitItem: columnSplitItem,
                            splitType: "column",
                            splitItemIndex: index,
                            columnWidthId: parentAccessorKey,
                        },
                    },
                ];
            }
            return query.rowSplitItems.map((rowSplitItem, rowSplitItemIndex) => {
                const accessorKey = getAccessorKey(`${rowSplitItem.field}_${rowSplitItemIndex}`);
                const size = getColumnWidth(config, accessorKey);
                return {
                    accessorKey,
                    header: rowSplitItem.label,
                    size: size || width,
                    meta: {
                        rowSpan: 1,
                        headerType: "normal",
                        splitItem: rowSplitItem,
                        splitItemIndex: rowSplitItemIndex,
                        splitType: "row",
                        columnWidthId: accessorKey,
                    },
                };
            });
        }
        if (query.columnSplitItems.length) {
            const columnSplitItem = query.columnSplitItems[index];
            const accessorKey = getAccessorKey(`${columnSplitItem.field}_${index}`);
            const size = getColumnWidth(config, accessorKey);
            return [
                {
                    accessorKey,
                    header: columnSplitItem.label,
                    size: size || width,
                    meta: {
                        rowSpan: 2,
                        headerType: "normal",
                        splitItem: columnSplitItem,
                        splitType: "column",
                        splitItemIndex: index,
                        columnWidthId: accessorKey,
                    },
                },
            ];
        }
        return [
            {
                accessorKey: TOTAL_FIELD,
                header: " ",
                size: getColumnWidth(config, TOTAL_FIELD) || width,
                meta: {
                    rowSpan: 2,
                    headerType: "normal",
                    columnWidthId: TOTAL_FIELD,
                },
            },
        ];
    }

    const splitItem = query.columnSplitItems[index];
    const columnWidthId = getAccessorKey(`${splitItem.label}_${index}`);
    return [
        {
            header: splitItem.label,
            columns: getTopLeftColumns(query, index + 1, config, width),
            meta: {
                headerType: "normal",
                splitItem,
                splitType: "column",
                splitItemIndex: index,
                columnWidthId,
            },
        },
    ];
};
