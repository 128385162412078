import { ChartConfig, getMeasureUnitAlternatives } from "@ignite-analytics/pivot-charts";
import { IntlShape } from "react-intl";

import { ChartListOption, Fields } from "./interfaces";
import messages from "./messages";

import { fm } from "@/contexts/intlContext";

export const getDecimalAlternatives = () => [
    { text: fm(messages.autoDecimals), value: "auto" as const },
    ...[0, 1, 2, 3, 4, 5, 6, 8, 10].map((n) => ({
        text: String(n),
        value: n,
    })),
];

const alignmentOptions = (): ChartListOption<"columnAlignment">[] => [
    { text: fm(messages.alignLeft), value: "left" },
    { text: fm(messages.alignCenter), value: "center" },
    { text: fm(messages.alignRight), value: "right" },
];

export const tableConfigFields = (): Fields<Pick<ChartConfig, Exclude<keyof ChartConfig, "optionsPerAgg">>> => ({
    columnAlignment: {
        label: fm(messages.columnAlignment),
        type: "select",
        items: alignmentOptions(),
    },
    totalValueRow: {
        label: fm(messages.totalValueRow),
        type: "checkbox",
    },
    totalValueColumn: {
        label: fm(messages.totalValueColumn),
        type: "checkbox",
    },
    deNormalizeHeaders: {
        label: fm(messages.deNormalizeHeaders),
        type: "checkbox",
    },
    valuesOverColumns: {
        label: fm(messages.valuesOverColumns),
        type: "checkbox",
    },
    fillAvailableSpace: {
        label: fm(messages.fillAvailableSpace),
        type: "checkbox",
    },
    disableStickyHeaders: {
        label: fm(messages.disableStickyHeaders),
        type: "checkbox",
    },
    wrapHeaderLabels: {
        label: fm(messages.wrapHeaderLabels),
        type: "checkbox",
    },
});

export const tableAggConfigFields = (
    intl: IntlShape,
    measureUnit?: number | "auto" | "date"
): Fields<ChartConfig["optionsPerAgg"][number]> => ({
    ...(measureUnit !== "date" && {
        decimals: {
            label: fm(messages.settingsDecimalsPH),
            type: "select",
            items: getDecimalAlternatives(),
        },
    }),
    measureUnit: {
        label: fm(messages.settingsMeasureUnitLabel),
        type: "select",
        items: getMeasureUnitAlternatives(intl),
    },
});

/**
 * Options that cannot be set per value aggregation, but have to be set for entire chart.
 * It's a function to make sure messages are generated at render (in case user switches language).
 * TODO: move this to pivot-charts package.
 */
export const otherConfigFields = (): Fields<Pick<ChartConfig, Exclude<keyof ChartConfig, "optionsPerAgg">>> => ({
    disableXAxisLabels: {
        label: fm(messages.disableXAxisLabels),
        type: "checkbox",
        exclude: ["sunburst", "sankey", "organization", "metric"],
    },
    legends: {
        label: fm(messages.labelShowLegends),
        type: "checkbox",
        exclude: ["sunburst", "sankey", "organization"],
    },
    polar: {
        label: fm(messages.polarOriented),
        type: "checkbox",
        exclude: ["sunburst", "sankey", "variwide", "organization", "metric"],
    },
    shareYAxis: {
        label: fm(messages.shareYAxis),
        type: "checkbox",
        condition: (config) => config.optionsPerAgg.length > 1 && !config.useAggsAsColumns,
        exclude: ["organization", "metric"],
    },
    shareYAxisLeft: {
        label: fm(messages.shareYAxisLeft),
        type: "multiselect",
        condition: (config) => config.optionsPerAgg.length > 1,
        exclude: ["organization", "metric"],
    },
    shareYAxisRight: {
        label: fm(messages.shareYAxisRight),
        type: "multiselect",
        condition: (config) => config.optionsPerAgg.length > 1,
        exclude: ["organization", "metric"],
    },
    useAggsAsColumns: {
        label: fm(messages.useAggsAsColumns),
        type: "checkbox",
        condition: (config, _, widget) =>
            config.optionsPerAgg.length > 1 &&
            !!widget?.valueConfigurations.some((agg) => agg.type === "script" || agg.type === "scripted_metric") &&
            !widget.columnSplitItems.filter((s) => s.size).length,
    },
});
