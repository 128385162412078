import { defineMessages } from "react-intl";

const messages = defineMessages({
    day: {
        id: "components.filterFooter.days",
        defaultMessage: "{value, plural, one {# day} other {# days}}",
    },
    month: {
        id: "components.filterFooter.months",
        defaultMessage: "{value, plural, one {# month} other {# months}}",
    },
    week: {
        id: "components.filterFooter.weeks",
        defaultMessage: "{value, plural, one {# week} other {# weeks}}",
    },
    quarter: {
        id: "components.filterFooter.quarters",
        defaultMessage: "{value, plural, one {# quarter} other {# quarters}}",
    },
    year: {
        id: "components.filterFooter.years",
        defaultMessage: "{value, plural, one {# year} other {# years}}",
    },
    starting: {
        id: "components.filterFooter.starting",
        defaultMessage: "starting {time} ago",
        description: "E.g. '5 years, starting 3 years ago'",
    },
    last: {
        id: "components.filterFooter.last",
        defaultMessage: "last",
        description: "For example used in the phrase 'Last 5 years'",
    },
    next: {
        id: "components.filterFooter.next",
        defaultMessage: "next",
        description: "For example used in the phrase 'Next 14 days'",
    },
    previous: {
        id: "components.filterFooter.previous",
        defaultMessage: "previous",
        description: "Foregående in Norwegian. For example used in the phrase 'Previous 3 months'",
    },
    any: {
        id: "components.chart.any",
        defaultMessage: "Any except missing values (Blank)",
        description: "Used for explaining the elasticsearch exists filter",
    },
    none: {
        id: "components.chart.none",
        defaultMessage: "Missing values (Blank)",
        description: "Used for explaining the elasticsearch must_not exists filter",
    },
    allExcept: {
        id: "components.chart.allExcept",
        defaultMessage: "All except",
        description: "Used for explaining the elasticsearch exclude filter",
    },
    previousMonth: {
        id: "components.filter.dateOption.lastMonth",
        defaultMessage: "Previous month",
    },
    thisYear: {
        id: "components.filter.dateOption.thisYear",
        defaultMessage: "This year",
    },
    previousYear: {
        id: "components.filter.dateOption.lastYear",
        defaultMessage: "Last year",
    },
    last2Years: {
        id: "components.filter.dateOption.last2Years",
        defaultMessage: "Last 2 years",
    },
    thisMonth: {
        id: "components.filter.dateOption.thisMonth",
        defaultMessage: "This month",
    },
    wholePeriod: {
        id: "components.filter.dateOption.wholePeriod",
        defaultMessage: "Full period",
    },
    some: {
        id: "components.filter.dateOption.some",
        defaultMessage: "Only some",
    },
    min: {
        id: "units.min",
        defaultMessage: "Min {min}",
        description: "Minimum",
    },
    max: {
        id: "units.max",
        defaultMessage: "Max {max}",
        description: "Maximum",
    },
});

export default messages;

export type MeasureUnit = keyof typeof messages;
