import { useElasticFieldsInContext } from "@ignite-analytics/elastic-fields";
import { isNotNullish } from "@ignite-analytics/general-tools";
import { TRANSACTION_DATE_GT } from "@ignite-analytics/global-types";
import { X as ClearIcon, ArrowTrendUp as TrendingUpIcon } from "@ignite-analytics/icons";
import { Alert, Button, IconButton, Stack, Tooltip } from "@mui/material";
import React, { useMemo } from "react";
import { useIntl } from "react-intl";

import { useCAContext } from "../../CAContextProvider/hooks";

import { autoSetupComparisonPeriods, removeTrendArrow } from "./helpers";
import messages from "./messages";
import PeriodFilters from "./PeriodFilters";

/**
 * This component provides a user friendly way to configure comparison filters (for e.g. trend arrows)
 */
const ComparisonPeriodSetup: React.FC = () => {
    const { openWidget, updateWidget } = useCAContext();
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fm = useIntl().formatMessage;
    const dataFields = useElasticFieldsInContext();
    const relevantDateField = useMemo(
        () => dataFields?.find((field) => field.globalTypeKey === TRANSACTION_DATE_GT),
        [dataFields]
    );
    if (!openWidget) return null;
    const valueConfigs = openWidget.valueConfigurations;
    const visibleValueIndices = valueConfigs
        .map((agg, i) => (agg.visible !== false ? i : undefined))
        .filter(isNotNullish);
    if (visibleValueIndices.length > 2) return <Alert severity="warning">{fm(messages.moreThanTwoAggs)}</Alert>;
    const comparisonPeriodsAreSetUp = visibleValueIndices.length === 2;
    if (comparisonPeriodsAreSetUp) {
        // The idea here is to provide a simple UI for changing the filters for the curent and previous period
        return (
            <Stack direction="row" justifyContent="space-between" alignContent="center">
                <Stack direction="row" justifyContent="space-between" flexWrap="wrap">
                    <PeriodFilters
                        // Use UUID as key to reset filter context when values are reordered
                        key={valueConfigs[visibleValueIndices[0]]?.uuid}
                        aggIndex={visibleValueIndices[0]}
                        period="current"
                    />
                    <PeriodFilters
                        // Use UUID as key to reset filter context when values are reordered
                        key={valueConfigs[visibleValueIndices[1]]?.uuid}
                        aggIndex={visibleValueIndices[1]}
                        period="previous"
                    />
                </Stack>
                <Stack direction="row" justifyContent="end" padding={1} alignItems="center">
                    <Tooltip title={fm(messages.removeTrendArrow)}>
                        <IconButton onClick={() => removeTrendArrow(updateWidget)} style={{ height: "min-content" }}>
                            <ClearIcon color="error" />
                        </IconButton>
                    </Tooltip>
                </Stack>
            </Stack>
        );
    }
    const queryContainsBucketScripts = valueConfigs.some((agg) => agg.type === "script");
    return (
        <Stack direction="row" justifyContent="end" paddingBottom={1} paddingTop={1}>
            <Tooltip
                title={
                    queryContainsBucketScripts
                        ? "Comparison periods are not supported when the chart contains scripts that are calculated using summarized values."
                        : undefined
                }
            >
                <Button
                    disabled={queryContainsBucketScripts || visibleValueIndices.length === 0}
                    endIcon={<TrendingUpIcon />}
                    onClick={() => autoSetupComparisonPeriods(relevantDateField, updateWidget)}
                >
                    {fm(messages.setUpComparisonPeriodButton)}
                </Button>
            </Tooltip>
        </Stack>
    );
};

export default ComparisonPeriodSetup;
