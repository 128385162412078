import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { PermissionGroup } from "@ignite-analytics/permissions";

const listCreateResource = createListResource<PermissionGroup>`/clients/1/permission-groups/`;
const detailResource = createDetailResource<PermissionGroup>`/permissions/permission-groups/${(params) => params.id}/`;

export const { Provider: PermissionGroupContextProvider, useAll: useAllPermissionGroups } = createEntityContext(
    listCreateResource,
    detailResource,
    {
        interchangableTypes: true,
        name: "PermissionGroup",
    }
);
