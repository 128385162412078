import { Literal, UnaryExpression } from "jsep";

import { ZERO_STRING } from "./constants";
import { ExpressionDraft } from "./interfaces";

export const getNumberUnary = (inputExpression: UnaryExpression) => {
    if (inputExpression.argument.type === "Literal") {
        const literal = inputExpression.argument as Literal;
        return `${inputExpression.operator}${literal.raw}`;
    }
    return ZERO_STRING;
};

const formatRawValue = (rawValue: string) => {
    if (Number.isInteger(parseFloat(rawValue)) && !rawValue.includes(".")) {
        return `${rawValue}.0`;
    }
    return rawValue;
};

export const serializeExpressionTree = (expression: ExpressionDraft): string => {
    switch (expression?.type) {
        case undefined:
            return "null";
        case "BinaryExpression":
        case "LogicalExpression":
            return `(${
                serializeExpressionTree(expression.left) +
                expression.operator +
                serializeExpressionTree(expression.right)
            })`;
        case "ConditionalExpression":
            return `(${serializeExpressionTree(expression.test)} ? (${serializeExpressionTree(
                expression.consequent
            )}) : (${serializeExpressionTree(expression.alternate)}))`;
        case "Literal":
            return formatRawValue(expression.raw);
        case "MemberExpression":
            return `${expression.object.name}.${expression.property.name}`;
        case "UnaryExpression":
            return getNumberUnary(expression);
    }
};
