import { ArrowRight as ArrowRightIcon } from "@ignite-analytics/icons";
import { ChartConfig } from "@ignite-analytics/pivot-charts";
import { LabelledAnalysisQuery, SplitItem } from "@ignite-analytics/pivot-ts";
import { Tooltip, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Header } from "@tanstack/react-table";
import React from "react";
import { IntlShape } from "react-intl";

import { PivotTableRow } from "../InnerTable/interfaces";

import { getSortDescription } from "./helpers";

import {
    createSplitItemSortUpdater,
    getIconForSortingRule,
} from "@/containers/CustomAnalysisPage/CustomAnalysis/helpers";
import { SplitChangeSpec } from "@/containers/CustomAnalysisPage/CustomAnalysis/PivotTable/interfaces";

type IconProps = {
    splitItem: SplitItem;
    splitItemIndex: number;
    splitType: "column" | "row";
    labelledQuery: LabelledAnalysisQuery;
    intl: IntlShape;
    config: ChartConfig;
    onSplitChange?: (spec: SplitChangeSpec) => void;
    columnAlignment?: "left" | "center" | "right";
    header: Header<PivotTableRow, unknown>;
};

export const HorizontalSortIcon: React.FC<IconProps> = ({
    splitItem,
    splitItemIndex,
    splitType,
    labelledQuery,
    config,
    intl,
    onSplitChange,
    columnAlignment,
    header,
}) => {
    const key = getIconForSortingRule(splitItem);
    const [from, to] = key.startsWith("sort-numeric")
        ? key.includes("up")
            ? [1, 9]
            : [9, 1]
        : key.includes("down")
        ? ["A", "Z"]
        : ["Z", "A"];
    return (
        <Stack
            justifySelf={columnAlignment ?? "center"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
                onSplitChange?.({
                    [splitType === "column" ? "columnSplitItems" : "rowSplitItems"]: {
                        [splitItemIndex]: createSplitItemSortUpdater(labelledQuery.valueAggregationItems.length),
                    },
                });
            }}
            width={header.getSize()}
        >
            <Tooltip title={getSortDescription(labelledQuery.valueAggregationItems, splitItem, intl, config)}>
                <Typography fontWeight={600} variant="textXs">
                    <Stack direction="row" alignItems="center" justifyContent={columnAlignment ?? "center"}>
                        {from}
                        <ArrowRightIcon fontSize="small" />
                        {to}
                    </Stack>
                </Typography>
            </Tooltip>
        </Stack>
    );
};
