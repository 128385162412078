import {
    BinaryExpression,
    ConditionalExpression,
    Expression,
    Identifier,
    Literal,
    MemberExpression,
    UnaryExpression,
    LogicalExpression,
} from "jsep";

export interface BinaryExpressionDraft extends Omit<BinaryExpression, "left" | "right"> {
    left: ExpressionDraft | null;
    right: ExpressionDraft | null;
}

export interface ConditionalExpressionDraft extends Omit<ConditionalExpression, "test" | "consequent" | "alternate"> {
    test: ExpressionDraft | null;
    consequent: ExpressionDraft | null;
    alternate: ExpressionDraft | null;
}

export interface ConstrainedMemberExpression extends Omit<MemberExpression, "object" | "property"> {
    object: Identifier;
    property: Identifier;
}

export interface LogicalExpressionDraft extends Omit<LogicalExpression, "right" | "left"> {
    right: ExpressionDraft | null;
    left: ExpressionDraft | null;
}

export type ExpressionDraft =
    | Literal
    | ConditionalExpressionDraft
    | BinaryExpressionDraft
    | ConstrainedMemberExpression
    | UnaryExpression
    | LogicalExpressionDraft
    | null;

export const isSupportedExpression = (expression: Expression | null): expression is ExpressionDraft => {
    if (expression === null) return true;
    const { type } = expression;
    if (expression.type === "UnaryExpression") {
        const { argument } = expression as UnaryExpression;
        return argument.type === "Literal";
    }
    if (type === "Literal") return true;
    if (type === "ConditionalExpression") {
        const { test, alternate, consequent } = expression as ConditionalExpression;
        return isSupportedExpression(test) && isSupportedExpression(alternate) && isSupportedExpression(consequent);
    }
    if (type === "BinaryExpression") {
        const { left, right } = expression as BinaryExpression;
        return isSupportedExpression(left) && isSupportedExpression(right);
    }
    if (type === "MemberExpression") {
        const { object, property } = expression as MemberExpression;
        return object.type === "Identifier" && property.type === "Identifier";
    }
    if (type === "LogicalExpression") {
        const { left, right } = expression as BinaryExpression;
        return isSupportedExpression(left) && isSupportedExpression(right);
    }
    return false;
};
