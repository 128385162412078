import React from "react";

import { getNumberUnary } from "../helpers";
import { ExpressionDraft } from "../interfaces";

import messages from "./messages";
import ParamPreview from "./ParamPreview";
import { StyledText } from "./style";

import { fm } from "@/contexts/intlContext";


interface Props {
    expression: ExpressionDraft;
    level: number;
    elasticIndex: string;
}

const MathematicalPreview: React.FC<Props> = ({ expression, level, elasticIndex }) => {
    const nextLevel = level + 1;
    switch (expression?.type) {
        case undefined:
            return <StyledText level={level}>{fm(messages.nothing)}</StyledText>;
        case "BinaryExpression":
        case "LogicalExpression":
            return (
                <>
                    <StyledText level={level}>(</StyledText>
                    <MathematicalPreview expression={expression.left} level={nextLevel} elasticIndex={elasticIndex} />
                    <StyledText level={level}>{expression.operator}</StyledText>
                    <MathematicalPreview expression={expression.right} level={nextLevel} elasticIndex={elasticIndex} />
                    <StyledText level={level}>)</StyledText>
                </>
            );
        case "ConditionalExpression":
            return (
                <>
                    <StyledText level={level}>IF (</StyledText>
                    <MathematicalPreview expression={expression.test} level={nextLevel} elasticIndex={elasticIndex} />
                    <StyledText level={level}>) THEN (</StyledText>
                    <MathematicalPreview
                        expression={expression.consequent}
                        level={nextLevel}
                        elasticIndex={elasticIndex}
                    />
                    <StyledText level={level}>) ELSE (</StyledText>
                    <MathematicalPreview
                        expression={expression.alternate}
                        level={nextLevel}
                        elasticIndex={elasticIndex}
                    />
                    <StyledText level={level}>)</StyledText>
                </>
            );
        case "Literal":
            return <StyledText level={level}>{expression.raw}</StyledText>;
        case "UnaryExpression":
            return <StyledText level={level}> {getNumberUnary(expression)} </StyledText>;

        case "MemberExpression":
            return (
                <StyledText level={level}>
                    <ParamPreview memberExpression={expression} elasticIndex={elasticIndex} />
                </StyledText>
            );
    }
};

export default MathematicalPreview;
