import { toCamelCase } from "@ignite-analytics/api-client";
import { ChartType } from "@ignite-analytics/pivot-charts";
import { IntlShape } from "react-intl";

import customAnalysisMessages from "../messages";

import { NOT_SUPPORTED_IN_CA } from "./interfaces";
import messages from "./messages";

import { staticFormatMessage } from "@/contexts/intlContext";

const isMessageKey = (s?: string | number): s is keyof typeof customAnalysisMessages =>
    typeof s === "string" && s in customAnalysisMessages;

export const safeFormatMessage = (s?: string | number) => {
    const key = toCamelCase(s, true);
    if (isMessageKey(key)) return staticFormatMessage(customAnalysisMessages[key]);
    return s ? s?.toString() : "";
};

export const stringToOption = <T extends string | number>(s?: T) => ({ text: safeFormatMessage(s), value: s });

export const checkZones = (currentIndex: string, hoverIndex: string) => {
    const from = currentIndex && currentIndex.split("-")[0];
    const to = hoverIndex && hoverIndex.split("-")[0];
    return from === to && !(from === "availableFields" && to === "availableFields");
};

export const isDataTableV2ElasticIndex = (elasticIndex?: string) => elasticIndex?.startsWith("datatable_v2_");

export const isOdd = (num?: number) => num && num % 2 === 1;

export const getSpecialValueLabels = (
    chartType: ChartType,
    intl: IntlShape
): Partial<Record<number, string>> | typeof NOT_SUPPORTED_IN_CA => {
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fm = intl.formatMessage;
    switch (chartType) {
        case "area":
        case "bar":
        case "bellcurve":
        case "column":
        case "line":
        case "waterfall":
        case "spline":
        case "heatmap":
        case "organization":
        case "sankey":
        case "sunburst":
            return {};
        case "metric":
            return { 0: fm(messages.valueItemSingular), 1: fm(messages.baseline) };
        case "bubble":
            return {
                0: fm(messages.yAxis),
                1: fm(messages.xAxis),
                2: fm(messages.bubbleSize),
                3: fm(messages.additionalValuesForToolip),
            };
        case "variwide":
            return { 0: fm(messages.valueItemSingular), 1: fm(messages.widthValues) };
        case "timeline":
        case "scatter":
            return NOT_SUPPORTED_IN_CA;
    }
};
