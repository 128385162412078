import { AggregatedFilter, SplitItem } from "@ignite-analytics/pivot-ts";

export const aggregatedFilterIsReliable = (aggFilter: AggregatedFilter, splitItem: SplitItem) => {
    // If the split item is sorted by something else than the operand in the agg filter, it cannot return correct
    // results reliably.
    if (aggFilter.leftAggIndex !== splitItem.sortAggIndex) return false;
    switch (aggFilter.operator) {
        // When using equality operators we cannot know if the results are reliable
        case "!=":
        case "==":
            return false;
        // If the split item is sorted by the aggFilter operand, and the sort direction mathces the filter operator,
        // the results can be reliable.
        case "<":
        case "<=":
            return splitItem.sortOrder === "asc";
        case ">":
        case ">=":
            return splitItem.sortOrder === "desc";
    }
};

export const isSplitItemSizeReliable = (item: SplitItem) =>
    item.aggregatedFilters?.every((aggFilter) => aggregatedFilterIsReliable(aggFilter, item)) ?? true;
