import { defineMessages } from "react-intl";

const messages = defineMessages({
    ifHeader: {
        id: "components.ScriptModal.expressionComponent.ifHeader",
        defaultMessage: "IF:",
    },
    thenHeader: {
        id: "components.ScriptModal.expressionComponent.thenHeader",
        defaultMessage: "THEN:",
    },
    elseHeader: {
        id: "components.ScriptModal.expressionComponent.elseHeader",
        defaultMessage: "ELSE:",
    },
    value: {
        id: "components.ScriptModal.expressionComponent.value",
        defaultMessage: "Value",
    },
});

export default messages;
