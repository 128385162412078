import { IconButton, styled, css } from "@mui/material";

import { ButtonPosition } from "./interfaces";

export const PositionedIconButton = styled(IconButton)<{
    position: ButtonPosition;
    forceShow?: boolean;
    bgColor?: string;
}>`
    position: absolute !important;
    top: ${({ position }) => (position.includes("bottom") ? "calc(100% - 10px)" : "")};
    bottom: ${({ position }) => (position.includes("top") ? "calc(100% - 10px)" : "")};
    left: ${({ position }) => (position.includes("right") ? "calc(100% - 10px)" : "")};
    right: ${({ position }) => (position.includes("left") ? "calc(100% - 10px)" : "")};
    transition: opacity 0.3s ease-in-out;
    background-color: ${({ bgColor, theme }) =>
        bgColor ? (bgColor === "success" ? theme.palette.success.main : theme.palette.error.main) : "transparent"};
    border-radius: 25%;
    :focus {
        opacity: 1;
    }
    opacity: 0;
    z-index: 3;
    :hover {
        opacity: 1;
        background-color: ${({ bgColor, theme }) =>
            bgColor
                ? bgColor === "success"
                    ? theme.palette.success.light
                    : theme.palette.error.light
                : "transparent"};
    }
    ${({ forceShow }) =>
        forceShow
            ? css`
                  opacity: 1 !important;
                  pointer-events: auto !important;
              `
            : undefined}
`;

export const RelativeContainer = styled("div")<{ showIcons: boolean; margin?: string }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: ${({ margin }) => margin ?? ""};
`;
