/* eslint-disable no-nested-ternary */
import { useGetFeatureToggleValue } from "@ignite-analytics/feature-toggle";
import { debounce } from "@ignite-analytics/general-tools";
import { getDefaultAggConfig, getValidAggOption } from "@ignite-analytics/pivot-charts";
import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Slider,
    Stack,
    Switch,
    Typography,
} from "@mui/material";
import { isString } from "lodash";
import React, { ComponentProps, useEffect, useState } from "react";

import { SelectedRows } from "..";
import { useCAContext } from "../../CAContextProvider/hooks";
import { getConfigValue } from "../helpers";
import { ChartConfigTypes, FieldConfig, FieldsParent } from "../interfaces";
import messages from "../messages";

import { GenericRangeComponent } from "./RangeComponent";
import { GenericRangeComponentProps } from "./RangeComponent/interfaces";
import MultipleRangesComponent from "./RangeComponent/MultiRanges";

import { fm } from "@/contexts/intlContext";

interface Props {
    fieldConfig: FieldConfig | FieldsParent;
    aggIndex?: number;
    path?: (keyof ChartConfigTypes | number)[];
    labels?: SelectedRows[];
}

const ConfigField: React.FC<Props> = ({ fieldConfig, aggIndex = 0, path = [], labels }) => {
    const { openWidget: widget, updateChartConfigField: updateValue } = useCAContext();
    const config = widget?.chartConfiguration;
    const [excluded, setExcluded] = useState<string[]>([]);
    const [yAxis, setYAxis] = useState<string[]>([]);

    useEffect(() => {
        if (config && path && isString(path[0]) && path[0].includes("Right") && config.shareYAxisRight) {
            setYAxis(config.shareYAxisRight);
        }
        if (config && path && isString(path[0]) && path[0].includes("Left") && config.shareYAxisLeft) {
            setYAxis(config.shareYAxisLeft);
        }
    }, [config, path]);
    useEffect(() => {
        if (config && path && isString(path[0]) && path[0].includes("Right") && config.shareYAxisLeft) {
            const ex = config.shareYAxisLeft;
            setExcluded(ex);
        }
        if (config && path && isString(path[0]) && path[0].includes("Left") && config.shareYAxisRight) {
            const ex = config.shareYAxisRight;
            setExcluded(ex);
        }
    }, [config, path]);

    const getFeatureToggleValue = useGetFeatureToggleValue();

    // Config should always be initialized whenever this component is rendered
    if (!config) return null;
    const validAggOptions = getValidAggOption(config, aggIndex);
    const type = validAggOptions?.type || getDefaultAggConfig(aggIndex).type || "column";

    // Check that this field shouldn't be hidden.
    if (fieldConfig.include && fieldConfig.include.indexOf(type) === -1) return null;
    if (fieldConfig.exclude && fieldConfig.exclude.indexOf(type) !== -1) return null;
    if (fieldConfig.condition && !fieldConfig.condition(config, aggIndex, widget)) return null;

    /**
     * If fieldConfig has children, render children instead.
     * Functional React components do not currently support returning arrays, so wrapping in empty JSX tag.
     */

    if ("children" in fieldConfig)
        return (
            <>
                {(Object.keys(fieldConfig.children) as (keyof typeof fieldConfig.children)[]).map((key) => {
                    const childConfig = fieldConfig.children[key];
                    return childConfig ? (
                        <ConfigField
                            labels={labels}
                            key={key}
                            fieldConfig={childConfig}
                            aggIndex={aggIndex}
                            path={[...path, key]}
                        />
                    ) : null;
                })}
            </>
        );
    const value = getConfigValue(path, config);
    const ID_VALUE = `configField-${path.slice(-1)[0]}`;
    const FIELD_CONFIG_TEST_ID = `${ID_VALUE}Select`;

    const handleSubmit = (valueChange: string[]) => {
        updateValue(path, valueChange);
        return valueChange;
    };

    return (
        <Stack direction="row" px={0.5}>
            {fieldConfig.type === "select" && fieldConfig.items ? (
                <FormControl size="small" fullWidth>
                    <InputLabel id={`${fieldConfig.label.toString()}select_label`}>
                        {fieldConfig.label?.toString()}
                    </InputLabel>
                    <Select
                        labelId={`${fieldConfig.label.toString()}select_label`}
                        label={<Typography>{fieldConfig.label?.toString()}</Typography>}
                        value={value}
                        onChange={(e) => {
                            updateValue(path, e.target.value);
                        }}
                        data-testid={FIELD_CONFIG_TEST_ID}
                        name="fieldConfigPicker"
                        sx={{ mt: 0 }}
                    >
                        {fieldConfig.items
                            .filter((item) => {
                                if (!item.featureToggleKey) return true;
                                return getFeatureToggleValue(item.featureToggleKey);
                            })
                            .map((item) => (
                                <MenuItem
                                    key={item.text?.toString()}
                                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                                    value={item.value as any}
                                    data-testid={`${FIELD_CONFIG_TEST_ID}-${item.text.toString()}Option`}
                                >
                                    {item.text?.toString()}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
            ) : fieldConfig.type === "multiselect" ? (
                <FormControl size="small" fullWidth>
                    <InputLabel id={`${fieldConfig.label.toString()}multiselect_label`}>
                        {fieldConfig.label?.toString()}
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId={`${fieldConfig.label.toString()}multiselect_label`}
                        label={fieldConfig.label?.toString()}
                        value={yAxis}
                        renderValue={(selected) => {
                            if (selected.length === 0) {
                                return <em>{fm(messages.placeholderSelectYAxis)}</em>;
                            }

                            return selected.join(", ");
                        }}
                        name={`selecor${path[0]}`}
                        onChange={(e) => {
                            if (!e) return;

                            const rows = Array.isArray(e.target.value) ? [...e.target.value] : [e.target.value];
                            handleSubmit(rows);
                        }}
                        multiple
                    >
                        {labels &&
                            labels
                                .filter((label) => label && !excluded.includes(label.value))
                                .map((label) => (
                                    <MenuItem value={label.value} key={`key_${label.value}`}>
                                        {label.value}
                                    </MenuItem>
                                ))}
                    </Select>
                </FormControl>
            ) : fieldConfig.type === "checkbox" ? (
                <FormControlLabel
                    control={
                        <Switch
                            data-testid={`${ID_VALUE}Checkbox`}
                            checked={!!value}
                            onChange={(e) => {
                                updateValue(path, e.target.checked);
                            }}
                        />
                    }
                    label={<Typography sx={{ pl: 0.5 }}>{fieldConfig.label?.toString()}</Typography>}
                    labelPlacement="end"
                    sx={{ pl: 2 }}
                />
            ) : fieldConfig.type === "range" ? (
                <GenericRangeComponent
                    current={typeof value === "object" ? (value as ChartConfigTypes["range"]) : undefined}
                    label={fieldConfig.label}
                    onChange={debounce<GenericRangeComponentProps["onChange"]>(
                        (newRange) => updateValue(path, newRange),
                        200
                    )}
                    data-testid={`${ID_VALUE}Range`}
                />
            ) : fieldConfig.type === "ranges" ? (
                <MultipleRangesComponent
                    label={fieldConfig.label}
                    value={Array.isArray(value) ? (value as ChartConfigTypes["breaks"]) : undefined}
                    onChange={debounce<ComponentProps<typeof MultipleRangesComponent>["onChange"]>(
                        (newRanges) => updateValue(path, newRanges),
                        500
                    )}
                    data-testid={`${ID_VALUE}Ranges`}
                />
            ) : (
                <Stack direction="column" alignItems="stretch" sx={{ minWidth: "100%" }}>
                    <Typography variant="textSm">{fieldConfig.label?.toString()}</Typography>
                    <Slider
                        min={5}
                        max={30}
                        aria-label="fontRange"
                        value={typeof value === "number" || typeof value === "string" ? Number(value) : undefined}
                        onChange={(_, val) => updateValue(path, val)}
                        valueLabelDisplay="auto"
                    />
                </Stack>
            )}
        </Stack>
    );
};

export default ConfigField;
