import { ElasticField, ElasticFieldType, ElasticIndex } from "@ignite-analytics/elastic-fields";
import { CONDITIONAL_TYPE, Filter } from "@ignite-analytics/filters";
import { AggregationItem, ScriptField, SplitItem, ValueConfiguration } from "@ignite-analytics/pivot-ts";
import _ from "lodash";
import { IntlShape } from "react-intl";

import { isScriptRelation } from "./typeGuards";

import { removeFullPeriodFilters } from "@/lib/helpers/filters";
import { getShortDescriptionOfFilters } from "@/lib/helpers/stringMappings";
import globalMessages from "@/lib/messages/globalMessages";

const newGetLabel = (field: string, elasticFields: ElasticField[]) => {
    let elasticField = elasticFields.find((f) => f.field === field);
    if (!elasticField) {
        elasticField = elasticFields.find((f) => "labelField" in f && f.labelField === field);
    }
    return elasticField?.label ?? field;
};

export const getLabel = (
    intl: IntlShape,
    item:
        | ElasticField
        | AggregationItem
        | ScriptField
        | { field: string; elasticIndex?: string; type?: ElasticFieldType; nested?: string }
        | SplitItem
        | Filter
        | undefined,
    elasticFields: ElasticField[],
    dataTables: ElasticIndex[],
    _elasticIndex: string | undefined,
    tableName: string | undefined
): string => {
    if (!item) return "";
    const elasticIndex = ("elasticIndex" in item && item.elasticIndex) || _elasticIndex || undefined;
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const fm = intl.formatMessage;
    if ("filterType" in item && item.filterType === CONDITIONAL_TYPE) {
        return _.uniq(
            item.children.map((child) => getLabel(intl, child, elasticFields, dataTables, elasticIndex, tableName))
        ).join(` ${fm(globalMessages.or)} `);
    }
    if (!("field" in item)) return item.label ?? "Unknown";
    if (!item.field && "label" in item && item.label) return item.label;

    const filters = ("filters" in item && item.filters?.length && removeFullPeriodFilters(item.filters)) || [];
    const suffix = filters.length
        ? ` [${getShortDescriptionOfFilters(
              filters.map((f) => ({
                  ...f,
                  label: getLabel(intl, f, elasticFields, dataTables, elasticIndex, tableName),
              })),
              intl,
              elasticFields,
              dataTables,
              elasticIndex
          )}]`
        : "";

    if (item.field === "_id" && elasticIndex) return (tableName ?? elasticIndex) + suffix;

    const label = newGetLabel(item.field, elasticFields);
    return label + suffix;
};

/**
 * Works kind of like the `getLabel`, but also supports script fields and ValueConfigurations.
 * Requires scriptFields to be loaded first.
 */
export const labelHelper = (
    intl: IntlShape,
    item: Parameters<typeof getLabel>[1] | ScriptField | ValueConfiguration,
    scriptFields: ScriptField[],
    elasticFields: ElasticField[] | undefined,
    dataTables: ElasticIndex[],
    elasticIndex: string | undefined,
    tableName: string | undefined
) => {
    if (!item) return "";
    if ((item?.type === "script" || item?.type === "scripted_metric") && "label" in item && item.label)
        return item.label;
    if (isScriptRelation(item)) return scriptFields.find((o) => o.id === item.script)?.label ?? "Script";
    return getLabel(intl, item, elasticFields ?? [], dataTables, elasticIndex, tableName);
};
