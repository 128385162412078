import React from "react";

import { ConstrainedMemberExpression } from "@/components/ScriptModal/interfaces";
import { useParamsContext } from "@/components/ScriptModal/ParamsContext";
import { useLabel } from "@/hooks/useLabel";

interface Props {
    memberExpression: ConstrainedMemberExpression;
    elasticIndex: string;
}

const ParamPreview: React.FC<Props> = ({ memberExpression, elasticIndex }) => {
    const { params } = useParamsContext() ?? {};
    const param = params?.[memberExpression.property.name];
    const label = useLabel(param, elasticIndex);
    return <>{label}</>;
};

export default ParamPreview;
