import { Calendar, X as ClearOutlinedIcon, Hashtag as TagOutlinedIcon } from "@ignite-analytics/icons";
import { AggregatedFilter, COMPARATIVE_OPERATORS } from "@ignite-analytics/pivot-ts";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import React, { useEffect, useMemo, useState } from "react";

import { EMPTY_ARRAY, OPERATOR_OPTIONS } from "./constants";
import { dayJsLocale, isValidAggregatedFilter } from "./helpers";
import { useValueAggDescriptors } from "./hooks";
import { IncompleteAggregatedFilter, OperatorTypes } from "./interfaces";
import messages from "./messages";

import { typeToIcon } from "@/components/ScriptModal/constants";
import { useCAContext } from "@/containers/CustomAnalysisPage/CustomAnalysis/CAContextProvider/hooks";
import { fm } from "@/contexts/intlContext";

interface Props {
    aggregatedFilter?: AggregatedFilter;
    onChange: (aggregatedFilter: AggregatedFilter) => void;
    onRemove: () => void;
}

const AggregatedFilterItem: React.FC<Props> = ({ aggregatedFilter: canonicalAggregatedFilter, onChange, onRemove }) => {
    const [state, _setState] = useState<IncompleteAggregatedFilter>(canonicalAggregatedFilter ?? { operator: ">" });
    useEffect(
        function updateStateFromProps() {
            _setState(canonicalAggregatedFilter ?? { operator: ">" });
        },
        [canonicalAggregatedFilter]
    );
    const { openWidget } = useCAContext();
    const { valueConfigurations = EMPTY_ARRAY } = openWidget ?? {};
    const setState = (newState: IncompleteAggregatedFilter) => {
        _setState(newState);
        if (isValidAggregatedFilter(newState)) onChange(newState);
    };
    const valueLabels = useValueAggDescriptors();
    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === "Enter") {
            if (isValidAggregatedFilter(state)) onChange(state);
        }
    };
    const handleBlur = () => {
        if (isValidAggregatedFilter(state)) onChange(state);
    };

    const isOperator = (op: any): op is OperatorTypes => COMPARATIVE_OPERATORS.includes(op);

    const valueAggOptions = useMemo(
        () =>
            valueConfigurations
                .map((valueAgg, aggIndex) => [valueAgg, aggIndex] as const)
                .map(([valueAgg, aggIndex]) => {
                    const TypeIcon = typeToIcon[valueAgg.type];
                    return (
                        <MenuItem key={aggIndex} value={aggIndex}>
                            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                <TypeIcon fontSize="inherit" />
                                <Typography variant="textSm">
                                    {valueLabels?.filter((labelTuple) => labelTuple[1] === valueAgg.uuid)[0][0]}
                                </Typography>
                            </Stack>
                        </MenuItem>
                    );
                }),
        [valueConfigurations, valueLabels]
    );
    return (
        <Stack minWidth="40vw" direction="row" alignItems="center" justifyContent="space-between" pt="xs" spacing={1}>
            <FormControl fullWidth>
                <InputLabel id="leftagg-value-select-label">{fm(messages.value)}</InputLabel>
                <Select
                    labelId="leftagg-value-select-label"
                    name="leftAggIndex"
                    value={state?.leftAggIndex}
                    onChange={(e) => {
                        const newAggIndex = Number(e.target.value);
                        setState({ ...state, leftAggIndex: newAggIndex });
                    }}
                    label={fm(messages.value)}
                    sx={{ minWidth: "30%" }}
                >
                    {valueAggOptions}
                </Select>
            </FormControl>

            <Stack alignSelf="flex-end">
                <Select
                    name="operator"
                    size="medium"
                    value={state?.operator}
                    onChange={(e) => {
                        const newOperator = e.target.value;
                        if (!isOperator(newOperator)) return;
                        setState({ ...state, operator: newOperator });
                    }}
                >
                    {COMPARATIVE_OPERATORS.map((op) => (
                        <MenuItem key={op} value={op}>
                            {OPERATOR_OPTIONS[op]}
                        </MenuItem>
                    ))}
                </Select>
            </Stack>
            {"rightConstant" in state && state.typeOfConstant === "number" && (
                <TextField
                    type="number"
                    name="rightConstantNumber"
                    value={state.rightConstant}
                    label={fm(messages.constantNumber)}
                    onChange={({ target: { value } }) => {
                        _setState({
                            ...state,
                            rightConstant: value ? Number(value) : undefined,
                            typeOfConstant: "number",
                        });
                    }}
                    inputProps={{ onBlur: handleBlur, onKeyDown: handleKeyPress }}
                />
            )}
            {"rightConstant" in state && state.typeOfConstant === "date" && (
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayJsLocale()}>
                    <DesktopDatePicker
                        key="rightConstantDate"
                        value={
                            typeof state.rightConstant === "number" ? dayjs(new Date(state.rightConstant)) : undefined
                        }
                        label={fm(messages.date)}
                        onChange={(dateValue) => {
                            if (!dateValue) return;
                            const parsedDate = Date.parse(dateValue?.toString());
                            setState({ ...state, rightConstant: parsedDate, typeOfConstant: "date" });
                        }}
                    />
                </LocalizationProvider>
            )}
            {!("rightConstant" in state) && (
                <FormControl fullWidth>
                    <InputLabel id="rightconst-value-select-label">{fm(messages.value)}</InputLabel>
                    <Select
                        labelId="rightconst-value-select-label"
                        name="leftAggIndex"
                        value={state?.rightAggIndex}
                        onChange={(e) => {
                            const value = Number(e.target.value);
                            const { rightAggIndex: _, rightConstant: __, ...rest } = state;
                            if (value >= 0) setState({ ...rest, rightAggIndex: value });
                            else
                                value === -1
                                    ? setState({ ...rest, rightConstant: undefined, typeOfConstant: "number" })
                                    : setState({ ...rest, rightConstant: undefined, typeOfConstant: "date" });
                        }}
                        label={fm(messages.value)}
                        sx={{ minWidth: "30%" }}
                    >
                        {[
                            <MenuItem value={-1} key="constant">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <TagOutlinedIcon />
                                    <Typography variant="textSm">{fm(messages.constantNumber)}</Typography>
                                </Stack>
                            </MenuItem>,
                            <MenuItem value={-2} key="date">
                                <Stack direction="row" alignItems="center" spacing={2}>
                                    <Calendar fontSize="small" />
                                    <Typography variant="textSm">{fm(messages.date)}</Typography>
                                </Stack>
                            </MenuItem>,
                            ...valueAggOptions,
                        ]}
                    </Select>
                </FormControl>
            )}
            <IconButton color="error" onClick={onRemove}>
                <ClearOutlinedIcon />
            </IconButton>
        </Stack>
    );
};

export default AggregatedFilterItem;
