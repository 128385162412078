import { Stringish } from "@ignite-analytics/general-tools";
import { Plus as Add, X as CloseOutlinedIcon } from "@ignite-analytics/icons";
import { Button, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import update from "immutability-helper";
import React, { useState } from "react";

import { GenericRangeComponent } from "..";

import { fm } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

export interface Props {
    value?: { from: number; to: number }[];
    label: Stringish;
    onChange: (ranges: { from: number; to: number }[]) => void;
}

interface ValidRange {
    from: number;
    to: number;
}

const MultipleRangesComponent: React.FC<Props> = ({ value, label, onChange }) => {
    const [ranges, setRanges] = useState<{ from: number | null; to: number | null }[]>(
        value && value.length ? value : []
    );

    const sendChange = (newRanges: typeof ranges) => {
        setRanges(newRanges);
        onChange(newRanges.filter((range): range is ValidRange => range.from !== undefined && range.to !== undefined));
    };

    return (
        <Stack direction="column" alignItems="stretch" sx={{ minWidth: "100%" }}>
            <Typography variant="textMd">
                <b>{label?.toString()}</b>
            </Typography>
            {ranges.map((range, i) => {
                const key = i;
                return (
                    <Stack key={key} direction="row">
                        <GenericRangeComponent
                            placeholders={[fm(globalMessages.from)?.toString(), fm(globalMessages.to)?.toString()]}
                            onChange={({ min: from, max: to }) =>
                                sendChange(update(ranges, { [i]: { $set: { from, to } } }))
                            }
                            current={{ min: range.from, max: range.to }}
                        />
                        <Tooltip title={fm(globalMessages.removeButton)}>
                            <IconButton onClick={() => sendChange([...ranges.slice(0, i), ...ranges.slice(i + 1)])}>
                                <CloseOutlinedIcon />
                            </IconButton>
                        </Tooltip>
                    </Stack>
                );
            })}
            <Button
                startIcon={<Add />}
                color="ghostGray"
                onClick={() => sendChange([...ranges, { from: null, to: null }])}
                size="small"
                fullWidth
            >
                {fm(globalMessages.addNew)}
            </Button>
        </Stack>
    );
};

export default MultipleRangesComponent;
