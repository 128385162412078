import { generators, RangeFilter } from "@ignite-analytics/filters";
import { Stringish } from "@ignite-analytics/general-tools";
import { Stack, TextField, Typography } from "@mui/material";
import React, { Component } from "react";

import { GenericRangeComponentProps } from "./interfaces";
import messages from "./messages";

import { fm } from "@/contexts/intlContext";

interface Props {
    label?: Stringish;
    placeholders: string[];
    onChange: (filter: RangeFilter) => void;
    field: string;
    chosenFilters: RangeFilter[];
    globalMin?: string | number | null;
    globalMax?: string | number | null;
}

interface State {
    min?: number;
    max?: number;
}

export class RangeFilterComponent extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        const chosenFilter = props.chosenFilters.find((item) => item.field === props.field);
        this.state = {
            min:
                chosenFilter && chosenFilter.min !== null
                    ? chosenFilter.min
                    : props.globalMin
                    ? Number(props.globalMin)
                    : undefined,
            max:
                chosenFilter && chosenFilter.max !== null
                    ? chosenFilter.max
                    : props.globalMax
                    ? Number(props.globalMax)
                    : undefined,
        };
    }

    componentDidMount() {
        const { min, max } = this.state;
        if (min || max) this.sendChange();
    }

    sendChange = () => {
        const { min = null, max = null } = this.state;
        const { onChange, field } = this.props;
        onChange(generators.generateRangeFilter(field, "float", min, max));
    };

    render() {
        const { min, max } = this.state;
        const { label, placeholders } = this.props;
        return (
            <Stack direction="row" spacing={2} alignItems="center">
                <Stack direction="column">
                    <Typography variant="textMd">
                        <b>{label ? fm(messages.filterFrom, { filter: label?.toString() }) : ""}</b>
                    </Typography>
                    <TextField
                        onChange={(e) => {
                            const value = e.target.value ? Number(e.target.value) : undefined;
                            this.setState({ min: value }, this.sendChange);
                        }}
                        value={min || undefined}
                        type="number"
                        placeholder={placeholders[0]}
                        size="small"
                    />
                </Stack>
                <Stack direction="column">
                    <Typography variant="textMd">
                        <b>{label ? fm(messages.filterTo, { filter: label?.toString() }) : ""}</b>
                    </Typography>
                    <TextField
                        onChange={(e) => {
                            const value = e.target.value ? Number(e.target.value) : undefined;
                            this.setState({ max: value }, this.sendChange);
                        }}
                        value={max || undefined}
                        type="number"
                        placeholder={placeholders[1]}
                        size="small"
                    />
                </Stack>
            </Stack>
        );
    }
}

export default RangeFilterComponent;

// Like the RangeFilterComponent, but less complicated (can be used when you don't need a filter, only min and max)
export const GenericRangeComponent: React.FC<GenericRangeComponentProps> = ({
    current,
    label,
    onChange,
    placeholders,
}) => {
    const { min, max } = current || { min: null, max: null };
    return (
        <RangeFilterComponent
            label={label}
            onChange={({ min: newMin, max: newMax }) => onChange({ min: newMin, max: newMax })}
            placeholders={placeholders || Array(2).fill(fm(messages.auto))}
            field="none"
            chosenFilters={[generators.generateRangeFilter("none", "float", min, max)]}
        />
    );
};
