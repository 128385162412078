import { arrayHelpers } from "@ignite-analytics/general-tools";
import { aggConfigFields, ChartConfig, defaultConfig } from "@ignite-analytics/pivot-charts";
import { Labelled, ValueConfiguration } from "@ignite-analytics/pivot-ts";
import { Stack } from "@mui/material";
import { IntlShape } from "react-intl";

import ConfigField from "./ConfigField";
import { tableAggConfigFields } from "./fields";
import { ChartConfigTypes, FieldConfig, FieldsParent } from "./interfaces";
import messages from "./messages";

import { staticFormatMessage } from "@/contexts/intlContext";

export const formatMeasureUnit = (unit: number | "auto" | "date"): string => {
    const siUnits = [
        { value: "date", symbol: staticFormatMessage(messages.measureUnitDate) },
        { value: "auto", symbol: staticFormatMessage(messages.measureUnitAuto) },
        { value: 1e-2, symbol: staticFormatMessage(messages.measureUnitPercent) },
        { value: 1, symbol: staticFormatMessage(messages.measureUnitOne) },
        { value: 1e3, symbol: staticFormatMessage(messages.measureUnitThousand) },
        { value: 1e6, symbol: staticFormatMessage(messages.measureUnitMillion) },
        { value: 1e9, symbol: staticFormatMessage(messages.measureUnitBillion) },
    ];
    const candidate = siUnits.find((candidateUnit) => candidateUnit.value === unit);
    return candidate ? candidate.symbol : "";
};

/**
 * Makes it possible to get config values several levels deep using a configKey with `.` separators
 */
export const getConfigValue = <T extends keyof ChartConfigTypes>(
    path: (keyof ChartConfigTypes | number)[],
    config: ChartConfig | typeof defaultConfig
) => path.reduce<any>((v, key) => v && v[key], config) as ChartConfigTypes[T];

export const getYAxisTitles = (aggregations: Labelled<ValueConfiguration>[], shareYAxis = false) =>
    aggregations.length > 1 && !shareYAxis
        ? aggregations.map((item) => item.label)
        : arrayHelpers
              .range(aggregations.length)
              .map((i) => getConfigValue<"title">(["optionsPerAgg", i, "yAxis", "title"], defaultConfig));

export const isFieldConfig = <T extends {}>(arg: T): arg is T & (FieldConfig | FieldsParent) =>
    "children" in arg || "label" in arg;

export const getTableConfigField = (
    intl: IntlShape,
    measureUnit: number | "auto" | undefined | "date",
    aggIndex: number
) => {
    const fields = tableAggConfigFields(intl, measureUnit);
    return (
        <Stack direction="column" pt={2} spacing={1}>
            {(Object.keys(fields) as (keyof typeof fields)[]).map((field) => {
                const fieldConfig = fields[field];
                return (
                    fieldConfig &&
                    isFieldConfig(fieldConfig) && (
                        <ConfigField
                            key={field}
                            fieldConfig={fieldConfig}
                            aggIndex={aggIndex}
                            path={["optionsPerAgg", aggIndex, field]}
                        />
                    )
                );
            })}
        </Stack>
    );
};

export const getChartConfigField = (
    intl: IntlShape,
    measureUnit: number | "auto" | undefined | "date",
    aggIndex: number,
    valueConfigurations: ValueConfiguration[] | undefined
) => {
    const value = valueConfigurations?.[aggIndex];
    const allowDateUnits =
        (value?.type === "date" && value.aggregation !== "value_count" && value.aggregation !== "cardinality") ||
        value?.type === "script" ||
        value?.type === "scripted_metric";
    const fields = aggConfigFields(intl, measureUnit, allowDateUnits);
    return (
        <Stack direction="column" pt={2} spacing={1}>
            {(Object.keys(fields) as (keyof typeof fields)[]).map((field) => {
                const fieldConfig = fields[field];
                return (
                    fieldConfig &&
                    isFieldConfig(fieldConfig) && (
                        <ConfigField
                            key={field}
                            fieldConfig={fieldConfig}
                            aggIndex={aggIndex}
                            path={["optionsPerAgg", aggIndex, field]}
                        />
                    )
                );
            })}
        </Stack>
    );
};
