import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

/**
 * Skeleton component for custom analysis module
 */

const SimpleSkeleton = () => <Skeleton variant="rounded" animation="wave" height="100%" width="100%" />;

const CustomAnalysisSkeleton: React.FC = () => (
        <Stack direction="row" spacing={1} p={1} sx={{ width: 1, height: "80vh", justifyContent: "start" }}>
            <Grid
                direction="column"
                spacing={1}
                justifyContent="space-between"
                sx={{ justifyContent: "start", height: 1, width: 28 / 100 }}
            >
                <Box sx={{ marginBottom: "2px", width: 1, height: "10%" }}>
                    <SimpleSkeleton />
                </Box>
                <Box sx={{ width: 1, height: "43%", marginBottom: "2px" }}>
                    <SimpleSkeleton />
                </Box>
                <Box sx={{ height: "15%", marginBottom: "2px", width: 1 }}>
                    <SimpleSkeleton />
                </Box>
                <Box sx={{ width: 1, height: "15%", marginBottom: "2px" }}>
                    <SimpleSkeleton />
                </Box>
                <Box sx={{ height: "15%", marginBottom: "2px", width: 1 }}>
                    <SimpleSkeleton />
                </Box>
            </Grid>
            <Grid direction="column" sx={{ justifyContent: "start", height: 1, width: 52 / 100 }}>
                <SimpleSkeleton />
            </Grid>
            <Grid sx={{ height: 1, width: 20 / 100 }}>
                <SimpleSkeleton />
            </Grid>
        </Stack>
    );

export default CustomAnalysisSkeleton;
