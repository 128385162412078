import { ElasticField, ElasticFieldType, useElasticFields } from "@ignite-analytics/elastic-fields";
import { fromEntries, getEntries, HoverContextProvider, isNotNullish } from "@ignite-analytics/general-tools";
import { X as CloseIcon } from "@ignite-analytics/icons";
import {
    BucketScriptRelation,
    ScriptedMetricRelation,
    ScriptField,
    ScriptParam,
    ValueConfiguration,
} from "@ignite-analytics/pivot-ts";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import {
    Alert,
    Autocomplete,
    Box,
    CardActions,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import * as Sentry from "@sentry/react";
import { AxiosError } from "axios";
import jsep from "jsep";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import ExpressionComponent from "./ExpressionComponent";
import { serializeExpressionTree } from "./helpers";
import { ExpressionDraft, isSupportedExpression } from "./interfaces";
import MathematicalPreview from "./MathematicalPreview";
import messages from "./messages";
import ParamsContextProvider from "./ParamsContext";

import { BUCKET_MODE, LINE_MODE, typeToIcon, WEIGHTED_MODE } from "@/components/ScriptModal/constants";
import { safeFormatMessage } from "@/containers/CustomAnalysisPage/CustomAnalysis/ApplyFieldsContainer/helpers";
import { isAggOption } from "@/containers/CustomAnalysisPage/CustomAnalysis/ApplyFieldsContainer/ValueField/helpers";
import { CAContext } from "@/containers/CustomAnalysisPage/CustomAnalysis/CAContextProvider";
import { fm, staticFormatMessage } from "@/contexts/intlContext";
import { useCurrentDepartment } from "@/entities/departments";
import { useCreateScriptAction, useDeleteScriptAction, useUpdateScriptAction } from "@/entities/scriptFields";
import globalMessages from "@/lib/messages/globalMessages";

interface Props {
    onClose: () => void;
    elasticIndex: string | undefined;
    open: boolean;
    item?: ScriptField;
}
type TabType = typeof BUCKET_MODE | typeof LINE_MODE | typeof WEIGHTED_MODE;

const ScriptModal: React.FC<Props> = ({ item, elasticIndex, open, onClose }) => {
    const deleteScript = useDeleteScriptAction({ id: item?.id ?? NaN });
    const updateScript = useUpdateScriptAction({ id: item?.id ?? NaN });
    const createScript = useCreateScriptAction();
    const department = useCurrentDepartment();
    const [label, setLabel] = useState(item?.label ?? "");
    const [mode, setMode] = useState(item?.type || "script");
    const [tabMode, setTabMode] = useState<TabType>(BUCKET_MODE);
    const [error, setError] = useState<{ type: "error" | "warning"; msg: string } | undefined>(undefined);
    const elasticFields = useElasticFields(elasticIndex, true);

    const alreadySetField =
        item && "forEach" in item && item.forEach ? item.forEach.replace(".keyword", "") : undefined;
    const [weightElasticField, setWeightElasticField] = useState<ElasticField | undefined>();
    const [weightAggregator, setWeightAggregator] = useState(
        (item?.type === "script" && item.forEachAggregation) || null
    );

    const [expressionTree, setExpressionTree] = useState<ExpressionDraft | "error">(null);
    const [params, setParams] = useState<Record<string, ScriptParam>>({});
    const [saveLoading, setSaveLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);

    const expression = useMemo(
        () => (expressionTree !== "error" ? serializeExpressionTree(expressionTree ?? null) : "null"),
        [expressionTree]
    );

    const findGlobalTypesInValueConfiguration = (valueConfiguration: ValueConfiguration) => {
        if (elasticFields && "field" in valueConfiguration && valueConfiguration.field) {
            return elasticFields?.find((es) => es?.field === valueConfiguration.field)?.globalTypeKey;
        }
        return null;
    };

    useEffect(
        function setInitialTabMode() {
            if (mode === "scripted_metric") setTabMode(LINE_MODE);
            else if (weightAggregator) setTabMode(WEIGHTED_MODE);
            else setTabMode(BUCKET_MODE);
        },
        [elasticFields, mode, weightAggregator]
    );

    useEffect(
        function setInitialWeightElasticField() {
            if (!elasticFields || !alreadySetField) return;
            const _weightElasticField = elasticFields?.find((elasticField) => elasticField.field === alreadySetField);
            if (_weightElasticField) setWeightElasticField(_weightElasticField);
        },
        [elasticFields, alreadySetField]
    );

    useEffect(
        function setInitialConfig() {
            try {
                setParams(fromEntries(item?.valueConfigurations.map((v) => [v.key, v]) ?? []));
                const _expressionTree = (item?.expression && jsep(item?.expression)) || null;
                if (!isSupportedExpression(_expressionTree)) throw Error();
                setExpressionTree(_expressionTree);
            } catch (err) {
                Sentry.captureException(err, { tags: { app: "analysis-app" } });
                setExpressionTree("error");
            }
        },
        [item]
    );

    // If the FunctionBox is in a custom analysis context we need to communicate changes to that context.
    // **NB**: The FunctionBox is not always opened in a custom analysis context
    const { updateWidget } = useContext(CAContext) || {};

    const onDelete = () => {
        if (!item?.id) return;
        setDeleteLoading(true);
        updateWidget?.({
            valueConfigurations: (prevs) => prevs.filter((prev) => !("script" in prev) || prev.script !== item.id),
        });
        deleteScript({ service: "dashboards" })
            .finally(() => setDeleteLoading(false))
            .catch((reason: AxiosError) => {
                if (reason?.response?.data) {
                    const errorMsg = `${Object.values(reason.response.data as Record<string, string>).join(
                        ", "
                    )}. If you are editing a widget, remember to save the widget before trying to delete any connected script.`;
                    setError({ type: "error", msg: errorMsg });
                }
            });
    };

    const save = () => {
        if (!department || !elasticIndex) return;
        if (!label || label.length === 0) {
            setError({ type: "warning", msg: staticFormatMessage(messages.missingTitleMsg) });
        }

        // Only include these fields if we have both a field and an aggregation
        const forEachFields =
            weightAggregator !== null && weightElasticField !== undefined
                ? {
                      forEachAggregation: weightAggregator,
                      forEach: weightElasticField.field,
                      forEachType: weightElasticField.type,
                  }
                : {
                      forEachAggregation: null,
                      forEach: null,
                      forEachType: null,
                  };

        setSaveLoading(true);
        const result: Omit<ScriptField, "id"> = {
            ...item,
            department: department.id,
            label,
            elasticIndex,
            ...forEachFields,
            valueConfigurations: getEntries(params)
                .filter(([key]) => expression.includes(`params.${key}`))
                .map(([key, param]) => ({ ...param, key })),
            type: mode,
            expression,
        };
        const updateValueConfigurationFromScript = (
            valueConfig: ValueConfiguration & { uuid: string },
            script: ScriptField
        ): ValueConfiguration & { uuid: string } => {
            if (!("script" in valueConfig)) {
                return valueConfig;
            }
            if (valueConfig.script !== script.id || valueConfig.type === script.type) {
                return valueConfig;
            }
            return script.type === "scripted_metric"
                ? { ...valueConfig, type: "scripted_metric", filters: [], aggregation: "avg" }
                : { ...valueConfig, type: "script" };
        };
        const id = item && item.id;
        const promise =
            id === undefined
                ? createScript(result, { service: "dashboards" }).then((res) => {
                      if (!res) return;
                      const rel: BucketScriptRelation | ScriptedMetricRelation =
                          res.type === "scripted_metric"
                              ? { type: res.type, script: res.id, filters: [], aggregation: "avg" }
                              : { type: res.type, script: res.id };
                      updateWidget?.({
                          valueConfigurations: { $push: [{ ...rel, uuid: uuidv4() }] },
                      });
                      track("Created Script", {
                          scriptType: tabMode,
                          hasConditional: expression.includes("?"),
                          containsScript: result.valueConfigurations.some((vc) => vc.type === "script"),
                          globalTypesUsed: result.valueConfigurations
                              .map(findGlobalTypesInValueConfiguration)
                              .filter(isNotNullish),
                      });

                      return res;
                  })
                : updateScript({ ...result, id }, { service: "dashboards" }).then((res) => {
                      if (!res) return;
                      updateWidget?.({
                          valueConfigurations: (prevs) =>
                              prevs.map((prev) => updateValueConfigurationFromScript(prev, res)),
                      });
                      track("Updated Script", {
                          scriptType: tabMode,
                          hasConditional: expression.includes("?"),
                          containsScript: result.valueConfigurations.some((vc) => vc.type === "script"),
                          globalTypesUsed: result.valueConfigurations
                              .map(findGlobalTypesInValueConfiguration)
                              .filter(isNotNullish),
                      });
                      return res;
                  });

        promise
            .then(() => {
                setSaveLoading(false);
                onClose();
            })
            .catch((reason) => {
                setSaveLoading(false);
                // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                setError({ type: "error", msg: `Failed to save script with reason: ${reason}` });
            });
    };
    const setTab = (tab: TabType) => {
        const newMode = tab === LINE_MODE ? "scripted_metric" : "script";
        setTabMode(tab);
        setMode(newMode);
        if (tab === WEIGHTED_MODE) {
            setWeightElasticField(elasticFields?.find((elasticField) => elasticField.field === alreadySetField));
            setWeightAggregator(item?.forEachAggregation ?? "sum");
        } else {
            setWeightElasticField(undefined);
            setWeightAggregator(null);
        }
    };

    // This container is passed into the dialog from material Ui so the filter menu is not rendered behind the dialog
    const containerRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={containerRef}>
            <Dialog
                container={containerRef.current}
                open={open}
                onClose={onClose}
                maxWidth="xl"
                fullWidth
                sx={{ p: 2 }}
            >
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h5" fontWeight={600}>
                            {fm(messages.addScript)}
                        </Typography>
                        <IconButton onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <Tabs variant="fullWidth" onChange={(_e, tab) => setTab(tab as TabType)} value={tabMode}>
                            {[
                                { name: fm(messages.bucketMode), id: BUCKET_MODE },
                                { name: fm(messages.metricsMode), id: LINE_MODE },
                                { name: fm(messages.weightedMode), id: WEIGHTED_MODE },
                            ].map((t) => (
                                <Tab key={t.id} value={t.id} label={t.name} />
                            ))}
                        </Tabs>
                        <Typography variant="h6" fontWeight={600}>
                            {fm(messages.buildYourScript)}
                        </Typography>

                        {expressionTree !== "error" ? (
                            <ParamsContextProvider
                                params={params}
                                setParams={setParams}
                                isLineScript={mode === "scripted_metric"}
                            >
                                <HoverContextProvider>
                                    {elasticIndex && (
                                        <ExpressionComponent
                                            onChange={setExpressionTree}
                                            expression={expressionTree}
                                            level={0}
                                            forcedType="number"
                                            vertical={false}
                                            elasticIndex={elasticIndex}
                                        />
                                    )}

                                    <Stack direction="column">
                                        <Typography variant="textMd" fontWeight={600}>
                                            {fm(messages.mathematicalPreview)}
                                        </Typography>
                                        <Paper elevation={0} variant="outlined" sx={{ p: 2 }}>
                                            <Stack direction="row" justifyContent="center">
                                                {elasticIndex && (
                                                    <MathematicalPreview
                                                        level={0}
                                                        expression={expressionTree}
                                                        elasticIndex={elasticIndex}
                                                    />
                                                )}
                                            </Stack>
                                        </Paper>
                                    </Stack>
                                </HoverContextProvider>
                            </ParamsContextProvider>
                        ) : (
                            <Typography variant="textXs">{fm(messages.unableToParse)} </Typography>
                        )}
                        {weightAggregator && mode === "script" && (
                            <Stack direction="column" spacing={2}>
                                <Stack direction="row" spacing={2} alignItems="center">
                                    <FormControl fullWidth>
                                        {/* TODO: Is it correct to allow to weight by fields of any type here? */}
                                        {(!alreadySetField || weightElasticField) && (
                                            <Autocomplete
                                                id="weight-by-label"
                                                options={elasticFields ?? []}
                                                value={weightElasticField}
                                                renderInput={(inputParams) => (
                                                    <TextField {...inputParams} label={fm(messages.weightBy)} />
                                                )}
                                                renderOption={(props, option) => {
                                                    const TypeIcon = typeToIcon[option.type as ElasticFieldType];

                                                    return (
                                                        <Box component="li" {...props}>
                                                            <TypeIcon fontSize="small" />
                                                            <Typography variant="textMd">{option.label}</Typography>
                                                        </Box>
                                                    );
                                                }}
                                                onChange={(_, value) => {
                                                    setWeightElasticField(value ?? undefined);
                                                }}
                                            />
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth sx={{ maxWidth: "50%" }}>
                                        <InputLabel id="weight-aggregator-label">
                                            {fm(messages.weightAggregator)}
                                        </InputLabel>
                                        <Select
                                            labelId="weight-aggregator-label"
                                            label={fm(messages.weightAggregator)}
                                            name="weightAggregator"
                                            value={weightAggregator}
                                            onChange={(e) => {
                                                const { value } = e.target;
                                                if (isAggOption(value)) {
                                                    setWeightAggregator(value);
                                                }
                                            }}
                                        >
                                            {(["sum", "avg", "min", "max"] as const).map((agg) => (
                                                <MenuItem key={agg} value={agg}>
                                                    {safeFormatMessage(agg)}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>
                                <FormHelperText>{fm(messages.weightByExplanation)}</FormHelperText>
                            </Stack>
                        )}
                        <CardActions>
                            <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ minWidth: "100%" }}
                            >
                                <TextField
                                    name="label"
                                    type="text"
                                    onChange={(e) => setLabel(e.target.value)}
                                    value={label}
                                    label={staticFormatMessage(messages.functionScriptName)}
                                    placeholder={staticFormatMessage(messages.functionNamePH)}
                                    fullWidth
                                />
                                <Stack direction="row">
                                    {(!item?.id || item.id < 0) && (
                                        <LoadingButton
                                            variant="contained"
                                            loading={saveLoading}
                                            disabled={deleteLoading || !label.length}
                                            color="primary"
                                            onClick={() => save()}
                                        >
                                            {staticFormatMessage(messages.createFunctionScript)}
                                        </LoadingButton>
                                    )}
                                    {item?.id && item.id > 0 && (
                                        <Stack direction="row" spacing={2}>
                                            <LoadingButton
                                                variant="contained"
                                                sx={{ backgroundColor: "error.main" }}
                                                onClick={onDelete}
                                                loading={deleteLoading}
                                                disabled={saveLoading}
                                            >
                                                {staticFormatMessage(globalMessages.deleteButton)}
                                            </LoadingButton>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                onClick={() => save()}
                                                loading={saveLoading}
                                                disabled={deleteLoading || !label.length}
                                            >
                                                {staticFormatMessage(globalMessages.saveButton)}
                                            </LoadingButton>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        </CardActions>
                    </Stack>
                </DialogContent>
            </Dialog>
            {error && (
                <Snackbar
                    open={!!error}
                    autoHideDuration={8000}
                    onClose={() => setError(undefined)}
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{ maxWidth: "40vw" }}
                >
                    <Alert severity={error.type}>{error.msg}</Alert>
                </Snackbar>
            )}
        </div>
    );
};

export default ScriptModal;
