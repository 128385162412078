import { defineMessages } from "react-intl";

const messages = defineMessages({
    selectDateRangePlaceholder: {
        id: "components.filter.dateFilterComponent.selectDateRangePlaceholder",
        defaultMessage: "Select date range",
    },
    auto: {
        id: "components.filter.dateFilterComponent.auto",
        defaultMessage: "Auto",
    },
    filterFrom: {
        id: "lib.filter.minFilter",
        defaultMessage: "{filter} - from",
    },
    filterTo: {
        id: "lib.filter.maxFilter",
        defaultMessage: "{filter} - to",
    },
});

export default messages;
