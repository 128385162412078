import { createDetailResource, createEntityContext, createListResource } from "@ignite-analytics/entities";
import { ScriptField } from "@ignite-analytics/pivot-ts";
import { useEffect } from "react";

const listCreateResource = createListResource<ScriptField>`analysis/script-fields/`;

const detailResource = createDetailResource<ScriptField>`/analysis/script-fields/${(params) => params.id}/`;

export const {
    Provider: ScriptFieldContextProvider,
    useMatches: useMatchingScriptFields,
    useObject: useScriptField,
    useCreateAction: useCreateScriptAction,
    useUpdateAction: useUpdateScriptAction,
    useDeleteAction: useDeleteScriptAction,
    useListAction: useGetAllScriptsAction,
    useThisContext: useScriptFieldContext,
} = createEntityContext(listCreateResource, detailResource, { interchangableTypes: true, name: "Script fields" });

export const useScriptsForDashboard = (dashboardId: number | undefined) => {
    const listAction = useGetAllScriptsAction();
    useEffect(() => {
        if (!dashboardId || Number.isNaN(dashboardId)) return;
        window.__currentlyFetchingDashboardScripts = true;
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        listAction({
            getParams: { dashboard: dashboardId },
            cache: true,
            service: "dashboards",
        }).then(() => {
            window.__currentlyFetchingDashboardScripts = undefined;
        });
    }, [dashboardId, listAction]);
    return useScriptFieldContext().listObjs;
};

export const useScriptWithoutFetching = (id: number) => useScriptFieldContext().detailObjs[id];
