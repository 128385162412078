import { ElasticField } from "@ignite-analytics/elastic-fields";
import { populateFilters } from "@ignite-analytics/filters";
import { ArrowLeft, X as CloseIcon, Trash as DeleteIcon, FloppyDisk as SaveIcon } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from "@mui/material";
import { AxiosError } from "axios";
import { isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";

import { useCAContext } from "../CAContextProvider/hooks";
import { EditableAnalysisWidget } from "../interfaces";
import messages from "../messages";

import WidgetHasChangedDialog from "./WidgetHasChangedDialog";

import CreateWidgetModal from "@/components/CreateWidgetModal";
import { useDeleteWidget, useUpdateWidget } from "@/components/Widgets/services";
import { fm } from "@/contexts/intlContext";
import globalMessages from "@/lib/messages/globalMessages";

interface IErrorData {
    detail?: string;
}

interface Props {
    widget: EditableAnalysisWidget;
    fieldsInIndex: ElasticField[] | undefined;
    setPopupError: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const EditWidgetTopBar: React.FC<Props> = ({ widget, fieldsInIndex, setPopupError }) => {
    const [openWidgetChangedDialog, setOpenWidgetChangedDialog] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [saveLoading, setSaveLoading] = useState(false);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const navigate = useNavigate();
    const updateExistingWidget = useUpdateWidget(widget.customDashboard);
    const deleteWidget = useDeleteWidget(widget.customDashboard);

    const { defaultDashboardId: dashboardId } = useCAContext();
    const initialWidgetRef = useRef<EditableAnalysisWidget>();

    useEffect(() => {
        if (!initialWidgetRef.current && widget) {
            initialWidgetRef.current = widget;
        }
    }, [widget]);

    const hasWidgetChanged = (currentWidget: EditableAnalysisWidget, initialWidget: EditableAnalysisWidget) => {
        const initialWidgetWithPopulatedFilters = {
            ...initialWidget,
            filters: populateFilters(initialWidget.filters, fieldsInIndex ?? []),
        };
        return !isEqual(currentWidget, initialWidgetWithPopulatedFilters);
    };

    const handleExceptions = (error: AxiosError) => {
        if (error.response) {
            switch (error.response.status) {
                case 403: {
                    setPopupError(fm(messages.permissionDenied)?.toString());
                    break;
                }
                default: {
                    const errorData: IErrorData = error.response.data ?? {};
                    if (errorData.detail) {
                        setPopupError(errorData.detail?.toString());
                    } else {
                        setPopupError(fm(messages.genericError).toString());
                    }
                    break;
                }
            }
        }
    };

    const handleBackToDashboard = () => {
        if (widget && initialWidgetRef.current) {
            if (hasWidgetChanged(widget, initialWidgetRef.current)) {
                setOpenWidgetChangedDialog(true);
            } else {
                navigate(`/dashboard/overview/${dashboardId}/`, {
                    state: { scrollToWidgetId: widget.id },
                });
            }
        }
    };

    const handleSaveExistingWidget = () => {
        if (widget) {
            if (widget.id) {
                setSaveLoading(true);
                updateExistingWidget(widget)
                    .then(() => {
                        track("Widget edited in CA microapp", { widget });
                        navigate(`/dashboard/overview/${dashboardId}/`, {
                            state: { scrollToWidgetId: widget.id },
                        });
                    })
                    .catch((err: AxiosError) => {
                        if (err.response) {
                            handleExceptions(err);
                        } else {
                            setPopupError(fm(messages.updateError)?.toString());
                        }
                    })
                    .finally(() => {
                        setSaveLoading(false);
                    });
            } else {
                setOpenCreateModal(true);
            }
        } else {
            setPopupError(fm(messages.updateError)?.toString());
        }
    };

    const handleDeleteExistingWidget = () => {
        if (widget) {
            setDeleteLoading(true);
            deleteWidget(widget)
                .then(() => {
                    track("Widget deleted in CA microapp", { widget });
                    navigate(`/dashboard/overview/${dashboardId}/`, {
                        state: { scrollToWidgetId: widget.id },
                    });
                })
                .catch((err: AxiosError) => {
                    if (err.response) {
                        handleExceptions(err);
                    } else {
                        setPopupError(fm(messages.deleteError)?.toString());
                    }
                })
                .finally(() => {
                    setDeleteLoading(false);
                });
        } else {
            setPopupError(fm(messages.deleteError)?.toString());
        }
    };

    return (
        <>
            <Box>
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Button color="ghostGray" size="medium" startIcon={<ArrowLeft />} onClick={handleBackToDashboard}>
                        {fm(messages.backToDashboard)}
                    </Button>
                    <Stack direction="row" spacing={1}>
                        {widget.id && (
                            <LoadingButton
                                loading={deleteLoading}
                                disabled={saveLoading}
                                size="medium"
                                startIcon={<DeleteIcon />}
                                variant="text"
                                color="inherit"
                                onClick={() => setDeleteDialogOpen(true)}
                            >
                                {fm(globalMessages.delete)}
                            </LoadingButton>
                        )}
                        <LoadingButton
                            loading={saveLoading}
                            disabled={deleteLoading}
                            size="medium"
                            startIcon={<SaveIcon />}
                            variant="contained"
                            color="primary"
                            onClick={handleSaveExistingWidget}
                        >
                            {fm(globalMessages.saveButton)}
                        </LoadingButton>
                    </Stack>
                </Stack>
            </Box>
            {/* Widget has changed Dialog */}
            <WidgetHasChangedDialog
                open={openWidgetChangedDialog}
                onClose={() => setOpenWidgetChangedDialog(false)}
                onContinue={() => {
                    navigate(`/dashboard/overview/${dashboardId}/`, {
                        state: { scrollToWidgetId: widget?.id },
                    });
                }}
            />
            {/* Delete widget confirmation dialog */}
            <Dialog open={deleteDialogOpen} onClose={setDeleteDialogOpen}>
                <DialogTitle>
                    <Stack direction="row" alignItems="center" justifyContent="space-between">
                        <Typography variant="h4">{fm(messages.deleteWidget)}</Typography>
                        <IconButton onClick={() => setDeleteDialogOpen(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="textMd">{fm(messages.deleteWidgetConfirmation)}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="text" color="inherit" onClick={() => setDeleteDialogOpen(false)}>
                        {fm(globalMessages.cancelButton)}
                    </Button>
                    <LoadingButton
                        loading={deleteLoading}
                        startIcon={<DeleteIcon />}
                        variant="contained"
                        color="error"
                        sx={{ color: (theme) => theme.palette.background.paper }}
                        onClick={handleDeleteExistingWidget}
                    >
                        {fm(globalMessages.delete)}
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {/* Create new analysis modal */}
            {openCreateModal && (
                <CreateWidgetModal
                    open={openCreateModal}
                    newWidget={widget}
                    onClose={() => setOpenCreateModal(false)}
                />
            )}
        </>
    );
};

export default EditWidgetTopBar;
