import { ApolloProvider, createGraphqlClient } from "@ignite-analytics/graphql-utilities";
import { getLocale } from "@ignite-analytics/locale";
import { getDesignTokens } from "@ignite-analytics/theme";
import { track } from "@ignite-analytics/track";
import { createTheme, CssBaseline, ThemeProvider as MuiThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider as ReactQueryClientProvider } from "@tanstack/react-query";
import { createBrowserHistory } from "history";
import React, { useEffect } from "react";
import { Router } from "react-router-dom";
import { CompatRouter, Route, Routes } from "react-router-dom-v5-compat";

import SessionContextProvider from "./contexts/SessionContextProvider";
import { EntitiesContextProvider } from "./entities";
import { errorLink } from "./errorLink";
import { useSetBreadcrumbs } from "./hooks/useSetBreadcrumbs";
import { getCurrentDomain } from "./lib/getCurrentDomain";
import { MicroAppMountValues } from "./types";

import { FatalErrorBoundary } from "@/components/ErrorBoundries/FatalErrorBoundary";
import { InfoMessage } from "@/components/InfoMessage";
import ApplicationPage from "@/containers";
import { InfoMessageProvider } from "@/contexts/InfoMessageProvider";
import { IntlContext } from "@/contexts/intlContext";
import { useOnUnhandledRejectionReporter } from "@/hooks/useOnUnhandledRejectionReporter";

const uri =
    getCurrentDomain() === "ignite"
        ? (process.env.REACT_APP_GRAPHQL_ROUTER_IGNITE_URL as string)
        : (process.env.REACT_APP_GRAPHQL_ROUTER_IGNITEPROCUREMENT_URL as string);

const graphqlClient = createGraphqlClient(uri, errorLink);
const queryClient = new QueryClient();

const App: React.FC<MicroAppMountValues> = ({
    sessionContext,
    history = createBrowserHistory(),
    locale = getLocale(),
    theme = "ignite-riddle",
    path: _path,
}) => {
    const path = _path || "/";
    useEffect(() => {
        track("Analysis: App loaded");
    }, []);
    useOnUnhandledRejectionReporter();
    useSetBreadcrumbs();
    return (
        <IntlContext locale={locale}>
            <FatalErrorBoundary>
                <CssBaseline />
                <ReactQueryClientProvider client={queryClient}>
                    <ApolloProvider client={graphqlClient}>
                        <MuiThemeProvider theme={createTheme(getDesignTokens(theme, "light"))}>
                            <SessionContextProvider sessionContext={sessionContext}>
                                <EntitiesContextProvider>
                                    <InfoMessageProvider>
                                        <Router history={history}>
                                            <CompatRouter>
                                                <Routes>
                                                    <Route path={`${path}*`} element={<ApplicationPage />} />
                                                    <Route
                                                        path="/analytics/dashboard/:dashboardId/edit-widget/:widgetId*"
                                                        element={<ApplicationPage />}
                                                    />
                                                </Routes>
                                            </CompatRouter>
                                        </Router>
                                        <InfoMessage />
                                    </InfoMessageProvider>
                                </EntitiesContextProvider>
                            </SessionContextProvider>
                        </MuiThemeProvider>
                    </ApolloProvider>
                </ReactQueryClientProvider>
            </FatalErrorBoundary>
        </IntlContext>
    );
};

export default App;
