import { useDebounce } from "@ignite-analytics/general-tools";
import { Stack, TextField, Typography } from "@mui/material";
import { Header } from "@tanstack/react-table";
import React, { useEffect } from "react";

import { PivotTableRow } from "../../interfaces";

type Props = {
    headerName: string;
    defaultHeaderName: string;
    editable?: boolean;
    valueAggregationIndex: number;
    onHeaderChange?: (value: string, defaultName: string, valueAggregationIndex: number) => void;
    header: Header<PivotTableRow, unknown>;
};
export const AggregationValueHeader: React.FC<Props> = ({
    headerName,
    defaultHeaderName,
    editable,
    valueAggregationIndex,
    onHeaderChange,
    header,
}) => {
    const [isHeaderFocused, setIsHeaderFocused] = React.useState(false);
    const [headerValue, setHeaderValue] = React.useState(headerName);

    useEffect(() => {
        setHeaderValue(headerName);
    }, [headerName]);

    const handleHeaderNameChange = React.useCallback(
        (value: string) => {
            onHeaderChange && onHeaderChange(value, defaultHeaderName, valueAggregationIndex);
        },
        [defaultHeaderName, onHeaderChange, valueAggregationIndex]
    );

    const debouncedHandleHeaderNameChange = useDebounce(handleHeaderNameChange, 500);

    if (editable && onHeaderChange && valueAggregationIndex !== undefined && isHeaderFocused) {
        return (
            <Stack width={header.getSize()}>
                <TextField
                    autoFocus
                    variant="standard"
                    value={headerValue}
                    onChange={({ target: { value } }) => {
                        setHeaderValue(value);
                        debouncedHandleHeaderNameChange(value);
                    }}
                    style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    fullWidth
                    onBlur={() => setIsHeaderFocused(false)}
                    sx={{
                        "& .MuiInputBase-input": {
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                        },
                    }}
                />
            </Stack>
        );
    }
    return (
        <Typography
            width={header.getSize()}
            style={{ overflow: "hidden", textOverflow: "ellipsis", cursor: "text" }}
            onClick={() => setIsHeaderFocused(true)}
        >
            {headerValue}
        </Typography>
    );
};
