import { uncacheElasticFields } from "@ignite-analytics/elastic-fields";
import { DataSource } from "@ignite-analytics/filters";
import { fromEntries, useDebounce } from "@ignite-analytics/general-tools";
import React, { useContext, useMemo } from "react";

import { useEntityEventListener } from "@/contexts/EntityEventChangeContext";
import { useGetManyDataTablesQuery } from "@/generated/client";

const AvailableIndicesContext = React.createContext<{ availableIndices: DataSource[] | undefined | null } | null>(null);

const AvailableIndicesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { result, refetch } = useGetManyDataTablesQuery({});

    const availableIndices = useMemo(() => {
        if (result.type === "error") return null;
        if (result.type !== "success") return undefined;

        return result.data.entities.map<DataSource>((dataTable) => ({
            name: dataTable.elasticIndex,
            label: dataTable.name,
            globalTypeKey: dataTable.globalTypeKey ?? undefined,
        }));
    }, [result]);

    useEntityEventListener("DataTable", (event) => {
        if (event.type === "CREATED" || event.type === "DELETED") {
            refetch();
        }
    });

    const debouncedUncacheElasticFieldsForAvailableIndices = useDebounce(() => {
        if (!availableIndices) {
            return;
        }
        availableIndices.forEach((index) => {
            uncacheElasticFields(index.name);
        });
    }, 1000);

    useEntityEventListener("DataColumn", () => {
        debouncedUncacheElasticFieldsForAvailableIndices();
    });

    const values = useMemo(() => ({ availableIndices }), [availableIndices]);

    return <AvailableIndicesContext.Provider value={values}>{children}</AvailableIndicesContext.Provider>;
};

/**
 * @returns List of ElasticIndex objects if they have been loaded, otherwise it returns undefined
 */
export const useAllElasticIndices = () => {
    const ctx = useContext(AvailableIndicesContext);
    if (ctx === null) throw Error("useAllElasticIndices called outside an AvailableIndicesContext");
    return ctx.availableIndices;
};

export const useElasticIndicesLabelMap = () =>
    fromEntries(useAllElasticIndices()?.map((index) => [index.name, index.label]) ?? []);

export default AvailableIndicesProvider;
